import React, { useEffect, useState } from "react";
import "./style.css";
import InvestorNotVerifiedBox from "../../Components/InvestorNotVerifiedBox/InvestorNotVerifiedBox";
import UserProfileInputs from "../../Components/UserProfileInputs/UserProfileInputs";
import SupportBox from "../../Components/SupportBox/SupportBox";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AccountSettings from "../../Components/AccountSettings/AccountSettings";
import { clientApi } from "../../Utils/AxiosCall";
import PageHeader from "../../Components/Components/Molecule/PageHeader/PageHeader";
import HeaderLabelTwo from "../../Components/Components/Atom/HeaderLabel/HeaderLabelTwo";
import headerIcon from "../../Assets/images/person_edit-profile.svg";
import checkIcon from "../../Assets/images/check-profile.svg";
import mailIcon from "../../Assets/images/mail-fill.svg";
import IdInformation from "./Components/IdInformation";
import AddressCard from "./Components/AddressCard";
import ContactInformation from "./Components/ContactInformation";
import SecurityCard from "./Components/SecurityCard";
import EmailsAndNotifications from "./Components/EmailsAndNotifications";
import AccountCard from "./Components/AccountCard";

const ProfileSettingsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const investorId = localStorage.getItem("investorId");
  const [profile, setProfile] = useState({});


  const fetchProfile = async () => {
    const { data } = await clientApi.get(
      `/api/investor/profile`
    );
    console.log(data)
    setProfile(data);
    localStorage.setItem("investorId", data.userId);
    localStorage.setItem("investorFirstName", data.firstName);
    localStorage.setItem("2FAStatus", data.twoFactorAuthenticationEnabled);
    localStorage.setItem("investorStatus", data.investorStatus);
  };

  useEffect(() => {

    fetchProfile();
  }, [investorId]);
  const VeriffStatus = ({data}) => {
    return (
      <div style={{display:"flex", flexDirection:"row", gap:"8px", alignItems:"center"}}>
        <div>{data?.investorStatus === "VERIFIED" ? "Verified" : "Not Verified"}</div>
        <div>{data?.investorStatus === "VERIFIED" ? <img src={checkIcon} alt="icon"/> : ""}</div>
      </div>
    );
  };
  const SubComponent = ({data}) => {
    
    return (
      <>
        <HeaderLabelTwo HeaderLabel="User ID" data={data?.userId||"-"}/>
        <HeaderLabelTwo HeaderLabel="User status" defaultValue="0" data={<VeriffStatus data={data} /> || "-"} />
      </>
    );
  };
  return (
    <>
    <div >
    <PageHeader title="Profile" image={headerIcon} subComponent={<SubComponent data={profile} />} />
    <div style={{display:"flex",justifyContent:"center"}}>
       <div className="page-width-control">
          <div style={{height:"32px",borderBottom:"1px solid #24242426"}}></div>
          <div style={{height:"48px"}}></div>
          <div style={{display:"flex",flexDirection:"row",gap:"118px"}} >
            <div style={{display:"flex",flexDirection:"column",gap:"48px",width:"100%",maxWidth:"634px"}}>
             <IdInformation data={profile}  />
             <AddressCard data={profile}/>
             <ContactInformation data={profile}/>
             <SecurityCard data={profile} callBack={fetchProfile} />
             <EmailsAndNotifications profile={profile} callBack={fetchProfile}/>
             <AccountCard/>
            </div>
            <div style={{background:""}}>
             <div className="support-card">
              <div style={{display:"flex",flexDirection:"column",gap:"16px"}}>
                <div className="support-card-header">Support</div>
                <div className="support-card-text">If you want to ask us a question about our services, then we would like to hear from you. Please contact our Customer Support Service.</div>
                <div style={{display:"flex",flexDirection:"row",gap:"8px",alignItems:"center"}}>
                  <img  src={mailIcon} alt="icon"/>
                  <a className="support-card-link" href="mailto:support@fucapital.io" style={{textDecoration:"none"}}>support@fucapital.io</a>

                </div>

              </div>

             </div>
            </div>
          </div>
       </div>
       </div>  
    </div>
    </>
  );
};

export default ProfileSettingsPage;
