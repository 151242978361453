// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .modal-xl {
    --bs-modal-width: 900px;
}
.two-fa-modal-header {
font-size: 24px;
font-weight: 700;
line-height: 36px;
text-align: left;
color: var(--secondary-color);
}
.two-fa-modal-label {
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
font-variation-settings: 'slnt' 0;
color: var(--secondary-color);
}
.two-fa-modal-qr-card{
border: 1px solid #2424240A;
border-radius: 3px;
padding: 32px;
display: flex;
flex-direction: row;
gap:48px;
align-items: center;
} 
.two-fa-modal-light-text{
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: center;
color: var(--font-color-1);
}
.two-fa-modal-light-text-bold{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: var(--secondary-color);
    }
 .tow-fa-optional-style{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--secondary-color);
    margin-top: 32px;
    cursor: pointer;
    border-bottom: 1px solid #2424240A;
 }   
 .tow-fa-optional-style:hover{
    
    border-bottom: 1px solid #161616B2;
    color: #161616B2;
 }`, "",{"version":3,"sources":["webpack://./src/Components/Modal2fa/style.css"],"names":[],"mappings":"CAAC;IACG,uBAAuB;AAC3B;AACA;AACA,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;AAChB,6BAA6B;AAC7B;AACA;AACA,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;AAChB,iCAAiC;AACjC,6BAA6B;AAC7B;AACA;AACA,2BAA2B;AAC3B,kBAAkB;AAClB,aAAa;AACb,aAAa;AACb,mBAAmB;AACnB,QAAQ;AACR,mBAAmB;AACnB;AACA;AACA,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,kBAAkB;AAClB,0BAA0B;AAC1B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,6BAA6B;IAC7B;CACH;IACG,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,6BAA6B;IAC7B,gBAAgB;IAChB,eAAe;IACf,kCAAkC;CACrC;CACA;;IAEG,kCAAkC;IAClC,gBAAgB;CACnB","sourcesContent":[" .modal-xl {\n    --bs-modal-width: 900px;\n}\n.two-fa-modal-header {\nfont-size: 24px;\nfont-weight: 700;\nline-height: 36px;\ntext-align: left;\ncolor: var(--secondary-color);\n}\n.two-fa-modal-label {\nfont-size: 16px;\nfont-weight: 400;\nline-height: 24px;\ntext-align: left;\nfont-variation-settings: 'slnt' 0;\ncolor: var(--secondary-color);\n}\n.two-fa-modal-qr-card{\nborder: 1px solid #2424240A;\nborder-radius: 3px;\npadding: 32px;\ndisplay: flex;\nflex-direction: row;\ngap:48px;\nalign-items: center;\n} \n.two-fa-modal-light-text{\nfont-size: 14px;\nfont-weight: 400;\nline-height: 20px;\ntext-align: center;\ncolor: var(--font-color-1);\n}\n.two-fa-modal-light-text-bold{\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 24px;\n    text-align: center;\n    color: var(--secondary-color);\n    }\n .tow-fa-optional-style{\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: center;\n    color: var(--secondary-color);\n    margin-top: 32px;\n    cursor: pointer;\n    border-bottom: 1px solid #2424240A;\n }   \n .tow-fa-optional-style:hover{\n    \n    border-bottom: 1px solid #161616B2;\n    color: #161616B2;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
