import React, { useState, useEffect } from "react";
import "./style.css";
import { Button, Input, message } from "antd";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { GetProfile, HandelError, clientApi } from "../../../Utils/AxiosCall";
import { IoAlertCircleOutline } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Modal2fa from "../../Modal2fa/Modal2fa";
import WithdrawalOnHold from "../../StatusModels/WithdrawalOnHold";
import { formatNumber } from "../../../Helper/HelperFunction";
import { FiCode } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
export default function WithdrawFunds() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("w");
  const [buttonStatus, setButtonStatus] = useState("ETHEREUM");
  const [buttonStatus2, setButtonStatus2] = useState("USDC");
  const [amount, setAmount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [ProfileData, setProfileData] = useState("");
  const [amountCalculated, setAmountCalculated] = useState("");
  const [withdrawAddress, setWithdrawAddress] = useState("");
  const [whithdrawRequstStatus, setWhithdrawRequstStatus] = useState(false);
  const [openConfirmWithdrawModal, setOpenConfirmWithdrawModal] =
    useState(false);
  const [withdawalOnHold, setWithdawalOnHold] = useState(false);
  const [twoFAModal, setTwoFAModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const CalculateWithdrawal = async () => {
    console.log("Calculate Withdrawal");
    console.log(amount);
    if (amount) {
      let amounts = parseFloat(amount);
      console.log(amounts);
      try {
        const response = await clientApi.post(
          "/api/investor/crypto-calculate-withdrawal",
          { amount: amounts, token: buttonStatus2 }
        );
        if (response.status === 200) {
          console.log(response.data);
          setAmountCalculated(response.data);
        }
      } catch (error) {
        HandelError(error);
      }
    }
  };
  const getProfile = async () => {
    try {
      const response = await GetProfile();
      if (response) {
        setProfileData(response);
        if (response?.holdWithdrawals) {
          return setWithdawalOnHold(true);
        }
        if (!response?.twoFactorAuthenticationEnabled) {
          setTwoFAModal(true);
        }
      }
    } catch (error) {
      HandelError(error);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    CalculateWithdrawal(); // Call the debounced function
  }, [amount, buttonStatus, buttonStatus2]);

  const RequstWithdrawal = async () => {
    try {
      const response = await clientApi.post(
        `/api/investor/crypto-request-withdrawal`,
        {
          address: withdrawAddress,
          amount: amount,
          estimatedTokenAmount: amountCalculated?.amount,
          network: buttonStatus,
          token: buttonStatus2,
        }
      );
      if (response.status === 200) {
        setWhithdrawRequstStatus(true);
        console.log(response.data);
        message.success("Withdrawal requested successful");
      }
    } catch (error) {
      message.error("Withdrawal requested unsuccessful");
      HandelError(error);
    }
  };
  useEffect(() => {
    if (id) {
      setOpenConfirmWithdrawModal(true);
      setOpenModal(false);
    }
  }, [id]);
  const Validate = async () => {
    if (!amount) {
      return message.error("Enter a valid amount !");
    }
    if (amountCalculated?.amount <= amountCalculated?.minAmount) {
      return message.error(
        `Minimum ${amountCalculated?.minAmount} need for withdrawal`
      );
    }
    if (withdrawAddress.length === 0) {
      return message.error(`Enter wallet address`);
    }
    await getProfile();
    setErrorMessage("");
    if (
      !ProfileData.holdWithdrawals &&
      ProfileData.twoFactorAuthenticationEnabled
    ) {
      setOpenModal(true);
    }
  };

  return (
    <div>
      <span className="font-700">To withdraw cryptocurrency</span>
      <div
        className="normal-font custom-padding-top-12"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <GoDotFill size={12} style={{ paddingRight: "4px" }} /> You can
          withdraw funds to your crypto wallet in USDT and USDC.
        </div>
        <div
          className="font-700"
          style={{ display: "flex", alignItems: "center" }}
        >
          <GoDotFill size={12} style={{ paddingRight: "4px" }} /> Please make
          sure to specify USDT or USDC on the Ethereum network or Polygon
          network (ERC20 standard tokens).
        </div>
        <div
          style={{ display: "flex", alignItems: "center", paddingTop: "24px" }}
        >
          <GoDotFill size={12} style={{ paddingRight: "4px" }} /> EUR balance
          will be automatically converted to USDT or USDC using the current
          market rate and a stable coin balance will de deposited into your
          wallet
        </div>
        <div
          style={{ display: "flex", alignItems: "center", paddingTop: "24px" }}
        >
          <GoDotFill size={12} style={{ paddingRight: "4px" }} /> Please note
          that transfer processing may take up to 2 business days but usually
          takes 1 business day.
        </div>
      </div>
      <div style={{ paddingTop: "24px" }}>
        <div className="">
          <div className="col-12 ">
            <Input
              disabled
              style={{ width: "100%" }}
              value={
                "Available funds " +
                `${
                  ProfileData
                    ? formatNumber(ProfileData.accounts.EUR.cashBalance)
                    : "0.00"
                }`
              }
              className="new_input new_input hight-56"
              placeholder="Available funds"
            />
          </div>
        </div>
        <div className="d-flex" style={{ paddingTop: "24px" }}>
          <div style={{ flex: 1 }}>
            <Button
              style={{ width: "50%", height: "52px" }}
              className={`${
                buttonStatus === "ETHEREUM"
                  ? "new_button_filled"
                  : "new_button_outline"
              }`}
              onClick={() => setButtonStatus("ETHEREUM")}
            >
              Ethereum
            </Button>
            <Button
              style={{ width: "50%", height: "52px" }}
              className={`${
                buttonStatus === "POLYGON"
                  ? "new_button_filled"
                  : "new_button_outline"
              }`}
              onClick={() => setButtonStatus("POLYGON")}
            >
              Polygon
            </Button>
          </div>
          <div style={{ width: "72px" }}></div>
          <div style={{ flex: 1 }}>
            <Button
              style={{ width: "50%", height: "52px" }}
              className={`${
                buttonStatus2 === "USDT"
                  ? "new_button_filled"
                  : "new_button_outline"
              }`}
              onClick={() => setButtonStatus2("USDT")}
            >
              USDT
            </Button>
            <Button
              style={{ width: "50%", height: "52px" }}
              className={`${
                buttonStatus2 === "USDC"
                  ? "new_button_filled"
                  : "new_button_outline"
              }`}
              onClick={() => setButtonStatus2("USDC")}
            >
              USDC
            </Button>
          </div>
        </div>
        <div
          className="d-flex"
          style={{
            paddingTop: "24px",
            flexDirection: "row",
            gap: "24px",
            width: "100%",
          }}
        >
          <div style={{ flex: 1 }}>
            <Input
              style={{ width: "100%" }}
              type="text"
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const inputValue = e.target.value;
                // Regular expression to match only numeric values
                const regex = /^[0-9]*$/;
                if (regex.test(inputValue)) {
                  setAmount(inputValue);
                }
              }}
              className="new_input hight-56"
              placeholder="Withdrawal Amount in EUR"
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FiCode size={24} />
          </div>
          <div style={{ flex: 1 }}>
            <Input
              type="text"
              disabled
              value={amount.length > 0 ? amountCalculated?.amount : ""}
              style={{ width: "100%" }}
              className="new_input hight-56"
              placeholder={`Amount to receive in ${
                buttonStatus2 === "USDC"
                  ? "USDC"
                  : buttonStatus2 === "USDT"
                  ? "USDT"
                  : ""
              }`}
            />
          </div>
        </div>
        <div style={{ paddingTop: "24px" }}>
          <div className="col-12 ">
            <Input
              style={{ width: "100%" }}
              value={withdrawAddress}
              onChange={(e) => {
                setWithdrawAddress(e.target.value);
              }}
              className="new_input hight-56"
              placeholder="Withdrawal address"
            />
          </div>
        </div>
        <div style={{ paddingTop: "24px" }}>
          <div className="col-12 ">
            <Button
              style={{ width: "164px" }}
              className="new_button_filled hight-56"
              onClick={() => Validate()}
            >
              Withdraw
            </Button>
            <span style={{ paddingLeft: "150px", color: "tomato" }}>
              {errorMessage}
            </span>
          </div>
        </div>
      </div>
      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        centered
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "0px",
        }}
        size="xl"
      >
        <div className="" style={{ padding: "48px" }}>
          <center>
            <h2 className="eb_garamond_font" style={{ marginBottom: "48px" }}>
              {" "}
              {!whithdrawRequstStatus
                ? "Withdraw Cryptocurrency"
                : "Confirm Withdrawal"}
            </h2>
          </center>
          {!whithdrawRequstStatus ? (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-12 custom-padding-bottom-12">
                      Network:
                    </div>
                    <div className="col-12">
                      <Input
                        disabled
                        style={{ width: "100%" }}
                        value={buttonStatus}
                        className="hight-56 new_input"
                        placeholder="Ethereum"
                      ></Input>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-12 custom-padding-bottom-12">
                      Token:
                    </div>
                    <div className="col-12">
                      <Input
                        disabled
                        style={{ width: "100%" }}
                        value={buttonStatus2}
                        className="hight-56 new_input"
                        placeholder="USDC"
                      ></Input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-12 custom-padding-bottom-12">
                      Amount to Withdraw
                    </div>
                    <div className="col-12">
                      <Input
                        disabled
                        style={{ width: "100%" }}
                        value={amount}
                        className="hight-56 new_input"
                        placeholder="$100.00"
                      ></Input>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-12 custom-padding-bottom-12">
                      Amount to Receive
                    </div>
                    <div className="col-12">
                      <Input
                        disabled
                        style={{ width: "100%" }}
                        value={amountCalculated?.amount}
                        className="hight-56 new_input"
                        placeholder="$100.00"
                      ></Input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 custom-padding-bottom-12">
                  Withdraw address:
                </div>
                <div className="col-12">
                  <Input
                    disabled
                    style={{ width: "100%" }}
                    className="hight-56 new_input "
                    value={withdrawAddress}
                    placeholder="Withdraw address"
                  ></Input>
                </div>
              </div>
              <div
                className="row custom-padding-top-24"
                style={{ color: "#EC441F" }}
              >
                <div className="col-12">
                  <IoAlertCircleOutline />
                  &nbsp; Please make sure that the selected network is the same
                  as where the funds are being deposited to.
                </div>
              </div>
              <div
                className="row custom-padding-top-24"
                style={{ color: "#EC441F" }}
              >
                <div className="col-12">
                  <IoAlertCircleOutline />
                  &nbsp; Please double check the selected token, address,
                  amount. Incorrect network or address might result in permanent
                  loss of funds!
                </div>
              </div>
              <div className="row custom-padding-top-24">
                <div className="col-md-6 pb-4 pb-md-0">
                  <Button
                    block
                    className="hight-56 new_button_outline"
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-md-6 pb-4 pb-md-0">
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    className="hight-56 new_button_filled"
                    onClick={() => RequstWithdrawal()}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-12">
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    className="hight-56 new_button_filled"
                    onClick={() => setOpenModal(false)}
                  >
                    Please confirm the transaction by clicking on the link sent
                    to your email
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
      <ConfirmWithdrawModal
        id={id}
        openConfirmWithdrawModal={openConfirmWithdrawModal}
        setOpenConfirmWithdrawModal={setOpenConfirmWithdrawModal}
      />
      {twoFAModal && <Modal2fa isOpen={twoFAModal} setIsOpen={setTwoFAModal} />}
      <WithdrawalOnHold
        isOpen={withdawalOnHold}
        setIsOpen={setWithdawalOnHold}
      />
    </div>
  );
}

const ConfirmWithdrawModal = ({
  id,
  openConfirmWithdrawModal,
  setOpenConfirmWithdrawModal,
}) => {
  const navigate = useNavigate();
  const [withdrawalDetails, setWithdrawalDetails] = useState({});
  const [WithdrawalConfirmed, setWithdrawalConfirmed] = useState(false);
  const [error, setError] = useState("");
  const [totp, setTotp] = useState("");
  const GetWithdrawalDeatils = async () => {
    setError("");
    try {
      const response = await clientApi.get(
        `/api/investor/public/withdrawal-details?withdrawal=${id}`
      );
      if (response.status === 200) {
        setWithdrawalDetails(response.data);
      }
    } catch (error) {
      HandelError(error);
    }
  };
  const CancelWithdrawal = async () => {
    setError("");
    try {
      const response = await clientApi.post(
        "/api/investor/public/crypto-cancel-withdrawal",
        { id: id }
      );
      if (response.status === 200) {
        message.success("cancellation request successfully");
        setOpenConfirmWithdrawModal(false);
      }
    } catch (error) {
      message.error("cancellation request unsuccessfully");
      HandelError(error);
    }
  };
  const ConfirmWithdrawal = async () => {
    setError("");
    try {
      if (!totp || totp.length < 6) {
        setError("Enter valid otp!");
        return;
      }
      const response = await clientApi.post(
        "/api/investor/public/crypto-confirm-withdrawal",
        { id: id, totp: totp }
      );
      if (response.status === 200) {
        message.success("Withdrawal successful");
        setWithdrawalConfirmed(true);
      }
    } catch (error) {
      message.error("Withdrawal unsuccessful");
      HandelError(error);
    }
  };
  useEffect(() => {
    if (id) {
      GetWithdrawalDeatils();
    }
  }, [id]);

  return (
    <>
      <Modal
        show={openConfirmWithdrawModal}
        onHide={() => setOpenConfirmWithdrawModal(false)}
        centered
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "0px",
        }}
        size="xl"
      >
        <div className="" style={{ padding: "48px" }}>
          <center>
            <h2 className="eb_garamond_font" style={{ marginBottom: "48px" }}>
              {" "}
              {!WithdrawalConfirmed
                ? "Confirm Withdrawal"
                : "Withdrawal Confirmation"}
            </h2>
          </center>
          {!WithdrawalConfirmed ? (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-12 custom-padding-bottom-12">
                      Network:
                    </div>
                    <div className="col-12">
                      <Input
                        disabled
                        style={{ width: "100%" }}
                        value={withdrawalDetails?.bankName}
                        className="hight-56 new_input"
                        placeholder="Ethereum"
                      ></Input>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-12 custom-padding-bottom-12">
                      Token:
                    </div>
                    <div className="col-12">
                      <Input
                        disabled
                        style={{ width: "100%" }}
                        value={withdrawalDetails?.swiftCode}
                        className="hight-56 new_input"
                        placeholder="USDC"
                      ></Input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row custom-padding-top-24">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-12 custom-padding-bottom-12">
                      Amount to Withdraw
                    </div>
                    <div className="col-12">
                      <Input
                        disabled
                        style={{ width: "100%" }}
                        value={withdrawalDetails?.amount}
                        className="hight-56 new_input"
                        placeholder="$100.00"
                      ></Input>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-12 custom-padding-bottom-12">
                      Amount to Receive
                    </div>
                    <div className="col-12">
                      <Input
                        disabled
                        style={{ width: "100%" }}
                        value={withdrawalDetails?.estimatedTokenAmount || 0.0}
                        className="hight-56 new_input"
                        placeholder="$100.00"
                      ></Input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row custom-padding-top-24">
                <div className="col-12 custom-padding-bottom-12">
                  Withdrawal details :
                </div>
                <div className="col-12">
                  <Input
                    disabled
                    style={{ width: "100%" }}
                    className="hight-56 new_input "
                    value={withdrawalDetails?.details}
                    placeholder="Withdraw address"
                  ></Input>
                </div>
              </div>
              <div className="row custom-padding-top-24">
                <div className="col-12 custom-padding-bottom-12">
                  Code from Authenticator App:
                </div>
                <div className="col-12">
                  <Input
                    maxLength={6}
                    style={{ width: "100%" }}
                    className="hight-56 new_input "
                    onChange={(e) => {
                      setTotp(e.target.value);
                    }}
                    value={totp}
                    placeholder="Enter 6-digit Code"
                  ></Input>
                </div>
              </div>
              <div className=" custom-padding-top-24 d-flex justfiy-items-center">
                {error && (
                  <span
                    className="custom-padding-bottom-12 custom-padding-top-12"
                    style={{ color: "tomato" }}
                  >
                    {error}
                  </span>
                )}
              </div>
              <div className="row ">
                <div className="col-md-6 pb-4 pb-md-0">
                  <Button
                    block
                    className="hight-56 new_button_outline"
                    onClick={() => CancelWithdrawal()}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-md-6 pb-4 pb-md-0">
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    className="hight-56 new_button_filled"
                    onClick={() => ConfirmWithdrawal()}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-12">
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    className="hight-56 new_button_filled"
                    onClick={() => {
                      setOpenConfirmWithdrawModal(false);
                      navigate("user/portfolio");
                    }}
                  >
                    Operation Successful. Click here to return to home page.
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
