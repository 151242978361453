import React from 'react';
import { Input } from 'antd';
const { TextArea } = Input; // Import TextArea

export default function SignUpInputBox({ label, isRequired, placeholder, onChange, fieldType, value, input, error, validationError, regexPattern, maxLength, rows,removeGap }) {
    // console.log(validationError);
    // Function to handle key press event with dynamic regex
    const handleKeyPress = (e) => {
        const regex = new RegExp(regexPattern);
        if (!regex.test(e.key)) {
            e.preventDefault();
        }
    };

    // Conditionally render Input or TextArea based on fieldType
    const renderInput = () => {
        if (fieldType === 'textarea') {
            return (
                <TextArea
                    placeholder={placeholder || ""}
                    className={`signUpInputBoxClass ${error?.error || validationError?.error ? "signUpInputBoxClassError" : ""}`}
                    value={value}
                    onChange={onChange}
                    maxLength={maxLength || ""}
                    rows={rows || 3} // Default to 3 rows if not specified
                />
            );
        } else {
            return (
                <Input
                    type={fieldType || "text"}
                    onKeyPress={regexPattern ? handleKeyPress : undefined}
                    placeholder={placeholder || ""}
                    className={`signUpInputBoxClass ${error?.error || validationError?.error ? "signUpInputBoxClassError" : ""}`}
                    value={value}
                    onChange={onChange}
                    maxLength={maxLength || ""}
                />
            );
        }
    };

    return (
        <div>
            {label && <div style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
                <span className='signUpLabelClass'>{label}</span>
                {isRequired && <span className='signUpLabelRequiredClass'>*</span>}
            </div>}
            {
                removeGap?<></>:<div style={{ height: "4px" }}></div>
            }
            <div className="">
                {renderInput()}
                {validationError?.message && <div className="error-message">{!validationError?.exists ? validationError?.message : ""}</div>}
            </div>
        </div>
    );
}