// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-divider {
  height:  24px;
}
.loan-input{
    height: 56px;
    font-size: 16px;
    min-width: 158px;
}

`, "",{"version":3,"sources":["webpack://./src/Pages/LoanDetails/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".card-divider {\n  height:  24px;\n}\n.loan-input{\n    height: 56px;\n    font-size: 16px;\n    min-width: 158px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
