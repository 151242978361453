// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deposit-container-body-data {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    justify-content: space-between;
    gap: 12px;
  }
  
  .deposit-container-body-data-bold{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--secondary-color);
  }
  .deposit-container-body-data-label{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--font-color-1);
  }
  .deposit-container-body-data-label-bold{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--secondary-color);
  }`, "",{"version":3,"sources":["webpack://./src/Pages/DepositAndWithdrawFunds/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe,EAAE,0CAA0C;IAC3D,8BAA8B;IAC9B,SAAS;EACX;;EAEA;IACE,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,6BAA6B;EAC/B;EACA;IACE,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,0BAA0B;EAC5B;EACA;IACE,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,6BAA6B;EAC/B","sourcesContent":[".deposit-container-body-data {\n    display: flex;\n    flex-wrap: wrap; /* Allows items to wrap to the next line */\n    justify-content: space-between;\n    gap: 12px;\n  }\n  \n  .deposit-container-body-data-bold{\n    font-family: 'Inter';\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 24px;\n    text-align: left;\n    color: var(--secondary-color);\n  }\n  .deposit-container-body-data-label{\n    font-family: 'Inter';\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: left;\n    color: var(--font-color-1);\n  }\n  .deposit-container-body-data-label-bold{\n    font-family: 'Inter';\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 24px;\n    color: var(--secondary-color);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
