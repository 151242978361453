import React, { useEffect, useState } from 'react'
import "./style.css"
import { Button, Card,Input } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import FirstLoanCard from '../../Components/LoanDetailsComponents/FirstLoanCard/FirstLoanCard';
import LoanState from '../../Components/LoanDetailsComponents/LoanState/LoanState';
import LoanDetailsCard from '../../Components/LoanDetailsComponents/LoanDetailsCard/LoanDetailsCard';
import LoanDetailsInvest from '../../Components/LoanDetailsComponents/LoanDetailsInvest/LoanDetailsInvest';
import LoanSchedulePayments from '../../Components/LoanDetailsComponents/LoanSchedulePayments/LoanSchedulePayments';
import { HandelError, clientApi } from '../../Utils/AxiosCall';
import LoanActivityHistory from '../../Components/LoanDetailsComponents/LoanActivityHistory/LoanActivityHistory';
import { StablecoinWarperFunction } from '../../Helper/HelperFunction';
import { RiArrowLeftLine } from "react-icons/ri";
import { useSelector } from 'react-redux';
export default function LoanDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const { id } = useParams();// id is the loan id
  const [toggleButton, setToggleButton] = useState(true)
  const [loanData, setLoanData] = useState("")
  const [accountSummaryData, setAccountSummaryData] = useState("")
  const [profileData, setProfileData] = useState("")
  const [isPrimary, setisPrimary] = useState(true)
      var currencyCode = useSelector(state => state.global.currencyCode);
  const GetSingleLoan=async ()=>{
     try {
         const response = await clientApi.post(`/api/investor/public/query-primary-market-single-loan`,{loanId:id})
         setLoanData(response.data)
     } catch (error) {
      HandelError(error);
     }
  }
  const GetAccountSummary=async ()=>{
    try {
        const response = await clientApi.post('/api/investor/account-summary', {currencyCode: currencyCode})
        setAccountSummaryData(response.data)
    } catch (error) {
     
    }
 }
 const GetProfile=async ()=>{
    try {
        const response = await clientApi.get(`/api/investor/profile`)
        setProfileData(response.data)
    } catch (error) {
     
    }
 
 }
  useEffect(() => {
    GetCallaAllFunction()
  }, [])
  const GetCallaAllFunction=()=>{
    GetProfile()
    GetSingleLoan()
    GetAccountSummary()
  }
  const AmountConverter = ( value ) => {
    const [data, setData] = useState(null);
  
    useEffect(() => {
        StablecoinWarperFunction(value).then(setData);
    }, [value]);
  
    if (data === null) return null; // or return a loading spinner
  
    return data;
  }
  return (
<>
      <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
           <div style={{width:"100%", maxWidth:"1700px",padding:"0px 48px"}}>
             <div style={{paddingTop:"48px",display:"flex",alignItems:"center",flexDirection:"row",gap:"8px"}}>
               <div style={{display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}} onClick={()=>navigate(-1)}>
               <RiArrowLeftLine size={24} />
               </div>
               <div className='eb_garamond_font'style={{fontSize:"40px",fontWeight:"400",lineHeight:"44px",color:"#242424"}} >
                Loan ID:&nbsp;{id}
               </div>
             </div>
             <div className='card-divider'></div>
  <FirstLoanCard principalIssued={loanData?.principalIssued} 
  currencySymbol={loanData?.currencySymbol} interestRatePercent={loanData?.interestRatePercent} 
  amountAvailable={loanData?.amountAvailable} termInDays={loanData?.termInDays} 
  loanStatus={loanData?.loanStatus} myInvestments={loanData?.myInvestments} 
  AmountConverter={AmountConverter}  
  />
   <div style={{width:"100%",height:"32px",borderBottom:"1px solid #24242426"}}></div>
   <div style={{paddingTop:"48px"}}>
   
   <div className="d-flex" style={{ flexDirection:"row",gap:"118px"}}>
    <div style={{width:"620px",display:"flex",flexDirection:"column",gap:"48px"}}>
      <div>
      <LoanDetailsCard data={loanData} AmountConverter={AmountConverter}/>
      </div>
        <div style={{height:"56px",display:"flex",flexDirection:"row",gap:"24px"}}>
          <div className={`${isPrimary ? "market-page-tabs-active" : "market-page-tabs"} `} style={{display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer",padding:"16px, 8px, 16px, 8px",width:"165px"}} onClick={() => setisPrimary(true)} >Schedule payments</div>
          <div className={`${!isPrimary ? "market-page-tabs-active" : "market-page-tabs"} `} style={{display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}} onClick={() => setisPrimary(false)}>Activity history</div>
      </div>
    </div>
    <div style={{width:"100%",maxWidth:"446px"}}>
        <LoanDetailsInvest holdInvesting={profileData?.holdInvesting} cashBalance={accountSummaryData?.cashBalance} 
        currencySymbol={accountSummaryData?.currencySymbol} loanId={id} 
        GetCallaAllFunction={GetCallaAllFunction} AmountConverter={AmountConverter} />
    </div>
</div>
   </div>
           </div>
      </div>
      <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",background:"white"}}>
      <div style={{width:"100%", maxWidth:"1700px"}}>
            {
              isPrimary ?
              <>
              <div style={{padding:"0px 0px",}} >
              <LoanSchedulePayments data={loanData?.paymentSchedule} currencySymbol={loanData?.currencySymbol} />
              </div>
              </>
              :
              <>
               <LoanActivityHistory data={loanData?.loanEvents} currencySymbol={loanData?.currencySymbol} />
              </>
            }
           </div>
      </div>
</>
  )
}
 