import React, { useState } from 'react'
import "./style.css"
import { HandelError, clientApi } from '../../../Utils/AxiosCall'
import { Button, Checkbox,message } from 'antd'
export default function UpdateReportsSettings({profile}) {
    console.log(profile)
    const [data, setData] = useState({
        receiveDailySummaryReport:profile.receiveDailySummaryReport?profile.receiveDailySummaryReport:false ,
        receiveMonthlySummaryReport: profile.receiveMonthlySummaryReport?profile.receiveMonthlySummaryReport:false,
        receiveNotInvestedFundsReminder: profile.receiveNotInvestedFundsReminder?profile.receiveNotInvestedFundsReminder:false,
        receiveWeeklySummaryReport: profile.receiveWeeklySummaryReport?profile.receiveWeeklySummaryReport:false
    })
    const [loader, setLoader] = useState(false)
    const UpdateReportSettings = async () => {
        try {
          setLoader(true)
          const response = await clientApi.post('/api/investor/change-reporting-settings', {
            receiveDailySummaryReport: data.receiveDailySummaryReport,
            receiveMonthlySummaryReport: data.receiveMonthlySummaryReport,
            receiveNotInvestedFundsReminder: data.receiveNotInvestedFundsReminder,
            receiveWeeklySummaryReport: data.receiveWeeklySummaryReport
          })
          if (response.status === 200) {
            console.log("It worked")
            message.success("updated successfully"); 
            setLoader(false)
          }
        } catch (error) {
          console.log(error)
          message.error("Ops! Try again.");
          setLoader(false)
          HandelError(error);
        }
      }
 const CancelFunction=()=>{
    setData({
        receiveDailySummaryReport:profile.receiveDailySummaryReport?profile.receiveDailySummaryReport:false ,
        receiveMonthlySummaryReport: profile.receiveMonthlySummaryReport?profile.receiveMonthlySummaryReport:false,
        receiveNotInvestedFundsReminder: profile.receiveNotInvestedFundsReminder?profile.receiveNotInvestedFundsReminder:false,
        receiveWeeklySummaryReport: profile.receiveWeeklySummaryReport?profile.receiveWeeklySummaryReport:false
    })
 }   
  return (
<div className='genaral-font-size-16'>
  <div className="col-12  " style={{paddingBottom:"20px",display:"flex",alignItems:"center"}} >
    <Checkbox  
      onChange={(e)=>setData({...data, receiveDailySummaryReport: !data.receiveDailySummaryReport})}
      checked={data.receiveDailySummaryReport} 
      style={{borderRadius:"0px" }}
    />
      <span style={{marginLeft:"12px"}} >Receive daily summary report</span>
  </div>
  <div className="col-12  " style={{paddingBottom:"20px",display:"flex",alignItems:"center"}}>
    <Checkbox 
      onChange={(e)=>setData({...data, receiveWeeklySummaryReport: !data.receiveWeeklySummaryReport})}
      checked={data.receiveWeeklySummaryReport}   
      style={{borderRadius:"0px" }}
    />
    <span style={{marginLeft:"12px"}} >Receive weekly summary report</span>
  </div>
  <div className="col-12 " style={{paddingBottom:"20px",display:"flex",alignItems:"center"}}>
    <Checkbox 
      onChange={(e)=>setData({...data, receiveMonthlySummaryReport: !data.receiveMonthlySummaryReport})}
      checked={data.receiveMonthlySummaryReport}  
      style={{borderRadius:"0px" }}
    />
    <span style={{marginLeft:"12px"}} >Receive monthly summary report</span>
  </div>
  <div className="col-12 " style={{paddingBottom:"20px",display:"flex",alignItems:"center"}}>
    <Checkbox 
      onChange={(e)=>setData({...data, receiveNotInvestedFundsReminder: !data.receiveNotInvestedFundsReminder})}
      checked={data.receiveNotInvestedFundsReminder}  
      style={{borderRadius:"0px" }}
    />
    <span style={{marginLeft:"12px"}} >Receive reminder about non invested funds</span>
  </div>
  <div className="es_flex " style={{ gap: 24 }}>
            <Button
             className="new_button_outline genaral-button-height"
             style={{minWidth:"123px"}}
              title="Cancel"
              onClick={() =>CancelFunction() }
            >Cancel</Button>
            <Button
            className="new_button_filled genaral-button-height"
            style={{minWidth:"123px"}}
              title="Save"
              loading={loader}
              onClick={()=>UpdateReportSettings()}
            >Update </Button>
          </div>
</div>
  )
}
