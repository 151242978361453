// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .investment-modal .ant-modal-content {
    padding: 0px !important;
    border-radius: 6px !important;
    box-shadow: 0px 32px 40px 0px #24242426;
    box-shadow: 0px 26px 32px -24px #24242459;
  }
  .investment-modal .ant-modal-root .ant-modal-mask {
    background-color:#242424BF !important;
}
 /* .modal-close-icon:hover {
  padding: 8px;
  gap: 0px;
  border-radius: 100%;
  opacity: 0;
  color: #161616B2;
} */

/* Pressed state */
/* .modal-close-icon:active {
  padding: 8px;
  gap: 0px;
  border-radius: 100%;
  opacity: 0;
  color: #24242426;
} */
.invest-modal-header{
font-family: Inter;
font-size: 24px;
font-weight: 700;
line-height: 36px;
text-align: left;
color: #242424;
padding-bottom: 16px;
}
.invest-modal-subheader{
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
color: #161616B2;
}
.invest-modal-subheader-value{
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #242424;
  }`, "",{"version":3,"sources":["webpack://./src/Components/PrimaryMarketTable/style.css"],"names":[],"mappings":";EACE;IACE,uBAAuB;IACvB,6BAA6B;IAC7B,uCAAuC;IACvC,yCAAyC;EAC3C;EACA;IACE,qCAAqC;AACzC;CACC;;;;;;GAME;;AAEH,kBAAkB;AAClB;;;;;;GAMG;AACH;AACA,kBAAkB;AAClB,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;AAChB,cAAc;AACd,oBAAoB;AACpB;AACA;AACA,kBAAkB;AAClB,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;AAChB,gBAAgB;AAChB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd","sourcesContent":["\n  .investment-modal .ant-modal-content {\n    padding: 0px !important;\n    border-radius: 6px !important;\n    box-shadow: 0px 32px 40px 0px #24242426;\n    box-shadow: 0px 26px 32px -24px #24242459;\n  }\n  .investment-modal .ant-modal-root .ant-modal-mask {\n    background-color:#242424BF !important;\n}\n /* .modal-close-icon:hover {\n  padding: 8px;\n  gap: 0px;\n  border-radius: 100%;\n  opacity: 0;\n  color: #161616B2;\n} */\n\n/* Pressed state */\n/* .modal-close-icon:active {\n  padding: 8px;\n  gap: 0px;\n  border-radius: 100%;\n  opacity: 0;\n  color: #24242426;\n} */\n.invest-modal-header{\nfont-family: Inter;\nfont-size: 24px;\nfont-weight: 700;\nline-height: 36px;\ntext-align: left;\ncolor: #242424;\npadding-bottom: 16px;\n}\n.invest-modal-subheader{\nfont-family: Inter;\nfont-size: 14px;\nfont-weight: 400;\nline-height: 20px;\ntext-align: left;\ncolor: #161616B2;\n}\n.invest-modal-subheader-value{\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 24px;\n  text-align: left;\n  color: #242424;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
