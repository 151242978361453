import React from 'react'
import './HeaderLabel.css'
export default function HeaderLabel({HeaderLabel, data,defaultValue, ...props}) {
  return (
    <div style={{display:"flex",flexDirection:"column"}}>
    <div className='header-label'>{HeaderLabel}</div>
    <div className='header-label-value'>{data ? data : (defaultValue !== undefined ? defaultValue : "")}</div>
</div> 
  )
}
