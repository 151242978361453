import React, { useEffect, useState } from 'react'
import './style.css';
import { Button, Input, message } from 'antd';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import { GetProfile, HandelError, clientApi } from '../../../Utils/AxiosCall';
import { Modal } from 'react-bootstrap';
import { FiChevronLeft, FiCode, FiCopy } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import QRCode from "react-qr-code";
import Modal2fa from '../../Modal2fa/Modal2fa';
import DepositOnHold from '../../StatusModels/DepositOnHold';
export default function DepositFunds() {
    const [buttonStatus, setButtonStatus] = useState("ETHEREUM")
    const [buttonStatus2, setButtonStatus2] = useState("USDC")
    const [amount, setAmount] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [amountCalculated, setAmountCalculated] = useState("")
    const [depositDetailes, setDepositDetailes] = useState("")
    const [genrateQRcode, setGenrateQRcode] = useState(false)
    const [profileData, setProfileData] = useState("")
    const CalculateDeposit = async (amount) => {
        console.log("Calculating Deposit")
        console.log(amount)
        setAmount(amount)
        if (amount) {
            let amounts = parseFloat(amount)
            console.log(amounts)
            try {
                const response = await clientApi.post('/api/investor/crypto-calculate-deposit', { amount: amounts, currency: buttonStatus2 })
                if (response.status === 200) {
                    console.log(response.data)
                    setAmountCalculated(response.data)
                }
            } catch (error) {
                console.log(error)
                HandelError(error);
            }

        }
    }
    useEffect(() => {
        CalculateDeposit(amount)
    }, [amount])
    useEffect(() => {
        CalculateDeposit(amount)
    }, [buttonStatus2])

    const GetDepositAddress = async () => {
        setDepositDetailes("")
        const investorId = localStorage.getItem("investorId");
        try {
            const response = await clientApi.post(`/api/investor/crypto-get-deposit-address`, {
                currency: buttonStatus2,
                memo: investorId,
                network: buttonStatus
            })
            console.log(response)
            if (response.status) {
                setDepositDetailes(response.data)
            }
        } catch (error) {

        }
    }
    const CloseModal = () => {
        if (genrateQRcode) {
            return setGenrateQRcode(false)
        }
        setDepositDetailes("")
        setOpenModal(false)
    }
    const OpenModal = async () => {
        try {
            await GetProfiles();
            if (!profileData.holdDeposits && profileData.twoFactorAuthenticationEnabled) {
                GetDepositAddress();
                setOpenModal(true);
            }

        } catch (error) {
            console.error("Error opening modal:", error);
            // Handle errors here
        }
    }

    const handleCopy = () => {
        if (depositDetailes?.address) {
            navigator.clipboard.writeText(depositDetailes?.address);
            message.success('Address copied to clipboard');
        } else {
            message.error('No address to copy');
        }
    };
    const [depositOnHoldModal, setDepositOnHoldModal] = useState(false)
    const [twoFAModal, setTwoFAModal] = useState(false)
    const GetProfiles = async () => {
        try {
            const response = await GetProfile();
            if (response) {
                setProfileData(response)
                if (response.holdDeposits) {
                    setDepositOnHoldModal(true);
                } else if (!response.twoFactorAuthenticationEnabled) {
                    setTwoFAModal(true);
                }
            }

        } catch (error) {
            // Handle errors here
        }
    }
    useEffect(() => {
        GetProfiles()
    }, [])

    return (
        <div>
            <span className='font-700 '>To deposit cryptocurrency</span>
            <div className='normal-font custom-padding-top-12' style={{ display: "flex", flexDirection: "column" }} >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                        <GoDotFill size={8} />
                    </div>
                    <div style={{ paddingLeft: "4px" }}>
                        You can add funds to your Investor’s account by transferring stable coins.
                    </div>
                </div>
                <div className='font-700' style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                        <GoDotFill size={8} />
                    </div>
                    <div style={{ paddingLeft: "4px" }}>
                        Each Investor gets their unique deposit address so we will be able to allocate your payment. Please make sure to transfer USDT or USDC tokens on Ethereum network or Polygon network (ERC20 standard tokens).
                    </div>
                </div>
                <div style={{ paddingTop: "24px", display: "flex", flexDirection: "row" }}>
                    <div>
                        <GoDotFill size={8} />
                    </div>
                    <div style={{ paddingLeft: "4px" }}>
                        Stable coins will be automatically converted to EUR using the current market rate and the EUR balance will be deposited into your investors account.
                    </div>
                </div>
                <div className='font-700' style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                        <GoDotFill size={8} />
                    </div>
                    <div style={{ paddingLeft: "4px" }}>
                        No deposit processing fees.
                    </div>
                </div>
                <div style={{ paddingTop: "24px", display: "flex", flexDirection: "row" }}>
                    <div>
                        <GoDotFill size={8} />
                    </div>
                    <div style={{ paddingLeft: "4px" }}>
                        Please note that transfer processing may take up to 2 business days but usually takes 1 business day.
                    </div>
                </div>
            </div>
            <div style={{ paddingTop: "24px" }}>
                <div className="d-flex">
                    <div style={{ flex: 1 }}>
                        <Button style={{ width: "50%", height: "52px" }} className={`${buttonStatus === "ETHEREUM" ? "new_button_filled" : "new_button_outline"}`} onClick={() => setButtonStatus("ETHEREUM")} >Ethereum</Button>
                        <Button style={{ width: "50%", height: "52px" }} className={`${buttonStatus === "POLYGON" ? "new_button_filled" : "new_button_outline"}`} onClick={() => setButtonStatus("POLYGON")} >Polygon</Button>
                    </div>
                    <div style={{ width: "72px" }}></div>
                    <div style={{ flex: 1 }}>
                        <Button style={{ width: "50%", height: "52px" }} className={`${buttonStatus2 === "USDT" ? "new_button_filled" : "new_button_outline"}`} onClick={() => setButtonStatus2("USDT")} >USDT</Button>
                        <Button style={{ width: "50%", height: "52px" }} className={`${buttonStatus2 === "USDC" ? "new_button_filled" : "new_button_outline"}`} onClick={() => setButtonStatus2("USDC")} >USDC</Button>
                    </div>
                </div>
                <div className="d-flex " style={{ paddingTop: "24px", flexDirection: "row", gap: "24px", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                        <Input type='number' onChange={(e) => {
                            setAmount(e.target.value);
                        }} style={{ width: "100%", height: "56px" }} className='new_input' placeholder={`Deposit Amount in ${buttonStatus2 === "USDC" ? "USDC" : buttonStatus2 === "USDT" ? "USDT" : ""}`} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <FiCode size={24} />
                    </div>
                    <div style={{ flex: 1 }} >
                        <Input disabled value={amount.length > 0 ? amountCalculated.amount : ""} style={{ width: "100%", height: "56px" }} className='new_input' placeholder='Deposit Amount in EUR' />
                    </div>
                </div>
                <div style={{ paddingTop: "24px" }}>
                    <div className="col-12 ">
                        <Button
                            style={{
                                height: "52px",
                                padding: "16px 48px",
                            }}
                            className="new_button_filled"
                            onClick={() => OpenModal()}
                        >
                            Show deposit address
                        </Button>
                    </div>
                </div>
            </div>

            <Modal show={openModal} onHide={() => CloseModal()} centered
                className="two-fa-modal"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "900px"
                }}
            >
                <div className="container" style={{ padding: "48px" }}>
                    <center><h2 className="eb_garamond_font" style={{ marginBottom: "46px" }}>Deposit Cryptocurrency</h2></center>
                    {!genrateQRcode ?
                        <>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-12 custom-padding-bottom-12">
                                            Network:
                                        </div>
                                        <div className="col-12">
                                            <Input style={{ width: "100%", }} disabled value={depositDetailes?.network} className='new_input hight-56' placeholder='Ethereum'  ></Input>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-12 custom-padding-bottom-12">
                                            Token:
                                        </div>
                                        <div className="col-12">
                                            <Input style={{ width: "100%", }} disabled className='new_input hight-56' value={depositDetailes?.currency} placeholder='USDC' ></Input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row custom-padding-top-24">
                                <div className="col-12 custom-padding-bottom-12">
                                    Deposit address:
                                </div>
                                <div className="col-8">
                                    <Input
                                        style={{ width: "100% ", }}
                                        disabled
                                        value={depositDetailes?.address}
                                        className='new_input hight-56'
                                        placeholder='Deposit Address'
                                        suffix={
                                            <Button
                                                type="text"
                                                onClick={handleCopy}
                                                icon={<FiCopy />}
                                            />
                                        }
                                    />
                                </div>
                                <div className="col md-1 d-flex justfiy-content-center align-items-center"> or </div>
                                <div className="col md-4 pt-0 pb-0"><Button className='hight-56 new_button_filled ' onClick={() => setGenrateQRcode(true)}>Generate QR Code</Button> </div>
                            </div>
                        </>
                        :
                        <>
                            <GenarteQRCode QRvalue={depositDetailes?.address} />
                        </>
                    }
                    <div className='custom-padding-top-24' style={{ display: "flex", flexDirection: "row", color: "#EC441F" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "12px" }}><IoAlertCircleOutline /></div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Minimum deposit amount is {depositDetailes?.minAmount || 0} {depositDetailes?.currency || "USDC"}</div>
                    </div>
                    <div className='custom-padding-top-24' style={{ display: "flex", flexDirection: "row", color: "#EC441F" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "12px" }}><IoAlertCircleOutline /></div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}> Only send {depositDetailes?.currency || "USDC"} on value {depositDetailes?.network || "Ethereum "} network to this address. Sending any other asset to this address may result in the loss of your deposit.</div>
                    </div>
                    <div className="row custom-padding-top-24">
                        <div className="col-12 pb-4 pb-md-0">
                            <Button
                                type="primary"
                                block
                                className='hight-56 new_button_outline'
                                onClick={() => CloseModal()}
                            >
                                Back
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            {twoFAModal && <Modal2fa isOpen={twoFAModal} setIsOpen={setTwoFAModal} />}
            <DepositOnHold isOpen={depositOnHoldModal} setIsOpen={setDepositOnHoldModal} />
        </div>
    )
}
const GenarteQRCode = ({ QRvalue }) => {
    return (
        <>
            <div className='normal-font'>
                <div>
                    <div className='custom-padding-bottom-12'>Steps to Deposit Cryptocurrency:

                        <div className='custom-padding-top-24' style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ marginLeft: "24px", marginRight: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {
                                    QRvalue ?
                                        <QRCode style={{ height: "150px", width: "150px" }} value={QRvalue} />
                                        :
                                        <div style={{ height: "150px", width: "150px", backgroundColor: "gray" }}></div>
                                }
                            </div>
                            <div>
                                <div className='custom-genaral-padding-12'>
                                    <ul className='black-circle-list'>
                                        <li>Launch your wallet app.</li>
                                        <li>Locate "Send" or "Transfer".</li>
                                        <li>Select USDT/USDC and scan the QR Code.</li>
                                        <li>Enter the amount to send</li>
                                        <li>Review details (address, amount, fees).</li>
                                        <li>Confirm with PIN/fingerprint.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}