import { Input } from 'antd'
import React from 'react'
import "./style.css"

export default function NewInputPassword({style,placeholder,value,onChange}) {
  return (
    <div>
        <Input
        type='password'
         style={style?style:""}
         placeholder={placeholder?placeholder:""}
         value={value?value:""}
         onChange={onChange}
         className='new_input_border genaral-input-box-height'
         iconRender={visible => (visible ? null : null)}
        ></Input>
    </div>
  )
}
