import React from 'react'
import'./style.css'
import BarChart from '../../../Chart/BarChart/BarChart'
export default function BarChartCard({harder,footerLabel,footerValue,labels,values}) {
  return (
    <div className='chart-card-container-style'>
        <div className='chart-card-container-style-header'>
            {harder||"Header"}
        </div>
        <div style={{height:"24px"}}></div>
        <BarChart labels={labels} values={values||[]}/>
        <div style={{height:"16px"}}></div>
      <div className='chart-card-container-footer'>
      {footerLabel||"1"} {footerValue||"1"}
      </div>
    </div>
  )
}
