import React, { useEffect, useState } from 'react'
import { gitBookUrl } from '../../Utils/AxiosCall';
import { FiExternalLink } from 'react-icons/fi';
import { Modal } from 'antd';
export default function MarketSecondaryInvestSuccessModal({status,setStatus,data,setData}) {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        setStatus(false)
        setData("")
    };

    useEffect(() => {
        if (status) {
            setShow(true)
        }
    }, [status])
var url=gitBookUrl+"/welcome-to-fu-capital/fu-capital-secondary-market"
return(<>
{/* <Modal show={show} onHide={handleClose} centered size='lg'>
    <Modal.Body>        
        <div className="container" style={{ padding:"48px" }}>
            <center><h2 className="eb_garamond_font" style={{ marginBottom: "40px" }}>Success</h2></center>
            <div><Button className='new_button_filled hight' style={{width:"100%"}}>Investment Request Recieved</Button></div>
            <div className='custom-padding-top-24' style={{lineHeight:"20px",fontSize:"16px",fontWeight:"400"}}>
            Congratulations! Your investment request for <span style={{fontWeight:"700"}}>{data}</span> has been successfully received. Thank you for choosing to invest with FU Capital.
            </div>

            <div className='custom-padding-top-24' style={{display:"flex",justifyContent:"space-between",alignItems:"center",}}>
               <div style={{lineHeight:"20px",fontWeight:"400",fontSize:"14px",color:"#EC441F"}}><a target='_blanck' href={url} style={{color:"#EC441F"}}>Learn more</a></div>
               <div ><a target='_blanck' href={url} style={{color:"#EC441F"}}><FiExternalLink size={16} /></a></div>
            </div>
        </div>
    </Modal.Body>
</Modal> */}
<Modal open={show}  centered footer={null}  onCancel={() => handleClose()} >        
        <div className="container" style={{ padding:"48px" }}>
            <center><h2 className="eb_garamond_font" style={{ marginBottom: "40px" }}>Success</h2></center>
            <div><div className='new_button_filled hight' style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>Deployment request recieved</div></div>
            <div className='custom-padding-top-24' style={{lineHeight:"20px",fontSize:"16px",fontWeight:"400"}}>
            Congratulations! Your claim request for <span style={{fontWeight:"700"}}>{data}</span> has been successfully received. Thank you for choosing to deploy your funds with FU Capital.
            </div>

            <div className='custom-padding-top-24' style={{display:"flex",alignItems:"center",}}>
               <div style={{lineHeight:"20px",fontWeight:"400",fontSize:"14px",color:"#EC441F"}}><a target='_blanck' href={url} style={{color:"#EC441F"}}>Learn more</a></div>
               <div ><a target='_blanck' href={url} style={{color:"#EC441F",paddingLeft:"4px"}}><FiExternalLink size={16} /></a></div>
            </div>
        </div>
</Modal>
</>)
}