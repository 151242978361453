import React, { useState } from 'react'
import "./style.css"
import NewInputPassword from '../../NewInputElement/NewInputPassword/NewInputPassword'
import { Button,message } from 'antd';
import { HandelError, clientApi } from '../../../Utils/AxiosCall';
import { LogOutCurrentSession } from '../../../Utils/Logout';
export default function UpdatePassword() {
    const [password, setPassword] = useState("");
    const [repeatNewPassword, setRepeatNewPassword] = useState("");
    const [newPassword, setNewPassword] = useState();
    const [error, setError] = useState("")
    const [loader, setLoader] = useState(false);
    const UpdatePasswordNow=async ()=>{
        if (newPassword!==repeatNewPassword) {
           setError("Passwords doesn't match!")
           return;
        }
        try {
            const data = await clientApi.post('/api/investor/change-password',{newPassword:newPassword,password:password,repeatNewPassword:repeatNewPassword})
            if (data.status===200) {
                setLoader(false);
                message.success("Profile updated successfully"); 
                setTimeout(LogOutCurrentSession, 2000);
            }
            } catch (error) {
            console.log(error)
            setLoader(false);
            setPassword("")
            setRepeatNewPassword("")
            setNewPassword("")
            message.error("Ops! Try again.");
            HandelError(error);
        }
    }

    

  return (
    <div className=''>
<div className="row">
    <div className="col-12 ">
        <NewInputPassword value={password}  onChange={(e)=>setPassword(e.target.value)} placeholder={"Current Password"}  />
    </div>
    <div className="col-md-6 custom-padding-top-24  ">
        <NewInputPassword value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} placeholder={"New Password"}   />
    </div>
    <div className="col-md-6 custom-padding-top-24">
        <NewInputPassword value={repeatNewPassword}  onChange={(e)=>setRepeatNewPassword(e.target.value)} placeholder={"Re-enter New Password"}  />
    </div>
</div>
       <div>
        {
            error?
            <p className='mt-2 es_form_err '>{error}</p>
            :""
        }
       </div>
       <div className="row">
       <div className='col-12 custom-padding-top-24'>
         <Button className='new_button_filled genaral-button-height' style={{width:"100px",}} onClick={()=>UpdatePasswordNow()} loading={loader} >Update</Button>
       </div>
       </div>

    </div>
  )
}
