import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import "./style.css";
import {
  GetProfile,
  GetWalletBalance,
  HandelError,
  clientApi,
} from "../../Utils/AxiosCall";
import { Link } from "react-router-dom";
import { newNumberformat } from "../../Helper/HelperFunction";
import { useSelector } from "react-redux";
import overviewIcon from "../../Assets/images/account-overview-icon.svg";
import invest_icon from "../../Assets/images/trending-up-invest.svg";
import depositIcon from "../../Assets/images/arrow-warm-up.svg";
import statementIcon from "../../Assets/images/calendar_clock.svg";
import { HeaderButton } from "../../Components/Components/Atom/HeaderButton/HeaderButton";
import { MetaMaskAvatar } from "react-metamask-avatar";
export default function AccountOverview() {
  const verification = localStorage.getItem("investorStatus");
  var currencyCode = useSelector((state) => state.global.currencyCode);
  const SelectedStableCoin = useSelector(
    (state) => state.global.SelectedStableCoin
  );
  console.log(currencyCode);
  const [data, setData] = useState("");
  const GetAccountOverview = async () => {
    try {
      // EUR || USD
      const response = await clientApi.post("/api/investor/account-summary", {
        currencyCode: SelectedStableCoin,
      });
      console.log(response.data);
      if (response?.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      HandelError(error);
    }
  };
  const [walletBalance, setWalletBalance] = useState([]);
  const GetBalances = async () => {
    try {
      const response = await GetWalletBalance();
      console.log(response);
      setWalletBalance(response);
    } catch (error) {}
  };
  useEffect(() => {
    GetAccountOverview();
    GetProfile();
    GetBalances();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundColor: "#F6F6F6",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div style={{ padding: "0px 48px", width: "100%", maxWidth: "1700px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div
                className="eb_garamond_font"
                style={{
                  padding: "40px 0px 24px 0px",
                  fontSize: "40px",
                  lineHeight: "44px",
                  fontWeight: "400",
                  color: "#242424",
                }}
              >
                Overview
              </div>
              <div
                style={{
                  height: "40px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "24px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="header-label">Available balance</div>
                  <div className="header-label-value">
                    {newNumberformat(data?.cashBalance)}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <div>
                    <HeaderButton
                      link="/user/market"
                      svgPath={<img src={invest_icon} alt="Invest icon" />}
                      label={"Buy"}
                    />
                  </div>
                  <div>
                    <HeaderButton
                      link="/user/deposit-funds"
                      svgPath={<img src={depositIcon} alt="deposit icon" />}
                      label={"Add funds"}
                    />
                  </div>
                  <div>
                    <HeaderButton
                      link="/user/account-statement"
                      svgPath={<img src={statementIcon} alt="statement icon" />}
                      label={"Weekly statement"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img src={overviewIcon} alt="overview Icon" />
            </div>
          </div>
          <div></div>
          <div
            className="account-overview-container"
            style={{ paddingTop: "32px" }}
          >
            <div className="account-overview-item">
              <Summary
                currency={data.currency}
                currencySymbol={data.currencySymbol}
                availableFunds={data?.cashBalance}
                totalPending={data?.totalPending}
                totalInvestments={data?.principalInvested}
                totalIncome={data?.accountValue}
                SelectedStableCoin={SelectedStableCoin}
              />
            </div>
            <div className="account-overview-item">
              <Income
                currency={data.currency}
                currencySymbol={data.currencySymbol}
                secondaryMarketIncome={data?.secondaryMarketIncome}
                interestReceived={data?.interestReceived}
                irr={data?.internalRateOfReturnPercent}
                totalIncome={data?.totalIncome}
                SelectedStableCoin={SelectedStableCoin}
              />
            </div>
            <div className="account-overview-item">
              <Portfolio
                currency={data.currency}
                currencySymbol={data.currencySymbol}
                principalCurrent={data?.principalCurrent}
                totalPrincipalLate={data?.totalPrincipalLate}
                principalPending={data?.principalPending}
                totalInvestments={data?.totalInvestments}
                SelectedStableCoin={SelectedStableCoin}
                principalInvested={data?.principalInvested}
              />
            </div>
          </div>
          {walletBalance?.walletBalances?.length > 0 &&
            walletBalance?.walletBalances[0]?.walletAddress && (
              <div style={{ paddingTop: "32px" }}>
                <div className="card card-style">
                  <WalletList data={walletBalance?.walletBalances} />
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

const Summary = ({
  SelectedStableCoin,
  availableFunds,
  totalPending,
  totalInvestments,
  totalIncome,
}) => {
  const [wrappedAvailableFunds, setWrappedAvailableFunds] = useState("0");
  const [wrappedTotalInvestments, setWrappedTotalInvestments] = useState("0");
  const [wrappedTotalPending, setWrappedTotalPending] = useState("0");
  const [wrappedTotalIncome, setWrappedTotalIncome] = useState("0");
  useEffect(() => {
    Promise.all([
      newNumberformat(availableFunds),
      newNumberformat(totalInvestments),
      newNumberformat(totalPending),
      newNumberformat(totalIncome),
    ])
      .then(
        ([
          wrappedAvailableFunds,
          wrappedTotalInvestments,
          wrappedTotalPending,
          wrappedTotalIncome,
        ]) => {
          setWrappedAvailableFunds(wrappedAvailableFunds);
          setWrappedTotalInvestments(wrappedTotalInvestments);
          setWrappedTotalPending(wrappedTotalPending);
          setWrappedTotalIncome(wrappedTotalIncome);
        }
      )
      .catch((error) => console.error(error));
  }, [availableFunds, totalInvestments, totalPending, totalIncome]);
  return (
    // custom-padding-bottom-12
    <div className="card card-style">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <div className="heading-account-overview ">
            Summary, {SelectedStableCoin}
          </div>
          <div className="heading-account-overview-value">
            {(wrappedTotalIncome || "0").length > 10
              ? (wrappedTotalIncome || "0").slice(0, 10) + "..."
              : wrappedTotalIncome || "0"}
          </div>
          <div className="custom-hr">
            <hr className=" hr-style "></hr>
          </div>
          <div className="account-overview-body-content">
            <div className="account-overview-body-content-data">
              <div>Available balance</div>
              <div>{wrappedAvailableFunds || "0"}</div>
            </div>
            <div className="account-overview-body-content-data">
              <div>Deployed balance</div>
              <div>{wrappedTotalInvestments || "0"}</div>
            </div>
            {/* <div className='account-overview-body-content-data'>
                        <div>Pending Payments</div>
                        <div>{wrappedTotalPending||'0'}</div>
                    </div> */}
          </div>
          <div
            className="custom-hr"
            style={{ paddingTop: "16px", paddingBottom: "16px" }}
          >
            <hr className=" hr-style "></hr>
          </div>
          <div className="account-overview-body-content-data">
            <div className="account-overview-body-content-data">
              Total,{SelectedStableCoin}
            </div>
            <div className=" account-overview-body-content-data-bold">
              {wrappedTotalIncome || "0"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Income = ({
  SelectedStableCoin,
  currencySymbol,
  secondaryMarketIncome,
  interestReceived,
  irr,
  totalIncome,
}) => {
  const [wrappedInterestReceived, setWrappedInterestReceived] = useState("0");
  const [wrappedSecondaryMarketIncome, setWrappedSecondaryMarketIncome] =
    useState("0");
  const [wrappedTotalIncome, setWrappedTotalIncome] = useState("0");

  useEffect(() => {
    Promise.all([
      newNumberformat(interestReceived),
      newNumberformat(secondaryMarketIncome),
      newNumberformat(totalIncome),
    ])
      .then(
        ([
          wrappedInterestReceived,
          wrappedSecondaryMarketIncome,
          wrappedTotalIncome,
        ]) => {
          setWrappedInterestReceived(wrappedInterestReceived);
          setWrappedSecondaryMarketIncome(wrappedSecondaryMarketIncome);
          setWrappedTotalIncome(wrappedTotalIncome);
        }
      )
      .catch((error) => console.error(error));
  }, [interestReceived, secondaryMarketIncome, totalIncome]);
  return (
    <div className="card card-style">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <div className="heading-account-overview ">
            Total income, {SelectedStableCoin}
          </div>
          <div className="heading-account-overview-value">
            {(wrappedTotalIncome || "0").length > 10
              ? (wrappedTotalIncome || "0").slice(0, 10) + "..."
              : wrappedTotalIncome || "0"}
          </div>
          <div className="custom-hr">
            <hr className=" hr-style "></hr>
          </div>
          <div className="account-overview-body-content">
            <div className="account-overview-body-content-data">
              <div>Interest income</div>
              <div>{wrappedInterestReceived || "0"}</div>
            </div>
            <div className="account-overview-body-content-data">
              <div>Secondary market income</div>
              <div>{wrappedSecondaryMarketIncome || "0"}</div>
            </div>
          </div>
          <div
            className="custom-hr"
            style={{ paddingTop: "16px", paddingBottom: "16px" }}
          >
            <hr className=" hr-style "></hr>
          </div>
          <div className="account-overview-body-content-data">
            <div className="">Total, {SelectedStableCoin}</div>
            <div className=" account-overview-body-content-data-bold">
              {wrappedTotalIncome || "0"}
            </div>
          </div>
          <div
            className="account-overview-body-content-data"
            style={{ paddingTop: "12px" }}
          >
            <div className="">Interest, {SelectedStableCoin}</div>
            <div className=" account-overview-body-content-data-bold">
              %&nbsp;{irr ? newNumberformat(irr) : "0"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Portfolio = ({
  SelectedStableCoin,
  currencySymbol,
  principalCurrent,
  totalPrincipalLate,
  principalPending,
  totalInvestments,
  principalInvested,
}) => {
  return (
    <div className="card card-style">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          <div className="heading-account-overview ">Number of claims</div>
          <div className="heading-account-overview-value">
            {newNumberformat(totalInvestments || 0) || "0"}
          </div>
          <div className="custom-hr">
            <hr className=" hr-style "></hr>
          </div>
          <div className="account-overview-body-content">
            <div className="account-overview-body-content-data">
              <div>Active</div>
              <div>{newNumberformat(principalCurrent || 0)}</div>
            </div>
            <div className="account-overview-body-content-data">
              <div>Pending</div>
              <div>{newNumberformat(totalPrincipalLate || 0)}</div>
            </div>
            <div className="account-overview-body-content-data">
              <div>Defaulted</div>
              <div>{newNumberformat(principalPending || 0)}</div>
            </div>
            {/* <div className='account-overview-body-content-data'>
                    <div>1 - 15 days late</div>
                    <div>{wrappedPrincipalLate15}</div>
                    </div>
                    <div className='account-overview-body-content-data'>
                    <div>16 - 30 days late</div>
                    <div>{wrappedPrincipalLate30}</div>
                    </div>
                    <div className='account-overview-body-content-data'>
                    <div>31 - 60 days late</div>
                    <div>{wrappedPrincipalLate60}</div>
                    </div>
                    <div className='account-overview-body-content-data'>
                    <div>61 + days late</div>
                    <div>{wrappedPrincipalLate61plus}</div>
                    </div> */}
          </div>
          <div
            className="custom-hr"
            style={{ paddingTop: "16px", paddingBottom: "16px" }}
          >
            <hr className=" hr-style "></hr>
          </div>
          <div className="account-overview-body-content-data">
            <div className="">Total, {SelectedStableCoin}</div>
            <div className=" account-overview-body-content-data-bold">
              {newNumberformat(principalInvested || "0")}
            </div>
          </div>
          <div style={{ marginTop: "32px" }}>
            <Link to="/user/market" style={{ textDecoration: "none" }}>
              <Button
                className="new_button_filled"
                style={{ width: "100%", height: "48px" }}
              >
                Buy
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
const WalletList = ({ data }) => {
  const columns1 = [
    {
      title: "Linked wallet",
      dataIndex: "walletAddress",
      key: "walletAddress",
      align: "left",
      width: "60%",
      render: (ele, key) => {
        return (
          <div
            className="account-overview-table-value"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            <MetaMaskAvatar address={key?.walletAddress || ""} size={24} />
            {key?.walletAddress || ""} &nbsp;
            <span style={{ fontSize: "12px" }}>{`(${
              key?.walletName || ""
            })`}</span>
          </div>
        );
      },
    },
    {
      title: "Available balance",
      dataIndex: "walletBalance",
      align: "right",
      width: "15%",
      render: (ele, key) => {
        return (
          <div className="account-overview-table-value">
            {newNumberformat(key?.walletBalance)}
          </div>
        );
      },
    },
    {
      title: "Deployed balance",
      dataIndex: "investedAmount",
      align: "right",
      width: "15%",
      render: (ele, key) => {
        return (
          <div className="account-overview-table-value">
            {newNumberformat(key?.investedAmount)}
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <div>
        <Table
          columns={columns1}
          dataSource={data || []}
          pagination={false}
          rowKey={(record) => record?.id}
          className="custom-table"
        />
      </div>
    </div>
  );
};
// const HeaderButton=({link,svgPath,label,alt})=>{
//  return(
//     <>
//        <Link to={link} style={{textDecoration:"none"}}>
//        <div className='header-button-overview' >
//          <div>{svgPath}</div>
//          <div>{label}</div>
//        </div>
//        </Link>
//     </>
//  )
// }
