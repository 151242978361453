// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot_password_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom_form {
    font-family: "Inter", sans-serif !important;
}
.input_forgot_password {
    background: #ffffff !important;
    border-radius: 0;
    border: 1px solid black !important;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    height:56px;
}

.input_forgot_password::placeholder {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
}

.forgot_password_form{
    max-width: 500px;
    min-width: 400px;
}
@media screen and (max-width: 416px) {
    .forgot_password_form {
        min-width: 332px; /* Adjust this value as needed */
    }
}
.heading_style{
    font-size: 40px !important;
}
.button_style{
    height: 56px !important;
}
@media screen and (max-width: 416px) {
    .heading_style {
        font-size: 30px !important; /* Adjust this value as needed */
    }
    .input_forgot_password {
        height: 40px !important; /* Adjust this value as needed */
    }
    .button_style{
        height: 40px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/ForgotPassword/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,2CAA2C;AAC/C;AACA;IACI,8BAA8B;IAC9B,gBAAgB;IAChB,kCAAkC;IAClC,gCAAgC;IAChC,eAAe;IACf,WAAW;AACf;;AAEA;IACI,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI;QACI,gBAAgB,EAAE,gCAAgC;IACtD;AACJ;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,uBAAuB;AAC3B;AACA;IACI;QACI,0BAA0B,EAAE,gCAAgC;IAChE;IACA;QACI,uBAAuB,EAAE,gCAAgC;IAC7D;IACA;QACI,uBAAuB;IAC3B;AACJ","sourcesContent":[".forgot_password_container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.custom_form {\n    font-family: \"Inter\", sans-serif !important;\n}\n.input_forgot_password {\n    background: #ffffff !important;\n    border-radius: 0;\n    border: 1px solid black !important;\n    font-family: 'Inter', sans-serif;\n    font-size: 16px;\n    height:56px;\n}\n\n.input_forgot_password::placeholder {\n    font-family: 'Inter', sans-serif;\n    font-size: 16px;\n}\n\n.forgot_password_form{\n    max-width: 500px;\n    min-width: 400px;\n}\n@media screen and (max-width: 416px) {\n    .forgot_password_form {\n        min-width: 332px; /* Adjust this value as needed */\n    }\n}\n.heading_style{\n    font-size: 40px !important;\n}\n.button_style{\n    height: 56px !important;\n}\n@media screen and (max-width: 416px) {\n    .heading_style {\n        font-size: 30px !important; /* Adjust this value as needed */\n    }\n    .input_forgot_password {\n        height: 40px !important; /* Adjust this value as needed */\n    }\n    .button_style{\n        height: 40px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
