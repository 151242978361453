import React, { useState } from "react";
import "./style.css";
import SingUpErrorCard from "../../Components/Components/Atom/SingUpErorrCard/SingUpErrorCard";
import SignUpInputBox from "../../Components/Components/Atom/SignUpInputBox/SignUpInputBox";
import PasswordInput from "../../Components/Components/Atom/PasswordInput/PasswordInput";
import { Link } from "react-router-dom";
import { Button, Checkbox, Col, Row } from "antd";
import arrowIcon from "../../Assets/images/arrow_right_alt.svg";
import { clientApi } from "../../Utils/AxiosCall";
import { getWebDeviceData } from "../../Utils/common";
import { setTwoFactorAuthenticationStatus } from "../../Store/reducers/global";
import { useDispatch } from "react-redux";
export default function SignUp() {
  const dispatch = useDispatch();
  const [error, setError] = useState({
    error: false,
    message: "",
  });
  const [validationError, setValidationError] = useState({
    email: {
      error: false,
      message: "",
      exists: false,
    },
    password: {
      error: false,
      message:
        "Minimal password requirements: 6 symbols and at least one number",
      exists: false,
    },
    termsAndConditions: {
      error: false,
      message: "",
      exists: false,
    },
  });
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    termsAndConditions: false,
    marketingConsent: false,
  });
  const [loader, setLoader] = useState(false);
  const validateForm = () => {
    let isValid = true;
    let validationErrorData = {
      email: {
        error: false,
        message: "",
        exists: false,
      },
      password: {
        error: false,
        message:
          "Minimal password requirements: 6 symbols and at least one number",
        exists: false,
      },
      termsAndConditions: {
        error: false,
        message: "",
        exists: false,
      },
    };

    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Regular expression for password validation (at least one number)
    const passwordRegex = /.*[0-9].*/;

    if (!formData.email) {
      validationErrorData.email.error = true;
      validationErrorData.email.message = "Email is required";
      validationErrorData.email.exists = false;
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      validationErrorData.email.error = true;
      validationErrorData.email.message = "Invalid email format";
      validationErrorData.email.exists = false;
      isValid = false;
    }

    if (!formData.password) {
      validationErrorData.password.error = true;
      validationErrorData.password.message = "Password is required";
      validationErrorData.password.exists = false;
      isValid = false;
    } else if (
      formData.password.length < 6 ||
      !passwordRegex.test(formData.password)
    ) {
      validationErrorData.password.error = true;
      validationErrorData.password.message =
        "Minimal password requirements: 6 symbols and at least one number";
      validationErrorData.password.exists = false;
      isValid = false;
    }
    if (!formData.termsAndConditions) {
      validationErrorData.termsAndConditions.error = true;
      validationErrorData.termsAndConditions.message =
        "To continue with registration you require to check this option";
      validationErrorData.termsAndConditions.exists = false;
      isValid = false;
    }
    const hasError = Object.values(validationErrorData).some(
      (field) => field.error
    );

    // Update the error state based on validation results
    if (hasError) {
      setError({
        error: true,
        message: "Form cannot be submitted, see errors below",
      });
    } else {
      setError({
        error: false,
        message: "",
      });
    }
    setValidationError(validationErrorData);
    return isValid;
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        setLoader(true);
        const webDeviceData = await getWebDeviceData();
        const data = {
          email: formData.email,
          password: formData.password,
          termsOfUseConsent: formData.termsAndConditions,
          privacyPolicyConsent: formData.termsAndConditions,
          marketingConsent: formData.marketingConsent,
          walletAddress: "",
          isCompany: false,
          promoCode: null,
          localeCode: "EN",
          webDeviceData: webDeviceData || null,
        };
        const response = await clientApi.post(
          "/api/investor/public/sign-up",
          data
        );
        if (response.status === 200) {
          window.location.assign("/user/verify-email");
          ClearData();
          setFormData({
            email: "",
            password: "",
          });
          dispatch(setTwoFactorAuthenticationStatus(false));
          setLoader(false);
        }
      } catch (error) {
        if (error?.response?.data?.validation?.errors[0] === "INVALID_LOGIN") {
          setError({
            error: true,
            message: "Email or password is invalid. Please try again!",
          });
        } else if (
          error?.response?.data?.validation?.fieldErrors?.email === "NOT_UNIQUE"
        ) {
          setValidationError({
            ...validationError,
            email: { error: true, message: "", exists: true },
          });
          setError({
            error: true,
            message:
              "User with this email address already registered. Please try another email.",
          });
        } else {
          ClearData();
          setError({
            error: true,
            message: "Something went wrong. Please try again later",
          });
        }

        setLoader(false);
      }
    }
  };
  const ClearData = () => {
    setValidationError({
      email: {
        error: false,
        message: "",
        exists: false,
      },
      password: {
        error: false,
        message:
          "Minimal password requirements: 6 symbols and at least one number",
        exists: false,
      },
      termsAndConditions: {
        error: false,
        message: "",
        exists: false,
      },
    });
  };
  return (
    <div className="onboarding-page-container">
      <Row justify="center" align="middle" className="antd-row-height-style">
        <Col xs={24} sm={16} md={16} lg={10}>
          <div
            className="sign-up-heading eb_garamond_font"
            style={{ paddingTop: "2rem" }}
          >
            Create profile
          </div>
          {error.error ? (
            <>
              <SingUpErrorCard data={error.message} />
            </>
          ) : (
            <>
              <div style={{ height: "2rem" }}></div>
            </>
          )}
          <div>
            <div>
              <SignUpInputBox
                label={"Email address"}
                placeholder={"Your email address"}
                isRequired={true}
                value={formData.email}
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value });
                }}
                validationError={validationError.email}
              />
            </div>
            <div className="gap-between-inputs">
              <PasswordInput
                label={"Password"}
                placeholder={"Enter your password"}
                isRequired={true}
                value={formData.password}
                onChange={(e) => {
                  setFormData({ ...formData, password: e.target.value });
                }}
                validationError={validationError.password}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: "32px",
              marginBottom: "32px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <div>
                <Checkbox
                  className={
                    validationError.termsAndConditions?.error
                      ? "custom-check-box-error"
                      : "custom-check-box"
                  }
                  value={formData.termsAndConditions}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      termsAndConditions: !formData?.termsAndConditions,
                    });
                  }}
                />
              </div>
              <div className="checkbox-label">
                I have read, understand and agree to the{" "}
                <a
                  href="https://fucapital.io/terms_of_use"
                  style={{
                    color: "#EC441F",
                    textDecoration: "none",
                    borderBottom: "1px solid #EC441F",
                  }}
                >
                  {" "}
                  Terms of Use{" "}
                </a>{" "}
                and{" "}
                <a
                  href="https://fucapital.io/privacy_policy"
                  style={{
                    color: "#EC441F",
                    textDecoration: "none",
                    borderBottom: "1px solid #EC441F",
                  }}
                >
                  {" "}
                  Privacy Policy
                </a>
              </div>
            </div>
            {validationError.termsAndConditions.error ? (
              <span className="error-message">
                {validationError.termsAndConditions.message}
              </span>
            ) : (
              <></>
            )}
            <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <div>
                <Checkbox
                  className="custom-check-box"
                  value={formData.marketingConsent}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      marketingConsent: !formData?.marketingConsent,
                    });
                  }}
                />
              </div>
              <div className="checkbox-label">
                I would like to receive FU Capital newsletter
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "2rem" }}>
            <Button
              className={`new_button_filled  hight-56`}
              style={{ width: "100%" }}
              htmlType="submit"
              loading={loader}
              disabled={
                !formData.email ||
                !formData.password ||
                !formData.termsAndConditions
              }
              onClick={() => handleSubmit()}
            >
              Continue
              <img src={arrowIcon} style={{ paddingLeft: "8px" }} />{" "}
            </Button>
          </div>
          <div
            style={{
              marginTop: "48px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#242424",
            }}
          >
            Have an account?
            <Link
              style={{
                marginLeft: 8,
                color: "#EC441F",
                textDecoration: "none",
              }}
              // onClick={() => window.location.assign("/user/login")}
              to={"/user/login"}
            >
              Sign in
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
}
