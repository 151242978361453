import React,{ useState, useEffect } from 'react'
import'./style.css'
import Modal from 'react-bootstrap/Modal';
import { Button, Checkbox, Input, Select, message } from 'antd';
import { Loan_extensions_list } from '../../Helper_Data/SelectBoxData';

export default function PrimaryMarketFilter({filter, setFilter, isModalOpen, setIsModalOpen,countries_list, 
                                            originators_list, collectionStatuses_list, products_list,clearFlitter,handleSearch}) {
    

    const handleClose = async () => {
        // setIsModalOpen(false);
       await clearFlitter();
       await  handleSearch();
    }
    
  
    return (
      <div>
        {/* show={show} */}
        <Modal show={isModalOpen} centered style={{ display: "flex", alignItems: "center", justifyContent: "center", }} size='lg' >
          <Modal.Body>
            <div className="" style={{ padding: "48px" }}>
            <div className='d-flex justify-content-center align-items-center' style={{paddingBottom:"48px"}}>
  <span className="popup-titel-text-style">Filter</span>
</div>
              <div className='custom-select-box'>
                <div className="row">
                <div className="col-md-6 ">
                    <div className="row">
                      <div className="col-12">
                      Products:
                      </div>
                      <div className="col-12 custom-padding-top-12">
                        <Select
                        mode="multiple"
                          placeholder={"All"}
                          className="new_input_normal_border select-box-height"
                          options={products_list}
                          style={{ width: "100%", }}
                          dropdownStyle={{ zIndex: 1200 }}
                          size={"large"}
                          value={filter.products}
                          onChange={(e) => {
                            setFilter({ ...filter, products: e });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-12">
                        Countries:
                      </div>
                      <div className="col-12 custom-padding-top-12">
                        <Select
                          mode="multiple"
                          placeholder={"All"}
                          className="new_input_normal_border  select-box-height"
                          options={countries_list}
                          style={{ width: "100%", }}
                          dropdownStyle={{ zIndex: 1200 }}
                          size={"large"}
                          value={filter.countries}
                          onChange={(e) => {
                            setFilter({ ...filter, countries: e });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row ">
                <div className="col-md-6 custom-padding-top-24">
                    <div className="row">
                      <div className="col-12">
                         Originators:
                      </div>
                      <div className="col-12 custom-padding-top-12">
                        <Select
                          mode="multiple"
                          placeholder={"All"}
                          className="new_select select-box-height"
                          options={originators_list}
                          style={{ width: "100%", }}
                          dropdownStyle={{ zIndex: 1200 }}
                          size={"large"}
                          value={filter.originators}
                          onChange={(e) => {
                            setFilter({ ...filter, originators: e });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 custom-padding-top-24">
                    <div className="row">
                      <div className="col-12">
                        Status:
                      </div>
                      <div className="col-12 custom-padding-top-12">
                        <Select
                          mode="multiple"
                          placeholder={"All"}
                          className="new_select select-box-height"
                          options={collectionStatuses_list}
                          style={{ width: "100%", }}
                          dropdownStyle={{ zIndex: 1200 }}
                          size={"large"}
                          value={filter.collectionStatuses}
                          onChange={(e) => {
                            setFilter({ ...filter, collectionStatuses: e });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-md-6 custom-padding-top-24">
                    <div className="row">
                      <div className="col-12">
                        Loan Extensions:
                      </div>
                      <div className="col-12 custom-padding-top-12">
                        <Select
                          placeholder={"All"}
                          className="new_select select-box-height"
                          options={Loan_extensions_list}
                          style={{ width: "100%", }}
                          dropdownStyle={{ zIndex: 1200 }}
                          size={"large"}
                            value={filter.extensions}
                          onChange={(e) => {
                            setFilter({ ...filter, extensions: e });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 custom-padding-top-24">
                    <div className="row">
                      <div className="col-12">
                        Interest Rate:
                      </div>
                      <div className="col-6 custom-padding-top-12">
                        <Input style={{ width: "100%", height: "42px" }} className='new_input' placeholder='From %'
                          onKeyPress={(e) => {
                            if (!/[0-9.]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.interestRatePercentFrom}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*\.?[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, interestRatePercentFrom: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                      <div className="col-6 custom-padding-top-12">
                        <Input style={{ width: "100%", height: "42px" }} className='new_input' placeholder='To %'
                          onKeyPress={(e) => {
                            if (!/[0-9.]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.interestRatePercentTo}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*\.?[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, interestRatePercentTo: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row">
                <div className="col-md-6 custom-padding-top-24">
                    <div className="row">
                      <div className="col-12">
                        Remaining Term in Days:
                      </div>
                      <div className="col-6 custom-padding-top-12">
                        <Input style={{ width: "100%", height: "42px" }} className='new_input' placeholder='From '
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.remainingTermInDaysFrom}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, remainingTermInDaysFrom: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                      <div className="col-6 custom-padding-top-12">
                        <Input style={{ width: "100%", height: "42px" }} className='new_input' placeholder='To '
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.remainingTermInDaysTo}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, remainingTermInDaysTo: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 custom-padding-top-24">
                    <div className="row">
                      <div className="col-12">
                      Available Amount:
                      </div>
                      <div className="col-6 custom-padding-top-12">
                        <Input style={{ width: "100%", height: "42px" }} className='new_input' placeholder='From '
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.principalOfferFrom}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, principalOfferFrom: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                      <div className="col-6 custom-padding-top-12">
                        <Input style={{ width: "100%", height: "42px" }} className='new_input' placeholder='To '
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.principalOfferTo}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, principalOfferTo: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row custom-padding-top-24">
  
                  <div className="col-md-6 custom-padding-top-12" style={{display:"flex",flexDirection:"row",gap:"12px",alignItems:"center"}}>
                    <div>
                    <Checkbox
                      checked={filter.excludeAlreadyInvested}
                      onChange={() => setFilter({ ...filter, excludeAlreadyInvested: !filter.excludeAlreadyInvested})}
                    ></Checkbox>
                    </div>
                    <div>Show loans only with Buyback obligation</div>  
                  </div>

                  <div className="col-md-6 d-md-flex justify-content-md-end custom-padding-top-12" style={{display:"flex",flexDirection:"row",gap:"12px",alignItems:"center"}}>
                    <div>
                    <Checkbox
                      checked={filter.forSale}
                      onChange={() => setFilter({ ...filter, buybackOnly: !filter.buybackOnly})}
                      style={{ marginRight: "12px" }}></Checkbox>
                    </div>
                    <div>
                    Exclude already invested
                    </div>
                  </div>
                </div>
  
              </div>
  
              <div className="row custom-padding-top-24">
                <div className="col-md-6 pb-4 pb-md-0">
                  <Button
                    type="primary"
                    block
                    style={{ backgroundColor: "#fff", borderRadius: '0', borderBlockColor: "#EC441F", height: '52px', color: "#EC441F", border: "1px solid #EC441F" }}
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-md-6 pb-4 pb-md-0">
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    // loading={loader}
                    style={{ backgroundColor: "#EC441F", borderRadius: '0', height: '52px' }}
                    onClick={() => {handleSearch()}}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
  
        </Modal>
      </div>
    )
  }
  