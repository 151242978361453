// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doughnut-chart-card-container-style {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #2424240A;
  }
  .doughnut-chart-card-container-style-header {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Components/Atom/DoughnutChartCard/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,2BAA2B;EAC7B;EACA;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":[".doughnut-chart-card-container-style {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    padding: 24px;\n    background-color: #fff;\n    border-radius: 6px;\n    border: 1px solid #2424240A;\n  }\n  .doughnut-chart-card-container-style-header {\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 24px;\n    text-align: left;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
