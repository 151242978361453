import React from 'react'

export default function ContactInformation({data}) {
    let number=data?.countryCode+" "+data?.phoneNumber
  return (
<div className='profile-card-container'>
        <div className='profile-card-style-header'>
        Contact information
        </div>
        <div className='profile-card-style'>
          <div style={{display:"flex",flexDirection:"column",gap:"4px"}}>
          <div className='profile-card-style-label'>Email</div>
          <div className='profile-card-style-value'>{data?.email||"-"}</div>
          </div>
          <div style={{height:"32px"}}></div>
          <div style={{display:"flex",flexDirection:"column",gap:"4px"}}>
          <div className='profile-card-style-label'>Telephone number</div>
          <div className='profile-card-style-value'>{!data?.phoneNumber&&!data?.countryCode?"-":number}</div>
          </div>
          {/* <div style={{paddingTop:"24px"}}>
            <span className='profile-card-style-link'>Change your address</span>
          </div> */}
        </div>
    </div>
  )
}
