import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { GetProfile, HandelError, clientApi } from "../../Utils/AxiosCall";
import countries from "i18n-iso-countries";
import { IoCloseSharp } from "react-icons/io5";
import { Button, Card, Input, Table } from "antd";
import ReactCountryFlag from "react-country-flag";
import "./style.css";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { CiCircleAlert } from "react-icons/ci";
import { FaRegStopCircle } from "react-icons/fa";
import {
  AmountConverter,
  StablecoinToUSDConverter,
  StablecoinWarperFunction,
  newNumberformat,
} from "../../Helper/HelperFunction";
import close_icon from "../../Assets/images/IconButton.svg";
import { useSelector } from "react-redux";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const BulkInvestPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const SelectedStableCoin = useSelector(
    (state) => state.global.SelectedStableCoin
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [loans, setLoans] = useState([]);
  const [investmentStatus, setInvestmentStatus] = useState([]);
  const [total, setTotal] = useState(0);
  const [confirmed, setConfirmed] = useState(false);
  const [investmentInProgress, setInvestmentInProgress] = useState(false);
  const [terminates, setTerminates] = useState(false);
  const [referenceData, setReferenceData] = useState(null);
  const [allLoanData, setAllLoanData] = useState([]);
  const terminatesRef = useRef(terminates);
  const removeLoan = (loan) => {
    const newLoans = loans.filter((l) => l !== loan);
    const totalAmount = newLoans.reduce((acc, loan) => acc + loan.amount, 0);
    setLoans(newLoans);
    setTotal(totalAmount);
  };

  const back = () => {
    navigate(-1);
  };

  useEffect(() => {
    console.log(terminates);
    terminatesRef.current = terminates;
  }, [terminates]);

  const stopInvestment = () => {
    console.log("stop investment");
    setTerminates(true);
  };

  const updateInvestmentStatus = (loanId, status) => {
    setInvestmentStatus((prevStatus) => {
      const index = prevStatus.findIndex((val) => val.loanId === loanId);
      const updatedStatus = [...prevStatus];
      updatedStatus.splice(index, 1, status);
      return updatedStatus;
    });
  };

  const investInAll = async () => {
    setConfirmed(true);
    setInvestmentInProgress(true);
    var count = 0;
    for (const loan of loans) {
      setInvestmentStatus((prevStatus) => [
        ...prevStatus,
        {
          loanId: loan.loanId,
          amount: loan.amount,
          currency: loan.currency,
          currencySymbol: loan.currencySymbol,
          status: "pending", // set default status to 'pending'
        },
      ]);
    }

    for (const loan of loans) {
      // if (terminates) return;
      await invest(loan);
    }

    setInvestmentInProgress(false);
  };

  const walletAddress = new URLSearchParams(location.search).get(
    "walletAddress"
  );
  const invest = async (loan) => {
    console.log(terminatesRef.current); // Access latest value via useRef
    const adjustedAmount =
      loan.amount <= loan.principalOffer ? loan.amount : loan.principalOffer;

    if (!terminatesRef.current) {
      try {
        await clientApi.post("/api/investor/invest", {
          loanId: loan.loanId,
          amount: adjustedAmount,
          walletAddress: walletAddress,
        });
        updateInvestmentStatus(loan.loanId, {
          loanId: loan.loanId,
          amount: adjustedAmount,
          currency: loan.currency,
          currencySymbol: loan.currencySymbol,
          status: "Successful",
        });
      } catch (error) {
        updateInvestmentStatus(loan.loanId, {
          loanId: loan.loanId,
          amount: adjustedAmount,
          currency: loan.currency,
          currencySymbol: loan.currencySymbol,
          status: "Failed",
          error: error.message,
        });
      }
    } else {
      updateInvestmentStatus(loan.loanId, {
        loanId: loan.loanId,
        amount: adjustedAmount,
        currency: loan.currency,
        currencySymbol: loan.currencySymbol,
        status: "Stopped",
        // error: error.message
      });
    }
  };

  const queryReferenceData = async () => {
    try {
      const response = await clientApi.post("/api/investor/reference-data", {});
      setReferenceData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    queryReferenceData();
    const fetchData = async () => {
      try {
        const loansResult = await clientApi.post(
          "/api/investor/public/query-primary-market",
          {
            filter: {
              loanIds: new URLSearchParams(location.search).getAll("loans"),
            },
          }
        );
        setAllLoanData(loansResult?.data);
        const updatedLoans = loansResult?.data?.items.map((loan) => ({
          ...loan,
          amount: Number(new URLSearchParams(location.search).get("amount")),
        }));
        const totalAmount = updatedLoans.reduce(
          (acc, loan) => acc + loan.amount,
          0
        );
        setLoans(updatedLoans);
        setTotal(totalAmount);
      } catch (error) {
        console.error(error);
        HandelError(error);
      }
    };
    fetchData();
  }, [location.search]);

  const columns = [
    {
      title: <span className="table-title">Country</span>,
      dataIndex: "countryCode",
      key: "countryCode",
      align: "center",
      width: 115.33,
      render: (ele, key) => {
        const countryName = countries.getName(ele, "en", {
          select: "official",
        });
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ReactCountryFlag countryCode={ele} svg />
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              {countryName}
            </div>
          </div>
        );
      },
    },
    {
      title: <span className="table-title">Loan Id</span>,
      dataIndex: "loanId",
      align: "center",
      width: 115.33,
      render: (text, record) => (
        <div>
          <div className="" style={{ fontSize: "14px" }}>
            <Link
              className="primary-color"
              style={{ textDecoration: "none" }}
              to={`/user/loan/${record.loanId}`}
            >
              <span style={{ borderBottom: "1px solid #EC441F33" }}>
                {record.loanId}
              </span>
            </Link>{" "}
          </div>
          <div className="" style={{ fontSize: "12px" }}>
            {record.productLabel}
          </div>
        </div>
      ),
    },
    {
      title: <span className="table-title">Lender</span>,
      dataIndex: "originatorCompanyName",
      align: "center",
      width: 115.33,
    },

    {
      title: (
        <span className="table-title">Listed&nbsp;on&nbsp;the platform</span>
      ),
      dataIndex: "issueDate",
      align: "center",
      width: 115.33,
    },
    {
      title: <span className="table-title">Duration</span>,
      dataIndex: "Duration",
      align: "center",
      width: 115.33,
      render: (text, record) => (
        <div>
          {record.termInDays >= 0 ? (
            <>
              <div>
                {record.termPeriod ? (
                  <>
                    {record.termPeriod.years ? (
                      <>{record.termPeriod.years}y&nbsp;</>
                    ) : (
                      <></>
                    )}
                    {record.termPeriod.months ? (
                      <>{record.termPeriod.months}m&nbsp;</>
                    ) : (
                      <></>
                    )}
                    {record.termPeriod.days ? (
                      <>{record.termPeriod.days}d</>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : record.termInDays < 0 ? (
            <>
              <span class="text-danger-1">{record.termInDays}d</span>
            </>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: <span className="table-title">Interest</span>,
      dataIndex: "principalIssued",
      align: "center",
      width: 115.33,
      render: (text, record) => (
        <div>
          <div>{newNumberformat(record.principalIssued)}</div>
        </div>
      ),
    },
    {
      title: <span className="table-title">Interest Rate</span>,
      dataIndex: "interestRatePercent",
      align: "center",
      width: 115.33,
      render: (text, record) => (
        <div>
          <div>{newNumberformat(record.interestRatePercent)}%</div>
        </div>
      ),
    },
    {
      title: <span className="table-title">Status</span>,
      dataIndex: "termInDays",
      align: "center",
      width: 115.33,
      render: (text, record) => (
        <div>
          {/* {
            record.termInDays >= 0 ?
              <>
                <div>{record.termPeriod ? < >
                  {
                    record.termPeriod.years ? <>{record.termPeriod.years}y&nbsp;</> : <></>
                  }
                  {
                    record.termPeriod.months ? <>{record.termPeriod.months}m&nbsp;</> : <></>
                  }
                  {
                    record.termPeriod.days ? <>{record.termPeriod.days}d</> : <></>
                  }
                </>
                  : ""}</div>
              </>
              :
              record.termInDays < 0 ?
                <>
                  <span class="text-danger-1">{record.termInDays}d</span>
                </>
                :
                ""
          } */}

          <div className="table-column-color">{record.loanStatus}</div>
        </div>
      ),
    },
    {
      title: <span className="table-title">Available&nbsp;loan amount</span>,
      dataIndex: "amountAvailable",
      align: "center",
      width: 115.33,
      render: (text, record) => (
        <div>
          <div>{newNumberformat(record.amountAvailable)}</div>
        </div>
      ),
    },
    {
      title: <span className="table-title">Deployed balance</span>,
      dataIndex: "amountAvailable",
      align: "center",
      width: 115.33,
      render: (text, record) => (
        <div>
          <div>{newNumberformat(record.myInvestments)}</div>
        </div>
      ),
    },

    {
      title: "Amount to invest",
      dataIndex: "Action",
      key: "Action",
      width: 115.33,
      align: "center",
      className: "investment-amount-column",
      render: (ele, key) => (
        <div
          style={{
            width: "100%",
            height: "40px",
            display: "flex",
            textAlign: "left",
            alignItems: "center",
          }}
          className="new_input new_input_normal_border"
        >
          {newNumberformat(key.amount)}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "Action1",
      key: "Action1",
      width: 48,
      align: "center",
      className: "investment-amount-column",
      render: (ele, key) => (
        <div>
          <span style={{ paddingLeft: "6px" }}>
            <img
              src={close_icon}
              alt="close"
              onClick={() => removeLoan(key)}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    },
  ];

  const [profileData, setProfileData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetProfile();
      console.log(response);
      setProfileData(response);
    };
    fetchData();
  }, []);
  const [availableFunds, setAvailableFunds] = useState("0");
  const [balanceAfterInvestment, setBalanceAfterInvestment] = useState("0");

  useEffect(() => {
    console.log(SelectedStableCoin);
    console.log(profileData?.accounts?.[SelectedStableCoin].cashBalance);
    setAvailableFunds(profileData?.accounts?.[SelectedStableCoin].cashBalance);
    setBalanceAfterInvestment(
      profileData?.accounts?.[SelectedStableCoin].cashBalance - total
    );
  }, [profileData, total]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "24px 0px",
        }}
      >
        <div style={{ maxWidth: "1700px", width: "100%", padding: "0px 48px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div
                className="eb_garamond_font"
                style={{
                  padding: "48px 0px 0px 0px",
                  fontSize: "40px",
                  lineHeight: "44px",
                  fontWeight: "400",
                  color: "#242424",
                }}
              >
                Multiple loan purchase
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#242424",
                  paddingBottom: "24px",
                }}
              >
                Review selected loans for buying and confirm your choice{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ background: "white" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "24px 0px",
            }}
          >
            <div
              style={{ maxWidth: "1700px", width: "100%", padding: "0px 48px" }}
            >
              {!confirmed ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ overflowX: "auto" }}>
                      <Table
                        columns={columns}
                        dataSource={loans}
                        // loading={apiLoad}
                        responsive
                        scroll={{ x: "max-content" }}
                        pagination={false}
                        showSizeChanger
                        className="custom-table"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "12px",
                        paddingTop: "24px",
                      }}
                    >
                      <div
                        style={{
                          width: "352px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              color: "#161616B2",
                            }}
                          >
                            Number of claims
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              color: "#242424",
                            }}
                          >
                            {newNumberformat(loans?.length) || "0"}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              color: "#161616B2",
                            }}
                          >
                            Total amount to buy
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              color: "#242424",
                            }}
                          >
                            {newNumberformat(total)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              color: "#161616B2",
                            }}
                          >
                            Available balance
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              color: "#242424",
                            }}
                          >
                            {newNumberformat(availableFunds)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              color: "#161616B2",
                            }}
                          >
                            Balance after purchase
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              color: "#242424",
                            }}
                          >
                            {newNumberformat(balanceAfterInvestment)}
                          </div>
                        </div>
                        <div
                          style={{
                            paddingTop: "24px",
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                          }}
                        >
                          <div>
                            <Button
                              className="new_button_outline"
                              style={{ width: "101px", height: "52px" }}
                              onClick={() => back()}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div>
                            <Button
                              className="new_button_filled"
                              style={{ width: "243px", height: "52px" }}
                              onClick={() => investInAll()}
                            >
                              Confirm buying {newNumberformat(total || 0)}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: "20px",
                        color: "#EC441F",
                      }}
                    >
                      Investing in Selected Loans
                    </div>
                  </div>
                  <div style={{ height: "12px" }}></div>
                  <div
                    style={{
                      height: "1px",
                      backgroundColor: "#EC441F",
                      width: "100%",
                    }}
                  />
                  <div style={{ height: "12px" }}></div>
                  {investmentStatus?.map((status, index) => {
                    return [
                      <div
                        key={`${index}-1`}
                        className="row"
                        style={{
                          width: "100%",
                          fontWeight: "400",
                          fontSize: "16px",
                        }}
                      >
                        <div className="col-6">
                          Invest
                          <span style={{ color: "#EC441F" }}>
                            {" "}
                            {status.amount || "0"}{" "}
                          </span>{" "}
                          in loan
                          <span style={{ color: "#EC441F" }}>
                            {status.loanId}
                          </span>
                        </div>
                        <div className="col-6 align-text-end">
                          {status.status}{" "}
                          <span>
                            {status.status === "Successful" && (
                              <>
                                <IoMdCheckmarkCircleOutline
                                  style={{
                                    paddingLeft: "6px",
                                    color: "#45C01F",
                                    height: "16px",
                                    width: "16px",
                                  }}
                                />
                              </>
                            )}{" "}
                            {status.status === "Failed" && (
                              <>
                                <CiCircleAlert
                                  style={{
                                    paddingLeft: "6px",
                                    color: "#EC441F",
                                    height: "16px",
                                    width: "16px",
                                  }}
                                />
                              </>
                            )}{" "}
                            {status.status === "Stopped" && (
                              <>
                                <FaRegStopCircle
                                  style={{
                                    paddingLeft: "6px",
                                    color: "#EC441F",
                                    height: "16px",
                                    width: "16px",
                                  }}
                                />
                              </>
                            )}{" "}
                          </span>{" "}
                        </div>
                      </div>,
                      <div key={`${index}-2`} style={{ height: "12px" }}></div>,
                    ];
                  })}
                  <div className="row">
                    <div className="col-6">
                      <Button
                        className="new_button_outline"
                        disabled={investmentInProgress === false ? true : false}
                        style={{ width: "100%", height: "52px" }}
                        onClick={() => stopInvestment()}
                      >
                        Stop
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button
                        className="new_button_filled"
                        disabled={investmentInProgress}
                        style={{ width: "100%", height: "52px" }}
                        onClick={() => navigate("/user/market")}
                      >
                        Return to Market
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkInvestPage;
