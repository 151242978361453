import { Avatar, Button, Divider, Dropdown, Space } from "antd";
import React, { useEffect, useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { LogOutCurrentSession } from "../../Utils/Logout";
import { CaretDownOutlined } from "@ant-design/icons";
import usdc_icon from "../../Assets/images/USDC.svg";
import usdt_icon from "../../Assets/images/USDT.svg";
import check_icon from "../../Assets/images/check.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrencyCode,
  setSelectedStableCoin,
} from "../../Store/reducers/global";
import WalletConnectNavBar from "../WalletConnectNavBar/WalletConnectNavBar";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { Menu } from "antd";
import add_circle from "../../Assets/images/add-circle-outline.svg";
import { Tooltip } from "antd";
import { useDisconnect } from "wagmi";
import badge from "../../Assets/images/EarlyBird.png";

export default function ActiveHeader(props) {
  // const firstName = localStorage.getItem("investorFirstName");
  // const lastName = localStorage.getItem("investorLastName");
  // const userId = localStorage.getItem("investorId");

  const { firstName, lastName, userId } = useSelector(
    (state) => state.global.GlobalProfileData
  );

  const firstInitial = firstName?.charAt(0);
  const lastInitial = lastName?.charAt(0);
  const combinedInitials = firstInitial + lastInitial;
  const fullName =
    (firstName ? firstName : "") + " " + (lastName ? lastName : "");
  const truncatedName =
    fullName?.length > 25 ? fullName.slice(0, 21) + "..." : fullName;
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };
  return (
    <div className="header-style" style={{ width: "100%" }}>
      <div className="header-inner">
        <div className="header-left">
          <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
            {props?.collapsed && (
              <div
                className="collapse-toggle-button-header"
                onClick={() => {
                  props?.setCollapsed(!props?.collapsed);
                }}
              >
                <AiOutlineDoubleRight size={14} />
              </div>
            )}
          </div>
          <HeaderLeft {...props} />
        </div>
        <div className="header-right">
          <Dropdown
            visible={dropdownVisible}
            onVisibleChange={setDropdownVisible}
            overlay={
              <MenuBody
                fullName={fullName}
                truncatedName={truncatedName}
                userId={userId}
                combinedInitials={combinedInitials}
                closeDropdown={closeDropdown}
                disconnectWallet={props?.disconnect}
              />
            }
            trigger={["click"]}
          >
            <div className="avatar-wrapper" onClick={toggleDropdown}>
              <img src={badge} alt="Background" className="background-image" />
              <div className="header-dropdown-avatars">{combinedInitials}</div>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

const MenuBody = ({
  fullName,
  truncatedName,
  userId,
  combinedInitials,
  closeDropdown,
  disconnectWallet,
}) => {
  return (
    <Menu style={{ backgroundColor: "#242424" }}>
      <div className="header-dropdown-menu">
        <div className="header-dropdown-menu-top">
          <div className="header-dropdown-menu-top-inner">
            <div className="header-dropdown-menu-avatar-wrapper">
              <Avatar className="header-dropdowns-avatar" size={64}>
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "30px",
                    fontWeight: "400",
                  }}
                >
                  {combinedInitials}
                </span>
              </Avatar>
              <div className="badge-wrapper">
                <img src={badge} alt="Badge" className="badge-image" />
                <span
                  className="badge-text"
                  style={{
                    color: "#C1A783",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  EARLY BIRD BADGE
                </span>
              </div>
            </div>

            <div className="header-dropdown-menu-info">
              <div className="header-dropdown-menu-name">{truncatedName}</div>
              <div className="header-dropdown-menu-id">
                User ID <span style={{ color: "#F6F6F6" }}>{userId}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="header-dropdown-menu-bottom">
          <div style={{ height: "8px" }}></div>
          <Link
            to={"/user/profile-settings"}
            style={{ textDecoration: "none" }}
            onClick={() => closeDropdown()}
          >
            <div className="header-dropdown-menu-item">Profile</div>
          </Link>
          <div
            className="header-dropdown-menu-item"
            onClick={() => {
              LogOutCurrentSession();
              closeDropdown();
              disconnectWallet();
            }}
          >
            Log out
          </div>
        </div>
      </div>
    </Menu>
  );
};

const HeaderLeft = (props) => {
  const GlobalProfileData = useSelector(
    (state) => state.global.GlobalProfileData
  );
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: "32px",
      }}
    >
      <div>
        <CurrencySwitch />
      </div>
      <div
        style={{
          width: "318px",
          height: "40px",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <div>Wallet</div>
        <div>
          {GlobalProfileData?.investorStatus === "NOT_VERIFIED" ||
          GlobalProfileData?.investorStatus === "REGISTRATION" ? (
            <>
              <Tooltip title="Verification under Progress">
                <div className="div-navbar-wallet-connect">
                  <div
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "400",
                    }}
                  >
                    Connect wallet
                  </div>
                  <div>
                    <img src={add_circle} alt="add circle" />
                  </div>
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              <WalletConnectNavBar {...props} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
// const CurrencySwitch = () => {
//     const dispatch = useDispatch();
//     const SelectedStableCoin = useSelector((state) => state?.global?.SelectedStableCoin);
//     const [stableCoin,setStableCoin] = useState(SelectedStableCoin)
//     const SetStableCoin=(coin)=>{
//         setStableCoin(coin)
//         dispatch(setSelectedStableCoin(coin))
//         setTimeout(() => {
//             window.location.reload();
//         }, 1000);
//     }

//     const MenuItems = () => {
//         return(
//             <div className="header-dropdown-menu-bottom-switch"  >
//                 <div style={{height:"8px"}}></div>
//                 <div className="header-dropdown-menu-item" onClick={() => {SetStableCoin('USDT')}} style={stableCoin === "USDT" ? {borderLeft: "4px solid #EC441F"} : {borderLeft: "4px solid transparent "}}>
//                     <div><img src={usdt_icon} alt='usdt'></img></div><div style={{width:"84px",fontSize:"16px",fontWeight:"400",lineHeight:"20px"}}>USDT</div>{stableCoin === "USDT"?<div><img src={check_icon} alt='check'></img></div>:<></>}
//                 </div>
//                 <div className="header-dropdown-menu-item" onClick={() => {SetStableCoin('USDC')}} style={stableCoin === "USDC" ? {borderLeft: "4px solid #EC441F"} : {borderLeft: "4px solid transparent"}}>
//                     <div><img src={usdc_icon} alt='usdc'></img></div><div style={{width:"84px",fontSize:"16px",fontWeight:"400",lineHeight:"20px"}}>USDC</div>{stableCoin === "USDC"?<div><img src={check_icon} alt='check'></img></div>:<></>}
//                 </div>
//             </div>
//         )
//     }

//     useEffect(() => {
//         console.log(SelectedStableCoin);
//     }, [SelectedStableCoin]);
//         return (
//         <Dropdown dropdownRender={(menu) => (<MenuItems />)} trigger={['click']} >
//             <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:"8px"}}>
//                <div>Curency</div>
//                 <div style={{ height:"40px",width:"164px",borderRadius:"3px",border:"1px",display:"flex",flexDirection:"row",alignItems:"center",padding:"8px",gap:"8px",backgroundColor:"#FFFFFF",borderBlockColor:"#24242459",cursor:"pointer"}}>
//                     <div><img src={stableCoin === "USDT" ? usdt_icon : usdc_icon} alt={stableCoin}></img></div><div style={{width:"84px",fontSize:"16px",fontWeight:"400",lineHeight:"20px"}}>{stableCoin}</div><div><CaretDownOutlined /></div>
//                     {/* {stableCoin === "USDT" ? "₮" : "($)"} */}
//                 </div>
//             </div>
//         </Dropdown>
//     )
// }

const CurrencySwitch = () => {
  const dispatch = useDispatch();
  const SelectedStableCoin = useSelector(
    (state) => state?.global?.SelectedStableCoin
  );
  const [stableCoin, setStableCoin] = useState(SelectedStableCoin);

  const SetStableCoin = (coin) => {
    setStableCoin(coin);

    dispatch(setSelectedStableCoin(coin));
    dispatch(setCurrencyCode(coin));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const menu = (
    <Menu style={{ backgroundColor: "#242424" }}>
      <div className="header-dropdown-menu-bottom-switch">
        <div style={{ height: "8px" }}></div>
        <div
          className="header-dropdown-menu-item"
          onClick={() => {
            SetStableCoin("USDT");
          }}
          style={
            stableCoin === "USDT"
              ? { borderLeft: "4px solid #EC441F" }
              : { borderLeft: "4px solid transparent " }
          }
        >
          <div>
            <img src={usdt_icon} alt="usdt"></img>
          </div>
          <div
            style={{
              width: "84px",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "20px",
            }}
          >
            USDT
          </div>
          {stableCoin === "USDT" ? (
            <div>
              <img src={check_icon} alt="check"></img>
            </div>
          ) : (
            <></>
          )}{" "}
        </div>
        <div
          className="header-dropdown-menu-item"
          onClick={() => {
            SetStableCoin("USDC");
          }}
          style={
            stableCoin === "USDC"
              ? { borderLeft: "4px solid #EC441F" }
              : { borderLeft: "4px solid transparent" }
          }
        >
          <div>
            <img src={usdc_icon} alt="usdc"></img>
          </div>
          <div
            style={{
              width: "84px",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "20px",
            }}
          >
            USDC
          </div>
          {stableCoin === "USDC" ? (
            <div>
              <img src={check_icon} alt="check"></img>
            </div>
          ) : (
            <></>
          )}
        </div>{" "}
      </div>
    </Menu>
  );

  useEffect(() => {
    console.log(SelectedStableCoin);
  }, [SelectedStableCoin]);

  return (
    <div style={{ position: "relative" }}>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomLeft">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <div>Stablecoin</div>
          <div
            style={{
              height: "40px",
              width: "164px",
              borderRadius: "3px",
              border: "1px solid #24242459",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px",
              gap: "8px",
              backgroundColor: "#FFFFFF",
              cursor: "pointer",
            }}
          >
            <img
              src={stableCoin === "USDT" ? usdt_icon : usdc_icon}
              alt={stableCoin}
            />
            <div
              style={{
                width: "84px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
              }}
            >
              {stableCoin}
            </div>
            <CaretDownOutlined />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
