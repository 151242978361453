// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loan-details-card-heading{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.4px;
    color: #242424;
}
.loan-details-divider{
    height: 12px;
}
.loan-details-horizontal-line{
    height: 1px;
    background: #EC441F;
    width: 100%;
}
.loan-details-font-size{
    font-size: 16px;
    }
    .loan-details-font-bold{
        font-weight: 700;
        color: black;
    }
    .loan-details-subdivision {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      .loan-details-subdivision-left,
      .loan-details-subdivision-right {
        flex: 1 1; /* Make both columns take up equal space */
        text-align: left; /* Ensure text is aligned to the left */
      }
      
      .loan-details-subdivision-left {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      
      .loan-details-subdivision-right {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }`, "",{"version":3,"sources":["webpack://./src/Components/LoanDetailsComponents/LoanDetailsCard/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,cAAc;AAClB;AACA;IACI,YAAY;AAChB;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,eAAe;IACf;IACA;QACI,gBAAgB;QAChB,YAAY;IAChB;IACA;QACI,aAAa;QACb,8BAA8B;QAC9B,mBAAmB;MACrB;;MAEA;;QAEE,SAAO,EAAE,0CAA0C;QACnD,gBAAgB,EAAE,uCAAuC;MAC3D;;MAEA;QACE,kBAAkB;QAClB,eAAe;QACf,gBAAgB;QAChB,iBAAiB;MACnB;;MAEA;QACE,kBAAkB;QAClB,eAAe;QACf,gBAAgB;QAChB,iBAAiB;MACnB","sourcesContent":[".loan-details-card-heading{\n    font-size: 20px;\n    font-weight: 700;\n    letter-spacing: -0.4px;\n    color: #242424;\n}\n.loan-details-divider{\n    height: 12px;\n}\n.loan-details-horizontal-line{\n    height: 1px;\n    background: #EC441F;\n    width: 100%;\n}\n.loan-details-font-size{\n    font-size: 16px;\n    }\n    .loan-details-font-bold{\n        font-weight: 700;\n        color: black;\n    }\n    .loan-details-subdivision {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n      }\n      \n      .loan-details-subdivision-left,\n      .loan-details-subdivision-right {\n        flex: 1; /* Make both columns take up equal space */\n        text-align: left; /* Ensure text is aligned to the left */\n      }\n      \n      .loan-details-subdivision-left {\n        font-family: Inter;\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 20px;\n      }\n      \n      .loan-details-subdivision-right {\n        font-family: Inter;\n        font-size: 16px;\n        font-weight: 500;\n        line-height: 24px;\n      }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
