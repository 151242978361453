export async function generateFadedColors(baseColor,data) {
  const fadedColors = [];
  
  // Define the initial colors
  const initialColors = ['#EC441F', '#000000', '#5C5C5C', '#6E7869','#C1A783'];

  // Add initial colors to the list
  for (let i = 0; i < Math.min(data?.length, initialColors?.length); i++) {
    fadedColors.push(initialColors[i]);
  }

  // If data size is less than or equal to the initial colors length, return the colors
  if (data?.length <= initialColors?.length) {
    return fadedColors;
  }

  // Convert base color to HSL
  const baseHSL = hexToHSL(baseColor);
  
  // Calculate maximum value in data array
  const maxDataValue = Math.max(...data);
  
  // Define the available shades to interpolate between
  const shades = ['#E0DDDC', '#92908E', '#C1A783'];

  // Calculate the number of steps required for interpolation
  const numSteps = data.length - initialColors.length;
  
  // Iterate over remaining data array
  for (let i = initialColors.length; i < data.length; i++) {
    // Calculate lightness based on data intensity (adjust as needed)
    let lightness;
    if (i === initialColors.length) {
      lightness = 50; // Set initial shade to 50%
    } else {
      // Calculate the index of the shade to interpolate between based on the current index and the number of steps
      const shadeIndex = Math.floor(((i - initialColors.length) / numSteps) * (shades.length - 1));
      // Interpolate between shades
      lightness = interpolateShade(baseHSL.l, shades[shadeIndex], shades[shadeIndex + 1]);
    }

    // Generate faded color using adjusted lightness
    const fadedColor = hslToHex(baseHSL.h, baseHSL.s, lightness);
    
    // Push faded color to array
    fadedColors.push(fadedColor);
  }
  
  return fadedColors;
}
  
function hexToHSL(hex) {
  if (!hex) return { h: 0, s: 0, l: 0 }; // Handle undefined or falsy values
  // Convert hex to RGB
  const r = parseInt(hex.substring(1, 3), 16) / 255;
  const g = parseInt(hex.substring(3, 5), 16) / 255;
  const b = parseInt(hex.substring(5, 7), 16) / 255;

  // Find min and max values
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  
  // Calculate hue
  let h;
  if (max === min) {
    h = 0;
  } else if (max === r) {
    h = ((g - b) / (max - min)) % 6;
  } else if (max === g) {
    h = (b - r) / (max - min) + 2;
  } else {
    h = (r - g) / (max - min) + 4;
  }
  h *= 60;
  if (h < 0) h += 360;

  // Calculate lightness
  const l = (max + min) / 2;

  // Calculate saturation
  let s;
  if (max === min) {
    s = 0;
  } else if (l <= 0.5) {
    s = (max - min) / (max + min);
  } else {
    s = (max - min) / (2 - max - min);
  }

  return { h, s: s * 100, l: l * 100 };
}

  
  function hslToHex(h, s, l) {
    // Convert HSL to RGB
    s /= 100;
    l /= 100;
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;
    let r, g, b;
    if (h >= 0 && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h >= 60 && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h >= 120 && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h >= 180 && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h >= 240 && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }
  
    // Convert RGB to HEX
    r = Math.round((r + m) * 255).toString(16).padStart(2, '0');
    g = Math.round((g + m) * 255).toString(16).padStart(2, '0');
    b = Math.round((b + m) * 255).toString(16).padStart(2, '0');
  
    return `#${r}${g}${b}`;
  }
  

  function interpolateShade(baseLightness, startShade, endShade) {
    // Convert start and end shades to HSL
    const startHSL = hexToHSL(startShade);
    const endHSL = hexToHSL(endShade);
  
    // Interpolate lightness between start and end shades
    const interpolatedLightness = (baseLightness - startHSL.l) / (endHSL.l - startHSL.l);
  
    // Return the interpolated lightness
    return startHSL.l + (interpolatedLightness * (endHSL.l - startHSL.l));
  }

  export async function generateRandomColors(data) {
    const colors = ['#EC441F', '#92908E'];
    const randomColors = [];
  
    for (let i = 0; i < data.length; i++) {
      const randomIndex = Math.floor(Math.random() * colors.length);
      randomColors.push(colors[randomIndex]);
    }
  
    return randomColors;
  }
  
  export async function generateAlternatingColors(data) {
    console.log(data)
    const colors = ['#92908E', '#EC441F'];
    const alternatingColors = [];
  
    for (let i = 0; i < data?.length; i++) {
      if (data[i] > 0) {
        const colorIndex = i % colors?.length;
        alternatingColors.push(colors[colorIndex]);
      } else {
        alternatingColors.push('#FFFFFF');
      }
    }
    console.log(alternatingColors)
  
    return alternatingColors;
  }

  export async function generateFadedColorsTwo(data) {
    const fadedColors = [];
    
    // Define the initial colors
    const initialColors = ['#EC441F', '#000000', '#5C5C5C', '#6E7869', '#C1A783'];
  
    // Add initial colors to the list up to the data length or initial colors length
    for (let i = 0; i < Math.min(data?.length, initialColors?.length); i++) {
      fadedColors.push(initialColors[i]);
    }
  
    // If data size is greater than the initial colors length, generate faded colors
    if (data?.length > initialColors?.length) {
      // Start fading colors from the last initial color
      let lastColor = initialColors[initialColors?.length - 1];
      for (let i = initialColors?.length; i < data?.length; i++) {
        lastColor = fadeColor(lastColor, 0.9); // Reduce brightness by 10%
        fadedColors.push(lastColor);
      }
    }
  
    return fadedColors;
  }
  
  // Function to fade a color by reducing its brightness
  function fadeColor(color, factor) {
    let {r, g, b} = hexToRgb(color);
    r = Math.floor(r * factor);
    g = Math.floor(g * factor);
    b = Math.floor(b * factor);
    return rgbToHex(r, g, b);
  }
  
  // Convert hex to RGB
  function hexToRgb(hex) {
    let r = 0, g = 0, b = 0;
    if (hex?.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return {r, g, b};
  }
  
  // Convert RGB to hex
  function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }