import React, { useEffect } from "react";
import "./style.css";
import { clientApi, GetProfile } from "../../Utils/AxiosCall";
import { useNavigate } from "react-router-dom";

const UnderMaintenance = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const data = await clientApi.get("/api/investor/profile");
                console.log(data, "data");
                if (data && data?.data?.firstName) {
                        navigate('/');
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        // Fetch profile on component mount
        fetchProfile();
    }, []);

    return (
        <div id="main">
            <div className="fof">
                <h1>We're sorry, something went wrong</h1>
                <p>We apologize for the inconvenience, please wait or try again after some time.</p>
            </div>
        </div>
    );
};

export default UnderMaintenance;