import React from 'react';
import './DatePickerComponent.css';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

const DatePickerComponent = ({ disablingDate, dateFormat, placeholder, value, onChange, suffixIcon, className }) => {
  const dayjsValue = value ? dayjs(value) : null;
  const disabledDate =disablingDate?dayjs(disablingDate):null
console.log(disablingDate);console.log(disabledDate)
  return (
    <DatePicker
      placeholder={placeholder || ""}
      className={className}
      style={{ width: "100%" }}
      defaultValue={dayjs()}
      value={dayjsValue || !placeholder && dayjs()}
      format={dateFormat || ""} 
      onChange={(date, dateString) => onChange(date)}
      disabledDate={(current) => (
        // current && current.isAfter(dayjs())
        disabledDate?
        current && (
          (disabledDate && current.isBefore(disabledDate)) || current.isAfter(dayjs())
        )
        :current && current.isAfter(dayjs())
      )}
      // disabledDate={disabledDate}
      dis
      popupClassName="noFooterTimePick"
      suffixIcon={<img src={suffixIcon} alt='icon'/>}
      popupStyle={{ zIndex: 999 }}
      renderExtraFooter={() => null} 
      allowClear={false}
    />
  );
};

export default DatePickerComponent;