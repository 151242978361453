import React, { useEffect } from 'react'
import "./style.css"
import countries from 'i18n-iso-countries';
import LoanState from '../LoanState/LoanState';
import { CountryCodes } from '../../../Helper_Data/CountryCodes';
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
export default function LoanDetailsCard({ data, AmountConverter }) {
  let countryName = countries.getName(data?.countryCode, 'en', { select: 'official' });
  function findCodeByLabel(label) {
    let country = CountryCodes.find(country => country.label === label);
    return country ? country.code : null;
  }
  let countryCode = findCodeByLabel(countryName);
  useEffect(() => { countryCode = findCodeByLabel(countryName) }, [data?.countryCode])
  return (
    <>
    <div >
    <LoanState fundedPercent={data?.fundedPercent} amountFunded={data?.amountFunded}
        currencySymbol={data?.currencySymbol} amountAvailable={data?.amountAvailable}
        availablePercent={data?.availablePercent} AmountConverter={AmountConverter}
        myInvestmentsPercent={data?.myInvestmentsPercent}
        myInvestments={data?.myInvestments}
      />
      <div style={{ display: "flex", flexDirection: "column", paddingTop: "48px" }}>
        <div className='loan-details-card-heading'>Loan details</div>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px", paddingTop: "24px" }}>
          <div className='loan-details-subdivision'>
            <div className='loan-details-subdivision-left'>Loan type</div>
            <div className='loan-details-subdivision-right'>{data?.productLabel}</div>
          </div>
          <div className='loan-details-subdivision'>
            <div className='loan-details-subdivision-left'>Lender</div>
            <div className='loan-details-subdivision-right'>{data?.originatorCompanyName}</div>
          </div>
          <div className='loan-details-subdivision'>
            <div className='loan-details-subdivision-left'>Country</div>
            <div className='loan-details-subdivision-right'><img src={`https://flagcdn.com/${countryCode?.toLowerCase()}.svg`} alt={countryName} style={{ marginRight: '8px', maxHeight: "15px", maxWidth: "15px" }} /> {countryName}</div>
          </div>
          <div className='loan-details-subdivision'>
            <div className='loan-details-subdivision-left'>Listed on the platform</div>
            <div className='loan-details-subdivision-right'>{data?.issueDate}</div>
          </div>
          <div className='loan-details-subdivision'>
            <div className='loan-details-subdivision-left'>Loan maturity</div>
            <div className='loan-details-subdivision-right'>{data?.maturityDate}</div>
          </div>
          <div className='loan-details-subdivision'>
            <div className='loan-details-subdivision-left'>Remaining loan maturity</div>
            <div className='loan-details-subdivision-right'>
              {data?.termInDays || "0"} Days
            </div>
          </div>
          <div className='loan-details-subdivision'>
            <div className='loan-details-subdivision-left'>Buy-back obligation</div>
            <div className='loan-details-subdivision-right'>
              {
                data?.hasBuyback ? "Yes, loan has buy-back obligation" : "No, loan doesn't have buy-back obligation"
              }
            </div>
          </div>
          <div className='loan-details-subdivision'>
            <div className='loan-details-subdivision-left'>Extensions</div>
            <div className='loan-details-subdivision-right'>
              {data?.extensions > 0 ?
                <>
                  Extended {data?.extensions} times for {data?.extendedForDays} days
                </>
                :
                <>-</>
              }
            </div>
          </div>
        </div>
      </div>
    </div>


    </>
  )
}
