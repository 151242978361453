import React from "react";
import { Select } from "antd";
import { IoMdArrowDropdown } from "react-icons/io";
export default function SignUpSelectBox({
  label,
  isRequired,
  placeholder,
  onChange,
  value,
  error,
  validationError,
  options,
  defaultValue,
  showSearch,
}) {
  return (
    <div>
      {label && (
        <div>
          <span className="signUpLabelClass">{label}</span>
          {isRequired && <span className="signUpLabelRequiredClass">*</span>}
        </div>
      )}
      <div style={{ height: "4px" }}></div>
      <div className="custom-select-box new_select_normal_border">
        <Select
          style={{ width: "100%", height: "56px" }}
          placeholder={placeholder || ""}
          className={`filter-felid-width-style filter-select-style ${
            error?.error || validationError?.error
              ? "new_input_error_border"
              : "new_input_normal_border"
          }`}
          defaultValue={defaultValue || ""}
          value={value}
          onChange={onChange}
          options={options}
          suffixIcon={<IoMdArrowDropdown color="black" size={14} />}
          showSearch={showSearch || false} // Enable search functionality
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
        {validationError?.message && (
          <div className="error-message">
            {!validationError?.exists ? validationError?.message : ""}
          </div>
        )}
      </div>
    </div>
  );
}
