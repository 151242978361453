import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    value: 0,
    visibleCreateAPIModal: false,
    nftTableView: true,
    nftSaleTableView: true,
    addNewLoansModal: false,
    createAssetFormModal: false,
    assetCreationSuccessModal: false,
    connectWallet: false,
    createAssetButtonData: {status: false, count: 0, loans: []},
    addNewLoansData: {
        status: false,
        count: 0,
        loans: [],
        currentPercentage: "",
    },
    createdAssetData: {},
    primaryInvestBasket: {},
    secondaryInvestBasket: {},
    primaryInvestBasketModal: false,
    secondaryInvestBasketModal: false,
    secondaryBuyModal: false,
    secondaryBuyBasket: {},
};

export const dashboard = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        openCreateAPIModal: (state) => {
            state.visibleCreateAPIModal = true;
        },
        closeCreateAPIModal: (state) => {
            state.visibleCreateAPIModal = false;
        },
        togglenftTableView: (state) => {
            state.nftTableView = !state.nftTableView;
        },
        togglenftSaleTableView: (state) => {
            state.nftSaleTableView = !state.nftSaleTableView;
        },
        toggleAddNewLoansModal: (state) => {
            state.addNewLoansModal = !state.addNewLoansModal;
        },
        toggleCreateAssetModal: (state) => {
            state.createAssetFormModal = !state.createAssetFormModal;
        },
        toggleAssetCreationSuccessModal: (state) => {
            state.assetCreationSuccessModal = !state.assetCreationSuccessModal;
        },
        connectWalletOn: (state) => {
            state.connectWallet = !state.connectWallet;
        },
        createAssetButtonData: (state, action) => {
            state.createAssetButtonData = {
                // ...action.payload,
                count: action.payload.count,
                // loans: [...state.createAssetButtonData.loans, action.payload.loans],
                loans: [...action.payload.loans],
            };
        },
        addNewLoansData: (state, action) => {
            state.addNewLoansData = {
                // ...action.payload,
                count: action.payload.count,
                // loans: [...state.createAssetButtonData.loans, action.payload.loans],
                loans: [...action.payload.loans],
                currentPercentage: action.payload.currentPercentage,
            };
        },
        setCreatedAssetData: (state, action) => {
            state.createdAssetData = {...action.payload};
        },
        primaryInvestBasket: (state, action) => {
            state.primaryInvestBasket = {...action.payload};
        },
        secondaryInvestBasket: (state, action) => {
            state.secondaryInvestBasket = {...action.payload};
        },
        primaryInvestBasketModal: (state) => {
            state.primaryInvestBasketModal = !state.primaryInvestBasketModal;
        },
        secondaryInvestBasketModal: (state) => {
            state.secondaryInvestBasketModal = !state.secondaryInvestBasketModal;
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload;
        },
        secondaryBuyModal: (state) => {
            state.secondaryBuyModal = !state.secondaryBuyModal;
        },
        secondaryBuyBasket: (state, action) => {
            state.secondaryBuyBasket = {...action.payload};
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    openCreateAPIModal,
    closeCreateAPIModal,
    togglenftTableView,
    togglenftSaleTableView,
    toggleAddNewLoansModal,
    toggleCreateAssetModal,
    toggleAssetCreationSuccessModal,
    connectWalletOn,
    createAssetButtonData,
    setCreatedAssetData,
    incrementByAmount,
    addNewLoansData,
    primaryInvestBasket,
    primaryInvestBasketModal,
    secondaryInvestBasketModal,
    secondaryInvestBasket,
    secondaryBuyModal,
    secondaryBuyBasket,
} = dashboard.actions;

export default dashboard.reducer;
