import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { clientApi } from "../../Utils/AxiosCall";
import { getWebDeviceData } from "../../Utils/common";
import { CountryCodes } from "../../Helper_Data/CountryCodes";
import { FiChevronDown } from "react-icons/fi";
import { FiEye, FiEyeOff } from "react-icons/fi";

const InvestorSignUpPage = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [gToken, setgToken] = useState("");
  const [err, setErr] = useState({ status: false, message: "" });
  const [isVerifyModalOpen, setIsVerifyModel] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isOtpModalOpen, setIsOtpModel] = useState(false);
  const [otp, setOtp] = useState("");
  const [formValue, setFormValue] = useState({
    email: "",
    code: "",
    mobile: "",
  });

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const createBackendUser = async (data) => {
    try {
      const response = await clientApi.post(
        "/api/investor/public/sign-up",
        {
          ...data,
        },
        headers
      );
      console.log(response.headers);
      localStorage.setItem("investorId", response.data.investorNumber);
      localStorage.setItem("investorEmail", response.data.email);
      if (response) {
        navigate("/user/personal-data");
      }
    } catch (error) {
      console.log(error.response.data);
      let errMsg;
      if (error?.response?.data?.validation?.errors[0] === "INVALID_LOGIN")
        errMsg = "Email or password is wrong. Please try again!";
      else if (
        error?.response?.data?.validation?.fieldErrors?.email === "NOT_UNIQUE"
      )
        errMsg =
          "The Email id already used. Please try again with another email id!";
      setLoader(false);
      setErr({ status: true, message: errMsg });
    }
  };

  const { Option } = Select;

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 100 }}>
        {CountryCodes.map((country) => (
          <Option key={country.code} value={country.value}>
            {country.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const onFinish = async (values) => {
    console.log(values);
    console.log(phoneCode + phoneNumber);
    if (
      !values.email ||
      !phoneNumber ||
      !values.password ||
      !values.cPassword
    ) {
      return setErr({ status: true, message: "Please fill all fields!" });
    }

    if (values.password !== values.cPassword) {
      return setErr({ status: true, message: "Passwords doesn't match!" });
    }

    if (!values.agree) {
      return setErr({
        status: true,
        message: "Please read and Agree to our Terms and Conditions",
      });
    }
    setFormValue({
      email: values.email,
      code: "91",
      mobile: values.mobile,
    });
    const webDeviceData = await getWebDeviceData();
    setLoader(true);
    // const [firstName, lastName] = values.name.split(" ");
    // console.log(phoneCode, phoneNumber);
    // console.log(phoneCode+phoneNumber)
    // return
    try {
      await createBackendUser({
        // name: values.name,
        // firstName,
        // lastName,
        email: values.email,
        countryCode: phoneCode,
        phoneNumber: phoneNumber,
        password: values.password,
        passwordRepeat: values.cPassword,
        termsOfUseConsent: values.agree,
        privacyPolicyConsent: values.agree,
        walletAddress: values.walletAddress,
        isCompany: false,
        promoCode: null,
        marketingConsent: true,
        localeCode: "EN",
        webDeviceData: webDeviceData || null,
      });
      setLoader(false);
    } catch (error) {}
  };
  const [phoneNumber, setPhoneNumber] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const [phoneCode, setPhoneCode] = useState("+49");
  const [filteredCountries, setFilteredCountries] = useState(CountryCodes);

  const handleInputKeyDown = (e) => {
    const char = e.key.toUpperCase();
    console.log(char);
    // Enable when you want to filter the countries based on the first character of the country name

    // const filtered = CountryCodes.filter(country => country.label.charAt(0).toUpperCase() === char);
    // if (filtered.length > 0){
    //     setFilteredCountries(filtered);
    // }else{
    //   setFilteredCountries(CountryCodes);
    // }
    setFilteredCountries(CountryCodes);
  };

  const handleDropdownVisibleChange = (open) => {
    if (!open) {
      setFilteredCountries(CountryCodes); // Reset the list when dropdown is closed
    }
  };
  return (
    <>
      <div className="onboarding-page-container">
        <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
          <div
            style={{
              width: "40px",
              height: "10px",
              backgroundColor: "#92908F",
            }}
          ></div>
          <div
            style={{
              width: "20px",
              height: "10px",
              backgroundColor: "#000000",
            }}
          ></div>
          <div
            style={{
              width: "20px",
              height: "10px",
              backgroundColor: "#000000",
            }}
          ></div>
        </div>
        <center className="login-signup-heading" style={{ marginTop: "48px" }}>
          Registration
        </center>
        <Form
          layout="vertical"
          onFinish={onFinish}
          // onValuesChange={onFormLayoutChange}
          className="onboarding-page-form custom-select-box"
        >
          <Form.Item
            name="email"
            className="login-signup-general-margin-bottom"
          >
            <Input placeholder="Email" className="input_signup" />
          </Form.Item>
          <div
            className="login-signup-general-margin-bottom"
            style={{ display: "flex" }}
          >
            <Select
              style={{
                maxWidth: "92px",
                borderRadius: "0px",
                marginRight: "24px",
              }}
              dropdownStyle={{ width: "200px", borderRadius: "0px" }}
              optionLabelProp="label"
              className="sign_up_select_code"
              defaultValue={phoneCode}
              onChange={(value) => setPhoneCode(value)}
              onInputKeyDown={handleInputKeyDown}
              onDropdownVisibleChange={handleDropdownVisibleChange}
              suffixIcon={<FiChevronDown color="black" size={14} />}
            >
              {filteredCountries.map((country) => (
                <Option
                  key={country.code}
                  label={<>{country.value}</>}
                  value={country.value}
                >
                  <img
                    src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}
                    alt={country.label}
                    style={{
                      marginRight: "8px",
                      maxHeight: "15px",
                      maxWidth: "15px",
                    }}
                  />
                  <span>
                    {country.label} &nbsp;{country.value}
                  </span>
                </Option>
              ))}
            </Select>
            <Input
              type="text"
              placeholder="Phone Number"
              className="input_signup"
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const inputValue = e.target.value;
                // Regular expression to match only numeric values
                const regex = /^[0-9]*$/;
                if (regex.test(inputValue)) {
                  setPhoneNumber(inputValue);
                }
              }}
            />
          </div>
          <Form.Item
            name="walletAddress"
            className="login-signup-general-margin-bottom"
          >
            <Input
              placeholder="Wallet Address (Optional)"
              className="input_signup"
            />
          </Form.Item>
          <Form.Item
            name="password"
            className="login-signup-general-margin-bottom"
          >
            <Input.Password
              className="input_signup"
              placeholder="Password"
              iconRender={(visible) => (visible ? <FiEye /> : <FiEyeOff />)}
              visibilityToggle
            />
          </Form.Item>
          <Form.Item name="cPassword">
            <Input.Password
              className="input_signup"
              placeholder="Confirm Password"
              iconRender={(visible) => (visible ? <FiEye /> : <FiEyeOff />)}
              visibilityToggle
            />
          </Form.Item>
          <div style={{ height: "8px" }}></div>
          <div className="couston-login">
            <Form.Item name="agree" valuePropName="checked" className="">
              <Checkbox>
                I have read and agree to the
                <Link
                  style={{
                    marginLeft: 8,
                    marginRight: 8,
                    color: "#EC441F",
                    textDecoration: "none",
                  }}
                >
                  Terms of Use
                </Link>
                and
                <Link
                  style={{
                    marginLeft: 8,
                    color: "#EC441F",
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </Link>
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="newsletter"
              className="check-box-style-singup"
              valuePropName="checked"
            >
              <Checkbox
                className="new-checkbox"
                style={{ borderRadius: "0px" }}
              />
              &nbsp; I would like to receive FU Capital newsletter
            </Form.Item>
          </div>

          <div style={{ height: "24px" }}></div>
          {err.status && (
            <p>
              <span style={{ color: "tomato" }}>{err.message}</span>
            </p>
          )}

          <Form.Item>
            <Button
              className="new_button_filled hight-56"
              style={{ width: "100%" }}
              htmlType="submit"
              loading={loader}
            >
              Next
            </Button>
          </Form.Item>
        </Form>

        <div style={{ marginTop: "24px" }}>
          <div>
            <p>
              Have an account?
              <Link
                style={{ marginLeft: 8, color: "#EC441F" }}
                onClick={() => window.location.assign("/user/login")}
              >
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorSignUpPage;
