import { message, Table, Pagination, Button, Input, Select, Modal } from "antd";
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  GetProfile,
  GetWalletBalance,
  HandelError,
  clientApi,
} from "../../Utils/AxiosCall";
import ReactCountryFlag from "react-country-flag";
import "./style.css";
import { FiFileText } from "react-icons/fi";
import countries from "i18n-iso-countries";
import { Link, useNavigate } from "react-router-dom";
import InvestingOnHold from "../StatusModels/InvestingOnHold";
import MarketPrimaryInvestSuccessModal from "../SuccessModal/MarketPrimaryInvestSuccessModal";
import {
  AmountConverter,
  StablecoinToUSDConverter,
  StablecoinWarperFunction,
  newNumberformat,
} from "../../Helper/HelperFunction";
import { PageSizeList1, WalletTestData } from "../../Helper_Data/SelectBoxData";
import { MdArrowDropDown } from "react-icons/md";
import close_icon from "../../Assets/images/IconButton.svg";
import agreement_icon from "../../Assets/images/description.svg";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { isValid } from "i18n-iso-countries";
import { IoMdArrowDropdown } from "react-icons/io";
import { MetaMaskAvatar } from "react-metamask-avatar";
import add_circle from "../../Assets/images/add-circle-outline.svg";
import add_circle_outline_disabled from "../../Assets/images/add-circle-outline-disabled.svg";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const { Option } = Select;
const GuestPrimaryMarketTable = forwardRef(
  (
    {
      filters,
      availableLoan,
      setAvailableLoan,
      isOpened,
      setIsOpened,
      setTotalCount,
    },
    ref
  ) => {
    const GlobalProfileData = useSelector(
      (state) => state.global.GlobalProfileData
    );
    const [apiLoad, setApiLoad] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [isModalOpenAll, setIsModalOpenAll] = useState(false);
    const [veriffStatus, setVeriffStatus] = useState("false");
    const [profileData, setProfileData] = useState("");
    const [holdInvesting, setHoldInvesting] = useState(false);
    const [InvestmentSucessfulModalStatus, setInvestmentSucessfulModalStatus] =
      useState(false);
    const [InvestmentSucessfulModalData, setInvestmentSucessfulData] =
      useState("");

    let filter = {};
    const checkFilter = async () => {
      if (filters.buybackOnly) filter.buybackOnly = filters.buybackOnly;
      if (filters.collectionStatuses.length > 0)
        filter.collectionStatuses = filters.collectionStatuses;
      if (filters.countries?.length > 0) filter.countries = filters.countries;
      if (filters.currencyCode) filter.currencyCode = filters.currencyCode;
      if (filters.excludeAlreadyInvested)
        filter.excludeAlreadyInvested = filters.excludeAlreadyInvested;
      if (filters.extensions === true || filters.extensions === false)
        filter.extensions = filters.extensions;
      if (
        filters.interestRatePercentFrom !== null &&
        filters.interestRatePercentFrom !== ""
      )
        filter.interestRatePercentFrom = filters.interestRatePercentFrom;
      if (
        filters.interestRatePercentTo !== null &&
        filters.interestRatePercentTo !== ""
      )
        filter.interestRatePercentTo = filters.interestRatePercentTo;
      if (filters.loanIds.length > 0) filter.loanIds = filters.loanIds;
      if (filters.originators?.length > 0)
        filter.originators = filters.originators;
      if (
        filters.principalOfferFrom !== null &&
        filters.principalOfferFrom !== ""
      )
        filter.principalOfferFrom = filters.principalOfferFrom;
      if (filters.principalOfferTo !== null && filters.principalOfferTo !== "")
        filter.principalOfferTo = filters.principalOfferTo;
      if (filters.products?.length > 0) filter.products = filters.products;
      if (
        filters.remainingTermInDaysFrom !== null &&
        filters.remainingTermInDaysFrom !== ""
      )
        filter.remainingTermInDaysFrom = filters.remainingTermInDaysFrom;
      if (
        filters.remainingTermInDaysTo !== null &&
        filters.remainingTermInDaysTo !== ""
      )
        filter.remainingTermInDaysTo = filters.remainingTermInDaysTo;
      return filter;
    };

    const getData = async () => {
      setApiLoad(true);
      await checkFilter();
      console.log(filter);
      clientApi
        .post("/api/investor/public/query-primary-market", {
          filter: filter,
          page,
          pageSize,
        })
        .then(async (res) => {
          setTotalCount(res?.data);
          setAvailableLoan(res.data?.total);
          setTableData(res.data?.items);
          setTotalItems(res.data?.total);
          setApiLoad(false);
        })
        .catch((err) => {
          setApiLoad(false);
          console.log(err);
        });
    };

    useEffect(() => {
      getData();
      GetProfileDetailes();
      const GetData = async () => {
        const response = await localStorage.getItem("investorStatus");
        setVeriffStatus(response);
      };
      GetData();
    }, []);
    useImperativeHandle(ref, () => ({
      getData,
    }));

    const GetProfileDetailes = async () => {
      // const response = await GetProfile()
      // setProfileData(response)
      // if (response?.holdInvesting) {
      //   return setHoldInvesting(true)
      // }
    };

    const [formState, setFormState] = useState({
      loanId: "",
      amountToInvest: "0",
      cashAvailable: "0.0",
      principalAvailable: "",
      currencySymbol: "",
      interestRatePercent: "",
      minimumInterestRate: "",
      maximumInterestRate: "",
      minInvestment: "",
    });

    const [isOpenModalInvestSelected, setIsOpenModalInvestSelected] =
      useState(false);
    const handleInvest = async (
      id,
      interestRatePercent,
      type,
      amountAvailable
    ) => {
      if (veriffStatus === "NOT_VERIFIED") {
        return setIsOpened(true);
      }
      GetProfileDetailes();
      if (!profileData?.holdInvesting) {
        if (type === 1) {
          const interestRates = tableData.map(
            (item) => item.interestRatePercent
          );
          const amountAvailable = tableData.map((item) => item.amountAvailable);

          const maxInterestRate = Math.max(...interestRates);
          const minInterestRate = Math.min(...interestRates);
          const minInvestment = Math.min(...amountAvailable);

          console.log(`Maximum Interest Rate: ${maxInterestRate}%`);
          console.log(`Minimum Interest Rate: ${minInterestRate}%`);

          console.log(` Amount Available: ${minInvestment}`);
          setFormState({
            ...formState,
            minimumInterestRate: minInterestRate,
            maximumInterestRate: maxInterestRate,
            minInvestment: minInvestment,
          });

          return setIsModalOpenAll(true);
        }
        try {
          // const response = await clientApi.post(`/api/investor/invest-options`, { loanId: id })
          // if (response?.status === 200) {
          setFormState({
            loanId: id,
            amountToInvest: "",
            cashAvailable: amountAvailable,
            principalAvailable: amountAvailable,
            currencyCode: "",
            currencySymbol: "",
            interestRatePercent: interestRatePercent,
          });
          // }
          //  type 1 for investAll
          // type 2 for investSelected

          if (type === 2) {
            setIsOpenModalInvestSelected(true);
          }
          // console.log(response)
        } catch (error) {
          HandelError(error);
        }
      }
    };

    const columns1 = [
      {
        title: <span className="table-title">Country</span>,
        dataIndex: "countryCode",
        key: "countryCode",
        align: "center",
        width: 110.4,
        render: (ele, key) => {
          const countryName = countries.getName(ele, "en", {
            select: "official",
          });
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ReactCountryFlag countryCode={ele} svg />
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                {countryName}
              </div>
            </div>
          );
        },
      },
      {
        title: <span className="table-title">Loan Id</span>,
        dataIndex: "loanId",
        align: "center",
        width: 110.4,
        render: (text, record) => (
          <div>
            <div className="" style={{ fontSize: "14px" }}>
              <Link
                className="primary-color"
                style={{ textDecoration: "none" }}
                to={`/user/guest-loan-details/${record.loanId}`}
              >
                <span style={{ borderBottom: "1px solid #EC441F33" }}>
                  {record.loanId}
                </span>
              </Link>{" "}
            </div>
            <div className="" style={{ fontSize: "12px" }}>
              {record.productLabel}
            </div>
          </div>
        ),
      },
      {
        title: <span className="table-title">Lender</span>,
        dataIndex: "originatorCompanyName",
        align: "center",
        width: 110.4,
      },

      {
        title: <span className="table-title">Listed on the platform</span>,
        dataIndex: "issueDate",
        align: "center",
        width: 110.4,
      },
      {
        title: <span className="table-title">Remaining duration</span>,
        dataIndex: "Duration",
        align: "center",
        width: 110.4,
        render: (text, record) => (
          <div>
            {record.termInDays >= 0 ? (
              <>
                <div>
                  {record.termPeriod ? (
                    <>
                      {record.termPeriod.years ? (
                        <>{record.termPeriod.years}y&nbsp;</>
                      ) : (
                        <></>
                      )}
                      {record.termPeriod.months ? (
                        <>{record.termPeriod.months}m&nbsp;</>
                      ) : (
                        <></>
                      )}
                      {record.termPeriod.days ? (
                        <>{record.termPeriod.days}d</>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : record.termInDays < 0 ? (
              <>
                <span style={{ color: "var(--error-color)" }}>
                  {record.termInDays}d
                </span>
              </>
            ) : (
              ""
            )}
          </div>
        ),
      },
      {
        title: "Loan maturity date",
        dataIndex: "maturityDate",
        key: "maturityDate",
        align: "center",
        width: 106.9,
      },
      {
        title: <span className="table-title">Loan amount</span>,
        dataIndex: "principalIssued",
        align: "center",
        width: 110.4,
        render: (text, record) => (
          <div>
            <div>{newNumberformat(record.principalIssued)}</div>
          </div>
        ),
      },
      {
        title: <span className="table-title">Interest </span>,
        dataIndex: "interestRatePercent",
        align: "center",
        width: 110.4,
        render: (text, record) => (
          <div>
            <div>{newNumberformat(record.interestRatePercent)}%</div>
          </div>
        ),
      },
      {
        title: <span className="table-title">Status</span>,
        dataIndex: "termInDays",
        align: "center",
        width: 110.4,
        render: (text, record) => (
          <div>
            <div className="table-column-color">{record.loanStatus}</div>
          </div>
        ),
      },
      {
        title: <span className="table-title">Available loan amount</span>,
        dataIndex: "amountAvailable",
        align: "center",
        width: 110.4,
        render: (text, record) => record.amountAvailable,
      },
      {
        title: <span className="table-title">Deployed balance</span>,
        dataIndex: "myInvestments",
        align: "center",
        // fixed: 'right',
        width: 110.4,
        render: (text, record) => {
          newNumberformat(record.myInvestments);
        },
      },

      {
        title:
          GlobalProfileData?.investorStatus === "NOT_VERIFIED" ||
          GlobalProfileData?.investorStatus === "REGISTRATION" ? (
            <Tooltip title="Verification under Progress">
              <Button
                className="new_button_filled-font-12px"
                style={{ height: 32, width: 96 }}
              >
                Buy all
              </Button>
            </Tooltip>
          ) : (
            <Button
              className="new_button_filled-font-12px"
              onClick={() => {
                handleInvest("", "", 1, "");
              }}
              style={{ height: 32, width: 96 }}
            >
              Buy all
            </Button>
          ),
        dataIndex: "Action",
        key: "Action",
        align: "center",
        width: 110.4,
        render: (record, key) =>
          GlobalProfileData?.investorStatus === "NOT_VERIFIED" ||
          GlobalProfileData?.investorStatus === "REGISTRATION" ? (
            <Tooltip title="Verification under Progress">
              <Button
                className="new_button_filled-font-12px"
                style={{ height: 32, width: 96 }}
              >
                Buy
              </Button>
            </Tooltip>
          ) : (
            <Button
              className="new_button_filled-font-12px"
              onClick={() => {
                console.log(record, key);
                handleInvest(
                  key?.loanId,
                  key?.interestRatePercent,
                  2,
                  key.amountAvailable
                );
              }}
              style={{ height: 32, width: 96 }}
            >
              Buy
            </Button>
          ),
      },
    ];

    const handlePageChange = (pageNumber) => {
      console.log(pageNumber);
      setPage(pageNumber);
    };

    const handlePageSizeChange = (newSize) => {
      console.log(newSize);
      setPageSize(newSize);
    };
    useEffect(() => {
      getData();
    }, [page, pageSize]);

    return (
      <>
        <Table
          columns={columns1}
          dataSource={tableData}
          loading={apiLoad}
          scroll={{ x: "max-content" }}
          pagination={false}
          className="custom-table custom-padding-bottom-24"
          style={{ padding: "0px", padding: "0px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "24px",
            paddingBottom: "24px",
          }}
        >
          <div>
            <Pagination
              current={page}
              pageSize={pageSize}
              total={totalItems}
              onChange={handlePageChange}
              onShowSizeChange={handlePageSizeChange}
              className="custom-pagination"
              pageSizeOptions={["10", "20", "50", "100"]}
              showSizeChanger={false}
            />
          </div>
          <div className="new_input_normal_border_pagination ">
            <Select
              placeholder={"All"}
              className="new_input_normal_border_pagination"
              options={PageSizeList1}
              style={{ width: "100%", height: "40px", paddingRight: "8px" }}
              dropdownStyle={{ zIndex: 1200 }}
              suffixIcon={<MdArrowDropDown color="black" size={24} />}
              // size={"large"}
              value={pageSize}
              onChange={handlePageSizeChange}
            />
          </div>
        </div>

        <InvestModalInvestALL
          isModalOpenAll={isModalOpenAll}
          setIsModalOpenAll={setIsModalOpenAll}
          formState={formState}
          setFormState={setFormState}
          tableData={tableData}
        />
        <InvestModalInvestSelected
          isOpenModalInvestSelected={isOpenModalInvestSelected}
          setIsOpenModalInvestSelected={setIsOpenModalInvestSelected}
          formState={formState}
          setFormState={setFormState}
          getData={getData}
          InvestmentSucessfulModalStatus={InvestmentSucessfulModalStatus}
          setInvestmentSucessfulModalStatus={setInvestmentSucessfulModalStatus}
          setInvestmentSucessfulData={setInvestmentSucessfulData}
        />
        <InvestingOnHold isOpen={holdInvesting} setIsOpen={setHoldInvesting} />
        <MarketPrimaryInvestSuccessModal
          status={InvestmentSucessfulModalStatus}
          setStatus={setInvestmentSucessfulModalStatus}
          data={InvestmentSucessfulModalData}
          setData={setInvestmentSucessfulData}
        />
      </>
    );
  }
);

const InvestModalInvestALL = ({
  isModalOpenAll,
  setIsModalOpenAll,
  tableData,
  formState,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [tableDatas, settableDatas] = useState("");
  const currencyCode = useSelector((state) => state.global.currencyCode);
  const [SelectedWalletAddress, setSelectedWalletAddress] = useState("");
  const [SelectedWalletDetails, setSelectedWalletDetails] = useState({});
  const [walletListDetails, setWalletListDetails] = useState({});
  const SelectedStableCoin = useSelector(
    (state) => state?.global?.SelectedStableCoin
  );
  const handleClose = () => {
    setIsModalOpenAll(false);
  };
  //   const GetAccountOverview = async () => {
  //     try {
  //       // EUR || USD
  //       const response = await clientApi.post('/api/investor/account-summary', { currencyCode: currencyCode, })
  //       console.log(response.data)
  //       if (response?.status === 200) {
  //         setData(response.data)
  //       }
  //     } catch (error) {
  //       console.log(error)
  //       HandelError(error);
  //     }
  //   }

  useEffect(() => {
    // GetAccountOverview()
    // const Get=async()=>{
    //   try {
    //     const response=await GetWalletBalance()
    //     setWalletListDetails(response)
    //   } catch (error) {
    //   }
    // }
    // Get()
  }, []);
  const [totalAmount, setTotalAmount] = useState("0.00");
  const [amount, setAmount] = useState(0);
  async function calculateTotalAmount() {
    const length = tableDatas?.length;
    const amounts = length * amount;
    setTotalAmount(amounts);
  }
  useEffect(() => {
    calculateTotalAmount();
  }, [amount]);
  useEffect(() => {
    console.log(tableData);
    settableDatas(tableData);
  }, [tableData]);
  const Validate = () => {
    let isValid = true;
    const amountToInvest = Number(amount);
    console.log(amountToInvest, SelectedWalletDetails?.walletBalance);

    if (isNaN(amountToInvest) || amount === "") {
      message.error("Please enter a valid amount to invest");
      isValid = false;
    } else if (amountToInvest < 10) {
      message.error("Minimum amount to invest is 10");
      isValid = false;
    } else if (amountToInvest > SelectedWalletDetails?.walletBalance) {
      message.error(
        "Amount to invest should be less than or equal to available amount in linked wallet"
      );
      isValid = false;
    }

    return isValid;
  };
  const handleNavigation = (walletAddress) => {
    if (!Validate()) {
      return;
    }
    const loansQueryString = tableData
      .map((loan) => `loans=${loan.loanId}`)
      .join("&");
    const amountQueryString = `amount=${amount}`;
    const walletAddressQueryString = `walletAddress=${SelectedWalletAddress}`;
    const queryString = `${loansQueryString}&${amountQueryString}&${walletAddressQueryString}`;

    navigate(`/user/bulk-invest?${queryString}`);
  };
  const [balance, setBalance] = useState("0");
  useEffect(() => {
    const fetchBalance = async () => {
      if (data.cashBalance) {
        const amount = data.cashBalance;
        const amounts = await StablecoinWarperFunction(amount);
        setBalance(amounts);
      }
    };

    fetchBalance();
  }, [data]);
  console.log(formState);
  const shortenAddress = (address) => {
    return `${address?.substring(0, 8)}...${address?.substring(
      address?.length - 8
    )}`;
  };
  const handleChange = (value) => {
    const addressToUse = value;
    setSelectedWalletAddress(addressToUse);
    const selectedItem = walletListDetails?.walletBalances?.find(
      (item) => item.walletAddress === addressToUse
    );
    setSelectedWalletDetails(selectedItem);
  };

  const navbarWalletAddress = useSelector(
    (state) => state.global.SelectedWalletAddress
  );

  useEffect(() => {
    console.log(walletListDetails);

    if (navbarWalletAddress) {
      setSelectedWalletAddress(navbarWalletAddress);
      const selectedWallet = walletListDetails?.walletBalances?.find(
        (wallet) => wallet.walletAddress === navbarWalletAddress
      );
      if (selectedWallet) {
        setSelectedWalletDetails(selectedWallet);
      }
    } else if (
      !SelectedWalletAddress &&
      walletListDetails?.walletBalances?.length > 0
    ) {
      setSelectedWalletAddress(
        walletListDetails?.walletBalances[0]?.walletAddress
      );
      setSelectedWalletDetails(walletListDetails?.walletBalances[0]);
    }
  }, [walletListDetails, navbarWalletAddress]);
  return (
    <>
      <div className="investment-modal">
        <Modal
          className="investment-modal"
          open={isModalOpenAll}
          centered
          closable={false}
          footer={null}
          width={560}
          onCancel={() => handleClose()}
          getContainer={false} // This renders the modal as a direct child of the Modal's parent, but might need adjustment based on your DOM structure
        >
          <div style={{ padding: "8px" }}>
            <div
              className="modal-close-icon"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <img src={close_icon} alt="close" onClick={() => handleClose()} />
            </div>
            <div style={{ padding: "0px 24px 32px 24px" }}>
              <div className="invest-modal-header">Buy all</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "48px",
                  paddingBottom: "32px",
                }}
              >
                <div>
                  <div className="invest-modal-subheader">Loan ID</div>
                  <div className="invest-modal-subheader-value">Multiple</div>
                </div>
                <div>
                  <div className="invest-modal-subheader">
                    Available loan amount
                  </div>
                  <div className="invest-modal-subheader-value">
                    up to {newNumberformat(formState?.minInvestment)}
                  </div>
                </div>
                <div>
                  <div className="invest-modal-subheader">Interest</div>
                  <div className="invest-modal-subheader-value">
                    {formState?.minimumInterestRate ===
                    formState?.maximumInterestRate
                      ? `${newNumberformat(formState?.maximumInterestRate)}%`
                      : `${newNumberformat(
                          formState?.minimumInterestRate
                        )}% - ${newNumberformat(
                          formState?.maximumInterestRate
                        )}%`}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  paddingBottom: "32px",
                }}
              >
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Amount to buy in each loan
                </div>
                <div>
                  <Input
                    style={{ width: "100%", height: "56px" }}
                    className="new_input_normal_border"
                    placeholder={`Enter sum, max ${
                      newNumberformat(formState?.minInvestment) || 0.0
                    }`}
                    value={amount || ""}
                    onKeyPress={(e) => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Regular expression to match only numeric values
                      const regex = /^[0-9]*\.?[0-9]*$/;
                      if (regex.test(inputValue)) {
                        setAmount(inputValue);
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    fontWeight: "12px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    letterSpacing: "0.3px",
                    color: "#161616B2",
                  }}
                >
                  No wallets connected
                </div>
              </div>
              <div
                style={{
                  paddingBottom: "32px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Linked Wallet
                </div>
                <div className="wallet-connect-modal-button-disabled">
                  <div
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "400",
                    }}
                  >
                    Connect new wallet{" "}
                    <img
                      src={add_circle_outline_disabled}
                      alt="img"
                      style={{ paddingLeft: "8px" }}
                    />
                  </div>
                  <div>
                    <img src={add_circle} alt="add circle" />
                  </div>
                </div>
              </div>
              <div
                style={{
                  paddingBottom: "32px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#242424",
                    }}
                  >
                    Number of claims
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      color: "#242424",
                    }}
                  >
                    {newNumberformat(tableDatas?.length) || "0"}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#242424",
                    }}
                  >
                    Total amount to buy
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      color: "#242424",
                    }}
                  >
                    {totalAmount || "-"}
                  </div>
                </div>
              </div>
              <div>
                <Link to={"/user/signUp"} style={{ textDecoration: "none" }}>
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    // loading={loader}
                    className="new_button_filled"
                    style={{
                      height: "48px",
                      width: "100%",
                      padding: "12px 24px",
                    }}
                  >
                    Sign up
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
const InvestModalInvestSelected = ({
  isOpenModalInvestSelected,
  setIsOpenModalInvestSelected,
  formState,
  setFormState,
  getData,
  setInvestmentSucessfulModalStatus,
  setInvestmentSucessfulData,
}) => {
  const SelectedStableCoin = useSelector(
    (state) => state?.global?.SelectedStableCoin
  );
  const [error, setError] = useState("");
  const [SelectedWalletAddress, setSelectedWalletAddress] = useState("");
  const [SelectedWalletDetails, setSelectedWalletDetails] = useState({});
  const [walletListDetails, setWalletListDetails] = useState({});
  const handleClose = () => {
    setIsOpenModalInvestSelected(false);
    setFormState({
      loanId: "",
      amountToInvest: "",
      cashAvailable: "0.0",
      principalAvailable: "",
      currencySymbol: "",
      interestRatePercent: "",
    });
  };
  const Validate = () => {
    let isValid = true;
    const amountToInvest = Number(formState.amountToInvest);
    console.log(amountToInvest, SelectedWalletDetails?.walletBalance);

    if (isNaN(amountToInvest) || formState.amountToInvest === "") {
      setError("Please enter a valid amount to invest");
      message.error("Please enter a valid amount to invest");
      isValid = false;
    } else if (amountToInvest < 10) {
      setError("Minimum amount to invest is 10");
      message.error("Minimum amount to invest is 10");
      isValid = false;
    } else if (amountToInvest > SelectedWalletDetails?.walletBalance) {
      setError(
        "Amount to invest should be less than or equal to available amount in linked wallet"
      );
      message.error(
        "Amount to invest should be less than or equal to available amount in linked wallet"
      );
      isValid = false;
    }

    return isValid;
  };

  const Invest = async () => {
    setError("");
    const amount = Number(formState.amountToInvest);

    if (!Validate()) {
      return;
    }

    try {
      const response = await clientApi.post(`/api/investor/invest`, {
        loanId: formState.loanId,
        amount: amount,
        walletAddress: SelectedWalletAddress,
      });
      if (response?.status === 200) {
        setInvestmentSucessfulData(formState.loanId);
        setIsOpenModalInvestSelected(false);
        setInvestmentSucessfulModalStatus(true);
        setError("");
        await getData();
      }
    } catch (error) {
      console.log(error);
      let errorHandled = false;

      if (
        error?.response?.data?.validation?.fieldErrors?.amount ===
        "INVALID_VALUE"
      ) {
        message.error("Invalid Amount");
        setError("Invalid Amount");
        errorHandled = true;
      } else if (error?.response?.data?.validation?.errors[0]) {
        message.error(error?.response?.data?.validation?.errors[0]);
        setError(error?.response?.data?.validation?.errors[0]);
        errorHandled = true;
      } else {
        message.error("Investment Failed");
        errorHandled = true;
      }

      if (!errorHandled) {
        HandelError(error);
      }
    }
  };
  useEffect(() => {
    setError("");
  }, [isOpenModalInvestSelected]);
  useEffect(() => {
    // const Get= async()=>{
    //   try {
    //     const response= await GetWalletBalance()
    //     setWalletListDetails(response)
    //   } catch (error) {
    //   }
    // }
    // Get()
  }, []);
  const [balance, setBalance] = useState("0");
  useEffect(() => {
    const fetchBalance = async () => {
      if (formState.cashAvailable) {
        const amount = formState.cashAvailable;
        const amounts = amount;
        setBalance(amounts);
      }
    };

    fetchBalance();
  }, [formState]);
  const shortenAddress = (address) => {
    return `${address?.substring(0, 8)}...${address?.substring(
      address?.length - 8
    )}`;
  };
  const handleChange = (value) => {
    const addressToUse = value;
    setSelectedWalletAddress(addressToUse);
    const selectedItem = walletListDetails?.walletBalances?.find(
      (item) => item.walletAddress === addressToUse
    );
    console.log(selectedItem);
    setSelectedWalletDetails(selectedItem);
  };

  const navbarWalletAddress = useSelector(
    (state) => state.global.SelectedWalletAddress
  );

  useEffect(() => {
    console.log(walletListDetails);

    if (navbarWalletAddress && walletListDetails) {
      console.log(walletListDetails);
      setSelectedWalletAddress(navbarWalletAddress);
      const selectedWallet = walletListDetails?.walletBalances?.find(
        (wallet) => wallet.walletAddress === navbarWalletAddress
      );
      console.log(selectedWallet);
      if (selectedWallet) {
        setSelectedWalletDetails(selectedWallet);
      }
    } else if (
      !SelectedWalletAddress &&
      walletListDetails?.walletBalances?.length > 0
    ) {
      setSelectedWalletAddress(
        walletListDetails?.walletBalances[0]?.WalletAddress
      );
      setSelectedWalletDetails(walletListDetails?.walletBalances[0]);
    }
  }, [walletListDetails, navbarWalletAddress]);

  return (
    <>
      <div className="investment-modal">
        <Modal
          className="investment-modal"
          open={isOpenModalInvestSelected}
          centered
          closable={false}
          footer={null}
          width={560}
          onCancel={() => handleClose()}
          getContainer={false} // This renders the modal as a direct child of the Modal's parent, but might need adjustment based on your DOM structure
        >
          <div style={{ padding: "8px" }}>
            <div
              className="modal-close-icon"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <img src={close_icon} alt="close" onClick={() => handleClose()} />
            </div>
            <div style={{ padding: "0px 24px 32px 24px" }}>
              <div className="invest-modal-header">Buy</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "48px",
                  paddingBottom: "32px",
                }}
              >
                <div>
                  <div className="invest-modal-subheader">Loan ID</div>
                  <div className="invest-modal-subheader-value">
                    {formState?.loanId}
                  </div>
                </div>
                <div>
                  <div className="invest-modal-subheader">
                    Available loan amount
                  </div>
                  <div className="invest-modal-subheader-value">
                    {newNumberformat(formState?.principalAvailable)}
                  </div>
                </div>
                <div>
                  <div className="invest-modal-subheader">Interest </div>
                  <div className="invest-modal-subheader-value">
                    {newNumberformat(formState?.interestRatePercent)} %
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  paddingBottom: "32px",
                }}
              >
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Amount to buy
                </div>
                <div>
                  <Input
                    style={{ width: "100%", height: "56px" }}
                    className="new_input_normal_border"
                    placeholder={`Enter sum, max ${
                      formState?.principalAvailable || 0.0
                    }`}
                    value={formState.amountToInvest}
                    onKeyPress={(e) => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Regular expression to match only numeric values
                      const regex = /^[0-9]*\.?[0-9]*$/;
                      if (regex.test(inputValue)) {
                        setFormState({
                          ...formState,
                          amountToInvest: inputValue,
                        });
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    fontWeight: "12px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    letterSpacing: "0.3px",
                    color: "#161616B2",
                  }}
                >
                  Linked Wallet Balance {SelectedStableCoin}:
                  {SelectedWalletDetails?.walletBalance || 0.0}
                </div>
              </div>
              <div
                style={{
                  paddingBottom: "32px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Linked Wallet
                </div>
                <div className="wallet-connect-modal-button-disabled">
                  <div
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "400",
                    }}
                  >
                    Connect new wallet{" "}
                    <img
                      src={add_circle_outline_disabled}
                      alt="img"
                      style={{ paddingLeft: "8px" }}
                    />
                  </div>
                  <div>
                    <img src={add_circle} alt="add circle" />
                  </div>
                </div>
              </div>
              <div style={{ paddingBottom: "32px" }}>
                <a
                  href={`/api/investor/public/agreement-preview?amount=${
                    formState.amountToInvest || null
                  }&loanId=${formState.loanId}`}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                    }}
                  >
                    <div>
                      <img src={agreement_icon}></img>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#EC441F",
                        borderBottom: "1px solid #EC441F33",
                      }}
                    >
                      Agreement Sample
                    </div>
                  </div>
                </a>
              </div>
              <div>
                <Link to={"/user/signUp"} style={{ textDecoration: "none" }}>
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    // loading={loader}
                    className="new_button_filled"
                    style={{
                      height: "48px",
                      width: "100%",
                      padding: "12px 24px",
                    }}
                  >
                    Sign up
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default GuestPrimaryMarketTable;
