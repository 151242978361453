import { Button, Input, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { FiAlertCircle, FiCopy, FiExternalLink } from 'react-icons/fi';
import { useBalance, useChains, useDisconnect, useEstimateFeesPerGas, useEstimateGas, useGasPrice, useSwitchChain } from 'wagmi'
import {  useWalletInfo, useWeb3Modal, useWeb3ModalState, } from '@web3modal/wagmi/react'
import { useConnect, useAccount, useWriteContract } from 'wagmi'
import { injected } from 'wagmi/connectors'
import { AmountConverter, GetMaticExchangeRate, StablecoinWarperFunction, StablecoinWarperFunctionWithOutFormatting, fetchStableCoinPrice, newNumberformat, toFixedWithoutRounding } from '../../Helper/HelperFunction';
import { parseEther, parseUnits, BaseError, ProviderRpcError } from 'viem';
import { GetProfile, GetWalletBalance, HandelError, clientApi, gitBookUrl } from '../../Utils/AxiosCall';
import "./style.css"
import { Modal } from 'antd'
import { ethers } from 'ethers';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import netWorkIcon from '../../Assets/images/polygon-amoy-network.svg'
import dayjs from 'dayjs';
import errorIcon from '../../Assets/images/error-icon.svg'
import successIcon from '../../Assets/images/wallet_success.svg'
import { setDepositTransactionStatus, setWithdrawalTransactionStatus } from '../../Store/reducers/global';
import Modal2fa from '../Modal2fa/Modal2fa';
import { IoMdArrowDropdown } from 'react-icons/io';
import { MetaMaskAvatar } from 'react-metamask-avatar';
import add_circle from '../../Assets/images/add-circle-outline.svg';
import SignUpInputBox from '../Components/Atom/SignUpInputBox/SignUpInputBox';
import close_icon from"../../Assets/images/IconButton.svg" ;
import checkIcons from "../../Assets/images/check-icon-invest.svg";
const { Option } = Select;
const OpenWallet = () => {
    const { open, close } = useWeb3Modal();
    console.log('Opening wallet...');
    close()
    try {
        console.log('Opening wallet1...');
      setTimeout(() => {
        console.log('Opening wallet2...');
        close()
        open();
      }, 2000); // 2 seconds delay
    } catch (error) {
      console.error('Failed to open wallet:', error);
    }
  };
export default function WebThreeDeposit({ addresss,  }) {
    const SelectedStableCoin = useSelector(state => state.global.SelectedStableCoin);
    const account = useAccount();
    const chains = useChains()
    const { selectedNetworkId } = useWeb3ModalState()
    const { chains: availableChains, switchChain } = useSwitchChain();
    const [assetSelected, setAssetSelected] = useState("")
    const [network, setNetwork] = useState(chains[0]?.name)
    const [tokenAmount, setTokenAmount] = useState("")
    const [tokenLabel, setTokenLabel] = useState("USDC")
    const [destinationAddress, setDestinationAddress] = useState("")
    const [exchangeRate, setExchangeRate] = useState({})
    const { open, close } = useWeb3Modal();
    const [reviewState, setReviewState] = useState(false)
    const [originalAssetList, setOriginalAssetList] = useState([])
    const [asset_list, setAsset_list] = useState([])
    const [transitionHash, setTransitionHash] = useState("")
    const [convertedRate, setConvertedRate] = useState(0)
    const [netWorkFee, setNetWorkFee] = useState(0)
    const [FUCapitalWalletBalance, setFUCapitalWalletBalance] = useState(0);
    const handleCopy = () => {
        if (addresss) {
            navigator.clipboard.writeText(addresss);
            message.success('Address copied to clipboard');
        } else {
            message.error('No address to copy');
        }
    };

    const convertToAssetList = async (originalArray) => {
        return originalArray.map(item => {
            return {
                label: item?.tokenName,
                value: item?.contractAddress
            };
        });
    }
    const getNetworkAddress = async () => {
        try {
            const response = await clientApi.get(`/api/investor/wallet/network-address?chainId=${chains[0]?.id}`);
            if (response?.status === 200) {
                console.log(response?.data)
                setOriginalAssetList(response?.data)
                setAsset_list(await convertToAssetList(response?.data))
            }
        } catch (error) {
            console.log(error)
        }
    }

    console.log(network)

    const { data: tokenBalance, isLoading: tokenLoading, error: tokenError } = useBalance({
        address: account?.address,
        token: assetSelected, // Replace with the token contract address // Link Token
        chainId: chains[0]?.id, // Replace with the chain ID (e.g., 1 for Ethereum mainnet)
    });
    // useEffect(() => {

    //     if (tokenBalance) {
    //         setStableCoin(tokenBalance?.formatted)
    //     }
    //     if (tokenLoading) {
    //         setStableCoinLoading(true)
    //     } else {
    //         setStableCoinLoading(false)
    //     }
    // }, [tokenBalance, tokenLoading, tokenError])
    console.log(tokenBalance, tokenLoading, tokenError)
    console.log(chains)
    const [isWalletLinked, setIsWalletLinked] = useState("");

    const isWalletLinkedCall = async () => {
        setIsWalletLinked("");
        try {
            const response = await clientApi.post(`/api/investor/is-wallet-linked`, { linked: true, walletAddress: account?.address });
            if (response?.status === 200) {
                console.log(response.data.linked)
                setIsWalletLinked(response.data.linked);
                return response.data.linked;
            }
        } catch (error) {
            HandelError(error);
            return ;
        }
    };
    
    const checkNetwork = async () => {
        console.log("hai");
        const isLinked = await isWalletLinkedCall();
        console.log(isLinked)
        if (isLinked === false) {
            try {
                const walletAddress = account?.address;  // Get the wallet address from the account
                if (!walletAddress) {
                    return message.error("No wallet connected. Please connect your wallet first.");
                }
    
                console.log(tokenAmount);
                if (!tokenAmount) {
                    return message.error("Please enter a valid amount to deposit");
                }
                if (parseFloat(tokenAmount) < 10) {
                    return message.error(`Minimum deposit ${SelectedStableCoin} is 10`);
                }
                if (parseFloat(tokenAmount) > 1000000) {
                    return message.error(`Maximum deposit ${SelectedStableCoin} is 1000000`);
                }
    
                if (parseFloat(tokenAmount) > parseFloat(tokenBalance.formatted)) {
                    return message.error("Insufficient balance to deposit");
                }
    
                if (selectedNetworkId && chains) {
                    if (selectedNetworkId !== chains[0]?.id) {
                        switchChain({ chainId: chains[0]?.id })?.catch(() => {
                            open({ view: 'Networks' });
                        });
                    } else {
                        setReviewState(true);
                    }
                } else {
                    message.error("Please check the network");
                    setReviewState(false);
                }
            } catch (error) {
                message.error("Error processing the request.");
            }
        } else if (isLinked === true) {
            message.error(`This wallet address is already linked with another FU Capital Account.\n Please try connecting with another wallet address!`);
        }
    };


    useEffect(() => {
        if (account?.isConnected && chains && chains.length > 0) {
            // close()
        }

    }, [account])


    useEffect(() => {
        getNetworkAddress()
    }, [chains])
    return (
        <>
            <div className='' style={{}}>
                {
                    !reviewState ?
                        <ReviewAndConfirm FUCapitalWalletBalance={FUCapitalWalletBalance} setFUCapitalWalletBalance={setFUCapitalWalletBalance} network={network} handleCopy={handleCopy} assetSelected={assetSelected} setAssetSelected={setAssetSelected} checkNetwork={checkNetwork} asset_list={asset_list} addresss={addresss} reviewState={reviewState} setReviewState={setReviewState} tokenBalance={tokenBalance} tokenAmount={tokenAmount} setTokenAmount={setTokenAmount} tokenLabel={tokenLabel} setTokenLabel={setTokenLabel} originalAssetList={originalAssetList} destinationAddress={destinationAddress} setDestinationAddress={setDestinationAddress} convertedRate={convertedRate} netWorkFee={netWorkFee} tokenLoading={tokenLoading}  />
                        :
                        <>
                            <ConfirmDeposit FUCapitalWalletBalance={FUCapitalWalletBalance} setFUCapitalWalletBalance={setFUCapitalWalletBalance} network={network} handleCopy={handleCopy} assetSelected={assetSelected} setAssetSelected={setAssetSelected} asset_list={asset_list} addresss={addresss} reviewState={reviewState} setReviewState={setReviewState} tokenBalance={tokenBalance} tokenAmount={tokenAmount} setTokenAmount={setTokenAmount} chains={chains} tokenLabel={tokenLabel} setTokenLabel={setTokenLabel} destinationAddress={destinationAddress} setDestinationAddress={setDestinationAddress} transitionHash={transitionHash} setTransitionHash={setTransitionHash} convertedRate={convertedRate} netWorkFee={netWorkFee} />
                        </>
                }

            </div>
            {/* <FundTransferSuccessModal data={transitionHash} chains={chains} setTransitionHash={setTransitionHash} tokenLabel={tokenLabel} tokenAmount={tokenAmount} setReviewState={setReviewState} netWorkFee={netWorkFee} /> */}
        </>

    )
}
const ReviewAndConfirm = ({FUCapitalWalletBalance, setFUCapitalWalletBalance, network, handleCopy, assetSelected, setAssetSelected, asset_list, checkNetwork, setTokenAmount, tokenAmount, tokenBalance, setTokenLabel, originalAssetList, setDestinationAddress, tokenLoading, }) => {
    const { open, } = useWeb3Modal();
    const { address, status } =useAccount();
    const { disconnect } = useDisconnect()
    console.log(network)
    const SelectedStableCoin = useSelector(state => state.global.SelectedStableCoin);
    useEffect(() => {
        if (asset_list && SelectedStableCoin) {
            // Find the asset in the list that matches the value from Redux
            const matchingAsset = asset_list.find(asset => asset.label === SelectedStableCoin);
            if (matchingAsset) {
                // If a matching asset is found, select its value
                selectToken(matchingAsset.value);
            } else if (asset_list.length > 0) {
                // If no matching asset is found but the list is not empty, select the first asset by default
                selectToken(asset_list[0].value);
            }
        }
    }, [asset_list, SelectedStableCoin]);
    let formattedTokenBalance = toFixedWithoutRounding(Number(tokenBalance?.formatted || 0.00), 2);
    const selectToken = (e) => {
        setAssetSelected(e);
        const selectedAsset = originalAssetList?.find(asset => asset?.contractAddress === e);
        if (selectedAsset) {
            setTokenLabel(selectedAsset?.tokenName);
            setDestinationAddress(selectedAsset?.destinationAddress);
        }
    }
    const { connectAsync, connect, connectors } = useConnect();
    const { walletInfo } = useWalletInfo();
    const [walletsName, setWalletsName] = useState("");
    const [walletConnectDetails, setWalletConnectDetails] = useState({});
    const [SelectedWalletAddress, setSelectedWalletAddress] = useState("")
    const [SelectedWalletDetails, setSelectedWalletDetails] = useState({})
    const[walletListDetails,setWalletListDetails]=useState({})

    useEffect(() => {
      const connector = findConnectorById(connectors,walletsName );
      if (connector) {
        console.log('Connector found:', connector);
        setWalletConnectDetails(connector);
      } else {
        console.log('Connector not found');
      }
    }, [connectors, walletInfo?.name,walletsName,SelectedWalletAddress]);
    
    const findConnectorById = (connectors, id) => {
      return connectors.find(connector => connector.name === id) || null;
    };
    
    const handleConnect = async (walletsName) => {
        const connector = findConnectorById(connectors,walletsName );
        if (connector) {
          console.log('Connector found:', connector);
          setWalletConnectDetails(connector);
        } else {
          console.log('Connector not found');
        }
      if (connector) {
        await connect({ connector: connector });
        console.log("Connected to wallet:", connector?.name);
      } else {
        console.log('Connector not found');
        message.error('Wallet not found');
      }
    };

    useEffect(() => {
        try {
            if (address && !walletListDetails?.walletBalances?.some(wallet => wallet.walletAddress === address)) {
                const updatedWalletBalances = [
                    ...walletListDetails.walletBalances,
                    {
                        walletName: walletInfo?.name ?? '', // or any default name you want to give
                        walletAddress: address,
                        walletBalance: 0 // or any default balance you want to give
                    }
                ];
                setWalletListDetails({
                    ...walletListDetails,
                    walletBalances: updatedWalletBalances
                });
            }
        } catch (error) {
            console.error('Error updating wallet list details:', error);
        }
    }, [walletListDetails, address]);
    const Get= async()=>{
        try {
          const response= await GetWalletBalance()
          if (address && !response?.walletBalances?.some(wallet => wallet.walletAddress === address)) {
              response.walletBalances.push({
                  walletName: walletInfo?.name||null, // or any default name you want to give
                  walletAddress: address,
                  walletBalance: 0 // or any default balance you want to give
                });
            }
          setWalletListDetails(response)
        } catch (error) {
          
        }
      }
    useEffect(() => {
        Get()
      }, [])

    const shortenAddress = (address) => {
        return `${address?.substring(0, 8)}...${address?.substring(address?.length - 8)}`;
      };
      const handleChange = (value, option) => {
        console.log(value);
        // console.log(option['data-item']?.walletName);

        if (value!=="newWallet") {
            setWalletsName(option['data-item']?.walletName);
            setSelectedWalletAddress(value);
            disconnect()
            setTimeout(async () => {
                await handleConnect(option['data-item']?.walletName);
            }, 2000);
        }else{
            console.log("new wallet")
            disconnect()
            OpenWallet()
            setSelectedWalletAddress("");
        }
        const addressToUse = value;
        
        const selectedItem = walletListDetails?.walletBalances?.find(item => item.walletAddress === addressToUse);
        console.log(selectedItem);
        setSelectedWalletDetails(selectedItem);
      };
      
      const navbarWalletAddress =address ;
      
      useEffect(() => {
        console.log(walletListDetails);
      
        if (navbarWalletAddress&&walletListDetails) {
          console.log(walletListDetails)
          setSelectedWalletAddress(navbarWalletAddress);
          const selectedWallet = walletListDetails?.walletBalances?.find(wallet => wallet.walletAddress === navbarWalletAddress);
          console.log(selectedWallet)
          if (selectedWallet) {
            setFUCapitalWalletBalance(selectedWallet?.walletBalance);
            setSelectedWalletDetails(selectedWallet);
          }
        } else if (!SelectedWalletAddress && walletListDetails?.walletBalances?.length > 0) {
            setFUCapitalWalletBalance(walletListDetails?.walletBalances[0]?.walletBalance);
          setSelectedWalletAddress(walletListDetails?.walletBalances[0]?.WalletAddress);
          setSelectedWalletDetails(walletListDetails?.walletBalances[0]);
        }
      
      }, [walletListDetails, navbarWalletAddress]);
      useEffect(() => {
        console.log(SelectedWalletAddress);
        if (!SelectedWalletAddress||SelectedWalletAddress==="undefined"||SelectedWalletAddress===undefined) {
            setSelectedWalletAddress("");
            
        }
      }, [SelectedWalletAddress]);
    return (
        < div className='card-style-deposit-withdraw-funds'>
            <div className="card-style-deposit-withdraw-funds-header">
                Add funds
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "4px", paddingBottom: "32px" }}>
                <div style={{ fontWeight: "400", fontSize: "16px", lineHeight: "24px" }}>Amount to add</div>
                <div>
                    <Input
                        style={{ width: "100%", height: "56px" }}
                        className='new_input_normal_border'
                        placeholder={`Enter sum`}
                        value={tokenAmount}
                        onKeyPress={(e) => {
                            // Allow only digits
                            if (!/^\d$/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match whole numbers
                            const regex = /^\d*$/;
                            if (regex.test(inputValue)) {
                                setTokenAmount(inputValue);
                            }
                        }}
                    />

                </div>
                <div style={{ fontWeight: "12px", fontWeight: "400", lineHeight: "16px", letterSpacing: "0.3px", color: "#161616B2" }}>
                    Available funds in connected wallet, {SelectedStableCoin}: {tokenLoading ? <Spin indicator={<LoadingOutlined spin style={{ color: 'var(--font-color-1)' }} />} size="small" /> : newNumberformat(formattedTokenBalance||0)}
                </div>
            </div>
            <div style={{ fontWeight: "400", fontSize: "16px", lineHeight: "24px" }}>Connected wallet</div>
            <div className='custom-select-box new_select_normal_border'>
            <Select
      placeholder={"Select wallet"}
      className="filter-felid-width-style new_input_normal_border filter-select-style"
      dropdownStyle={{ zIndex: 1200 }}
      size={"large"}
      style={{ width: "100%", height: "56px" }}
      value={SelectedWalletAddress||null}
    //   onClick={(value, option) => handleChange(value, option)}
    onSelect={(value, option) => handleChange(value, option)}
    suffixIcon={<IoMdArrowDropdown color="black" size={14} />}
    // suffixIcon={SelectedWalletAddress?<img src={checkIcons} alt='icon'/>:""}
      showSearch={false}
    //   disabled

    >
      {walletListDetails?.walletBalances?.map((item) => (
        <Option 
          key={item?.walletAddress} 
          value={item.walletAddress}
          data-item={item} // Pass the entire item as a data attribute
          onClick={() => handleChange(item.walletAddress, item)}
        >
          <div style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
              <MetaMaskAvatar address={item?.walletAddress} size={24} />
              <span style={{ paddingLeft: "8px", paddingRight: "8px" }}>{shortenAddress(item?.walletAddress)}</span>
              <span style={{ fontSize: "12px" }}>{`(${item.walletName})`}</span>
            </div>
            <div>
              {/* <span style={{ paddingRight: "8px" }}>{`${newNumberformat(item.walletBalance)}`}</span> */}
            </div>
          </div>
        </Option>
      ))}
              <div 
          key={"newWallet"} 
          value={"newWallet"}
          onClick={() => handleChange("newWallet", "newWallet")}
          data-item={"newWallet"} 
        >
      <div className='div-navbar-wallet-connect'  style={{width:"100%"}}>
    <div style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }}>Connect new wallet</div>
    <div><img src={add_circle} alt='add circle' /></div>
  </div>
        </div>

    </Select>
                      </div>

            <div className="" style={{ lineHeight: "24px", fontSize: "16px", fontWeight: "400", paddingBottom: "32px",paddingTop:"32px" }}>
                <Button style={{
                    height: "52px",
                    padding: "16px 48px",
                    gap: "8px",
                    width: "100%"
                }}
                    onClick={() => { checkNetwork() }}
                    className={!tokenAmount ? "new_button_filled_disabled" : "new_button_filled"}
                >Review and Confirm</Button>
            </div>
            <div className='network-label' style={{ display: "flex", justifyContent: "flex-end", alignContent: "center", gap: "8px" }}>
                <div>Powered by {network} Network</div>
                <div><img src={netWorkIcon} alt='icon' /> </div>
            </div>

        </div>
    )
}
const ConfirmDeposit = ({FUCapitalWalletBalance, setFUCapitalWalletBalance, network, handleCopy, assetSelected, setAssetSelected, asset_list, setReviewState, tokenBalance, tokenAmount, chains, destinationAddress, tokenLabel, setTransitionHash, setTokenAmount }) => {
    const dispatch = useDispatch();
    const { connectAsync,connect, connectors } = useConnect()
    const { address } = useAccount()
    const SelectedStableCoin = useSelector(state => state.global.SelectedStableCoin);
    const DepositTransaction = useSelector(state => state.global.DepositTransactionStatus);
    const GlobalProfileData = useSelector(state => state.global.GlobalProfileData);
    const { open } = useWeb3Modal();
    const { data, error: transferError, isPending, isSuccess, writeContractAsync, variables } = useWriteContract()
    let formattedTokenBalance = toFixedWithoutRounding(Number(tokenBalance?.formatted || 0.00), 2);
    const [started, setStarted] = useState(false)
    const [errors, setErrors] = useState()
    const [completed, setCompleted] = useState(false)
    const { walletInfo } = useWalletInfo()
    console.log(connectors)
    console.log(walletInfo)
    // console.log(walletInfo.id)
    // dispatch(setDepositTransactionStatus('success'))

    useEffect(() => {
        console.log(transferError?.name, transferError)
        if (transferError?.name) {
            dispatch(setDepositTransactionStatus('failed'))
        }
    }, [transferError])
       const [twoFaModal, setTwoFaModal] = useState(false) // this is for 2FA enabling modal
       const [open2FAModal, setOpen2FAModal] = useState(false) // this is for verification modal
    const handlePayment = async () => {
        try {

            setErrors('')
            setStarted(true)
            if (!address) {
                // await connectAsync({ chainId: chains[0]?.id, connector: injected() })
                open()
            }
            console.log(assetSelected, destinationAddress)
            //   return
            const data = await writeContractAsync({
                chainId: chains[0]?.id,
                address: assetSelected, // replace with your token's contract address
                functionName: 'transfer',
                abi: [{ "inputs": [{ "internalType": "address", "name": "recipient", "type": "address" }, { "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "transfer", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "nonpayable", "type": "function" }],
                args: [
                    destinationAddress, // recipient address
                    tokenAmount * (10 ** tokenBalance?.decimals), // amount to send
                ],
            })
            setCompleted(true)
            console.log(data)
        } catch (err) {
            console.log(err)
            console.log(err?.code)
            setStarted(false)
            setErrors("Payment failed. Please try again.")
        }
    }
    const sendTransactionToBackend = async (data) => {
        try {
            const body = {
                chainId: chains[0]?.id,
                chainName: chains[0]?.name,
                token: tokenLabel,
                amount: tokenAmount,
                txnHash: data,
                sourceAddress: address,
                destinationAddress: destinationAddress,
                fiatCurrency: "USD",
                txnType: "DEPOSIT",
                walletName:walletInfo?.name
            }

            const response = await clientApi.post(`/api/investor/deposit-details`, body)
            if (response?.status === 200) {
                dispatch(setDepositTransactionStatus('success'))
                setTransitionHash(data)
            }


        } catch (error) {
            console.log(error)
            HandelError(error)
        }
    }
    useEffect(() => {
        if (data?.length === 66) {
            sendTransactionToBackend(data)
        }
    }, [data])

    const BoldDataComponent = ({ label, value }) => {
        return (<>
            <div className='deposit-container-body-data deposit-container-body-data-bold' >
                <div>{label}</div>
                <div>{newNumberformat(value)}</div>
            </div>
        </>)
    }
    const NormalDataComponent = ({ label, value }) => {
        return (<>
            <div className='deposit-container-body-data ' >
                <div className='deposit-container-body-data-label'>{label}</div>
                <div className='deposit-container-body-data-label'>{value}</div>
            </div>
        </>)
    }
    const [profileData, setProfileData] = useState({})
    useEffect(() => {
        const getData = async () => {
            const data = await GetProfile();
            setProfileData(data)
            console.log(data)
        }
        getData()
    }, [])
    useEffect(() => {
        calculateFUBalances()
        CalculateWalletBalances()
    }, [profileData])
    const [FUBalances, setFUBalances] = useState('');
    const calculateFUBalances = async () => {

        try {
            let newFUBalances = parseFloat(FUCapitalWalletBalance) + parseFloat(tokenAmount);
            console.log(newFUBalances)
            let formatted= newNumberformat(newFUBalances||0)
            console.log(formatted)
            setFUBalances(formatted); // Assuming newNumberformat is a function that formats the number as a string
        } catch (error) {
            console.error('Error calculating FU balances:', error);
            setFUBalances('0');
        }
    };
    let WalletBalances = ""
    const CalculateWalletBalances = () => {
        console.log(tokenBalance, tokenAmount)
        WalletBalances = parseFloat(tokenBalance?.formatted) - parseFloat(tokenAmount)
        console.log(WalletBalances)
        return newNumberformat(WalletBalances)
    }
    console.log(DepositTransaction)
    const CheckTwoFA = () => {
        // console.log(profileData?.twoFactorAuthenticationEnabled, profileData?.walletAddress?.length,!profileData?.twoFactorAuthenticationEnabled && profileData?.walletAddress?.length <= 1)
        if (!GlobalProfileData?.twoFactorAuthenticationEnabled) {
          // Assuming setTwoFaModal accepts a callback function that gets called when the modal closes
          setTwoFaModal(true);
        } 
       else if (GlobalProfileData?.twoFactorAuthenticationEnabled) {
            setOpen2FAModal(true);      
        }
         else {
          handlePayment();
        }
      };
      
      // This function gets called after the modal is closed
      const onModalClose = () => {
        // You can place any logic here that you want to execute after the modal has closed
        // For example, you might want to call handlePayment() here if that's the intended flow
        handlePayment();
      };
      const Childe=()=>{
        const formatAddress = (address) => {
            if (!address) return '';
            return `${address.slice(0, 6)}...${address.slice(-6)}`;
          };
        return(
          <div>
             {formatAddress(address)}&nbsp;{`(${walletInfo?.name})`}
          </div>
        )
      }
    /////////////////////////////////////////////////////

    const [totp, setTotp] = useState("")
    const [validationError, setValidationError] = useState({
        error: false,
        message: "",
        exists: false
})
const [loader, setLoader] = useState(false);
    const veriff2fa  = async () => {
        setLoader(true);
        try {
          const data = await clientApi.post("/api/investor/2fa/validate", { totp: totp });
          console.log(data)
          if (data.status === 200) {
            setTotp("")
            // message.success("Two factor authentication disable");
            // localStorage.setItem("2FAStatus", "false");  
            setErrors('')
            handlePayment()
            setCompleted(true)
            handelClose()
            setLoader(false);
          }
        } catch (error) {
            setLoader(false);
          if (error?.response?.data?.validation?.fieldErrors?.totp === "INVALID_VALUE") {
            setValidationError({
              error: true,
              message: "Invalid code",
              exists: false
            });
          } else {
            HandelError(error);
          }
          
        }
      }
    const handelClose=()=>{
        setOpen2FAModal(false)
        setTotp("")
        setLoader(false);
        setValidationError({
            error: false,
            message: "",
            exists: false
        })
    }
    /////////////////////////////////////////////////////
    return (
        <>
            {
                (DepositTransaction === "first") && <>
                    <div className='card-style-deposit-withdraw-funds'>
                        <div className="card-style-deposit-withdraw-funds-header">
                            Deposit summary
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "16px", paddingBottom: "32px" }}>
                            <NormalDataComponent label={"Stablecoin"} value={SelectedStableCoin} />
                            <NormalDataComponent label={"Connected wallet"} value={<Childe/>} />
                            <NormalDataComponent label={`Linked wallet balance after add, ${SelectedStableCoin}`} value={FUBalances} />
                            <NormalDataComponent label={`Connected wallet balance after add, ${SelectedStableCoin}`} value={CalculateWalletBalances()} />
                            <BoldDataComponent label={"Add amount"} value={tokenAmount} />
                        </div>
                        <div className="">
                            <div className="" style={{ display: "flex", flexDirection: "row", gap: "24px", width: "100%" }}>


                                <Button style={{
                                    height: "52px",
                                    padding: "16px 48px",
                                    gap: "8px",
                                    width: "80px"
                                }}
                                    onClick={() => { setReviewState(false) }}
                                    disabled={isPending}
                                    className="new_button_outline  ">Back</Button>
                                <Button style={{
                                    height: "52px",
                                    padding: "16px 48px",
                                    width: "100%"
                                }}
                                    disabled={isPending}
                                    onClick={() => { CheckTwoFA() }}
                                    className="new_button_filled">Confirm add {newNumberformat(tokenAmount)}</Button>
                            </div>
                            <div className='network-label' style={{ display: "flex", justifyContent: "flex-end", alignContent: "center", gap: "8px", paddingTop: "32px" }}>
                                <div>Powered by {network} Network</div>
                                <div><img src={netWorkIcon} alt='icon' /> </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                (DepositTransaction === "failed") && <>
                    <ErrorMessagesCard tokenAmount={tokenAmount} setReviewState={setReviewState} handlePayment={handlePayment} isPending={isPending} setTokenAmount={setTokenAmount} />
                </>
            }
            {
                (DepositTransaction === "success") && <><SuccessMessagesCard tokenAmount={tokenAmount} setReviewState={setReviewState} handlePayment={handlePayment} isPending={isPending} setTokenAmount={setTokenAmount} /></>
            }
                                           <Modal
    className='investment-modal'
    open={open2FAModal}
    centered
    closable={false}
    footer={null}
    width={560}
    onCancel={() => handelClose()}
    style={{}}
    >
      <div style={{padding:"8px"}}>
      <div className="modal-close-icon" style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}>
      <img src={close_icon} alt="close" onClick={() => handelClose()} />
    </div>
    <div style={{padding:"0px 26px 32px 26px"}}>
        <div className='profile-modal-header'>Verify Two-factor authentication</div>
        <div style={{height:"32px"}}></div>
        <SignUpInputBox label={"Enter authentication code"} isRequired={true}  placeholder={"6-digit code"}  value={totp} onChange={(e)=>{setTotp(e.target.value)}} validationError={validationError} regexPattern="^[0-9]*$" maxLength={6}/>
        <div style={{height:"40px"}}></div>
        <Button
             className={`${totp.length === 6 ? "new_button_filled" : "new_button_filled_disabled"}`}
            // className="new_button_filled hight-56"
            style={{width:"100%",height:"56px"}}
                  htmlType="submit"
                  disabled={totp.length !== 6}
                  loading={loader}
                  onClick={() => veriff2fa()}
                >
                  Verify
                </Button>
        </div>
        </div>
        </Modal>
            <Modal2fa isOpen={twoFaModal} setIsOpen={setTwoFaModal} onClose={onModalClose} />
        </>
    )
}


const FundTransferSuccessModal = ({ data, chains, setTransitionHash, tokenLabel, tokenAmount, setReviewState }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        setTransitionHash("")
        setReviewState(false)
    };
    const handleShow = () => setShow(true);
    useEffect(() => {
        if (data?.length === 66) {
            setShow(true)
        }
    }, [data])
    const chainUrl = chains[0]?.blockExplorers?.default?.url || ""
    const handleCopy = () => {
        if (data) {
            var newAddress = chainUrl + "/tx/" + data
            navigator.clipboard.writeText(newAddress);
            message.success('Address copied to clipboard');
        } else {
            message.error('No address to copy');
        }
    };
    return (<>
        <Modal show={show} onHide={handleClose} centered size='lg'>
            <Modal.Body>
                <div className="container" style={{ padding: "48px" }}>
                    <center><h2 className="eb_garamond_font" style={{ marginBottom: "46px" }}>Success</h2></center>
                    <div><Button className='new_button_filled hight' style={{ width: "100%" }}>Deposit Successful</Button></div>
                    <div className='custom-padding-top-24'>
                        Congratulations! Your Deposit request for {tokenAmount} {tokenLabel} has been successfully received. Please note that transfer processing may take up to 2 business days but usually takes 1 business day.
                    </div>
                    <div className='custom-padding-top-24'>
                        <div className="row">
                            <div className="col-8">
                                <Input
                                    style={{ width: "100% ", color: "#EC441F !important" }}
                                    disabled
                                    value={data}
                                    className='new_input deposit-fund  hight-56'
                                    placeholder='Transaction hash'
                                    suffix={
                                        <Button
                                            type="text"
                                            onClick={handleCopy}
                                            icon={<FiCopy />}
                                        />
                                    }
                                />
                            </div>
                            <div className="col-4 pt-0 pb-0">
                                <a href={`${chainUrl}/tx/${data}`} target='_blanck' style={{ textDecoration: "none" }}>
                                    <Button className='hight-56 new_button_filled ' style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}><FiExternalLink size={16} /><span style={{ paddingLeft: "8px" }}>View on explorer</span>
                                    </Button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='custom-padding-top-24' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <div style={{ lineHeight: "20px", fontWeight: "400", fontSize: "14px", color: "#EC441F" }}><a target='_blanck' href={gitBookUrl} style={{ color: "#EC441F" }}>Learn more</a></div>
                        <div ><a target='_blanck' href={gitBookUrl} style={{ color: "#EC441F" }}><FiExternalLink size={16} /></a></div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>)
}

const NormalDataComponent = ({ label, value }) => {
    return (<>
        <div className='deposit-container-body-data ' >
            <div className='deposit-container-body-data-label'>{label}</div>
            <div className='deposit-container-body-data-label'>{value}</div>
        </div>
    </>)
}
const NormalDataComponentSuccess = ({ label, value }) => {
    return (<>
        <div className='deposit-container-body-data ' >
            <div className='deposit-container-body-data-label'>{label}</div>
            <div className='deposit-container-body-data-label' style={{ color: "var(--secondary-color)" }}>{value}</div>
        </div>
    </>)
}
const BoldDataComponent = ({ label, value }) => {
    return (<>
        <div className='deposit-container-body-data deposit-container-body-data-bold' >
            <div>{label}</div>
            <div>{value}</div>
        </div>
    </>)
}

const formatDate = (date) => {
    return dayjs(date).format('D MMMM YYYY, HH:mm');
};
const ErrorMessagesCard = ({ transferError, tokenAmount, setReviewState, handlePayment, isPending, setTokenAmount }) => {
    const dispatch = useDispatch();
    const SelectedStableCoin = useSelector(state => state.global.SelectedStableCoin);
    const GoBack = () => {
        dispatch(setDepositTransactionStatus('first'))
        dispatch(setWithdrawalTransactionStatus('first'))
        setReviewState(false); setTokenAmount("")
    }
    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", padding: "32px 24px" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={errorIcon} alt='icon' style={{height:"64px"}} />
                </div>
                <div className='deposit-container-body-data-bold' style={{ fontSize: "24px", lineHeight: "36px", textAlign: "center", paddingBottom: "32px" }}>
                Something has gone wrong
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "16px", paddingBottom: "32px", width: "100%" }}>
                    <NormalDataComponent label={`Add funds date and time`} value={formatDate(dayjs())} />
                    <NormalDataComponent label={"Stablecoin"} value={SelectedStableCoin} />
                    <NormalDataComponent label={`Add funds amount`} value={newNumberformat(tokenAmount)} />
                    <div className='custom-hr'>
                        <hr className=' hr-style-2 '></hr>
                    </div>
                    <BoldDataComponent label={"Status"} value={<span style={{ color: "var(--error-color)" }}>Error</span>} />
                </div>
                <div className='deposit-container-body-data-bold' style={{ color: "var(--error-color)" }}>Add funds process aborted, funds returned to your wallet!</div>
                <div style={{ paddingTop: "72px" }}>
                    <Button style={{
                        height: "52px",
                        padding: "16px 48px",
                        width: "100%"
                    }}
                        disabled={isPending}
                        onClick={() => { handlePayment() }}
                        className="new_button_filled">Try add {SelectedStableCoin} {newNumberformat(tokenAmount)} again!</Button>
                </div>
                <div style={{ paddingTop: "24px", display: "flex", justifyContent: "center" }}>
                    <div
                        className='new-web-three-cancel-button'
                        onClick={() => { if (!isPending) GoBack(); }}
                        style={{
                            cursor: isPending ? 'not-allowed' : 'pointer',
                            opacity: isPending ? 0.5 : 1,
                        }}
                    >
                        Cancel
                    </div>
                </div>
            </div>
        </>
    )
}
const SuccessMessagesCard = ({ transferError, tokenAmount, setReviewState, handlePayment, isPending, setTokenAmount }) => {
    const dispatch = useDispatch();
    const SelectedStableCoin = useSelector(state => state.global.SelectedStableCoin);
    const GoBack = () => {
        dispatch(setDepositTransactionStatus('first'))
        dispatch(setWithdrawalTransactionStatus('first'))
        setReviewState(false); setTokenAmount("")
    }
    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", padding: "32px 24px" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={successIcon} alt='icon' style={{height:"64px"}}  />
                </div>
                <div className='deposit-container-body-data-bold' style={{ fontSize: "24px", lineHeight: "36px", textAlign: "center", paddingBottom: "32px" }}>
                Your add funds successfully initiated
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "16px", paddingBottom: "32px", width: "100%" }}>
                    <NormalDataComponentSuccess label={`Add funds date and time`} value={formatDate(dayjs())} />
                    <NormalDataComponentSuccess label={"Stablecoin"} value={SelectedStableCoin} />
                    <NormalDataComponentSuccess label={`Add funds amount`} value={newNumberformat(tokenAmount)} />
                    <div className='custom-hr'>
                        <hr className=' hr-style-2 '></hr>
                    </div>
                    <BoldDataComponent label={"Status"} value={<span style={{ color: "var(--success-color)" }}>Success</span>} />
                </div>
                <div className='deposit-container-body-data-bold' style={{ fontSize: "14px", textAlign: "center" }}>Your stable coins should arrive shortly,depending on network load.<br></br>We’ll notify you by email.</div>
                <div style={{ paddingTop: "24px", display: "flex", justifyContent: "center" }}>
                    <div className='new-web-three-deposit-again-button' onClick={() => { GoBack() }}>
                    Add funds again
                    </div>
                </div>
            </div>
        </>
    )
}