import { Button } from 'antd'
import React from 'react'
import { Modal } from 'react-bootstrap'

export default function InvestingOnHold({isOpen,setIsOpen}) {
  return (
    <div>
                <Modal centered 
        size='lg'
        show={isOpen}
        onHide={()=>setIsOpen(false)}
        >
        <div className="container" style={{ padding:"48px" }}>
                    <center><h2 className="eb_garamond_font" style={{ marginBottom: "46px" }}>Investing On Hold</h2></center>
                    <div ><Button className='new_button_filled hight' style={{width:"100%"}} >Contact  support@fucapital for help</Button></div>
        </div>
        </Modal>
    </div>
  )
}
