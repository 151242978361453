import React, { useEffect, useState } from "react";
import { LogOutCurrentSession } from "../../Utils/Logout";
import SingUpErrorCard from "../../Components/Components/Atom/SingUpErorrCard/SingUpErrorCard";
import { Button, Col, Row } from "antd";
import { clientApi, GetProfile, HandelError } from "../../Utils/AxiosCall";
import arrowIcon from "../../Assets/images/arrow_right_alt.svg";
import checkIcon from "../../Assets/images/check-vriff.svg";

function VerificationUnderProcess() {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({
    error: false,
    message: "",
  });
  const [profileData, setProfileData] = useState({});
  const GetData = async () => {
    try {
      const response = await GetProfile();
      setProfileData(response);
    } catch (error) {
      HandelError(error);
    }
  };
  useEffect(() => {
    GetData();
  }, []);
  // useEffect(() => {
  //     if (profileData?.investorStatus=="VERIFIED") {

  //     }
  // }, [profileData])
  const verified = () => {
    clientApi
      .post(`/api/investor/start-verification`)
      .then((res) => {
        console.log(res.data.redirectUrl);
        window.location.replace(res.data.redirectUrl);
      })
      .catch((err) => {
        HandelError(err);
      });
  };
  const GoToHomePage = () => {
    const response = "token" + Math.floor(Math.random() * 1000000);
    localStorage.setItem("token", response);
    window.location.assign("/");
  };
  return (
    <div>
      {profileData?.investorStatus == "VERIFIED" ? (
        <>
          <div className="onboarding-page-container">
            <Row
              justify="center"
              align="middle"
              className="antd-row-height-style"
            >
              <Col xs={24} sm={16} md={16} lg={10}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={checkIcon}
                    alt="icon"
                    style={{ paddingBottom: "16px" }}
                  />
                </div>
                <div className="sign-up-heading eb_garamond_font">Done!</div>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    Congratulations! You successfully completed signed up
                    process on FU Capital.
                  </div>
                </div>
                <div style={{ marginTop: "32px" }}>
                  <Button
                    className="new_button_filled hight-56"
                    style={{ width: "100%" }}
                    htmlType="submit"
                    loading={loader}
                    onClick={() => GoToHomePage()}
                  >
                    To user profile
                    <img src={arrowIcon} style={{ paddingLeft: "8px" }} />
                  </Button>
                </div>
                <div
                  style={{
                    marginTop: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#EC441F",
                      borderBottom: "1px solid EC441F",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      LogOutCurrentSession();
                    }}
                  >
                    Log out
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <>
          <div className="onboarding-page-container">
            <Row
              justify="center"
              align="middle"
              className="antd-row-height-style"
            >
              <Col xs={24} sm={16} md={16} lg={10}>
                <div className="sign-up-heading eb_garamond_font">
                  Verification in progress
                </div>
                {error.error ? (
                  <>
                    <SingUpErrorCard data={error.message} />
                  </>
                ) : (
                  <>
                    <div style={{ height: "48px" }}></div>
                  </>
                )}
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    Congratulations! You successfully completed the signup
                    process on FU Capital.
                  </div>
                </div>
                <div style={{ marginTop: "32px" }}>
                  <Button
                    className="new_button_outline hight-56"
                    style={{ width: "100%" }}
                    htmlType="submit"
                    loading={loader}
                    onClick={() => verified()}
                  >
                    Resubmit documents
                  </Button>
                  <div
                    style={{
                      paddingTop: "4px",
                      fontSize: "12px",
                      color: "var(--font-color-1)",
                    }}
                  >
                    If you have not completed the verification steps in Veriff,
                    please do so to proceed.
                  </div>
                </div>
                <div style={{ marginTop: "32px" }}>
                  <Button
                    className="new_button_filled hight-56"
                    style={{ width: "100%" }}
                    htmlType="submit"
                    loading={loader}
                    onClick={() => GoToHomePage()}
                  >
                    To user profile
                    <img src={arrowIcon} style={{ paddingLeft: "8px" }} />
                  </Button>
                </div>
                <div
                  style={{
                    marginTop: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#EC441F",
                      borderBottom: "1px solid EC441F",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      LogOutCurrentSession();
                    }}
                  >
                    Log out
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
}

export default VerificationUnderProcess;
