// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 1200px) {
   .custom-2fa .col-auto {
      flex: 0 0 auto;
      width: auto;
    }
  }
  
  @media screen and (max-width: 1199px) {
   .custom-2fa .col-md-2 {
      flex: 0 0 25.666667%;
      max-width: 25.666667%;
    }
  
  .custom-2fa  .col-md-10 {
      flex: 0 0 75.333333%;
      max-width: 75.333333%;
    }
  }
  .black-circle-list {
    list-style-type: disc;
    color: black; /* Set the color of the bullet points */
}
.custom-ol ol{
  margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Components/AccountSettings/Two-factorAuthentication/style.css"],"names":[],"mappings":"AAAA;GACG;MACG,cAAc;MACd,WAAW;IACb;EACF;;EAEA;GACC;MACG,oBAAoB;MACpB,qBAAqB;IACvB;;EAEF;MACI,oBAAoB;MACpB,qBAAqB;IACvB;EACF;EACA;IACE,qBAAqB;IACrB,YAAY,EAAE,uCAAuC;AACzD;AACA;EACE,kBAAkB;AACpB","sourcesContent":["@media screen and (min-width: 1200px) {\n   .custom-2fa .col-auto {\n      flex: 0 0 auto;\n      width: auto;\n    }\n  }\n  \n  @media screen and (max-width: 1199px) {\n   .custom-2fa .col-md-2 {\n      flex: 0 0 25.666667%;\n      max-width: 25.666667%;\n    }\n  \n  .custom-2fa  .col-md-10 {\n      flex: 0 0 75.333333%;\n      max-width: 75.333333%;\n    }\n  }\n  .black-circle-list {\n    list-style-type: disc;\n    color: black; /* Set the color of the bullet points */\n}\n.custom-ol ol{\n  margin-bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
