import React from 'react'
import'./style.css'
import { formatNumber, newNumberformat } from '../../../Helper/HelperFunction'
export default function LoanState({fundedPercent,amountFunded,currencySymbol,amountAvailable,availablePercent,AmountConverter,myInvestmentsPercent,myInvestments}) {
  const convertedAmountFunded = newNumberformat(amountFunded);
  const convertedAmountAvailable = newNumberformat(amountAvailable);
  const convertedAmountMyInvestments = newNumberformat(myInvestments);
  return (
    <div className='' style={{ display: "flex", minHeight: "194px",border:"none" }}>
         <div className=" " style={{width:"100%"}}>
            <div className="">
             <span className='loan-state-heading'>Raise progress</span>
            </div>
            <div style={{height:"12px"}}></div>
            <div className=" ">
            <div className='loan-state-progress'>
            <div className='loan-state-progress-inside' style={{width:`${fundedPercent||"0"}%`}}>
              {/* <div className='loan-state-progress-inside-inside' style={{width:`${myInvestmentsPercent||"0"}%`}} ></div> */}
            </div>
           </div>
            </div>
            <div style={{height:"16px"}}></div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{display:"flex",flexDirection:"column"}} >
            <div className="loan-state-font-subheading"> Already funded ({newNumberformat(fundedPercent) || "0"}%)</div>
            <div className='loan-state-font-subheading-value'>{convertedAmountFunded || "0,00"} </div>
          </div>
          <div className="loan-state-font-subheading" style={{ display: "flex", justifyContent: "flex-end",flexDirection:"column" }}>
            <div style={{ textAlign: "right" }}>Available loan amount ({newNumberformat(availablePercent) || "0"}%)</div>
            <div className='loan-state-font-subheading-value' style={{ textAlign: "right" }}>{convertedAmountAvailable || "0,00"}</div>
          </div>
        </div>
        <div style={{height:"12px"}}></div>
        <div style={{display:"flex",flexDirection:"row",gap:"16px",alignItems:"center"}}>

          <div className='my-investment-percentage'>Deployed balance ({`${myInvestmentsPercent||"0"}%`})</div>
          <div className='my-investment-card'>
            {convertedAmountMyInvestments||"0,00"}
          </div>
        </div>
           {/* <div style={{height:"12px"}}></div>
            <div className="col-6 loan-state-font-subheading loan-state-font-bold ">
            {convertedAmountFunded || "0.00"} 
            </div>
            <div className="col-6 loan-state-font-subheading loan-state-font-bold " style={{display:"flex",justifyContent:"flex-end"}}>
           {convertedAmountAvailable || "0.00"} 
           </div> */}
         </div>
    </div>
  )
}
