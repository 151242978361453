import React, { useEffect } from 'react';

const IntercomWidget = () => {
  useEffect(() => {
    // Fetch user data from localStorage
    const user = {
      id: localStorage.getItem('user_id'),
      name: localStorage.getItem('user_name'),
      email: localStorage.getItem('user_email'),
      createdAt: parseInt(localStorage.getItem('user_createdAt'), 10), // Assuming this is a UNIX timestamp
    };

    // Initialize Intercom settings
    window.intercomSettings = {
      app_id: 'u9vw74gk', // Replace with your workspace ID
      user_id: user.id,
      name: user.name,
      email: user.email,
      created_at: user.createdAt, // UNIX timestamp
    };

    (function() {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function() { i.c(arguments); };
        i.q = [];
        i.c = function(args) { i.q.push(args); };
        w.Intercom = i;
        var l = function() {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/u9vw74gk'; // Replace with your workspace ID
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }, []); // Empty dependency array to run once on mount

  return null;
};

export default IntercomWidget;
