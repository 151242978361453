// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main {
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
}

.fof {
    display: table-cell;
    vertical-align: middle;
}

.fof h1 {
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    /* animation: type 0.5s alternate infinite; */
}

@keyframes type {
    from {
        box-shadow: inset -3px 0px 0px #888;
    }
    to {
        box-shadow: inset -3px 0px 0px transparent;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/NoPage/style.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,qBAAqB;IACrB,mBAAmB;IACnB,6CAA6C;AACjD;;AAEA;IACI;QACI,mCAAmC;IACvC;IACA;QACI,0CAA0C;IAC9C;AACJ","sourcesContent":["#main {\n    display: table;\n    width: 100%;\n    height: 100vh;\n    text-align: center;\n}\n\n.fof {\n    display: table-cell;\n    vertical-align: middle;\n}\n\n.fof h1 {\n    font-size: 50px;\n    display: inline-block;\n    padding-right: 12px;\n    /* animation: type 0.5s alternate infinite; */\n}\n\n@keyframes type {\n    from {\n        box-shadow: inset -3px 0px 0px #888;\n    }\n    to {\n        box-shadow: inset -3px 0px 0px transparent;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
