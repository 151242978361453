// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-button{
    height: 100%;
    padding: 8px 16px;
    border: 1px solid var(--border-color-1);
    background-color: #FFFFFF;
    color: var(--secondary-color);
    display: flex;
    flex-direction: row;
    gap:8px;
    align-items: center;
    border-radius: 3px;
}
.header-button:hover{
    border-color: var(--font-color-1);
}
.header-button:focus{
    border-color: var(--secondary-color);
    box-shadow: 0px 0px 0px 4px #EC441F1A;
}
.header-button div {
    text-decoration: none;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Components/Atom/HeaderButton/HeaderButton.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,uCAAuC;IACvC,yBAAyB;IACzB,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,OAAO;IACP,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,iCAAiC;AACrC;AACA;IACI,oCAAoC;IACpC,qCAAqC;AACzC;AACA;IACI,qBAAqB;EACvB","sourcesContent":[".header-button{\n    height: 100%;\n    padding: 8px 16px;\n    border: 1px solid var(--border-color-1);\n    background-color: #FFFFFF;\n    color: var(--secondary-color);\n    display: flex;\n    flex-direction: row;\n    gap:8px;\n    align-items: center;\n    border-radius: 3px;\n}\n.header-button:hover{\n    border-color: var(--font-color-1);\n}\n.header-button:focus{\n    border-color: var(--secondary-color);\n    box-shadow: 0px 0px 0px 4px #EC441F1A;\n}\n.header-button div {\n    text-decoration: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
