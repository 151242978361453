import React,{useState} from "react";
import { Button, Card,message } from "antd";
import "./style.css";
import { clientApi } from "../../Utils/AxiosCall";

const InvestorNotVerifiedBoxWithMoreDetails = () => {
    const investorId = localStorage.getItem("investorId");
    const [loader, setLoader] = useState(false)
    const verified = () => {
        setLoader(true)
        clientApi.post(`/api/investor/start-verification`).then((res) => {
            setLoader(false)
            console.log(res.data.redirectUrl)
            window.location.replace(res.data.redirectUrl)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
    }
    
    return (
      <div className="">
        <Card style={{borderRadius:"3px"}} >
            <div className="verified-box-card verified-status-body">
                <p className="verified-status-p">Verify your Identity</p>
                <p className="">
                    To invest in FU Capital, you need to verify your identity.
                </p>
                <div className="row">
  <div className="col-12 col-md-4 pt-2 d-flex">
    <div className="card verified-new-bt-card-style flex-fill" style={{padding:"24px"}}>
      <div className="row">
        <div className="col-12 custom-padding-bottom-24 col-12 pe-0 ps-0">
          <center><span className="font-20-px-700 ">1.</span></center>
        </div>
        <div className="col-12 pe-0 ps-0">
          <center>Prepare your ID or Passport;</center>
        </div>
      </div>
    </div>
  </div>
  <div className="col-12 col-md-4 pt-2 d-flex">
    <div className="card verified-new-bt-card-style flex-fill" style={{padding:"24px"}}>
      <div className="row">
        <div className="col-12 custom-padding-bottom-24 col-12 pe-0 ps-0">
          <center><span className="font-20-px-700 ">2.</span></center>
        </div>
        <div className="col-12 pe-0 ps-0">
          <center>Have enough light to take a quality photo;</center>
        </div>
      </div>
    </div>
  </div>
  <div className="col-12 col-md-4 pt-2 d-flex">
    <div className="card verified-new-bt-card-style flex-fill" style={{padding:"24px"}}>
      <div className="row">
        <div className="col-12 custom-padding-bottom-24 col-12 pe-0 ps-0">
          <center><span className="font-20-px-700 ">3.</span></center>
        </div>
        <div className="col-12 pe-0 ps-0">
          <center>Make sure the document and all the essential data is clearly visible.</center>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="pt-3 pb-3 text-color">
 <p className="">
 FU Capital operates in compliance with European laws and regulations, thus we are bound to verify the identity of all out investors. Please have your passport or ID card ready and make sure you have enough light in your room to make the verification process quick and easy, so you can start investing as soon as possible!

 </p>
 <p>
 Upon verification, we will ask you various questions, for example - the source of your funds, your occupation, address, DOB, and other essential information that will help us identify you and make sure you’re investing responsibly.
 </p>
 <p>
 The whole verification process takes approximately 10 minutes.
 </p>

</div>

                <Button loading={loader} className="new_button_filled verified-status-button" onClick={(e) => verified()}>Verify </Button>
            </div>
        </Card>
        </div>
    );
};

export default InvestorNotVerifiedBoxWithMoreDetails;