// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.market-page-tabs{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    font-variation-settings: 'slnt' 0;
    color: #161616B2;
    border-bottom: 3px solid transparent;
}
.market-page-tabs-active {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    font-variation-settings: 'slnt' 0;
    color: #161616;
    border-bottom: 3px solid #EC441F; /* Corrected border property */
}
.custom-card .ant-card .ant-card-body{
    padding: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/InvestorMarketPage/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,iCAAiC;IACjC,gBAAgB;IAChB,oCAAoC;AACxC;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,iCAAiC;IACjC,cAAc;IACd,gCAAgC,EAAE,8BAA8B;AACpE;AACA;IACI,YAAY;AAChB","sourcesContent":[".market-page-tabs{\n    font-family: Inter;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: left;\n    font-variation-settings: 'slnt' 0;\n    color: #161616B2;\n    border-bottom: 3px solid transparent;\n}\n.market-page-tabs-active {\n    font-family: Inter;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    text-align: left;\n    font-variation-settings: 'slnt' 0;\n    color: #161616;\n    border-bottom: 3px solid #EC441F; /* Corrected border property */\n}\n.custom-card .ant-card .ant-card-body{\n    padding: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
