import React from "react";
import "./style.css";
import {Link} from "react-router-dom";

const NoPage = () => {
    return (
        <div id="main">
            <div className="fof">
                <h1>Error 404</h1>
                <p>
                    Page not found! <Link to="/">Go back</Link>
                </p>
            </div>
        </div>
    );
};

export default NoPage;
