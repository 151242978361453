// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom_form {
    font-family: "Inter", sans-serif !important;
}

.login-responsive-form{
    min-width: 512px;
}
@media screen and (max-width: 416px) {
    .login-responsive-form {
        min-width: 332px; /* Adjust this value as needed */
    }
}
@media screen and (max-width: 511px) {
    .login-responsive-form {
        min-width: 400px; /* Adjust this value as needed */
    }
}

.two-fa-heading {
    font-weight: 400;
    font-size: 40px;
    line-height: 44px;
    font-family: "EB Garamond" ;
    }`, "",{"version":3,"sources":["webpack://./src/Pages/InvestorLoginPage/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI;QACI,gBAAgB,EAAE,gCAAgC;IACtD;AACJ;AACA;IACI;QACI,gBAAgB,EAAE,gCAAgC;IACtD;AACJ;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,2BAA2B;IAC3B","sourcesContent":[".login_container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.custom_form {\n    font-family: \"Inter\", sans-serif !important;\n}\n\n.login-responsive-form{\n    min-width: 512px;\n}\n@media screen and (max-width: 416px) {\n    .login-responsive-form {\n        min-width: 332px; /* Adjust this value as needed */\n    }\n}\n@media screen and (max-width: 511px) {\n    .login-responsive-form {\n        min-width: 400px; /* Adjust this value as needed */\n    }\n}\n\n.two-fa-heading {\n    font-weight: 400;\n    font-size: 40px;\n    line-height: 44px;\n    font-family: \"EB Garamond\" ;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
