import { combineReducers } from "redux";
import dashboard from "./dashboard";
import global from "./global";
import investor from "./investor";

export default combineReducers({
  global,
  dashboard,
  investor,
});
