import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Radio,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { clientApi, GetProfile, HandelError } from "../../Utils/AxiosCall";
import {
  annualIncome_list,
  fundsAvailableForInvestment_list,
  personal_details_country_list,
  sourceOfFunds_list,
} from "../../Helper_Data/SelectBoxData";
import { Modal } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import Modal2fa from "../../Components/Modal2fa/Modal2fa";
import { conversionRate } from "../../Helper/HelperFunction";

export default function InvestorFinancialData() {
  const navigate = useNavigate();
  const [err, setErr] = useState({ status: false, message: "" }); // Error State
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [twoFAModal, setTwoFAModal] = useState(false);
  const onFinish = async (values) => {
    if (
      !values.sourceOfFunds ||
      !values.annualIncome ||
      !values.fundsAvailableForInvestment ||
      !values.countryOfTaxResidence ||
      values.politicalExposure === undefined ||
      values.politicalExposure === null
    ) {
      return setErr({ status: true, message: "Please fill all fields!" });
    }
    setLoader(true);
    try {
      await UpdatePersonalData({
        annualIncome: values.annualIncome,
        countryOfTaxResidence: values.countryOfTaxResidence,
        fundsAvailableForInvestment: values.fundsAvailableForInvestment,
        politicalExposure: values.politicalExposure,
        sourceOfFunds: values.sourceOfFunds,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const UpdatePersonalData = async (data) => {
    try {
      data = await clientApi.post("/api/investor/update-financial-info", {
        ...data,
      });
      setLoader(false);
      console.log(data);
      if (data.status === 200) {
        await conversionRate();
        localStorage.setItem("token", true);
        navigate("/user/portfolio");
      }
    } catch (error) {
      setLoader(false);
      setErr({ status: true, message: "Something went wrong ! " });
      HandelError(error);
    }
  };

  useEffect(() => {
    const fetchFeeData = async () => {
      const response = await GetProfile();
      if (!response?.twoFactorAuthenticationEnabled) {
        setTwoFAModal(true);
      }
    };
    fetchFeeData();
  }, []);
  return (
    <>
      <div className="onboarding-page-container">
        <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
          <div
            style={{
              width: "40px",
              height: "10px",
              backgroundColor: "#EC441F",
            }}
          ></div>
          <div
            style={{
              width: "40px",
              height: "10px",
              backgroundColor: "#EC441F",
            }}
          ></div>
          <div
            style={{
              width: "40px",
              height: "10px",
              backgroundColor: "#92908F",
            }}
          ></div>
        </div>
        <center className="login-signup-heading" style={{ marginTop: "48px" }}>
          Almost there!
        </center>
        <Form
          layout="vertical"
          onFinish={onFinish}
          // onValuesChange={onFormLayoutChange}
          className="onboarding-page-form custom-select-box"
        >
          <Form.Item
            name="sourceOfFunds"
            className="login-signup-general-margin-bottom"
          >
            <Select
              placeholder={"What is the main source of your investment funds?"}
              className="sign_up_select"
              options={sourceOfFunds_list || []}
              style={{ width: "100%" }}
              suffixIcon={<FiChevronDown color="black" size={20} />}
            />
          </Form.Item>
          <Form.Item
            name="annualIncome"
            className="login-signup-general-margin-bottom"
          >
            <Select
              placeholder={"What is your annual after-tax household income?"}
              className="sign_up_select"
              options={annualIncome_list || []}
              style={{ width: "100%" }}
              suffixIcon={<FiChevronDown color="black" size={20} />}
            />
          </Form.Item>
          <Form.Item
            name="fundsAvailableForInvestment"
            className="login-signup-general-margin-bottom"
          >
            <Select
              placeholder={"How much of your income do you plan to invest?"}
              className="sign_up_select"
              options={fundsAvailableForInvestment_list || []}
              suffixIcon={<FiChevronDown color="black" size={20} />}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="countryOfTaxResidence"
            style={{ paddingBottom: "8px" }}
          >
            <Select
              placeholder={"What is your country of tax residence"}
              className="sign_up_select"
              options={personal_details_country_list || []}
              style={{ width: "100%" }}
              suffixIcon={<FiChevronDown color="black" size={20} />}
            />
          </Form.Item>

          <div style={{ paddingBottom: "16px" }}>
            Information on Politically Exposed Person (PEP)
          </div>
          <Form.Item name="politicalExposure">
            <Radio.Group className="singup-radio">
              <div>
                <Radio buttonStyle="solid" size="large" value={true}>
                  I am a Politically Exposed Person
                </Radio>
              </div>
              <div style={{ paddingTop: "8px" }}>
                <Radio value={false}>
                  I am not a Politically Exposed Person
                </Radio>
              </div>
            </Radio.Group>
          </Form.Item>
          {err.message && (
            <p
              style={{ display: "flex", alignItems: "center", color: "tomato" }}
            >
              {err.message}
            </p>
          )}

          <div className="row" style={{ paddingTop: "8px" }}>
            <div className="col-md-12">
              <Form.Item>
                <Button
                  className="new_button_filled hight-56"
                  style={{ width: "100%" }}
                  htmlType="submit"
                  loading={loader}
                >
                  Finish
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
        <div style={{ marginTop: 24 }}>
          <div>
            <p>
              Have an account?
              <Link
                style={{ marginLeft: 8, color: "#EC441F" }}
                onClick={() => window.location.assign("/user/login")}
              >
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>
      <Modal2fa isOpen={twoFAModal} setIsOpen={setTwoFAModal} />
    </>
  );
}
