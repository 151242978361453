import React, { useState } from "react";
import arrowIconLeft from "../../Assets/images/arrow_left_alt.svg"; // Ensure to provide the correct path to your arrow icon image
import { Button, Col, Row } from "antd";
import SingUpErrorCard from "../../Components/Components/Atom/SingUpErorrCard/SingUpErrorCard";
import { clientApi } from "../../Utils/AxiosCall";
import { Link } from "react-router-dom";

function VeriffVerification() {
  const [error, setError] = useState({
    error: false,
    message: "",
  });

  const CardComponent = ({ number, message }) => {
    return (
      <div className="veriff-card ">
        <div className="veriff-card-number eb_garamond_font">{number}</div>
        <div className="veriff-card-text">{message}</div>
      </div>
    );
  };
  const [loader, setLoader] = useState(false); // Ensure to import useState from react
  const verified = () => {
    setLoader(true);
    clientApi
      .post(`/api/investor/start-verification`)
      .then((res) => {
        console.log(res.data.redirectUrl);
        window.location.replace(res.data.redirectUrl);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "80px",
          position: "relative",
        }}
      >
        <Link
          to={"/user/financial-data"}
          style={{ textDecoration: "none", visibility: "hidden" }}
        >
          <div style={{}}>
            <img src={arrowIconLeft} alt="icon"></img>{" "}
            <span
              style={{ borderBottom: "1px solid #24242426", color: "#242424" }}
            >
              Income and residency
            </span>
          </div>
        </Link>
        <div
          className="page-sub-header"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          Sign up step 3 of 3
        </div>
        <div className="" style={{ visibility: "hidden" }}>
          ID verification{" "}
        </div>
      </div>
      <div className="onboarding-page-container">
        <Row justify="center" align="middle" className="antd-row-height-style">
          <Col xs={24} sm={16} md={16} lg={10}>
            <div className="sign-up-heading eb_garamond_font">
              ID verification
            </div>
            {error.error ? (
              <>
                <SingUpErrorCard data={error.message} />
              </>
            ) : (
              <>
                <div style={{ height: "48px" }}></div>
              </>
            )}
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <CardComponent
                number={"1."}
                message={"Prepare your ID or Passport"}
              />
              <CardComponent
                number={"2."}
                message={"Have enough light to take a quality photo"}
              />
              <CardComponent
                number={"3."}
                message={
                  "Make sure the document and all the essential data are clearly visible"
                }
              />
            </div>
            <div style={{ marginTop: "32px" }}>
              <Button
                className="new_button_filled hight-56"
                style={{ width: "100%" }}
                htmlType="submit"
                loading={loader}
                onClick={() => verified()}
              >
                Verify your ID
              </Button>
            </div>
            <div
              className="veriff-content"
              style={{
                marginTop: "24px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <div>
                FU Capital adheres to strict European laws and regulations,
                which require us to verify the identity of all users on our
                platform. To ensure a smooth and efficient verification process,
                please have your passport or ID card ready. Additionally, make
                sure you have enough light in your room, as this will help
                expedite the process, allowing you to start using our services
                as quickly as possible.
              </div>
              <div>
                During the verification process, we will ask you for essential
                information such as the source of your funds, your occupation,
                address, date of birth, and other details necessary to confirm
                your identity.
              </div>
              <div>The entire process typically takes around 10 minutes.</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default VeriffVerification;
