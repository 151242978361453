import React from 'react'
import { Link } from 'react-router-dom'
import './HeaderButton.css'
export const HeaderButton=({link,svgPath,label,alt})=>{
    return(
       <div>
          <Link to={link} style={{textDecoration:"none"}}>
          <div className='header-button' >
            <div>{svgPath}</div>
            <div>{label}</div>
          </div>
          </Link>
       </div>
    )
   }
