import React from 'react'
import './style.css'
import DoughnutChart from '../../../Chart/DoughnutChart/DoughnutChart'
export default function DoughnutChartCard(props) {
    console.log("DoughnutChartCard props",props)
  return (
    <div className='doughnut-chart-card-container-style' >
       <div className='doughnut-chart-card-container-style-header'>{props?.label}</div>
       <div style={{height:"24px"}}></div>
       <div>
        <DoughnutChart label={props?.Chartlabels||[]} values={props?.values||[]} referenceData={props?.referenceData||[]}/>
       </div>
    </div>
  )
}
