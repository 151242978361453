// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.investment-amount-column {
    background-color: #ffffff !important; /* replace with the color you want */
  }`, "",{"version":3,"sources":["webpack://./src/Pages/BulkInvest/style.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC,EAAE,oCAAoC;EAC5E","sourcesContent":[".investment-amount-column {\n    background-color: #ffffff !important; /* replace with the color you want */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
