import { Pagination, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { FiCheckCircle } from "react-icons/fi";
import { AmountConverter, formatNumber, newNumberformat } from '../../../Helper/HelperFunction';
import { PageSizeList1 } from '../../../Helper_Data/SelectBoxData';
import { MdArrowDropDown } from 'react-icons/md';
import { FaCheckCircle } from 'react-icons/fa';

export default function LoanSchedulePayments({data,currencySymbol}) {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalItems, setTotalItems] = useState(data?.length||0)
    const columns = [
        {
          title: "Date ",
          dataIndex: "date",
          align: 'center',
         width:182.86,
        },
    
        {
          title: "Principal",
          dataIndex: "principal",
          align: 'center',
         
          render: (text, record) => (
            <div >
               <span> {newNumberformat(record.principal)}</span>
            </div>
          ),
        },
        {
          title: "Interest",
          dataIndex: "interest",
          align: 'center',
         
          render: (text, record) => (
            <div >
               <span>{newNumberformat(record.interest)}</span>
            </div>
          ),
        },
        {
          title: "Total",
          dataIndex: "total",
          align: 'center',
         
          render: (text, record) => (
            <div >
               <span> {newNumberformat(record.total)}</span>
            </div>
          ),
        },
        {
          title:<span>Payment&nbsp;Recieved</span>,
          dataIndex: "paid",
          align: 'center',
         
          render: (text, record) => (
            <div >
               <span> {newNumberformat(record.paid)}</span>
            </div>
          ),
        },
        {
            title:<span>Payment&nbsp;Date</span>,
            dataIndex: "paymentDate",
            align: 'center',
            render: (text, record) => (
              <div >
                 <span> {record.paymentDate||"-"}</span>
              </div>
            ),
          },
          {
            title: "Status",
            dataIndex: "status",
            align: 'center',
           
            render: (text, record) => (
              <div>
  <span style={{ color: record.status === "Late" ? "red" : "black" }}>
  {record.status === 'Paid' && (
      <>
        <FaCheckCircle style={{ width: "15px", height: "15px",color: "#45C01F" }} />
        <span style={{ color: "#45C01F", paddingRight: "4px" }}> </span>
      </>
    )}
    {record.status}
    
  </span>
</div>
            )
          },

      ];
      const handlePageChange = (pageNumber) => {
        console.log(pageNumber);
        setPage(pageNumber)
      };
    
      const handlePageSizeChange = (newSize) => {
        console.log(newSize);
        setPageSize(newSize);
      };
      useEffect(() => {
        setTotalItems(data?.length)
      }, [data])
    return (
        <>
                <div className=" " style={{padding:"0px 48px"}}>
                    <Table
          columns={columns}
          dataSource={data}
        //   loading={apiLoad}
          responsive
          scroll={{ x: "max-content" }}
          pagination={false}
          showSizeChanger
          className="custom-table custom-padding-bottom-24"
        />
        <div style={{display:"flex",flexDirection:"row",width:"100%",alignItems:"center",justifyContent:"space-between",paddingTop:"24px",paddingBottom:"24px"}}>
    <div>
    <Pagination
            current={page}
            pageSize={pageSize}
            total={totalItems}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange}
            className="custom-pagination"
            pageSizeOptions={['10', '20', '50', '100']}
            showSizeChanger={false}
          />
</div>
          <div className="new_input_normal_border_pagination ">
            <Select
              placeholder={"All"}
              className="new_input_normal_border_pagination"
              options={PageSizeList1}
              style={{ width: "100%",height:"40px",paddingRight:"8px" }}
              dropdownStyle={{ zIndex: 1200 }}
              suffixIcon={<MdArrowDropDown color="black"  size={24}/>}
              // size={"large"}
              value={pageSize}
              onChange={handlePageSizeChange}
            />
          </div>

</div>
                    </div>
        </>
    )
}
