import { useEffect, useState, useRef } from "react";
import { Button, theme, message } from "antd";
import PrimaryMarketTable from "../../Components/PrimaryMarketTable/PrimaryMarketTable";
import SecondaryMarketTable from "../../Components/SecondaryMarketTable/SecondaryMarketTable";
import "./style.css";
import { GetProfile, HandelError, clientApi } from "../../Utils/AxiosCall";
import { transformData } from "../../Helper/SelectBoxDataGenrater";
import { Loan_extensions_list } from "../../Helper_Data/SelectBoxData";
import PrimaryMarketFilter from "../../Components/PrimaryMarketFilter/PrimaryMarketFilter";
import SecondaryMarketFilter from "../../Components/SecondaryMarketFilter/SecondaryMarketFilter";
import InvestorVeriffModal from "../../Components/InvestorNotVerifiedBox/InvestorVeriffModal";
import { FiSliders } from "react-icons/fi";
import market_icon from "../../Assets/images/store.svg";
import PrimaryMarketFilterNew from "../../Components/PrimaryMarketFilter/PrimaryMarketFilterNew";
import PageHeader from "../../Components/Components/Molecule/PageHeader/PageHeader";
import { useSelector } from "react-redux";
import SecondaryMarketFilterNew from "../../Components/SecondaryMarketFilter/SecondaryMarketFilterNew";
import { useNavigate, useLocation } from "react-router-dom";

const InvestorMarketPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var currencyCode = useSelector((state) => state.global.SelectedStableCoin);
  const navigate = useNavigate();
  const location = useLocation();
  const initalPrimaryState = () => {
    return location.state?.isPrimary ?? true;
  };
  const [isPrimary, setisPrimary] = useState(initalPrimaryState);
  const [filterOn, setfilterOn] = useState(false);
  const [isBuy, setisBuy] = useState(true);
  const [countries_list, setCountries_list] = useState([]);
  const [search_listener, setSearch_listener] = useState(0);
  const [originators_list, setOriginators_list] = useState([]);
  const [collectionStatuses_list, setCollectionStatuses_list] = useState([]);
  const [products_list, setProducts_list] = useState([]);
  const [availableLoan, setAvailableLoan] = useState(0);
  const [isOpenVeriffModal, setIsOpenVeriffModal] = useState(false);
  const [totalCount, setTotalCount] = useState({});
  const [filter, setFilter] = useState({
    buybackOnly: null,
    collectionStatuses: [],
    countries: [],
    currencyCode: currencyCode,
    excludeAlreadyInvested: null,
    extensions: null,
    interestRatePercentFrom: null,
    interestRatePercentTo: null,
    loanIds: [],
    originators: [],
    principalOfferFrom: null,
    principalOfferTo: null,
    products: [],
    remainingTermInDaysFrom: null,
    remainingTermInDaysTo: null,
    smDiscountOrPremiumPercentFrom: null,
    smDiscountOrPremiumPercentTo: null,
    smOfferPrincipalAvailableFrom: null,
    smOfferPrincipalAvailableTo: null,
    loanDurationInDaysTo: null,
    loanDurationInDaysFrom: null,
  });
  const searchRef = useRef();

  const GetReferenceData = async () => {
    try {
      const response = await clientApi.post("/api/investor/reference-data", {});
      if (response?.status === 200) {
        const countries = await transformData(response.data.countries);
        const originators = await transformData(response.data.originators);
        const collectionStatuses = await transformData(
          response.data.collectionStatuses
        );
        const products = await transformData(response.data.products);

        setCountries_list(countries);
        setOriginators_list(originators);
        setCollectionStatuses_list(collectionStatuses);
        setProducts_list(products);
      }
    } catch (error) {
      HandelError(error);
    }
  };

  const clearFlitter = () => {
    setFilter({
      buybackOnly:
        filter.buybackOnly === true ||
        filter.buybackOnly === false ||
        filter.buybackOnly === null
          ? null
          : null,
      collectionStatuses: [],
      currencyCode: currencyCode,
      excludeAlreadyInvested:
        filter.excludeAlreadyInvested === true ||
        filter.excludeAlreadyInvested === false ||
        filter.excludeAlreadyInvested === null
          ? null
          : null,
      extensions: null,
      interestRatePercentFrom: null,
      interestRatePercentTo: null,
      loanIds: [],
      originators: [],
      principalOfferFrom: null,
      principalOfferTo: null,
      products: [],
      remainingTermInDaysFrom: null,
      remainingTermInDaysTo: null,
      smDiscountOrPremiumPercentFrom: null,
      smDiscountOrPremiumPercentTo: null,
      smOfferPrincipalAvailableFrom: null,
      smOfferPrincipalAvailableTo: null,
      remainingTermInDaysFrom: null,
      remainingTermInDaysTo: null,
    });
    handelFilter();
  };

  const handleSearch = () => {
    // Call the getData function in the child component
    searchRef.current.getData();
    handelFilter();
  };
  // useEffect(() => {
  //   console.log(filter);
  // }, [filter]);
  const {
    token: { colorBgContainer, colorPrimary, colorSuccess, colorError },
  } = theme.useToken();
  const [isPrimaryFilterOpen, setIsPrimaryFilterOpen] = useState(false);
  const [isSecondaryFilterOpen, setIsSecondaryFilterOpen] = useState(false);
  const handelFilter = () => {
    if (isPrimary) {
      setIsPrimaryFilterOpen(!isPrimaryFilterOpen);
    } else {
      setIsSecondaryFilterOpen(!isSecondaryFilterOpen);
    }
  };

  useEffect(() => {
    GetReferenceData();
    GetProfile();
  }, []);

  console.log("location.state", location.state);
  return (
    <>
      <div>
        <div
          className=""
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <div
            className=""
            style={{ width: "100%", padding: "0px 48px", maxWidth: "1700px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div
                  className="eb_garamond_font"
                  style={{
                    padding: "48px 0px 24px 0px",
                    fontSize: "40px",
                    lineHeight: "44px",
                    fontWeight: "400",
                    color: "#242424",
                  }}
                >
                  Market
                </div>
                <div
                  style={{
                    height: "56px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "24px",
                  }}
                >
                  <div
                    className={`${
                      isPrimary ? "market-page-tabs-active" : "market-page-tabs"
                    } `}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // setisPrimary(true);
                      navigate("/user/market", { state: { isPrimary: true } });
                      clearFlitter();
                    }}
                  >
                    Primary market
                  </div>
                  <div
                    className={`${
                      !isPrimary
                        ? "market-page-tabs-active"
                        : "market-page-tabs"
                    } `}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // setisPrimary(false);
                      navigate("/user/market", { state: { isPrimary: false } });
                      clearFlitter();
                    }}
                  >
                    Secondary market
                  </div>
                </div>
              </div>
              <div>
                <img src={market_icon} alt="Market Icon" />
              </div>
            </div>
          </div>
        </div>
        <div
          className=""
          style={{
            background: "#FFFFFF",
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div
            className=""
            style={{ width: "100%", padding: "0px 48px", maxWidth: "1700px" }}
          >
            <div>
              {isPrimary ? (
                <PrimaryMarketFilterNew
                  filter={filter}
                  setFilter={setFilter}
                  clearFlitter={clearFlitter}
                  handleSearch={handleSearch}
                  isPrimary={isPrimary}
                  searchRef={searchRef}
                  countries_list={countries_list}
                  originators_list={originators_list}
                  collectionStatuses_list={collectionStatuses_list}
                  products_list={products_list}
                  Loan_extensions_list={Loan_extensions_list}
                  isModalOpen={isPrimaryFilterOpen}
                  setIsModalOpen={setIsPrimaryFilterOpen}
                  totalCount={totalCount}
                />
              ) : (
                <SecondaryMarketFilterNew
                  filter={filter}
                  setFilter={setFilter}
                  clearFlitter={clearFlitter}
                  handleSearch={handleSearch}
                  isPrimary={isPrimary}
                  searchRef={searchRef}
                  countries_list={countries_list}
                  originators_list={originators_list}
                  collectionStatuses_list={collectionStatuses_list}
                  products_list={products_list}
                  Loan_extensions_list={Loan_extensions_list}
                  isModalOpen={isPrimaryFilterOpen}
                  setIsModalOpen={setIsPrimaryFilterOpen}
                  totalCount={totalCount}
                />
              )}
            </div>

            {isPrimary ? (
              <PrimaryMarketTable
                filters={filter}
                ref={searchRef}
                availableLoan={availableLoan}
                setAvailableLoan={setAvailableLoan}
                isOpened={isOpenVeriffModal}
                setIsOpened={setIsOpenVeriffModal}
                setTotalCount={setTotalCount}
              />
            ) : (
              <SecondaryMarketTable
                isBuy={isBuy}
                filters={filter}
                ref={searchRef}
                availableLoan={availableLoan}
                setAvailableLoan={setAvailableLoan}
                isOpened={isOpenVeriffModal}
                setIsOpened={setIsOpenVeriffModal}
                setTotalCount={setTotalCount}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestorMarketPage;
