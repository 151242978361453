// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-label{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.30000001192092896px;
    text-align: left;
    color:var(--font-color-1);
    }
    .header-label-value{
        font-family: 'Inter';
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color:var(--secondary-color);
        }
 .header-label-value-two{
            font-family: 'Inter';
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color:var(--secondary-color);
            }       `, "",{"version":3,"sources":["webpack://./src/Components/Components/Atom/HeaderLabel/HeaderLabel.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,qCAAqC;IACrC,gBAAgB;IAChB,yBAAyB;IACzB;IACA;QACI,oBAAoB;QACpB,eAAe;QACf,gBAAgB;QAChB,iBAAiB;QACjB,gBAAgB;QAChB,4BAA4B;QAC5B;CACP;YACW,oBAAoB;YACpB,eAAe;YACf,gBAAgB;YAChB,iBAAiB;YACjB,gBAAgB;YAChB,4BAA4B;YAC5B","sourcesContent":[".header-label{\n    font-family: 'Inter';\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 16px;\n    letter-spacing: 0.30000001192092896px;\n    text-align: left;\n    color:var(--font-color-1);\n    }\n    .header-label-value{\n        font-family: 'Inter';\n        font-size: 16px;\n        font-weight: 700;\n        line-height: 24px;\n        text-align: left;\n        color:var(--secondary-color);\n        }\n .header-label-value-two{\n            font-family: 'Inter';\n            font-size: 16px;\n            font-weight: 500;\n            line-height: 24px;\n            text-align: left;\n            color:var(--secondary-color);\n            }       "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
