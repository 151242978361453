import React from 'react'

export default function PageHeader({subComponent, title,image, ...props}) {
  return (
    <div style={{justifyContent:"center", display:"flex"}}>
    <div style={{padding:"0px 48px",width:"100%",maxWidth:"1700px"}}>
    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
       <div>
        <div className="eb_garamond_font" style={{padding:"40px 0px 24px 0px",fontSize:"40px",lineHeight:"44px",fontWeight:"400",color:"#242424"}}  >{title}</div>
        <div style={{height:"40px",display:"flex",flexDirection:"row",gap:"24px"}}>
            {subComponent}
        </div>
       </div>
       <div>
         <img src={image} alt="Icon"/>
       </div>
    </div>
    </div>
    </div>

  )
}
