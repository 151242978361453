import React, { useEffect, useState } from "react";
import { Card, message, Tooltip, Input, Select, DatePicker, Button } from "antd";
import dayjs from "dayjs";
import "./style.css";
import { GetProfile, HandelError, clientApi } from "../../Utils/AxiosCall";
import { CountryCodes } from "../../Helper_Data/CountryCodes";
import { transformData } from "../../Helper/SelectBoxDataGenrater";
import moment from 'moment';
const UserProfileInputs = ({ profile }) => {
  const [investorDetails, setInvestorDetails] = useState({});
  const [formDisabled, setFormDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [referenceData, setReferenceData] = useState({})
  const [countries_list, setCountries_list] = useState([]);


  const investorId = localStorage.getItem("investorId");

  const fetchReferenceData = async () => {
    await clientApi.post("/api/investor/reference-data")
      .then(async (res) => {
        console.log(res)
        const countries = await transformData(res?.data?.countries);
        setReferenceData(res?.data);
        setCountries_list(countries);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchReferenceData()
    setInvestorDetails(profile);
  }, [profile]);

  const updateInvestorDetails = async () => {
    setLoader(true);
    console.log(investorDetails);
    // const year = dayjs(investorDetails?.dateOfBirth).get("year");
    // const month = dayjs(investorDetails?.dateOfBirth).get("month") + 1;
    // const day = dayjs(investorDetails?.dateOfBirth).get("date");
    // Parse the date string using Moment.js
    const date = moment(investorDetails?.dateOfBirth);
    
    // Extract day, month, and year
    const day = date.date(); // Get the day of the month (1-31)
    const month = date.month() + 1; // Get the month (0-11), adding 1 to get month number (1-12)
    const year = date.year(); // Get the year
    console.log(year, month, day);
   const data= {
      address: investorDetails?.address,
      companyName: "",
      country: investorDetails?.country,
      dateOfBirth: {
        day: day,
        month: month,
        year: year
      },
      firstName: investorDetails?.firstName,
      lastName: investorDetails?.lastName,

    }
    
    try {
      await clientApi.post(
        `/api/investor/update-personal-info`,
        // {
        //   ...investorDetails,
        //   dateOfBirth: {
        //     day,
        //     month,
        //     year,
        //   },
        // }
        {
          ...data
        }
      );
      setLoader(false);
      message.success("Profile updated successfully");
      setFormDisabled(true);
      GetProfile()
    } catch (error) {
      setLoader(false);
      message.error("Ops! Try again.");
      HandelError(error);
    }
  };

  const dateFormat = "DD/MM/YYYY";
  const { Option } = Select;



  return (
    <Card>
      <>
        <div  style={{paddingBottom:"12px"}}>
          <span className="user-info-title" >User Information</span>
        </div>
        <div className=" user-profile-inputs custom-padding-bottom-24 " >
          <div className="user-profile-flex-1">
            <Input
              placeholder="-"
              className="new_input hight-56"
              disabled={formDisabled}
              value={investorDetails?.firstName}
              onChange={({ target }) =>
                setInvestorDetails({
                  ...investorDetails,
                  firstName: target.value,
                })
              }
            />
          </div>
          <div className="user-profile-flex-1">
            <Input
              placeholder="-"
              className="new_input hight-56"
              disabled={formDisabled}
              value={investorDetails?.lastName}
              onChange={({ target }) =>
                setInvestorDetails({
                  ...investorDetails,
                  lastName: target.value,
                })
              }
            />
          </div>
          <div className="user-profile-flex-1">
            <DatePicker
              disabled={formDisabled}
              className="new_input hight-56"
              style={{ width: "100%" }}
              value={investorDetails?.dateOfBirth ? dayjs(investorDetails?.dateOfBirth) :""}
              format={dateFormat}
              onChange={(e,date) =>
                // console.log(date)
                setInvestorDetails({
                  ...investorDetails,
                  dateOfBirth: date,
                })
              }
              disabledDate={(current) => {
                // Can not select days before today and today
                return current && current.isAfter(dayjs().endOf('day'));
              }}
            />
          </div>
        </div>

        <div className=" user-profile-inputs custom-padding-bottom-24" >
          <div className="user-profile-flex-1 user-profile-flex-row">
            <div className={`custom-select-box ${formDisabled ? 'custom-select-box-disabled ' : ''}`}>
              <Select
                style={{ width: '82px', borderRadius: "0px", marginRight: "24px" }}
                dropdownStyle={{ width: '200px', borderRadius: "0px" }}
                optionLabelProp="label"
                className={`genaral-select-box-height`}
                defaultValue={"+49"}
                disabled={true}
                value={investorDetails?.countryCode}
                onChange={(target) => {

                  setInvestorDetails({
                    ...investorDetails,
                    countryCode: target,
                  })
                }
                }
              >
                {CountryCodes.map(country => (
                  <Option key={country.code} label={<>{country.value}</>} value={country.value}>
                    <><img src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`} alt={country.label} style={{ marginRight: '8px', maxHeight: "15px", maxWidth: "15px" }} /><span>{country.label} &nbsp;{country.value} </span></>
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: "100%" }}>
              <Input
                placeholder="+1234567890"
                className="new_input hight-56"
                style={{ width: "100%" }}
                disabled={true}
                value={investorDetails?.phoneNumber}
                onChange={({ target }) =>
                  setInvestorDetails({
                    ...investorDetails,
                    phoneNumber: target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="user-profile-flex-1">
            <Input
              placeholder="columbus@gmail.com"
              className="new_input hight-56"
              disabled={true}
              value={investorDetails?.email}
              onChange={({ target }) =>
                setInvestorDetails({
                  ...investorDetails,
                  email: target.value,
                })
              }
            />
          </div>
          <div className={`user-profile-flex-1 custom-select-box ${formDisabled ? 'custom-select-box-disabled ' : ''}`} >
            <Select
              value={investorDetails?.country}
              placeholder={"Country"}
              disabled={formDisabled}
              className={`genaral-select-box-height `}
              options={countries_list}
              style={{ width: "100%" }}
              onChange={( target ) =>
                setInvestorDetails({
                  ...investorDetails,
                  country: target,
                })
              }
            />
          </div>
        </div>

        <div className=" user-profile-inputs  " >
          <div className="user-profile-flex-1">
            <Input
              placeholder="ABCD"
              className="new_input hight-56"
              disabled={formDisabled}
              value={investorDetails?.address}
              onChange={({ target }) =>
                setInvestorDetails({
                  ...investorDetails,
                  address: target.value,
                })
              }
            />
          </div>
        </div>
      </>
    </Card>
  );
};

export default UserProfileInputs;
