import { message } from "antd";
import cookie from "react-cookies";
import { clientApi } from "./AxiosCall";
import {
  USDCprice,
  USDTprice,
  SelectedStableCoin,
} from "../Store/reducers/global";
import { store } from "../Store/store";
export const LogOutCurrentSession = async (path) => {
  const state = store.getState();
  let USDCPrice = state.global.USDCprice;
  let USDTPrice = state.global.USDTprice;
  const investorId = localStorage.getItem("investorId");
  const investorEmail = localStorage.getItem("investorEmail");
  try {
    const response = await clientApi.post(
      `/api/investor/public/logout?userId=${investorId}&username=${investorEmail}`
    );
    console.log(response);
    if (response) {
      store.dispatch(USDCprice(null));
      store.dispatch(USDTprice(null));
      cookie.remove("token");
      localStorage.clear();
      sessionStorage.clear();
      window.location.assign(path || "/user/login");
    }
  } catch (error) {
    message.error("Ops! Try again");
    console.log(error);
  }
};
