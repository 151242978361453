
import { useEffect, useState } from "react";
import { USDCprice, USDTprice,SelectedStableCoin } from "../Store/reducers/global";
import {store} from '../Store/store'
import { clientApi } from "../Utils/AxiosCall";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function validateEmail(email) {
  return emailRegex.test(email);
}
export function formatNumber(input) {
  // Convert input to number if it's a string
  if (typeof input !== 'number' && typeof input !== 'string' && (input === null || input === undefined)) {
    return false;
  }
  const number = typeof input === 'string' ? parseFloat(input) : input;

  // Ensure number is a valid number
  if (isNaN(number)) {
    return "Invalid Number";
  }
  
  // Convert number to fixed decimal with 2 decimal places
  const formattedNumber = number.toFixed(2);
  
  // Return formatted number as string
  return formattedNumber;
}
 export function toFixedWithoutRounding(number, fixed) {
  const re = new RegExp(`^-?\\d+(?:\.\\d{0,${(fixed || -1)}})?`);
  return number.toString().match(re)[0];
}

// export const fetchStableCoinPrice = async (CoinData) => {
//   console.log("CoinData",CoinData)
//   let coin="usd-coin"
//   if (CoinData==="USDC") {
//       coin="usd-coin"
//   }else if (CoinData==="USDT") {
//       coin="tether"
//   }
//   try {
//     const response = await clientApi.get(`/api/investor/conversionrate/${coin}`)
//     // console.log(response.data)
//     // await fetch(
//     //   `http://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=usd`);
//     const data = ""
//     // await response.json();
//     console.log("data",data)
//     if (data.tether) {
//       return data.tether.usd;
//     } else if (data['usd-coin'].usd) {
//       return data['usd-coin'].usd;
//     } else {
//       return null;
//     }
//   } catch (error) {
//     console.error('Error fetching USDC price:', error);
//     return null; // Or throw an error if preferred
//   }
// };

const requestCache = {};

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const fetchStableCoinPrice = async (CoinData, retryAttempt = 1) => {
  console.log("CoinData", CoinData);
  let coin = "usd-coin";
  if (CoinData === "USDC") {
    coin = "usd-coin";
  } else if (CoinData === "USDT") {
    coin = "tether";
  }

  if (requestCache[coin] && requestCache[coin].processing) {
    console.log(`Request for ${coin} already in progress. Waiting for completion.`);
    try {
      const result = await requestCache[coin].promise;
      console.log("result", result)
      return parseFloat(result?.data?.conversionRate);
    } catch (error) {
      console.error('Error waiting for existing request:', error);
      return null;
    }
  }

  requestCache[coin] = { processing: true, promise: null };

  try {
    const promise = clientApi.get(`/api/investor/conversionrate/${coin}`);
    requestCache[coin].promise = promise;
    const response = await promise;
    console.log("response", response);
    const data = response.data;
    console.log("data", data);

    requestCache[coin].processing = false;
    return parseFloat(data?.conversionRate);
  } catch (error) {
    console.error('Error fetching USDC price:', error);
    requestCache[coin].processing = false;

    // Retry logic
    if (retryAttempt > 0) {
      console.log(`Retrying in 2 seconds...`);
      await delay(2000); // Wait for 2 seconds
      return fetchStableCoinPrice(CoinData, retryAttempt - 1); // Decrement retryAttempt and retry
    } else {
      return null; // Or throw an error if preferred
    }
  }
};

export const GetMaticExchangeRate = async () => {
  try {
    const response = await fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd`);
    const data = await response.json();
    console.log("data",data)
    if (data['matic-network'].usd) {
      return data['matic-network'].usd;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching Matic price:', error);
    return null; // Or throw an error if preferred
  }
}

export const newNumberformat = (number, locale = 'de-DE') => {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });


  return formatter.format(number||0);
};
export const newNumberformatWithFraction = (result) => {
  let formattedResult = String(result); // Ensure result is a string
   formattedResult = newNumberformat(formattedResult)
  if (!formattedResult.includes(',')) {
    formattedResult = `${formattedResult},00`;
  } else {
    const parts = formattedResult.split(',');
    if (parts[1]?.length === 1) {
      formattedResult = `${parts[0]},${parts[1]}0`;
    }
  }
  return formattedResult;
}
let USDTLivePrice = 0;
let USDCLivePrice = 0;
export const conversionRate = async () => {
  const updateConversionRate = async () => {
    const state = store.getState();
    let USDCPrice = state.global.USDCprice;
    let USDTPrice = state.global.USDTprice;

      USDCPrice = await fetchStableCoinPrice("USDC");
      USDCLivePrice=USDCPrice;
      if (USDCPrice!==null) {
        console.log("USDCPrice",USDCPrice)
        store.dispatch(USDCprice(USDCPrice));
      }
      USDTPrice = await fetchStableCoinPrice("USDT");
      USDTLivePrice=USDTPrice;
      if (USDTPrice!==null) {
        console.log("USDCPrice",USDCPrice)
        store.dispatch(USDTprice(USDTPrice));
      }    
  }

  // Update the conversion rate immediately
  await updateConversionRate();

  // Then update it every 5 minutes
  // setInterval(updateConversionRate, 15 * 60 * 1000);
}

export const StablecoinWarperFunction = async (amount) => {
  const state = store.getState();
  let USDCPrice = state.global.USDCprice || USDCLivePrice;
  let USDTPrice = state.global.USDTprice|| USDTLivePrice;
  let SelectedCoin = state.global.SelectedStableCoin;
  if (!USDCPrice || !USDTPrice) {
    await conversionRate();  
    USDCPrice = state.global.USDCprice;
    USDTPrice = state.global.USDTprice;
  }
  let amountInStablecoin;
  if (SelectedCoin === "USDC" && USDCPrice !== 0) {
    amountInStablecoin = (parseFloat(amount) / USDCPrice).toFixed(2);
  }
  if (SelectedCoin === "USDT" && USDTPrice !== 0) {
    amountInStablecoin = (parseFloat(amount) / USDTPrice).toFixed(2);
  }
  const formattedNumber = newNumberformat(amountInStablecoin);
  if (formattedNumber === "NaN" || formattedNumber === "Infinity") {
    return "0";
  }
  return formattedNumber;
}
export const StablecoinWarperFunctionWithOutFormatting = async (amount) => {
  const state = store.getState();
  let USDCPrice = state.global.USDCprice || USDCLivePrice;
  let USDTPrice = state.global.USDTprice|| USDTLivePrice;
  let SelectedCoin = state.global.SelectedStableCoin;
  if (!USDCPrice || !USDTPrice) {
    await conversionRate();  
    USDCPrice = state.global.USDCprice;
    USDTPrice = state.global.USDTprice;
  }
  let amountInStablecoin;
  if (SelectedCoin === "USDC" && USDCPrice !== 0) {
    amountInStablecoin = (parseFloat(amount) / USDCPrice).toFixed(2);
  }
  if (SelectedCoin === "USDT" && USDTPrice !== 0) {
    amountInStablecoin = (parseFloat(amount) / USDTPrice).toFixed(2);
  }

  if (amountInStablecoin === "NaN" || amountInStablecoin === "Infinity") {
    return "0";
  }
  return amountInStablecoin;
}
// export const AmountConverter = ({ value }) => {
//   const [data, setData] = useState(null);

//   useEffect(() => {
//     StablecoinWarperFunction(value).then((result) => {
//       let formattedResult = result;
//       if (!result.includes(',')) {
//         formattedResult = `${result},00`;
//       } else {
//         const parts = result.split(',');
//         if (parts[1].length === 1) {
//           formattedResult = `${parts[0]},${parts[1]}0`;
//         }
//       }
//       setData(formattedResult);
//     });
//   }, [value]);
  

//   if (data === null) return "0,00"; // or return a loading spinner

//   return <>{data}</>;
// }
export const AmountConverter = ({ value }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const formattedResult = newNumberformat(value);
    setData(formattedResult);
  }, [value]);

  if (data === null) return "0,00"; // or return a loading spinner

  return <>{data}</>;
};

function hasDecimalPoint(number) {
  return !Number.isInteger(number);
}

export const StablecoinToUSDConverter = async ( value ) => {
  const state = store.getState();
  let USDCPrice = state.global.USDCprice;
  let USDTPrice = state.global.USDTprice;
  let SelectedCoin = state.global.SelectedStableCoin;
  console.log("SelectedCoin",SelectedCoin)
  console.log("USDCPrice",USDCPrice)
  console.log("USDTPrice",USDTPrice)
  if (!USDCPrice || !USDTPrice) {
    await conversionRate();  
    USDCPrice = state.global.USDCprice;
    USDTPrice = state.global.USDTprice;
  }
  let amountInUSD;
  if (SelectedCoin === "USDC") {
    amountInUSD = (parseFloat(value) * USDCPrice);
  }
  if (SelectedCoin === "USDT") {
    amountInUSD = (parseFloat(value) * USDTPrice);
  }
  return amountInUSD;
}