import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { clientApi, GetProfile, HandelError } from "../../Utils/AxiosCall";
import { personal_details_country_list } from "../../Helper_Data/SelectBoxData";
import { FiChevronDown } from "react-icons/fi";

export default function InvestorPersonalData() {
  const navigate = useNavigate();
  const [err, setErr] = useState({ status: false, message: "" }); // Error State
  const [loader, setLoader] = useState(false);
  const onFinish = async (values) => {
    if (
      !values.firstName ||
      !values.lastName ||
      !values.country ||
      !values.address
    ) {
      return setErr({ status: true, message: "Please fill all fields!" });
    }
    setLoader(true);
    try {
      await UpdatePersonalData({
        address: values.address,
        country: values.country,
        firstName: values.firstName,
        lastName: values.lastName,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const UpdatePersonalData = async (data) => {
    localStorage.setItem("investorFirstName", data?.firstName);
    try {
      data = await clientApi.post("/api/investor/update-personal-info", {
        ...data,
      });
      setLoader(false);
      console.log(data);
      if (data?.status === 200) {
        navigate("/user/financial-data");
      }
    } catch (error) {
      setLoader(false);
      setErr({ status: true, message: "Something went wrong ! " });
      HandelError(error);
    }
  };
  useEffect(() => {
    GetProfile();
  }, []);

  return (
    <>
      <div className="onboarding-page-container">
        <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
          <div
            style={{
              width: "40px",
              height: "10px",
              backgroundColor: "#EC441F",
            }}
          ></div>
          <div
            style={{
              width: "40px",
              height: "10px",
              backgroundColor: "#92908F",
            }}
          ></div>
          <div
            style={{
              width: "20px",
              height: "10px",
              backgroundColor: "#000000",
            }}
          ></div>
        </div>
        <center className="login-signup-heading" style={{ marginTop: "48px" }}>
          Build your Profile
        </center>

        <Form
          layout="vertical"
          onFinish={onFinish}
          // onValuesChange={onFormLayoutChange}
          className="onboarding-page-form custom-select-box"
        >
          <Form.Item
            name="firstName"
            className="login-signup-general-margin-bottom"
          >
            <Input placeholder="First Name" className="input_signup" />
          </Form.Item>
          <Form.Item
            name="lastName"
            className="login-signup-general-margin-bottom"
          >
            <Input placeholder="Last Name" className="input_signup" />
          </Form.Item>
          <Form.Item
            name="country"
            className="login-signup-general-margin-bottom"
          >
            <Select
              placeholder={"Country"}
              className="sign_up_select"
              options={personal_details_country_list || []}
              style={{ width: "100%" }}
              suffixIcon={<FiChevronDown color="black" size={20} />}
            />
          </Form.Item>
          <Form.Item name="address">
            <Input placeholder="Address" className="input_signup" />
          </Form.Item>
          {err.status && (
            <p className="es_form_err" style={{ marginTop: 30 }}>
              {err.message}
            </p>
          )}
          <div style={{ height: "8px" }}></div>
          <Form.Item>
            <Button
              className="new_button_filled hight-56"
              style={{ width: "100%" }}
              htmlType="submit"
              loading={loader}
            >
              Setup 2 Factor Authentication
            </Button>
          </Form.Item>
        </Form>
        <div style={{ marginTop: 24 }}>
          <div>
            <p>
              Have an account?
              <Link
                style={{ marginLeft: 8, color: "#EC441F" }}
                onClick={() => window.location.assign("/user/login")}
              >
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
