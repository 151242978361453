import React, { useEffect, useState } from "react";
import "./style.css";
import SingUpErrorCard from "../../Components/Components/Atom/SingUpErorrCard/SingUpErrorCard";
import SignUpInputBox from "../../Components/Components/Atom/SignUpInputBox/SignUpInputBox";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "antd";
import arrowIcon from "../../Assets/images/arrow_right_alt.svg";
import arrowIconDisabled from "../../Assets/images/arrow_right_alt_disabled.svg";
import arrowIconLeft from "../../Assets/images/arrow_left_alt.svg";
import { Select } from "antd";
import SignUpSelectBox from "../../Components/Components/Atom/SignUpSelectBox/SignUpSelectBox";
import {
  annualIncome_list,
  fundsAvailableForInvestment_list,
  sourceOfFunds_list,
} from "../../Helper_Data/SelectBoxData";
import { transformData } from "../../Helper/SelectBoxDataGenrater";
import { clientApi, GetProfile, HandelError } from "../../Utils/AxiosCall";

export default function FinancialData() {
  const { Option } = Select;
  const [error, setError] = useState({
    error: false,
    message: "",
  });
  const [formData, setFormData] = useState({
    sourceOfFunds: "",
    annualIncome: "",
    fundsAvailableForInvestment: "",
    countryOfTaxResidence: "LV",
  });
  const [profileData, setProfileData] = useState({});
  const [country_list, setCountry_list] = useState([]);
  const GetCountryList = async () => {
    try {
      const response = await clientApi.post(
        "/api/investor/reference-data/countries",
        {}
      );
      if (response?.status === 200) {
        console.log(response);
        const countries = await transformData(response.data.countries);
        setCountry_list(countries);
      }
    } catch (error) {
      // HandelError(error);
    }
  };
  const GetData = async () => {
    try {
      const data = await GetProfile();
      setFormData({
        sourceOfFunds: data?.sourceOfFunds || "",
        annualIncome: data?.annualIncome || "",
        fundsAvailableForInvestment: data?.fundsAvailableForInvestment || "",
        countryOfTaxResidence: data?.countryOfTaxResidence || "LV",
      });
      setProfileData(data);
    } catch (error) {
      // HandelError(error)
    }
  };
  useEffect(() => {
    GetCountryList();
    GetData();
  }, []);
  const [validationError, setValidationError] = useState({
    sourceOfFunds: {
      error: false,
      message: "",
      exists: false,
    },
    annualIncome: {
      error: false,
      message: "",
      exists: false,
    },
    fundsAvailableForInvestment: {
      error: false,
      message: "",
      exists: false,
    },
    countryOfTaxResidence: {
      error: false,
      message: "",
      exists: false,
    },
  });
  const [loader, setLoader] = useState(false);
  const validateForm = () => {
    let isValid = true;
    let validationErrorData = {
      sourceOfFunds: {
        error: false,
        message: "",
        exists: false,
      },
      annualIncome: {
        error: false,
        message: "",
        exists: false,
      },
      fundsAvailableForInvestment: {
        error: false,
        message: "",
        exists: false,
      },
      countryOfTaxResidence: {
        error: false,
        message: "",
        exists: false,
      },
    };
    // Validation for sourceOfFunds
    if (!formData.sourceOfFunds) {
      validationErrorData.sourceOfFunds.error = true;
      validationErrorData.sourceOfFunds.message = "This field is obligatory";
      isValid = false;
    }

    // Validation for annualIncome
    if (!formData.annualIncome) {
      validationErrorData.annualIncome.error = true;
      validationErrorData.annualIncome.message = "This field is obligatory";
      isValid = false;
    }

    // Validation for fundsAvailableForInvestment
    if (!formData.fundsAvailableForInvestment) {
      validationErrorData.fundsAvailableForInvestment.error = true;
      validationErrorData.fundsAvailableForInvestment.message =
        "This field is obligatory";
      isValid = false;
    }

    // Validation for countryOfTaxResidence
    if (!formData.countryOfTaxResidence) {
      validationErrorData.countryOfTaxResidence.error = true;
      validationErrorData.countryOfTaxResidence.message =
        "This field is obligatory";
      isValid = false;
    }

    const hasError = Object.values(validationErrorData).some(
      (field) => field.error
    );

    // Update the error state based on validation results
    if (hasError) {
      setError({
        error: true,
        message: "Form cannot be submitted, see errors below",
      });
    } else {
      setError({
        error: false,
        message: "",
      });
    }
    setValidationError(validationErrorData);
    return isValid;
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      const body = {
        annualIncome: formData.annualIncome,
        countryOfTaxResidence: formData.countryOfTaxResidence,
        fundsAvailableForInvestment: formData.fundsAvailableForInvestment,
        sourceOfFunds: formData.sourceOfFunds,
        politicalExposure: false,
      };
      try {
        setLoader(true);
        const response = await clientApi.post(
          "/api/investor/update-financial-info",
          body
        );
        console.log(response);
        if (response?.status === 200) {
          setLoader(false);
          window.location.assign("/user/veriff");
        }
      } catch (error) {
        setLoader(false);
        if (
          error?.response?.status !== 403 ||
          error?.response?.status !== 401
        ) {
          setError({
            error: true,
            message: "Form cannot be submitted, see errors below",
          });
        } else {
          HandelError(error);
        }
      }
    }
  };
  useEffect(() => {
    if (profileData?.phoneNumber?.length <= 0) {
      window.location.assign("/user/personal-data");
    }
    if (profileData?.investorStatus == "VERIFIED") {
      const response = "token" + Math.floor(Math.random() * 1000000);
      localStorage.setItem("token", response);
      window.location.assign("/");
    }
  }, [profileData]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "80px",
          position: "relative",
        }}
      >
        <Link
          to={"/user/personal-data"}
          style={{ textDecoration: "none", visibility: "hidden" }}
        >
          <div style={{ visibility: "hidden" }}>
            <img src={arrowIconLeft} alt="icon"></img>{" "}
            <span
              style={{ borderBottom: "1px solid #24242426", color: "#242424" }}
            >
              Profile information
            </span>
          </div>
        </Link>
        <div
          className="page-sub-header"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          Sign up step 2 of 3
        </div>
        <div
          className="page-sub-header-disabled-link"
          style={{ visibility: "hidden" }}
        >
          ID verification <img src={arrowIconDisabled} alt="icon"></img>
        </div>
      </div>
      <div className="onboarding-page-container">
        <Row justify="center" align="middle" className="antd-row-height-style">
          <Col xs={24} sm={16} md={16} lg={10}>
            <div className="sign-up-heading eb_garamond_font">
              Income and residency
            </div>
            {error.error ? (
              <>
                <SingUpErrorCard data={error.message} />
              </>
            ) : (
              <>
                <div style={{ height: "48px" }}></div>
              </>
            )}
            <div>
              <div>
                <SignUpSelectBox
                  label={"What is the main source of your deployable funds?"}
                  placeholder={"Select"}
                  isRequired={true}
                  value={formData.sourceOfFunds}
                  onChange={(e) => {
                    setFormData({ ...formData, sourceOfFunds: e });
                  }}
                  validationError={validationError.sourceOfFunds}
                  options={sourceOfFunds_list || []}
                />
              </div>
              <div className="gap-between-inputs">
                <SignUpSelectBox
                  label={"What is your annual net income of a household?"}
                  placeholder={"Select"}
                  isRequired={true}
                  value={formData.annualIncome}
                  onChange={(e) => {
                    setFormData({ ...formData, annualIncome: e });
                  }}
                  validationError={validationError.annualIncome}
                  options={annualIncome_list || []}
                />
              </div>
              <div className="gap-between-inputs">
                <SignUpSelectBox
                  label={"How much of your income do you plan to deploy?"}
                  placeholder={"Select"}
                  isRequired={true}
                  value={formData.fundsAvailableForInvestment}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      fundsAvailableForInvestment: e,
                    });
                  }}
                  validationError={validationError.fundsAvailableForInvestment}
                  options={fundsAvailableForInvestment_list || []}
                />
              </div>
              <div className="gap-between-inputs">
                <SignUpSelectBox
                  label={"What is your tax residence?"}
                  placeholder={"Select"}
                  isRequired={true}
                  value={formData.countryOfTaxResidence}
                  onChange={(e) => {
                    setFormData({ ...formData, countryOfTaxResidence: e });
                  }}
                  validationError={validationError.countryOfTaxResidence}
                  options={country_list || []}
                  defaultValue={"LV"}
                  showSearch={true}
                />
              </div>
            </div>
            <div style={{ paddingTop: "32px" }}>
              <Button
                className="new_button_filled hight-56"
                style={{ width: "100%" }}
                htmlType="submit"
                loading={loader}
                onClick={() => handleSubmit()}
              >
                Continue
                <img src={arrowIcon} style={{ paddingLeft: "8px" }} />{" "}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
