import React from "react";
import {Card} from "antd";

const SupportBox = () => {
    return (
        <Card>

            <div className="user-info-title custom-padding-bottom-12">Support</div>
            <div className="">
                <div style={{fontSize:"14px",lineHeight:"20px"}}>
                    We are happy to answer your questions. Contact our support team on -&nbsp;
                    <span style={{color: "#EC441F", cursor: "pointer",fontWeight:700}}>
            support@fucapital.io
          </span>
                </div>
            </div>
        </Card>
    );
};

export default SupportBox;
