import {Button} from "antd";
import React from "react";

import "./style.css";

const ButtonFilled = ({title, onClick, style, loading, disabled}) => {
    return (
        <Button
            onClick={onClick}
            className="es_button_filled"
            style={style}
            loading={loading}
            disabled={disabled}
        >
            {title}
        </Button>
    );
};

export default ButtonFilled;
