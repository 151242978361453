import { Input } from 'antd';
import React from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi';

export default function PasswordInput({label, isRequired, placeholder, onChange, fieldType, value, error, validationError}) {  
    console.log(validationError)
    return (
      <div>
          {label && <div style={{display: "flex", flexDirection: "row", gap: "4px"}}>
            <span className='signUpLabelClass'>{label}</span>
            {isRequired && <span className='signUpLabelRequiredClass'>*</span>}
          </div>}
          <div className="">
              <Input.Password
                type={fieldType ? fieldType : "text"} 
                placeholder={placeholder || ""}
                className={`signUpInputBoxClass ${error?.error || validationError?.error ? "signUpInputBoxClassError" : ""}`}
                value={value}
                onChange={onChange}
                iconRender={visible => (visible ? <FiEye /> : <FiEyeOff />)}
                visibilityToggle
              />
              {validationError?.message && <div className={validationError.error?"error-message":"error-message-normal"} >{validationError?.message}</div>}
          </div>
      </div>
    );
  }

