import React from 'react'

export default function AccountCard() {
  return (
<div className='profile-card-container'>
        <div className='profile-card-style-header'>
        Profile
        </div>
        <div className='profile-card-style'>
           <div style={{fontSize:"14px",fontWeight:"400",lineHeight:"20px",color:"var(--secondary-color)",}}>
            If you no longer wish to use our services and want to close your user profile, please <a href="mailto:support@fucapital.io" style={{textDecoration:"none",color: "var(--primary-color)", borderBottom: "1px solid var(--primary-color)"}}>
  contact us
</a>.
           </div>
        </div>
    </div>
  )
}
