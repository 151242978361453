import { Button, Modal } from "antd";
import React, { useState } from "react";
import close_icon from "../../../Assets/images/IconButton.svg";
import PasswordInput from "../../../Components/Components/Atom/PasswordInput/PasswordInput";
import { clientApi, HandelError } from "../../../Utils/AxiosCall";
import { message } from "antd";
import { LogOutCurrentSession } from "../../../Utils/Logout";
import { useDispatch, useSelector } from "react-redux";
import Modal2fa from "../../../Components/Modal2fa/Modal2fa";
import SignUpInputBox from "../../../Components/Components/Atom/SignUpInputBox/SignUpInputBox";
import { setTwoFactorAuthenticationStatus } from "../../../Store/reducers/global";
export default function SecurityCard({ data, callBack }) {
  const TwoFactorAuthenticationStatus = useSelector(
    (state) => state.global.TwoFactorAuthenticationStatus
  );
  const [openPassword, setOpenPassword] = useState(false);
  const [open2fa, setOpen2fa] = useState(false);
  const [open2faDisable, setOpen2faDisable] = useState(false);
  console.log(TwoFactorAuthenticationStatus);
  return (
    <div className="profile-card-container">
      <div className="profile-card-style-header">Security</div>
      <div className="profile-card-style">
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <div className="profile-card-style-label">Password</div>
          <div>
            <span
              className="profile-card-style-link"
              onClick={() => setOpenPassword(true)}
            >
              Change password
            </span>
          </div>
        </div>
        <div style={{ height: "32px" }}></div>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <div className="profile-card-style-label">
            Two-factor authentication is{" "}
            {!TwoFactorAuthenticationStatus ? "disabled" : "enabled"}
          </div>
          <div>
            <span className="profile-card-style-link">
              {TwoFactorAuthenticationStatus ? (
                <>
                  {/* <span onClick={()=>setOpen2faDisable(true)}>Disable</span> */}
                </>
              ) : (
                <>
                  <span onClick={() => setOpen2fa(true)}>Enable</span>
                </>
              )}
            </span>
          </div>
        </div>
        {/* <div style={{paddingTop:"24px"}}>
            <span className='profile-card-style-link'>Change your address</span>
          </div> */}
      </div>
      <ChangePassword
        open={openPassword}
        setOpen={setOpenPassword}
        callBack={callBack}
      />
      <Modal2fa isOpen={open2fa} setIsOpen={setOpen2fa} />
      <Disable2FA open={open2faDisable} setOpen={setOpen2faDisable} />
    </div>
  );
}
const ChangePassword = ({ open, setOpen, callBack }) => {
  const [formData, setFormData] = useState({ password: "", newPassword: "" });
  const [validationError, setValidationError] = useState({
    newPassword: {
      error: false,
      message:
        "Minimal password requirements: 8 symbols and at least one number",
      exists: false,
    },
    password: {
      error: false,
      message: "",
      exists: false,
    },
  });
  const validateForm = () => {
    let isValid = true;
    let validationErrorData = {
      newPassword: {
        error: false,
        message:
          "Minimal password requirements: 8 symbols and at least one number",
        exists: false,
      },
      password: {
        error: false,
        message: "",
        exists: false,
      },
    };
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/;
    if (!formData.password) {
      validationErrorData.password.error = true;
      validationErrorData.password.message = "Password is required";
      validationErrorData.password.exists = false;
      isValid = false;
    }
    if (!formData.newPassword) {
      validationErrorData.newPassword.error = true;
      validationErrorData.newPassword.message = "Password is required";
      validationErrorData.newPassword.exists = false;
      isValid = false;
    } else if (formData.newPassword.length < 8) {
      validationErrorData.newPassword.error = true;
      validationErrorData.newPassword.message =
        "Minimal password requirements: 8 symbols and at least one number";
      validationErrorData.newPassword.exists = false;
      isValid = false;
    }
    if (!passwordRegex.test(formData.newPassword)) {
      validationErrorData.newPassword.error = true;
      validationErrorData.newPassword.message =
        "Minimal password requirements: 8 symbols and at least one number";
      validationErrorData.newPassword.exists = false;
      isValid = false;
    }
    console.log(passwordRegex.test(formData.newPassword));
    setValidationError(validationErrorData);
    return isValid;
  };
  const [loader, setLoader] = useState(false);
  const UpdatePasswordNow = async () => {
    if (validateForm()) {
      try {
        const data = await clientApi.post("/api/investor/change-password", {
          newPassword: formData.newPassword,
          password: formData.password,
          repeatNewPassword: formData.newPassword,
        });
        if (data.status === 200) {
          setLoader(false);
          message.success("Password updated successfully");
          setTimeout(LogOutCurrentSession, 2000);
        }
      } catch (error) {
        console.log(error);
        if (
          error?.response?.data?.validation?.fieldErrors?.password ==
          "INVALID_VALUE"
        ) {
          setValidationError({
            ...validationError,
            password: {
              error: true,
              message: "Invalid password",
              exists: true,
            },
          });
        }
        setLoader(false);
      }
    }
  };
  return (
    <>
      <Modal
        className="investment-modal"
        open={open}
        centered
        closable={false}
        footer={null}
        width={560}
        onCancel={() => setOpen(false)}
        style={{}}
      >
        <div style={{ padding: "8px" }}>
          <div
            className="modal-close-icon"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img src={close_icon} alt="close" onClick={() => setOpen(false)} />
          </div>
          <div style={{ padding: "0px 26px 32px 26px" }}>
            <div className="profile-modal-header">Change password</div>
            <div className="gap-between-inputs">
              <PasswordInput
                label={"Password"}
                placeholder={"Enter your password"}
                isRequired={true}
                value={formData.password}
                onChange={(e) => {
                  setFormData({ ...formData, password: e.target.value });
                }}
                validationError={validationError.password}
              />
            </div>
            <div className="gap-between-inputs">
              <PasswordInput
                label={"New Password"}
                placeholder={"Enter your password"}
                isRequired={true}
                value={formData.newPassword}
                onChange={(e) => {
                  setFormData({ ...formData, newPassword: e.target.value });
                }}
                validationError={validationError.newPassword}
              />
            </div>
            <div style={{ height: "40px" }}></div>
            <div style={{ width: "100%", height: "56px" }}>
              <Button
                className="new_button_filled"
                style={{ width: "100%", height: "100%" }}
                onClick={() => UpdatePasswordNow()}
                loading={loader}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
const Disable2FA = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [totp, setTotp] = useState("");
  const [validationError, setValidationError] = useState({
    error: false,
    message: "",
    exists: false,
  });
  const [loader, setLoader] = useState(false);
  const Disable2FACall = async () => {
    setLoader(true);
    try {
      const data = await clientApi.post("/api/investor/2fa/disable", {
        totp: totp,
      });
      console.log(data);
      if (data.status === 200) {
        dispatch(setTwoFactorAuthenticationStatus(false));
        setTotp("");
        message.success("Two factor authentication disable");
        localStorage.setItem("2FAStatus", "false");
        handelClose();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      if (
        error?.response?.data?.validation?.fieldErrors?.totp === "INVALID_VALUE"
      ) {
        setValidationError({
          error: true,
          message: "Invalid code",
          exists: false,
        });
      } else {
        HandelError(error);
      }
    }
  };
  const handelClose = () => {
    setOpen(false);
    setTotp("");
    setLoader(false);
    setValidationError({
      error: false,
      message: "",
      exists: false,
    });
  };
  return (
    <>
      <Modal
        className="investment-modal"
        open={open}
        centered
        closable={false}
        footer={null}
        width={560}
        onCancel={() => handelClose()}
        style={{}}
      >
        <div style={{ padding: "8px" }}>
          <div
            className="modal-close-icon"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img src={close_icon} alt="close" onClick={() => handelClose()} />
          </div>
          <div style={{ padding: "0px 26px 32px 26px" }}>
            <div className="profile-modal-header">
              Disable Two-factor authentication
            </div>
            <div style={{ height: "32px" }}></div>
            <SignUpInputBox
              label={"Enter authentication code"}
              isRequired={true}
              placeholder={"6-digit code"}
              value={totp}
              onChange={(e) => {
                setTotp(e.target.value);
              }}
              validationError={validationError}
              regexPattern="^[0-9]*$"
              maxLength={6}
            />
            <div style={{ height: "40px" }}></div>
            <Button
              className={`${
                totp.length === 6
                  ? "new_button_filled"
                  : "new_button_filled_disabled"
              }`}
              // className="new_button_filled hight-56"
              style={{ width: "100%", height: "56px" }}
              htmlType="submit"
              disabled={totp.length !== 6}
              loading={loader}
              onClick={() => Disable2FACall()}
            >
              Disable
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
