import React, { useEffect, useState } from 'react'
import { injected } from 'wagmi/connectors'
import WalletBeforeConnect from './WalletBeforeConnect'
import { useAccount, useConnect } from 'wagmi';
import { MetaMaskAvatar } from 'react-metamask-avatar';
import WebThreeDeposit from './WebThreeDeposit';
import errorIcon from '../../Assets/images/error-icon.svg'
import { useDispatch, useSelector } from 'react-redux';
import { newNumberformat } from '../../Helper/HelperFunction';
import dayjs from 'dayjs';
import { setDepositTransactionStatus, setWithdrawalTransactionStatus } from '../../Store/reducers/global';
import WebThreeWithdraw from './WebThreeWithdraw';
export default function WebThreeDepositFunds({  }) {
  const dispatch = useDispatch();
  const DepositTransactionStatus = useSelector(state => state.global.DepositTransactionStatus);
  const WithdrawalTransactionStatus = useSelector(state => state.global.WithdrawalTransactionStatus);
  const { address, isConnecting, isConnected, isDisconnected, status } = useAccount();
  const { connect } = useConnect()
  const localAddress = localStorage.getItem("walletAddress")
  const [addresss, setAddresss] = useState("")

  const [transactionAmount, setTransactionAmount] = useState(0);
  console.log(address)
  function shortenAddress(chars = 4) {
    return `${addresss?.substring(0, 5)}......${addresss?.substring(addresss?.length - 11)}`;
  }
  const [disconnectTime, setDisconnectTime] = useState(null);

  useEffect(() => {
    let timeoutId;
  
    if (address?.length > 11) {
      setAddresss(address);
    } 
    
    if (isDisconnected) {
      setDisconnectTime(Date.now());
      timeoutId = setTimeout(() => {
        setAddresss("");
      }, 3 * 60 * 1000); // 3 minutes
    }
  
    if (isConnected) {
      setDisconnectTime(null);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [localAddress, isConnected, isDisconnected, address]);

  console.log("addresss :" + addresss, "localAddress :" + localAddress)
  const [isDeposit, setIsDeposit] = useState(true)
  // console.log(DepositTransactionStatus)
  useEffect(() => {
    dispatch(setDepositTransactionStatus('first'))
    dispatch(setWithdrawalTransactionStatus('first'))
}, [])
  return (
    <div className='' >
      {
        (!addresss) ? <WalletBeforeConnect /> : (
          <>
          <div className="containerss">
            <div className='withdraw-deposit-card-width-control'>
              {
                (DepositTransactionStatus === "first" && WithdrawalTransactionStatus === "first") && (
                  <div style={{ height: "56px", display: "flex", flexDirection: "row", gap: "24px",padding:"0px 24px" }}>
                    <div className={`${isDeposit ? "market-page-tabs-active" : "market-page-tabs"} `} style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} onClick={() => setIsDeposit(true)} >Add</div>
                    <div className={`${!isDeposit ? "market-page-tabs-active" : "market-page-tabs"} `} style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} onClick={() => setIsDeposit(false)}>Withdraw</div>
                  </div>
                )
              }
              <div className=''>
                  {
                    isDeposit?
                    <WebThreeDeposit addresss={addresss}  />
                    :
                    <WebThreeWithdraw addresss={addresss} 
                    // setStableCoin={setStableCoin} setStableCoinLoading={setStableCoinLoading} 
                    transactionAmount={transactionAmount} setTransactionAmount={setTransactionAmount} />
                  }
              </div>

            </div>
            </div>
          </>
        )
      }

    </div>
  )
}
