export function ValidateEmail(email) {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return !!(reg.test(email));
}

export const truncateAddress = (address) => {
    if (!address) return "No Account";
    const match = address.match(
        /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
    );
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
};

export const toHex = (num) => {
    const val = Number(num);
    return "0x" + val.toString(16);
};

export const shortenAddress = (address) =>
    `${address.slice(0, 5)}...${address.slice(address.length - 4)}`;


   export const getWebDeviceData = () => {
     const userAgent = navigator.userAgent;
     const platform = navigator.platform;
     const isTouchDevice =
       "maxTouchPoints" in navigator && navigator.maxTouchPoints > 0;
     const screenSize = {
       width: window.screen.width,
       height: window.screen.height,
     };

     // Extracting browser details from the user agent string
     const browserDetails = (function () {
       const match = userAgent.match(/(Chrome|Safari|Firefox|Edge)\/(\S+)/);
       return match
         ? { name: match[1], version: match[2] }
         : { name: "", version: "" };
     })();

     // Constructing the webDeviceData object
     const webDeviceData = {
       original: userAgent,
       deviceType: isTouchDevice ? "smartphone" : "pc",
       os: platform.includes("Win")
         ? "Windows"
         : platform.includes("Mac")
         ? "Mac OSX"
         : platform.includes("Linux")
         ? "Linux"
         : "Unknown",
       osVersion: platform.match(/(\d+(\.\d+)?)/)
         ? platform.match(/(\d+(\.\d+)?)/)[0]
         : "Unknown",
       browser: browserDetails.name,
       browserVersion: browserDetails.version,
       browserVendor:
         browserDetails.name === "Chrome"
           ? "Google"
           : browserDetails.name === "Safari"
           ? "Apple"
           : "Unknown",
       isFromIphone: /iPhone/.test(userAgent),
       isFromIpad: /iPad/.test(userAgent),
       isFromIpod: /iPod/.test(userAgent),
       isFromIos: /iPhone|iPad|iPod/.test(userAgent),
       isFromAndroid: /Android/.test(userAgent),
       isFromAndroidTablet:
         /Android/.test(userAgent) && /Tablet/.test(userAgent),
       isFromWindowsPhone: /Windows Phone/.test(userAgent),
       isFromPc: !isTouchDevice,
       isFromSmartphone: isTouchDevice && screenSize.width <= 767,
       isFromMobilephone: isTouchDevice && screenSize.width <= 480,
       isFromAppliance: /SmartTV|SmartTV\//.test(userAgent),
       isFromCrawler: /bot|googlebot|crawler|spider|robot|crawling/i.test(
         userAgent
       ),
       isFromTablet:
         isTouchDevice && screenSize.width > 480 && screenSize.width <= 1024,
       clientTime: new Date().toISOString(),
       screenResolution: `${screenSize.width}x${screenSize.height}`,
     };

     return webDeviceData;
   };

