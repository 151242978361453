// import React, { useEffect, useRef, useState } from 'react';
// import { Chart, DoughnutController, ArcElement, CategoryScale, Tooltip, Legend } from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// import { generateFadedColors } from '../../../Helper/generateFadedColors';
// import { MdRectangle } from "react-icons/md";
// import"./style.css";
// Chart.register(DoughnutController, ArcElement, CategoryScale, Tooltip, Legend, ChartDataLabels);

// export default function DoughnutChart({ labels, values,referenceData}) {
//   const canvasRef = useRef(null);
//   const [chartInstance, setChartInstance] = useState(null);

//   useEffect(() => {
//     if (chartInstance) {
//       chartInstance.destroy();
//     }
//     let labelsData = labels || [];
//     let valuesData = values || [];
//     let color;

//     if (labels?.length===0 || values?.length===0 ) {
//       labelsData = ["No data"];
//       valuesData = [1];
//       color = ["#EC441F"];
//       const chartInstance = new Chart(canvasRef.current, {
//         type: 'doughnut',
//         data: {
//           labels: labelsData,
//           datasets: [
//             {
//               data: valuesData,
//               backgroundColor: color
//             }
//           ]
//         },
//         options: {
//           plugins: {
//             datalabels: {
//               display: false, // Disable data labels
//             },
//             tooltip: {
//               enabled: false, // Disable tooltips
//             },
//             legend: {
//               display: false,
//             },
//           },
//           cutout: '100%',
//         }
//       });
//      setChartInstance(chartInstance);

//     } else {
//       generateFadedColors("#EC441F", valuesData).then((colors) => {
//         color = colors;
//         const chartInstance = new Chart(canvasRef.current, {
//           type: 'doughnut',
//           data: {
//             labels: labelsData,
//             datasets: [
//               {
//                 data: valuesData,
//                 backgroundColor: color
//               }
//             ]
//           },
//           options: {
//             plugins: {
//               datalabels: {
//                 color: '#fff',
//                 font: function(context) {
//                   var width = context.chart.width;
//                   var size;

//                   if (width > 500) {
//                     size = 10;
//                   } else if (width > 400) {
//                     size = 10;
//                   } else if (width > 300) {
//                     size = 10;
//                   } else {
//                     size = 8;
//                   }

//                   return {
//                     size: size
//                   };
//                 },
//                 formatter: (value, context) => {
//                   return value;
//                 }
//               },
//               legend: {
//                 display: false,
//               },
//             },
//             cutout: '70%',
//           }
//         });
//         setChartInstance(chartInstance);
//       });
//     }

//     return () => {
//       if (chartInstance instanceof Chart) {
//         chartInstance.destroy();
//       }
//     };
//   }, [labels, values]);

  

//   return (
// <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
//   <div style={{ padding:"24px",display:"flex",alignItems:"center",justifyContent:"center" }}>
//     <canvas ref={canvasRef} style={{maxWidth:"200px",maxHeight:"200px"}} />
//   </div>
//   <div className='d-flex ' style={{justifyContent:"space-evenly",flexWrap:"wrap",alignItems:"center"}}>
//   {chartInstance?.data?.labels.map((label, index) => {
//     return (
//       <div key={index} >
        // <div className='d-flex' style={{alignItems:"center",flexDirection:"row",gap:"8px"}}>
        //   <div style={{display:"flex",alignItems:"center",justifyContent:"center"}} ><MdRectangle size={14} color={`${chartInstance?.data?.datasets[0]?.backgroundColor[index]}`} /></div>
        //   <div className='font-12-px' style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }} title={referenceData?.countries?.find(country => country?.id === label) ? referenceData?.countries?.find(country => country.id === label).label : label}>{referenceData?.countries?.find(country => country.id === label) ? referenceData?.countries?.find(country => country.id === label).label : label}</div>
        // </div>
//       </div>
//     )})}
// </div>
// </div>

//   );
// }

import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { generateFadedColorsTwo } from '../../../Helper/generateFadedColors';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ label, values, referenceData }) => {
  const [colors, setColors] = useState(["#EC441F"]); // Default color

  useEffect(() => {
    generateFadedColorsTwo(values)
      .then((colors) => {
        setColors(colors);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [values]);

  const data = {
    labels: label || [],
    datasets: [
      {
        data: values || [],
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "24px" }}>
      <div style={{ width: '144px', height: '144px' }}>
        <Doughnut data={data} options={options} />
      </div>
      <div className='d-flex' style={{ flexDirection: "column", gap: "12px" }}>
        {data.labels.map((label, index) => {
          const countryLabel = referenceData?.countries?.find(country => country.id === label)?.label || label;
          return(
            <div key={index}>
            <div className='d-flex' style={{ alignItems: "center", flexDirection: "row", gap: "8px", justifyContent: "flex-start" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "12px", height: "12px", background: `${data.datasets[0].backgroundColor[index]}`, borderRadius: "2px" }} />
              </div>
              <div className='new-box-label'>
              {
                    countryLabel.length > 15 ? `${countryLabel.substring(0, 15)}...` : countryLabel
                }
              </div>
            </div>
          </div>
          )
        })}
      </div>
    </div>
  );
};

export default DoughnutChart;
