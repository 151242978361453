// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loan-state-heading{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.4px;
    color: #242424;
}
.loan-state-progress{
   height: 40px;
   background: #000000;
   width: 100%;
   border-radius: 3px;
   overflow: hidden;
}
.loan-state-progress-inside{
    height: 40px;
    background: #EC441F;
}
.loan-state-progress-inside-inside{
    height: 40px;
    background: #C1A783;
}
.loan-state-font-subheading{
    font-size: 14px;
    font-weight: 400;
    color: #161616B2;
}
.loan-state-font-subheading-value{
    font-size: 20px;
    font-weight: 700;
    line-height:30px;
    color: #242424;
}

.loan-state-font-bold{
    font-weight: 700;
    color: black;
}
.my-investment-card{
    height: 30px;
    border-radius: 3px;
    padding: 0px 8px;
    gap: 8px;
    line-height: 30px;
    font-size: 20px;
    font-weight: 700;
    color: #000000;
}
.my-investment-percentage{
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/Components/LoanDetailsComponents/LoanState/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,cAAc;AAClB;AACA;GACG,YAAY;GACZ,mBAAmB;GACnB,WAAW;GACX,kBAAkB;GAClB,gBAAgB;AACnB;AACA;IACI,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,QAAQ;IACR,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".loan-state-heading{\n    font-size: 20px;\n    font-weight: 700;\n    letter-spacing: -0.4px;\n    color: #242424;\n}\n.loan-state-progress{\n   height: 40px;\n   background: #000000;\n   width: 100%;\n   border-radius: 3px;\n   overflow: hidden;\n}\n.loan-state-progress-inside{\n    height: 40px;\n    background: #EC441F;\n}\n.loan-state-progress-inside-inside{\n    height: 40px;\n    background: #C1A783;\n}\n.loan-state-font-subheading{\n    font-size: 14px;\n    font-weight: 400;\n    color: #161616B2;\n}\n.loan-state-font-subheading-value{\n    font-size: 20px;\n    font-weight: 700;\n    line-height:30px;\n    color: #242424;\n}\n\n.loan-state-font-bold{\n    font-weight: 700;\n    color: black;\n}\n.my-investment-card{\n    height: 30px;\n    border-radius: 3px;\n    padding: 0px 8px;\n    gap: 8px;\n    line-height: 30px;\n    font-size: 20px;\n    font-weight: 700;\n    color: #000000;\n}\n.my-investment-percentage{\n    line-height: 24px;\n    font-size: 14px;\n    font-weight: 500;\n    color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
