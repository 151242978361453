import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Layout, Menu, theme } from "antd";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount, useChains, useDisconnect } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import FuCapitalLogo from "../Assets/images/FUCapitallogo.svg";
import FuCapitalLogoSmall from "../Assets/images/FUsymbol.svg";
import "./style.css";
import { LogOutCurrentSession } from "../Utils/Logout";
import {
  GetProfile,
  HandelError,
  clientApi,
  gitBookUrl,
  versionNo,
} from "../Utils/AxiosCall";
import Modal2fa from "../Components/Modal2fa/Modal2fa";
import ActiveHeader from "../Components/Header/Header";

import { AiOutlineDoubleLeft } from "react-icons/ai";
import {
  DepositIcon,
  HelpIcon,
  MarketIcon,
  OverviewIcon,
  PortfolioIcon,
  StatementIcon,
} from "../Helper/CustomIcons";
import { TfiHelpAlt } from "react-icons/tfi";
import { setSelectedWalletAddress } from "../Store/reducers/global";
import IntercomWidget from "../Components/Intercom/Intercom";
const { Header, Content, Footer, Sider } = Layout;
const InvestorLeftNavbar = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var currencyCode = useSelector((state) => state.global.currencyCode);

  const { address, status } = useAccount();
  const { disconnect } = useDisconnect();
  const chains = useChains();
  const { selectedNetworkId } = useWeb3ModalState();
  // const { walletInfo } = useWalletInfo()
  //  console.log(walletInfo?.name)

  const [collapsed, setCollapsed] = useState(false);
  // const [connected, setConnected] = useState(false);
  const [_, setProvider] = useState();
  const [account, setAccount] = useState();
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState(
    localStorage.getItem("walletAddress") || null
  );
  let [walletLabel, setWalletLabel] = useState(
    localStorage.getItem("walletLabel") || null
  );
  const [depositOnHoldModal, setDepositOnHoldModal] = useState(false);
  const [twoFAModal, setTwoFAModal] = useState(false);
  const connectWallet = async () => {
    // window.scrollTo(0, 0);
    try {
      const response = await GetProfile();
      if (response) {
        if (response.holdDeposits) {
          return setDepositOnHoldModal(true);
        }
        disconnectWallets();
        // else if (!response.twoFactorAuthenticationEnabled) {
        //     setTwoFAModal(true)
        // }else if (response.twoFactorAuthenticationEnabled) {
        try {
          open({ view: "AllWallets" });
          localStorage.setItem("walletAddress", address);
          setWalletAddress(address);
          setIsLoading(false);
        } catch (error) {
          setError(error);
        }
        // }
        return response;
      }
    } catch (error) {
      HandelError(error);
    }
  };
  useEffect(() => {
    if (status === "connected" || status === "connecting") {
      if (address) {
        setWalletAddress(address);
        localStorage.setItem("walletAddress", address);
      }
    }
  }, [address, status]);

  const disconnectWallets = async () => {
    disconnect();
    // disconnectWallet(walletLabel);
    localStorage.removeItem("walletAddress");
    setWalletAddress(null);
    // dispatch(setSelectedWalletAddress(null));
    refreshState();
  };

  const refreshState = () => {
    setAccount("");
    setChainId("");
    setProvider();
  };

  const { open, close } = useWeb3Modal();
  const [selectedKey, setSelectedKey] = useState(window.location.pathname);

  useEffect(() => {
    setSelectedKey(window.location.pathname);
  }, [window.location.pathname]);
  const ping = async () => {
    try {
      const response = await clientApi.get("/api/investor/public/ping");
      console.log(response);
      if (response?.status === 200) {
        console.log(response?.data);
        if (response?.data?.authenticated === false) {
          LogOutCurrentSession();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    ping();
    GetProfile();
  }, []);

  const SwitchWallet = async () => {
    // disconnectWallets();
    // setTimeout(open, 2000); // 2000 milliseconds = 2 seconds
    open({ view: "AllWallets" });
  };
  const HandelLogout = () => {
    if (selectedNetworkId && chains) {
      if (selectedNetworkId !== chains[0]?.id) {
        disconnect();
      }
    }
    LogOutCurrentSession();
  };
  useEffect(() => {
    if (!currencyCode) {
      localStorage.setItem("currencyCode", "USD");
      currencyCode = "USD";
    }
  }, []);
  const walletProps = {
    connectWallet,
    disconnect: disconnectWallets,
    SwitchWallet,
    depositOnHoldModal,
    setDepositOnHoldModal,
    setCollapsed,
    collapsed,
  };
  return (
    <Layout className="custom-menu">
      <Sider
        className="custom-sider hide-scrollbar"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width={250}
        collapsedWidth={64}
        style={{
          background: `#242424`,
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          left: 0,
          top: 0,
        }}
        trigger={null}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div>
            <div
              style={{
                height: 56,
                backgroundColor: "#161616B2",
              }}
              className={`${
                collapsed
                  ? "custom-header-close-side-bar"
                  : "custom-header-open-side-bar"
              }`}
            >
              <Link
                to={"/user/account-overview"}
                style={{ textDecoration: "none" }}
              >
                <img
                  src={collapsed ? FuCapitalLogoSmall : FuCapitalLogo}
                  alt="logo"
                  width={collapsed ? 40 : 84.97}
                  height={collapsed ? 12.5 : 32}
                  style={{
                    transition:
                      "transform 0.5s ease, width 0.5s ease, height 0.5s ease",
                  }}
                />
              </Link>
              {!collapsed && (
                <div
                  className="collapse-toggle-button-header collapse-toggle-button-header-open"
                  onClick={() => {
                    setCollapsed(!collapsed);
                  }}
                >
                  <AiOutlineDoubleLeft size={14} color="white" />
                </div>
              )}
            </div>
            <div className={`custom-menu ${collapsed ? "collapsed" : ""}`}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "32px 0px 12px 0px",
                }}
              >
                <Link
                  to="/user/account-overview"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className={
                      selectedKey === "/user/account-overview"
                        ? "custom-menu-items-selected"
                        : "custom-menu-items"
                    }
                  >
                    <div
                      className={
                        selectedKey === "/user/account-overview"
                          ? "custom-menu-items-inside-selected"
                          : "custom-menu-items-inside"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <div
                          className={
                            collapsed
                              ? "custom-menu-items-inside-icon-padding-collapsed"
                              : "custom-menu-items-inside-icon-padding"
                          }
                        >
                          <OverviewIcon />
                        </div>
                        {!collapsed && (
                          <div
                            className="custom-menu-items-font"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Overview
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/user/portfolio" style={{ textDecoration: "none" }}>
                  <div
                    className={
                      selectedKey === "/user/portfolio"
                        ? "custom-menu-items-selected"
                        : "custom-menu-items"
                    }
                  >
                    <div
                      className={
                        selectedKey === "/user/portfolio"
                          ? "custom-menu-items-inside-selected"
                          : "custom-menu-items-inside"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <div
                          className={
                            collapsed
                              ? "custom-menu-items-inside-icon-padding-collapsed"
                              : "custom-menu-items-inside-icon-padding"
                          }
                        >
                          <PortfolioIcon />
                        </div>
                        {!collapsed && (
                          <div
                            className="custom-menu-items-font"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Portfolio
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/user/market" style={{ textDecoration: "none" }}>
                  <div
                    className={
                      selectedKey === "/user/market"
                        ? "custom-menu-items-selected"
                        : "custom-menu-items"
                    }
                  >
                    <div
                      className={
                        selectedKey === "/user/market"
                          ? "custom-menu-items-inside-selected"
                          : "custom-menu-items-inside"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <div
                          className={
                            collapsed
                              ? "custom-menu-items-inside-icon-padding-collapsed"
                              : "custom-menu-items-inside-icon-padding"
                          }
                        >
                          <MarketIcon />
                        </div>
                        {!collapsed && (
                          <div
                            className="custom-menu-items-font"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Market
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/user/account-statement"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className={
                      selectedKey === "/user/account-statement"
                        ? "custom-menu-items-selected"
                        : "custom-menu-items"
                    }
                  >
                    <div
                      className={
                        selectedKey === "/user/account-statement"
                          ? "custom-menu-items-inside-selected"
                          : "custom-menu-items-inside"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <div
                          className={
                            collapsed
                              ? "custom-menu-items-inside-icon-padding-collapsed"
                              : "custom-menu-items-inside-icon-padding"
                          }
                        >
                          <StatementIcon />
                        </div>
                        {!collapsed && (
                          <div
                            className="custom-menu-items-font"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Statement
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/user/deposit-funds"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className={
                      selectedKey === "/user/deposit-funds"
                        ? "custom-menu-items-selected"
                        : "custom-menu-items"
                    }
                  >
                    <div
                      className={
                        selectedKey === "/user/deposit-funds"
                          ? "custom-menu-items-inside-selected"
                          : "custom-menu-items-inside"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "16px",
                        }}
                      >
                        <div
                          className={
                            collapsed
                              ? "custom-menu-items-inside-icon-padding-collapsed"
                              : "custom-menu-items-inside-icon-padding"
                          }
                        >
                          <DepositIcon />
                        </div>
                        {!collapsed && (
                          <div
                            className="custom-menu-items-font"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Add&nbsp;and&nbsp;Withdraw
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: "12px" }}>
            <div
              style={{ fontSize: "8px", color: "#fff", paddingLeft: "13px" }}
            >
              version:{versionNo}
            </div>
            <a
              target="_blank"
              href={gitBookUrl}
              style={{ textDecoration: "none" }}
            >
              <div className={"custom-menu-items"}>
                <div className={"custom-menu-items-inside"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "16px",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className={
                        collapsed
                          ? "custom-menu-items-inside-icon-padding-collapsed"
                          : "custom-menu-items-inside-icon-padding"
                      }
                    >
                      <TfiHelpAlt size={32} />
                    </div>
                    {!collapsed && (
                      <div
                        className="custom-menu-items-font"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Help
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </Sider>
      <Layout>
        <Header
          className="custom-header"
          style={{
            zIndex: 4,
            background: "transparent",
            position: "sticky",
            top: 0,
          }}
        >
          <ActiveHeader {...walletProps} />
        </Header>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <div style={{ flex: 1, overflow: "auto" }}>
            <Content
              style={{
                overflow: "hidden",
                height: "100%",
              }}
            >
              <div>
                <Outlet />
                <IntercomWidget />
                {/* {twoFAModal && <Modal2fa isOpen={twoFAModal} setIsOpen={setTwoFAModal} />} */}
              </div>
            </Content>
          </div>
          <div className="custom-footer">
            <Footer className="d-flex justify-content-center">
              <span>©2024, FU Capital Inc.</span>
            </Footer>
          </div>
        </div>
      </Layout>
    </Layout>
  );
};
export default InvestorLeftNavbar;
