// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-flex-column-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    /* height: 344px; */
    overflow-x: auto;
    padding-top: 16px;
  }
  
  .filter-flex-row-container {
    display: flex;
    flex-direction: row;
  align-items: center;
    gap: 24px;
  }
  
  .filter-flex-column-small-gap {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .filter-product-label-style {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #242424;
  }
  
  .filter-felid-width-style {
     flex:1 1
  }
.filter-button-style{
    width: 154px;
    border-radius: 3px;
    padding: 8px 16px;
}
.filter-button-style:hover{
    cursor: pointer;
    background-color: #24242426;
    color: #161616B2;
}`, "",{"version":3,"sources":["webpack://./src/Components/PrimaryMarketFilter/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;IACT,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB,mBAAmB;IACjB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,QAAQ;EACV;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;EAChB;;EAEA;KACG;EACH;AACF;IACI,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,2BAA2B;IAC3B,gBAAgB;AACpB","sourcesContent":[".filter-flex-column-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 24px;\n    /* height: 344px; */\n    overflow-x: auto;\n    padding-top: 16px;\n  }\n  \n  .filter-flex-row-container {\n    display: flex;\n    flex-direction: row;\n  align-items: center;\n    gap: 24px;\n  }\n  \n  .filter-flex-column-small-gap {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n  }\n  \n  .filter-product-label-style {\n    font-size: 13px;\n    font-weight: 400;\n    line-height: 16px;\n    color: #242424;\n  }\n  \n  .filter-felid-width-style {\n     flex:1\n  }\n.filter-button-style{\n    width: 154px;\n    border-radius: 3px;\n    padding: 8px 16px;\n}\n.filter-button-style:hover{\n    cursor: pointer;\n    background-color: #24242426;\n    color: #161616B2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
