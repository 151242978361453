// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lone-header{
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #161616B2;
    }
    .lone-header-value{
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #242424;
      }`, "",{"version":3,"sources":["webpack://./src/Components/LoanDetailsComponents/FirstLoanCard/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB;IACA;MACE,kBAAkB;MAClB,eAAe;MACf,gBAAgB;MAChB,iBAAiB;MACjB,gBAAgB;MAChB,cAAc;MACd","sourcesContent":[".lone-header{\n    font-family: Inter;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n    color: #161616B2;\n    }\n    .lone-header-value{\n      font-family: Inter;\n      font-size: 16px;\n      font-weight: 500;\n      line-height: 24px;\n      text-align: left;\n      color: #242424;\n      }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
