// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    text-align: center;
}

.maintenance {
    /* max-width: 600px;
    padding: 20px;
    background: #fff;
    border-radius: 8px; */
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.maintenance h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.maintenance p {
    font-size: 1.2rem;
    color: #666;
}

.maintenance a {
    color: #007bff;
    text-decoration: none;
}

.maintenance a:hover {
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/UnderMaintenance/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI;;;yBAGqB;IACrB,+CAA+C;AACnD;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["#main {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f4f4f4;\n    text-align: center;\n}\n\n.maintenance {\n    /* max-width: 600px;\n    padding: 20px;\n    background: #fff;\n    border-radius: 8px; */\n    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */\n}\n\n.maintenance h1 {\n    font-size: 2.5rem;\n    margin-bottom: 20px;\n    color: #333;\n}\n\n.maintenance p {\n    font-size: 1.2rem;\n    color: #666;\n}\n\n.maintenance a {\n    color: #007bff;\n    text-decoration: none;\n}\n\n.maintenance a:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
