// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-chips {
    height: 36px;
    background: #2424240A;
    border-radius: 999px;
    padding: 8px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: center;
}
.custom-chips:hover {
    background: #24242426;
    color: #161616B2;
}
.custom-chips-data{
 padding: 0px 4px;
 gap:8px;
 display: flex;
 align-items: center;
 justify-content: center;
}
.active{
    background: #24242426;
    color: #161616B2;
}`, "",{"version":3,"sources":["webpack://./src/Components/Components/Atom/CustomChips/CustomChips.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qBAAqB;IACrB,oBAAoB;IACpB,YAAY;IACZ,QAAQ;IACR,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB,oBAAoB;AACpB,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,kBAAkB;AAClB;AACA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;AACA;CACC,gBAAgB;CAChB,OAAO;CACP,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB;AACA;IACI,qBAAqB;IACrB,gBAAgB;AACpB","sourcesContent":[".custom-chips {\n    height: 36px;\n    background: #2424240A;\n    border-radius: 999px;\n    padding: 8px;\n    gap: 8px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\nfont-family: 'Inter';\nfont-size: 14px;\nfont-weight: 400;\nline-height: 20px;\ntext-align: center;\n}\n.custom-chips:hover {\n    background: #24242426;\n    color: #161616B2;\n}\n.custom-chips-data{\n padding: 0px 4px;\n gap:8px;\n display: flex;\n align-items: center;\n justify-content: center;\n}\n.active{\n    background: #24242426;\n    color: #161616B2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
