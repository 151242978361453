import { Button, Checkbox } from 'antd'
import { message } from 'antd'
import { Modal } from 'antd'
import React,{useState,useEffect} from 'react'
import close_icon from"../../../Assets/images/IconButton.svg" ;
import { clientApi, HandelError } from '../../../Utils/AxiosCall';
export default function EmailsAndNotifications({profile,callBack}) {
  console.log(profile)
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({
    receiveDailySummaryReport:profile.receiveDailySummaryReport?profile.receiveDailySummaryReport:false ,
    receiveMonthlySummaryReport: profile.receiveMonthlySummaryReport?profile.receiveMonthlySummaryReport:false,
    receiveNotInvestedFundsReminder: profile.receiveNotInvestedFundsReminder?profile.receiveNotInvestedFundsReminder:false,
    receiveWeeklySummaryReport: profile.receiveWeeklySummaryReport?profile.receiveWeeklySummaryReport:false
})

const [loader, setLoader] = useState(false)
const UpdateReportSettings = async () => {
    try {
      setLoader(true)
      const response = await clientApi.post('/api/investor/change-reporting-settings', {
        receiveDailySummaryReport: data.receiveDailySummaryReport,
        receiveMonthlySummaryReport: data.receiveMonthlySummaryReport,
        receiveNotInvestedFundsReminder: data.receiveNotInvestedFundsReminder,
        receiveWeeklySummaryReport: data.receiveWeeklySummaryReport
      })
      if (response.status === 200) {
        await callBack()
        console.log("It worked")
        message.success("Notification settings updated successfully"); 
        setLoader(false)
        CancelFunction()
      }
    } catch (error) {
      console.log(error)
      // message.error("Ops! Try again.");
      setLoader(false)
      HandelError(error);
    }
  }
const CancelFunction=()=>{
setData({
    receiveDailySummaryReport:profile.receiveDailySummaryReport?profile.receiveDailySummaryReport:false ,
    receiveMonthlySummaryReport: profile.receiveMonthlySummaryReport?profile.receiveMonthlySummaryReport:false,
    receiveNotInvestedFundsReminder: profile.receiveNotInvestedFundsReminder?profile.receiveNotInvestedFundsReminder:false,
    receiveWeeklySummaryReport: profile.receiveWeeklySummaryReport?profile.receiveWeeklySummaryReport:false
})
setOpen(false)
}   
useEffect(() => {
  setData({
    receiveDailySummaryReport:profile.receiveDailySummaryReport?profile.receiveDailySummaryReport:false ,
    receiveMonthlySummaryReport: profile.receiveMonthlySummaryReport?profile.receiveMonthlySummaryReport:false,
    receiveNotInvestedFundsReminder: profile.receiveNotInvestedFundsReminder?profile.receiveNotInvestedFundsReminder:false,
    receiveWeeklySummaryReport: profile.receiveWeeklySummaryReport?profile.receiveWeeklySummaryReport:false
})
}, [profile])
const [trueCount, setTrueCount] = useState(0);

useEffect(() => {
  const count = Object.values(data).filter(value => value === true).length;
  setTrueCount(count);
}, [data]);
  return (
<div className='profile-card-container'>
        <div className='profile-card-style-header'>
        Communications and notices
        </div>
        <div className='profile-card-style'>
          <div style={{display:"flex",flexDirection:"column",gap:"16px"}}>
          <div className='profile-card-style-label'>Selected options: {trueCount||0} of 4</div>
          <div><span className='profile-card-style-link' onClick={()=>setOpen(true)}>Manage communications and notices</span></div>
          </div>
        </div> 
        <NotificationsModal open={open} setOpen={setOpen} data={data} setData={setData} callBack={UpdateReportSettings} cancelFunction={CancelFunction}/>
    </div>
  )
}

const NotificationsModal=({open,setOpen,data,setData,callBack,cancelFunction,loader})=>{

  const handelClose=()=>{
    cancelFunction()
  }
  return(
      <>
                  <Modal
  className='investment-modal'
  open={open}
  centered
  closable={false}
  footer={null}
  width={560}
  onCancel={() => handelClose()}
  style={{}}
  >
    <div style={{padding:"8px"}}>
    <div className="modal-close-icon" style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}>
    <img src={close_icon} alt="close" onClick={() => handelClose()} />
  </div>
  <div style={{padding:"0px 26px 32px 26px"}}>
      <div className='profile-modal-header'>Manage communications and notices</div>
      <div style={{height:"32px"}}></div>
      <div style={{display:"flex", flexDirection:"column",gap:"16px"}}>
        <div className='emails-and-notifications-style-row'>
          <div>
          <Checkbox  
      onChange={(e)=>setData({...data, receiveDailySummaryReport: !data.receiveDailySummaryReport})}
      checked={data.receiveDailySummaryReport} 
      style={{borderRadius:"0px" }}
    />
          </div>
           <div>
           Receive daily summary report
           </div>
        </div>
        <div className='emails-and-notifications-style-row'>
          <div>
          <Checkbox 
      onChange={(e)=>setData({...data, receiveWeeklySummaryReport: !data.receiveWeeklySummaryReport})}
      checked={data.receiveWeeklySummaryReport}   
      style={{borderRadius:"0px" }}
    />
          </div>
           <div>
           Receive weekly summary report
           </div>
        </div>
        <div className='emails-and-notifications-style-row'>
          <div>
          <Checkbox 
      onChange={(e)=>setData({...data, receiveMonthlySummaryReport: !data.receiveMonthlySummaryReport})}
      checked={data.receiveMonthlySummaryReport}  
      style={{borderRadius:"0px" }}
    />
          </div>
           <div>
           Receive monthly summary report
           </div>
        </div>
        <div className='emails-and-notifications-style-row'>
          <div>
          <Checkbox 
      onChange={(e)=>setData({...data, receiveNotInvestedFundsReminder: !data.receiveNotInvestedFundsReminder})}
      checked={data.receiveNotInvestedFundsReminder}  
      style={{borderRadius:"0px" }}
    />
          </div>
           <div>
           Receive reminder about non deployeed funds
           </div>
        </div>
      </div>
      <div style={{height:"40px"}}></div>
      <Button
           className={ "new_button_filled"}
          // className="new_button_filled hight-56"
          style={{width:"100%",height:"56px"}}
                htmlType="submit"
                loading={loader}
                onClick={() =>callBack() }
              >
                Save settings
              </Button>
      </div>
      </div>
      </Modal>
      </>
  )
}