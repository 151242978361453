import React from 'react'
import './HeaderLabel.css'
import { newNumberformat } from '../../../../Helper/HelperFunction'
export default function HeaderLabelTwo({HeaderLabel, data,defaultValue, ...props}) {
  console.log("HeaderLabelTwo",HeaderLabel,data,defaultValue)
  return (
    <div style={{display:"flex",flexDirection:"column"}}>
    <div className='header-label'>{HeaderLabel}</div>
    <div className='header-label-value-two'>{data ? data : (defaultValue !== undefined ? defaultValue : "")}</div>
</div> 
  )
}
