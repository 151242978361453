import React, { useEffect, useRef, useState } from 'react';
import { Chart, BarController, CategoryScale, LinearScale, Tooltip, Legend, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { generateAlternatingColors, generateFadedColors, generateRandomColors } from '../../../Helper/generateFadedColors';


Chart.register(BarController, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels, BarElement);

export default function BarChart({ labels, values }) {
  // console.log("labels", labels, "values", values)
  const canvasRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    let labelsData = labels || [];
    let valuesData = values || [];
    let color;

    if (chartInstance) {
      chartInstance.destroy();
    }
    const allZeros = valuesData.every(element => element === 0);
    if ( allZeros ) {
      console.log("working")
      let maxVal = 100;
      generateAlternatingColors(valuesData).then((colors) => {
        color = colors;
        const newChartInstance = new Chart(canvasRef.current, {
          type: 'bar',
          data: {
            labels: labelsData,
            datasets: [
              {
                data:[],
                backgroundColor: color,
                barPercentage: 1, // adjust this value to change the bar width
              }
            ]
          },
          options: {
            maintainAspectRatio: false,
            hover: {
              mode: null
            },
            interaction: {
              mode: null
            },
            plugins: {
              datalabels: {
                color: '#000', // Set the color to black
                anchor: 'end', // Position the label at the end (top) of the bar
                align: 'top', // Align the label to the top
                formatter: (value, context) => {
                  return value === 0 ? null : value;
                },
              },
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false
              }
            },
            scales: {
              x: {
                grid: {
                  display: false, // This will remove the grid lines from the X-axis
                },
                ticks: {
                  maxRotation: 45, // This will set the maximum rotation of the ticks to 90 degrees
                  minRotation: 45, // This will set the minimum rotation of the ticks to 90 degrees
                }
              },
              y: {  
                grid: {
                  display: true, // This will remove the grid lines from the Y-axis
                  drawTicks:false, // Do not draw the border line around the chart
                  drawOnChartArea: true, // Draw grid lines only within the chart area
                },
                border: {
                  width: 3,
                  color: '#fff', // <-------------- Color of the x-axis
                },
                max: maxVal , // maximum value
                min:0,
                ticks: {
                  stepSize: 25, // Set the step size to 25
                  callback: function(value, index, values) {
                    return value; // Ensure that the ticks are displayed as desired
                  }
                },
              },
            },
            cutout: '30%',

          }
        });

        setChartInstance(newChartInstance);
      });
    } else {
      console.log("working2")
      let minVal = Math.min(...valuesData);
      let maxVal = Math.max(...valuesData);
      let stepSize = (maxVal - minVal) / 10; // Change 10 to the number of divisions you want
      console.log("minVal", minVal, "maxVal", maxVal, "stepSize", stepSize)
      generateAlternatingColors( valuesData).then((colors) => {
        color = colors;
        const newChartInstance = new Chart(canvasRef.current, {
          type: 'bar',
          data: {
            labels: labelsData,
            datasets: [
              {
                data: valuesData,
                backgroundColor:"#EC441F",
                barPercentage:1, // adjust this value to change the bar width
              }
            ]
          },
          options: {
            maintainAspectRatio: false,
            hover: {
              mode: null
            },
            interaction: {
              mode: null
            },
            plugins: {
              datalabels: {
                color: '#000', // Set the color to black
                anchor: 'end', // Position the label at the end (top) of the bar
                align: 'top', // Align the label to the top
                formatter: (value, context) => {
                  return value === 0 ? null : value;
                },
                afterDraw: (context) => {
                  console.log('Label height:', context.size.height);
                }
              },
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false, // Ensure tooltips are disabled
              }
            },
            scales: {
              x: {
                grid: {
                  display: false, // This will remove the grid lines from the X-axis
                },
                ticks: {
                  maxRotation: 45, // This will set the maximum rotation of the ticks to 90 degrees
                  minRotation: 45, // This will set the minimum rotation of the ticks to 90 degrees
                }
              },
              y: {
                
                grid: {
                  display: true, // This will remove the grid lines from the Y-axis
                  drawTicks:false, // Do not draw the border line around the chart
                  drawOnChartArea: true, // Draw grid lines only within the chart area
                },
                border: {
                  width: 3,
                  color: '#fff', // <-------------- Color of the x-axis
                },
                max: Math.ceil(maxVal / 200) *200 +200, // maximum value
                ticks: {
                  stepSize: 200, 
                  beginAtZero: true, // start at zero
                }
              },
            },
            cutout: '30%',
          }
        });
        setChartInstance(newChartInstance);
      });
    }

    return () => {
      if (chartInstance instanceof Chart) {
        chartInstance.destroy();
      }
    };
  }, [labels, values]);

  return (

    <div style={{ minHeight: '244px',paddingTop:"24px",maxHeight:"264px",height:"100%" }}>
    <canvas ref={canvasRef} style={{ height: '100%', width: '100%' }} />
  </div>

  );
}


