import { Button, Card } from 'antd'
import React, { useEffect, useState } from 'react'
import "./style.css"
import UpdatePassword from './UpdatePassword/UpdatePassword'
import TwoFactorAuthentication from './Two-factorAuthentication/TwoFactorAuthentication'
import UpdateReportsSettings from './UpdateReportsSettings/UpdateReportsSettings'

export default function AccountSettings({profile,fetchProfile}) {
    const [buttonStatus, setButtonStatus] = useState("update-password")
    useEffect(() => {
console.log(buttonStatus)
    }, [buttonStatus])
    
  return (
    <div>
            <Card className=''> 
            <div className="container-buttons">
          <div>
            <Button className={`${buttonStatus==="update-password"?"account_settings_button_filled":"account_settings_button_outline"}`} onClick={()=>setButtonStatus("update-password")} >Update Password</Button>
          </div>
          <div>
            <Button className={`${buttonStatus==="2FA"?"account_settings_button_filled":"account_settings_button_outline"}`} onClick={()=>setButtonStatus("2FA")} >Two-factor Authentication</Button>
          </div>
          <div>
            <Button className={`${buttonStatus==="update-reports-settings"?"account_settings_button_filled":"account_settings_button_outline"}`} onClick={()=>setButtonStatus("update-reports-settings")} >Update Reports Settings</Button>
          </div>
        </div>
        <div className="custom-padding-top-24">
            {
                buttonStatus==="update-password"?
                <>
                <UpdatePassword/>
                </>
                :
                buttonStatus==="2FA"?
                <>
                <TwoFactorAuthentication fetchProfile={fetchProfile}/>
                </>
                :
                buttonStatus==="update-reports-settings"?
                <>
                <UpdateReportsSettings profile={profile}/>
                </>
                :""
            }
        </div>
        </Card> 
    </div>
  )
}
