import React, { useEffect, useState } from 'react'
import Logo from '../../Assets/images/portfolio_page_logo.svg'
import { Button } from 'antd'
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { GetProfile, HandelError } from '../../Utils/AxiosCall';
import Modal2fa from '../Modal2fa/Modal2fa';
import DepositOnHold from '../StatusModels/DepositOnHold';
import { useAccount } from 'wagmi';
import WalletIcon from '../../Assets/images/wallet_error.svg'
import add_circle from '../../Assets/images/add-circle-outline.svg'
import helpIcon from '../../Assets/images/help.svg'

export default function WalletBeforeConnect() {
  const { address,isConnected,isDisconnected } =useAccount();
    const { open, } = useWeb3Modal();
    const [error, setError] = useState("")
    const [depositOnHoldModal, setDepositOnHoldModal] = useState(false)
    const [twoFAModal, setTwoFAModal] = useState(false)
    const [profileData, setProfileData] = useState("")
    const [walletAddress, setWalletAddress] = useState(localStorage.getItem("walletAddress"));
    const GetProfiles = async () => {
        try {
            const response = await GetProfile();
            if (response) {
                setProfileData(response)
                if (response.holdDeposits) {
                  return  setDepositOnHoldModal(true);
                } 
                // else if (!response.twoFactorAuthenticationEnabled) {
                //     setTwoFAModal(true)
                // }else if (response.twoFactorAuthenticationEnabled) {
                   
                // }
                open(); 
                return response;
            }

        } catch (error) {
            HandelError(error);
        }
    }
      const connectWallet = async () => {
        try {
            await GetProfiles();
        } catch (error) {
            HandelError(error);
        }
      };
      useEffect(() => {
   const data = localStorage.getItem("walletAddress")
              if (data?.length<10) {
                setWalletAddress(data)
                localStorage.setItem("walletAddress",data)
              }
      }, [address,isConnected,isDisconnected])
      
  return (
    <div style={{maxHeight:"500px"}}> 
      <div style={{padding:"32px 24px"}}>
       <div style={{paddingBottom:"16px",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <img src={WalletIcon} alt="logo" />
       </div>
       <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
        <div style={{fontSize:"24px",fontWeight:"500",lineHeight:"36px",color:"var(--secondary-color)",paddingBottom:"8px"}} >No wallets are connected to your profile</div>
        <div style={{fontSize:"16px",fontWeight:"400",lineHeight:"24px",color:"var(--font-color-1)",paddingBottom:"32px"}}>Connect a wallet to start</div>
        </div>
        <div style={{paddingBottom:"48px",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <div className='div-navbar-wallet-connect' onClick={()=>connectWallet()} style={{height:"48px",padding:"8px 24px",width:"271px"}}>
      <div style={{fontSize:"16px",lineHeight:"24px",fontWeight:"400"}} >Connect new wallet</div><div><img src={add_circle} alt='add circle' /></div>
       </div>
       </div>
       {/* <div style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"8px", color:"var(--font-color-1)", cursor:"pointer"}}>
  <div><img src={helpIcon} alt='icon'/></div>
  <div style={{height:"20px", border:"0px", borderBottom:"1px solid #24242426"}}>
  Learn how to connect a wallet
  </div>
</div> */}

      </div>
    {/* {twoFAModal && <Modal2fa isOpen={twoFAModal} setIsOpen={setTwoFAModal} />} */}
            <DepositOnHold isOpen={depositOnHoldModal} setIsOpen={setDepositOnHoldModal} />
  </div>
  )
}
