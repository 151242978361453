import React, { useEffect, useState } from "react";
import "./style.css";
import SingUpErrorCard from "../../Components/Components/Atom/SingUpErorrCard/SingUpErrorCard";
import SignUpInputBox from "../../Components/Components/Atom/SignUpInputBox/SignUpInputBox";
import PasswordInput from "../../Components/Components/Atom/PasswordInput/PasswordInput";
import { Link } from "react-router-dom";
import { Button, Checkbox, Col, Row } from "antd";
import arrowIcon from "../../Assets/images/arrow_right_alt.svg";
import { clientApi, GetProfile, HandelError } from "../../Utils/AxiosCall";
import { message } from "antd";
export default function VerifyEmail() {
  const [error, setError] = useState({
    error: false,
    message: "",
  });
  const [validationError, setValidationError] = useState({
    otp: {
      error: false,
      message: "",
      exists: false,
    },
  });
  const [formData, setFormData] = useState({
    otp: "",
  });
  const [loader, setLoader] = useState(false);
  const validateForm = () => {
    let isValid = true;
    let validationErrorData = {
      otp: {
        error: false,
        message: "",
        exists: false,
      },
    };
    if (!formData.otp) {
      validationErrorData.otp.error = true;
      validationErrorData.otp.message = "This field is required";
      validationErrorData.otp.exists = false;
      isValid = false;
    }
    setValidationError(validationErrorData);
    return isValid;
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      const body = {
        otp: formData.otp,
        email: profileData.email,
      };
      try {
        setError({ error: false, message: "" });
        setLoader(true);
        const response = await clientApi.post(
          "/api/investor/public/validate-otp",
          body
        );
        console.log(response);
        if (response?.status === 200) {
          // message.success("Otp has been sent successfully");
          setLoader(false);
          window.location.assign("/user/personal-data");
        }
      } catch (error) {
        setLoader(false);
        if (error?.response?.data === "Invalid OTP") {
          return setError({
            error: true,
            message:
              "The recovery code is invalid or has already been used. Please try again.",
          });
        }
        {
          HandelError(error);
        }
      }
    }
  };
  const ClearData = () => {
    setFormData({
      otp: "",
    });
    setValidationError({
      otp: {
        error: false,
        message: "",
        exists: false,
      },
    });
  };
  const [profileData, setProfileData] = useState({});
  const GetData = async () => {
    try {
      const data = await GetProfile();
      console.log(data);
      setProfileData(data);
    } catch (error) {
      // HandelError(error)
    }
  };
  useEffect(() => {
    GetData();
  }, []);
  const reSendOtp = async () => {
    try {
      setError({ error: false, message: "" });
      const response = await clientApi.post("/api/investor/public/resend-otp", {
        email: profileData.email,
      });
      console.log(response);
      if (response?.status === 200) {
        message.success("Otp has been sent successfully");
      }
    } catch (error) {
      console.log(error);
      HandelError(error);
    }
  };
  useEffect(() => {
    if (profileData.emailVerified) {
      window.location.assign("/user/personal-data");
    }
  }, [profileData]);
  return (
    <div className="onboarding-page-container">
      <Row justify="center" align="middle" className="antd-row-height-style">
        <Col xs={24} sm={16} md={16} lg={10}>
          <div className="sign-up-heading eb_garamond_font">
            Verify your email address
          </div>
          {error.error ? (
            <>
              <SingUpErrorCard data={error.message} />
            </>
          ) : (
            <>
              <div className="emil-verification-text">
                An email containing a verification code has been sent to the
                email address {profileData?.email}
              </div>
            </>
          )}
          <div>
            <div>
              <SignUpInputBox
                label={"Verification code"}
                placeholder={"Paste verification code"}
                isRequired={true}
                value={formData.otp}
                onChange={(e) => {
                  setFormData({ ...formData, otp: e.target.value });
                }}
                validationError={validationError.otp}
                maxLength={6}
              />
            </div>
          </div>
          <div className="verify-email-container">
            <div className="resend-code" onClick={() => reSendOtp()}>
              Resend code
            </div>
            <Button
              className="new_button_filled hight-56 verify-button"
              htmlType="submit"
              loading={loader}
              onClick={() => handleSubmit()}
            >
              Verify
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
