import React from "react";
import {Button, Card} from "antd";
import ButtonFilled from "../ButtonFilled/ButtonFilled";

import "./style.css";
import { clientApi } from "../../Utils/AxiosCall";

const InvestorNotVerifiedBox = () => {
    const investorId = localStorage.getItem("investorId");
    const verified=()=>{
        clientApi.post(`/api/investor/start-verification`).then((res)=>{
            console.log(res.data.redirectUrl)
            window.location.replace(res.data.redirectUrl)
        }).catch((err)=>{
            console.log(err)
        })
    }

    return (
        <Card>
            <div className="verified-box-card verified-status-body">
                <div className="verified-status-p ">Not Verified</div>
                <div className="verified-desctiption-p">
                    If you have not verified your profile, you can still invest in loan
                    baskets. However for withdrawal of funds or interest to be credited
                    into your account, you will have to complete the Veriff verification
                    first.
                </div>
                <Button className="new_button_filled verified-status-button" onClick={(e) => verified()}>Finish Verification </Button>
                {/* <ButtonFilled
  title="Finish Verification"
  className="verified-status-button"
  style={{width: 200, height: 40, borderRadius: 0, border: "solid 1px #EC441F"}}
  
/> */}
            </div>
        </Card>
    );
};

export default InvestorNotVerifiedBox;
