// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-md-half {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
  }
  .custom-ul ul{
    margin-bottom: 0px;
  }`, "",{"version":3,"sources":["webpack://./src/Components/DepositAndWithdrawFundsComponents/DepositFunds/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,kBAAkB;EACpB","sourcesContent":[".col-md-half {\n    flex: 0 0 4.16667%;\n    max-width: 4.16667%;\n  }\n  .custom-ul ul{\n    margin-bottom: 0px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
