// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.es_button_filled {
    /* background: #1d8895; */
    background: #ec441f !important;
    /* border: 1px solid #5857fb; */
    border-radius: 25px;
    width: 165px;
    height: 50px;
    color: #fff !important;
}

.es_button_filled:hover {
    /* background: #19646e; */
    background: #cd3a19 !important;
    color: #fff !important;
    transition: ease-in-out !important;
    outline: none !important;
    border: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ButtonFilled/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,8BAA8B;IAC9B,+BAA+B;IAC/B,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,8BAA8B;IAC9B,sBAAsB;IACtB,kCAAkC;IAClC,wBAAwB;IACxB,oBAAoB;AACxB","sourcesContent":[".es_button_filled {\n    /* background: #1d8895; */\n    background: #ec441f !important;\n    /* border: 1px solid #5857fb; */\n    border-radius: 25px;\n    width: 165px;\n    height: 50px;\n    color: #fff !important;\n}\n\n.es_button_filled:hover {\n    /* background: #19646e; */\n    background: #cd3a19 !important;\n    color: #fff !important;\n    transition: ease-in-out !important;\n    outline: none !important;\n    border: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
