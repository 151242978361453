import React, { useEffect, useState } from 'react'
import { transformData } from '../../../Helper/SelectBoxDataGenrater'
import { clientApi } from '../../../Utils/AxiosCall'

export default function IdInformation({data}) {
    const SubData=({label,value})=>{
        return(
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                <div className='profile-card-style-label'>{label||"-"}</div>
                <div className='profile-card-style-value'>{value||"-"}</div>
        </div>
        )
    }

    const [country_list, setCountry_list] = useState([])
    const [country, setCountry] = useState("-")
    const GetCountryList = async () => {
      try {
          const response = await clientApi.post('/api/investor/reference-data/countries', {});
          console.log(response);
          if (response?.status === 200) {
              const countries = await transformData(response.data.countries);
              setCountry_list(countries);
  
              // Assuming data.country contains the country ID you want to match
              const matchingCountry = countries.find(country => country.id === data.country);
              if (matchingCountry) {
                  setCountry(matchingCountry.label); // Set the label of the matching country
              } else {
                  setCountry("-"); // Default to the first country's label if no match is found
              }
          }
      } catch (error) {
          // HandleError(error);
      }
  }
    useEffect(() => {
        GetCountryList();
    }, [])
  return (
    <div className='profile-card-container'>
        <div className='profile-card-style-header'>
        ID information
        </div>
        <div className='profile-card-style'>
          <div style={{display:"flex",flexDirection:"column",gap:"8px"}}>
          <SubData label={"First name"} value={data?.firstName} />
          <SubData label={"Last name"} value={data?.lastName} />
          <SubData label={"Date of birth"} value={data?.dateOfBirth} />
          {/* <SubData label={"Country"} value={country} /> */}
          <SubData label={"Document number"} value={data?.documentNumber} />
          <SubData label={"Document expires"} value={data?.documentExpirationDate} />
          </div>
          {
            data?.investorStatus !== "VERIFIED" ?
            <>
                      <div style={{paddingTop:"24px",}}>
            <span className='profile-card-style-link'>Update ID via verification</span>
          </div>
            </>
            :
            <></>
          }

        </div>
      
    </div>
  )
}
