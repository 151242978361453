import { Table,Card, Button } from 'antd';
import React, { useEffect, useState } from 'react'
import { FiCheckCircle } from "react-icons/fi";
import Accordion from 'react-bootstrap/Accordion';
import { AmountConverter, formatNumber, newNumberformat } from '../../../Helper/HelperFunction';
import { useAccordionButton } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';

export default function LoanActivityHistory({ data, currencySymbol }) {
  const columns = [
    {
      title: "Date ",
      dataIndex: "date",
      align: 'center',
      width: '14%',
    },

    {
      title: "Principal",
      dataIndex: "principal",
      align: 'center',
      width: '14%',
      render: (text, record) => (
        <div >
           <span> {newNumberformat(record.principal)}</span>
        </div>
      ),
    },
    {
      title: "Interest",
      dataIndex: "interest",
      align: 'center',
      width: '14%',
      render: (text, record) => (
        <div >
           <span> {newNumberformat(record.interest)}</span>
        </div>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      align: 'center',
      width: '14%',
      render: (text, record) => (
        <div >
           <span> {newNumberformat(record.total)}</span>
        </div>
      ),
    },
    {
      title:<>Payment&nbsp;Recieved</>,
      dataIndex: "paid",
      align: 'center',
      width: '16%',
      render: (text, record) => (
        <div >
           <span> {newNumberformat(record.paid)}</span>
        </div>
      ),
    },
    {
      title:<>Payment&nbsp;Date</>,
      dataIndex: "paymentDate",
      align: 'center',
      width: '14%',
      render: (text, record) => (
        <div >
           <span> {record.paymentDate||"-"}</span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      align: 'center',
      width: '14%',
      render: (text, record) => (
        <div>
<span style={{ color: record.status === "Late" ? "red" : "black" }}>
{record.status === 'Paid' && (
<>
  <FaCheckCircle style={{ width: "15px", height: "15px",color: "#45C01F" }} />
  <span style={{ color: "#45C01F", paddingRight: "4px" }}> </span>
</>
)}
{record.status}

</span>
</div>
      )
    },

  ];

  return (
    <>
    <div style={{padding:"32px 48px 0px 48px"}}>
    {
      data?
      <>

          {
            data.map((item, index) =>{
              return(
                <>
                  <div style={{fontSize:"16px",fontWeight:"700",lineHeight:"24px",color:"#242424"}}>
                  {item?.type} on {item?.eventDate}
                  </div>
                  <div style={{height:"12px"}}></div>
                    <Table
                      columns={columns}
                      dataSource={item?.paymentSchedule}
                      //   loading={apiLoad}
                      responsive
                      scroll={{ x: "max-content" }}
                      pagination={false}
                      showSizeChanger
                      className="custom-table"
                    />
                <div style={{height:"32px"}}></div>
                </>
              )
            } )
          }
      </>
      :
      <></>
    }
    </div>
    </>
  )
}
