import React, { useEffect, useState } from "react";
import "./style.css";
import SingUpErrorCard from "../../Components/Components/Atom/SingUpErorrCard/SingUpErrorCard";
import SignUpInputBox from "../../Components/Components/Atom/SignUpInputBox/SignUpInputBox";
import PasswordInput from "../../Components/Components/Atom/PasswordInput/PasswordInput";
import { Link } from "react-router-dom";
import { Button, Checkbox, Col, Row } from "antd";
import arrowIcon from "../../Assets/images/arrow_right_alt.svg";
import arrowIconDisabled from "../../Assets/images/arrow_right_alt_disabled.svg";
import { Select } from "antd";
import { FiChevronDown } from "react-icons/fi";
import { CountryCodes } from "../../Helper_Data/CountryCodes";
import { IoMdArrowDropdown } from "react-icons/io";
import { clientApi, GetProfile, HandelError } from "../../Utils/AxiosCall";
import { phone } from "phone";
export default function PersonalData() {
  const { Option } = Select;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    countryCodes: "+371",
    phoneNumber: "",
    address: "",
  });
  const [profileData, setProfileData] = useState({});
  const GetData = async () => {
    try {
      const data = await GetProfile();
      console.log(data);
      setProfileData(data);
      setFormData({
        firstName: data?.firstName || "",
        lastName: data?.lastName || "",
        countryCodes: data?.countryCode || "+371",
        phoneNumber: data?.phoneNumber || "",
        address: data?.address || "",
      });
    } catch (error) {
      HandelError(error);
    }
  };
  useEffect(() => {
    GetData();
  }, []);
  useEffect(() => {
    console.log(profileData);
    console.log(profileData?.emailVerified);
    if (profileData?.emailVerified === false) {
      window.location.assign("/user/verify-email");
    }
    if (profileData?.phoneNumber?.length > 0) {
      window.location.assign("/user/financial-data");
    }
  }, [profileData]);

  const [error, setError] = useState({
    error: false,
    message: "",
  });
  const [validationError, setValidationError] = useState({
    firstName: {
      error: false,
      message: "",
      exists: false,
    },
    lastName: {
      error: false,
      message: "",
      exists: false,
    },
    phoneNumber: {
      error: false,
      message: "",
      exists: false,
    },
    address: {
      error: false,
      message: "",
      exists: false,
    },
  });
  const [loader, setLoader] = useState(false);
  const validateForm = () => {
    let isValid = true;
    let validationErrorData = {
      firstName: {
        error: false,
        message: "",
        exists: false,
      },
      lastName: {
        error: false,
        message: "",
        exists: false,
      },
      phoneNumber: {
        error: false,
        message: "",
        exists: false,
      },
      address: {
        error: false,
        message: "",
        exists: false,
      },
    };
    // Validation for firstName

    if (!formData.firstName) {
      validationErrorData.firstName.error = true;
      validationErrorData.firstName.message = "This field is obligatory";
      isValid = false;
    }

    // Validation for lastName
    if (!formData.lastName) {
      validationErrorData.lastName.error = true;
      validationErrorData.lastName.message = "This field is obligatory";
      isValid = false;
    }
    const country = CountryCodes.find(
      (country) => country?.value === formData?.countryCodes
    );
    // console.log(country,formData?.countryCodes)
    const validatePhoneNumber = phone(formData.phoneNumber, {
      country: country?.code,
    });
    // console.log(validatePhoneNumber)
    // Validation for phoneNumber
    if (!formData.phoneNumber) {
      validationErrorData.phoneNumber.error = true;
      validationErrorData.phoneNumber.message = "This field is obligatory";
      isValid = false;
    } else if (!validatePhoneNumber.isValid) {
      validationErrorData.phoneNumber.error = true;
      validationErrorData.phoneNumber.message = "Invalid phone number";
      isValid = false;
    }
    // return

    // Validation for address
    if (!formData.address) {
      validationErrorData.address.error = true;
      validationErrorData.address.message = "This field is obligatory";
      isValid = false;
    }

    // Existing email and password validations here...

    const hasError = Object.values(validationErrorData).some(
      (field) => field.error
    );

    // Update the error state based on validation results
    if (hasError) {
      setError({
        error: true,
        message: "Form cannot be submitted, see errors below",
      });
    } else {
      setError({
        error: false,
        message: "",
      });
    }
    setValidationError(validationErrorData);
    return isValid;
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      const data = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        countryCode: formData.countryCodes,
        phoneNumber: formData.phoneNumber,
        address: formData.address,
      };
      try {
        setLoader(true);
        const response = await clientApi.post(
          "/api/investor/update-personal-info",
          data
        );
        if (response?.status === 201 || response?.status === 200) {
          setLoader(false);
          window.location.assign("/user/financial-data");
        } else {
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);

        if (error?.response?.data?.fieldErrors?.phoneNumber === "NOT_UNIQUE") {
          // Handle the specific error for duplicate phone number
          setValidationError((prevState) => ({
            ...prevState,
            phoneNumber: {
              error: true,
              message: "Phone number already in use",
              exists: true,
            },
          }));
          setError({
            error: true,
            message: "Form cannot be submitted, see errors below",
          });
        } else if (
          error?.response?.status !== 403 ||
          error?.response?.status !== 401
        ) {
          setError({
            error: true,
            message: "Form cannot be submitted, see errors below",
          });
        } else {
          HandelError(error);
        }
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "80px",
          position: "relative",
        }}
      >
        <div style={{ visibility: "hidden" }}>Invisible Spacer</div>
        <div
          className="page-sub-header"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          Sign up step 1 of 3
        </div>
        <div
          className="page-sub-header-disabled-link"
          style={{ visibility: "hidden" }}
        >
          Income and residency <img src={arrowIconDisabled} alt="icon"></img>
        </div>
      </div>
      <div className="onboarding-page-container">
        <Row justify="center" align="middle" className="antd-row-height-style">
          <Col xs={24} sm={16} md={16} lg={10}>
            <div className="sign-up-heading eb_garamond_font">
              Profile information
            </div>
            {error.error ? (
              <>
                <SingUpErrorCard data={error.message} />
              </>
            ) : (
              <>
                <div style={{ height: "3rem" }}></div>
              </>
            )}
            <div>
              <div>
                <SignUpInputBox
                  label={"First name"}
                  placeholder={"Your first name"}
                  isRequired={true}
                  value={formData.firstName}
                  onChange={(e) => {
                    setFormData({ ...formData, firstName: e.target.value });
                  }}
                  error={error}
                  validationError={validationError.firstName}
                />
              </div>
              <div className="gap-between-inputs">
                <SignUpInputBox
                  label={"Last name"}
                  placeholder={"Your last name"}
                  isRequired={true}
                  value={formData.lastName}
                  onChange={(e) => {
                    setFormData({ ...formData, lastName: e.target.value });
                  }}
                  error={error}
                  validationError={validationError.lastName}
                />
              </div>
              <div
                className="gap-between-inputs"
                style={{ display: "flex", flexDirection: "row", gap: "4px" }}
              >
                <span className="signUpLabelClass">Telephone number</span>
                <span className="signUpLabelRequiredClass">*</span>
              </div>

              <div
                className=" "
                style={{ display: "flex", flexDirection: "row", gap: "8px" }}
              >
                <div className="custom-select-box new_select_normal_border">
                  <Select
                    style={{
                      width: "100%",
                      height: "56px",
                      borderRadius: "0px",
                      marginRight: "8px",
                    }}
                    dropdownStyle={{ width: "200px", borderRadius: "0px" }}
                    optionLabelProp="label"
                    className=" new_input_normal_border"
                    defaultValue="+371"
                    onChange={(value) =>
                      setFormData({ ...formData, countryCodes: value })
                    }
                    value={formData.countryCodes}
                    suffixIcon={<IoMdArrowDropdown color="black" size={14} />}
                    showSearch
                    filterOption={(input, option) =>
                      option.children[1].props.children[0]
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0 ||
                      option.children[1].props.children[2]
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {CountryCodes.map((country) => (
                      <Option
                        key={country.code}
                        label={
                          <>
                            {" "}
                            <img
                              src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}
                              alt={country.label}
                              style={{
                                marginRight: "8px",
                                height: "24px",
                                maxWidth: "32px",
                              }}
                            />{" "}
                            {country.value}
                          </>
                        }
                        value={country.value}
                      >
                        <img
                          src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}
                          alt={country.label}
                          style={{
                            marginRight: "8px",
                            maxHeight: "15px",
                            maxWidth: "15px",
                          }}
                        />
                        <span>
                          {country.label} &nbsp;{country.value}
                        </span>
                      </Option>
                    ))}
                  </Select>
                </div>
                <div style={{ width: "100%" }}>
                  <SignUpInputBox
                    placeholder={"Your phone number"}
                    value={formData.phoneNumber}
                    onChange={(e) => {
                      setFormData({ ...formData, phoneNumber: e.target.value });
                    }}
                    validationError={validationError.phoneNumber}
                    regexPattern="^[0-9]*$"
                    maxLength={15}
                    removeGap={true}
                  />
                </div>
              </div>
              <div className="gap-between-inputs">
                <SignUpInputBox
                  label={"Address of residence"}
                  placeholder={"Address\nCity/Province, Post index\nCountry"}
                  isRequired={true}
                  value={formData.address}
                  onChange={(e) => {
                    setFormData({ ...formData, address: e.target.value });
                  }}
                  error={error}
                  validationError={validationError.address}
                  fieldType={"textarea"}
                />
              </div>
            </div>
            <div style={{ paddingTop: "32px" }}>
              <Button
                className="new_button_filled hight-56"
                style={{ width: "100%" }}
                htmlType="submit"
                loading={loader}
                onClick={() => handleSubmit()}
              >
                Continue
                <img src={arrowIcon} style={{ paddingLeft: "8px" }} />{" "}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
