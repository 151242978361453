// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-divider {
    height:  24px;
  }
  .loan-input{
      height: 56px;
      font-size: 16px;
      min-width: 158px;
  }
  
  .loan-invest-card-heading{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.4px;
    color: #EC441F;
}
.loan-invest-divider{
    height: 24px;
}
.loan-invest-horizontal-line{
    height: 1px;
    background: #EC441F;
    width: 100%;
}
.loan-invest-font-size{
    font-size: 16px;
    }
    .loan-invest-font-bold{
        font-weight: 700;
        color: black;
    }`, "",{"version":3,"sources":["webpack://./src/Pages/GuestPages/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;EACA;MACI,YAAY;MACZ,eAAe;MACf,gBAAgB;EACpB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,cAAc;AAClB;AACA;IACI,YAAY;AAChB;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,eAAe;IACf;IACA;QACI,gBAAgB;QAChB,YAAY;IAChB","sourcesContent":[".card-divider {\n    height:  24px;\n  }\n  .loan-input{\n      height: 56px;\n      font-size: 16px;\n      min-width: 158px;\n  }\n  \n  .loan-invest-card-heading{\n    font-size: 20px;\n    font-weight: 700;\n    letter-spacing: -0.4px;\n    color: #EC441F;\n}\n.loan-invest-divider{\n    height: 24px;\n}\n.loan-invest-horizontal-line{\n    height: 1px;\n    background: #EC441F;\n    width: 100%;\n}\n.loan-invest-font-size{\n    font-size: 16px;\n    }\n    .loan-invest-font-bold{\n        font-weight: 700;\n        color: black;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
