import React from 'react'

export default function AddressCard({data}) {
  return (
<div className='profile-card-container'>
        <div className='profile-card-style-header'>
        Address
        </div>
        <div className='profile-card-style'>
          <div style={{display:"flex",flexDirection:"column",gap:"4px"}}>
          <div className='profile-card-style-label'>Your address of residence</div>
          <div className='profile-card-style-value'>{data?.address||"-"}</div>
          </div>
          {/* <div style={{paddingTop:"24px"}}>
            <span className='profile-card-style-link'>Change your address</span>
          </div> */}
        </div>
    </div>
  )
}
