import { Button, Form, Input,  message } from 'antd'
import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { GetProfile, clientApi } from '../../Utils/AxiosCall'
import"./style.css"
import { useDispatch, useSelector } from 'react-redux'
import { setTwoFactorAuthenticationStatus } from '../../Store/reducers/global'
import { Modal } from 'antd'
import close_icon from"../../Assets/images/IconButton.svg" ;
import two_fa_arrow from"../../Assets/images/two-fa-arrow_right.svg" ;
import SignUpInputBox from '../Components/Atom/SignUpInputBox/SignUpInputBox'
export default function Modal2fa({isOpen,setIsOpen,onClose}) {
  const dispatch = useDispatch();
  const TwoFactorAuthenticationStatus = useSelector(state => state.global.TwoFactorAuthenticationStatus);
  const [selectedKey, setSelectedKey] = useState(window.location.pathname);
    const [openModal, setOpenModal] = useState(false)
    const [loader, setLoader] = useState(false);
    const [twoFA, setTwoFA] = useState("")
    const [totp, setTotp] = useState("")
    const [disable2FA, setDisable2FA] = useState(false)
    const [error, setError] = useState("")
    const [validationError, setValidationError] = useState({
            error: false,
            message: "",
            exists: false
    })
    const setUp2FA = async () => {
      try {
        setLoader(true)
        const data = await clientApi.post("/api/investor/2fa/setup");
        if (data) {
          setTwoFA(data.data)
          console.log(data);
          console.log("hai")
          setOpenModal(true);
          setLoader(false) 
        }

      } catch (error) {
        console.log(error);
        setLoader(false)
      }
    }
    const activate2FA = async () => {
        setLoader(true)
      setError('')
      setValidationError({
        error: false,
        message: "",
        exists: false
      
      })
      try {
        const data = await clientApi.post("/api/investor/2fa/enable", { totp: totp });
        console.log(data);
        setOpenModal(false);
        setIsOpen(false)
        setDisable2FA(true)
        localStorage.setItem("2FAStatus", "true");
        dispatch(setTwoFactorAuthenticationStatus(true))
        setTotp("")
        setLoader(false)
        GetProfile()
        message.success("Two factor authentication enabled")
        if (typeof onClose === 'function') {
          onClose();
      }
      } catch (error) {
        setLoader(false)
        if (error?.response?.data?.validation?.fieldErrors?.totp === "INVALID_VALUE") {
          setValidationError({
            error: true,
            message: "Invalid code",
            exists: false
          
          })
          setError("Invalid code")
        } else {
          message.error("Ops! Try again.");
        }
      }
    }
    
    useEffect(() => {
        if (isOpen) {
            setUp2FA()
        }
    }, [isOpen])

    const handelClose = () => {
      setOpenModal(false);
      setIsOpen(false);
      setTotp("");
      setValidationError({
          error: false,
          message: "",
          exists: false
      });
      // Check if onClose is a function before calling it
      if (typeof onClose === 'function') {
          onClose();
      }
  };
    
  return (
    <>
      <Modal 
          className='investment-modal'
          open={openModal}
          centered
          closable={false}
          footer={null}
          width={560}
          onCancel={() => {handelClose()}}
          style={{}}
     >
            <div style={{padding:"8px"}}>
      <div className="modal-close-icon" style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}>
      <img src={close_icon} alt="close" onClick={() =>{handelClose()}} />
    </div>
    <div style={{padding:"0px 26px 32px 26px"}}>
       <div style={{display:"flex",flexDirection:"column",gap:"16px"}}>
        <div className='two-fa-modal-header'>Enable two-factor authentication</div>
        <div className='two-fa-modal-label'> To add and withdrawal funds, we suggest to enable two-factor authentication.</div>
        <div className='two-fa-modal-label' style={{display:"flex",flexDirection:"row",gap:"2px"}}>
          <div>1.</div>
          <div>Install the Google Authenticator or other verification code app on your mobile Android or iOS device if you don’t have one.</div>
        </div>
        <div className='two-fa-modal-label' style={{display:"flex",flexDirection:"row",gap:"2px"}}>
          <div>2.</div>
          <div>Scan the code with the verification code app.</div>
        </div>
        <div className='two-fa-modal-qr-card'>
           <div>
           {
                        twoFA.registrationURL ?
                          <QRCode style={{ height: "160px", width: "160px" }} value={twoFA.registrationURL} />
                          :
                          <div style={{ height: "160px", width: "160px", backgroundColor: "gray" }}></div>
                      }
           </div>
           <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                <div className='two-fa-modal-light-text'>Or manually type the code</div>
                <div className='two-fa-modal-light-text-bold'>{twoFA.secret}</div>
           </div>
        </div>
        <div className='two-fa-modal-label' style={{display:"flex",flexDirection:"row",gap:"2px"}}>
          <div>3.</div>
          <div>Enter the Two-factor authentication code provided by app.</div>
        </div>
          <div style={{display:"flex",flexDirection:"row",gap:"16px",alignItems:"center"}}>
            <div style={{width:"100%"}}>
            <SignUpInputBox label={"Authentication code"} isRequired={true}  placeholder={"6-digit code"}  value={totp} onChange={(e)=>{setTotp(e.target.value)}} validationError={validationError} regexPattern="^[0-9]*$" maxLength={6}/>
            </div>
             <div style={{display:"flex",paddingTop:"22px",paddingBottom:`${validationError.error?"19px":"0px"}`}} >
             <Button
             className={`${totp.length === 6 ? "new_button_filled" : "new_button_filled_disabled"}`}
            // className="new_button_filled hight-56"
            style={{width:"128px",height:"57px"}}
                  htmlType="submit"
                  disabled={totp.length !== 6}
                  loading={loader}
                  onClick={() => activate2FA()}
                >
                  Verify
                </Button>
             </div>
          </div>
       </div>
{/* <div style={{display:"flex",justifyContent:"center",alignItems:"center",paddingTop:"32px"}}>
          <div style={{display:"flex",flexDirection:"row",gap:"8px",cursor:"pointer"}}>
          <div onClick={()=>handelClose()} >Continue without 2FA</div><img src={two_fa_arrow}></img>
          </div>
        </div> */}
    </div>
    </div>
      {/* <div className="" style={{padding:"48px"}}>
            <center><h2 className="eb_garamond_font" style={{ marginBottom: "46px" }}>Two-Factor Authentication</h2></center>
            <div className='custom-ol custom-padding-bottom-24'>
              <div>
                <div className='custom-padding-bottom-24' style={{display:"flex",flexDirection:"row"}}>
                  <div style={{display:"flex",alignItems:"center"}}>1.&nbsp;&nbsp;</div><div style={{display:"flex",alignItems:"center"}}>Install the Google Authenticator app on your mobile Android or iOS device if you don’t have one.</div>
                   </div>
                <div className='custom-padding-bottom-24'>2. Scan the code with the Authenticator app.

                  <div className='custom-padding-top-24' style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{marginLeft:"24px",  marginRight: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      {
                        twoFA.registrationURL ?
                          <QRCode style={{ height: "150px", width: "150px" }} value={twoFA.registrationURL} />
                          :
                          <div style={{ height: "150px", width: "150px", backgroundColor: "gray" }}></div>
                      }
                    </div>
                    <div>
                      <div className=''>
                        <p>Authentication secret code:<b>{twoFA.secret}</b></p>
                        <ul className='black-circle-list'>
                          <li >Please write down or print a copy of secret code and put in a safe place.</li>
                          <li >If your phone gets lost, stolen or erased, you will need this code to setup Authenticator app.</li>
                          <li >Do not share this code with anyone.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
                <div >
                  3. Enter the Two-factor authentication code provided by the Authenticator app.
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-8 ">
                    <Input
                      type="text"
                      placeholder="6-digit code"
                      // pattern="[0-9]*"
                      maxLength={6}
                      className="new_input hight-56"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Regular expression to match only numeric values
                        const regex = /^[0-9]*$/;
                        if (regex.test(inputValue)) {
                          setTotp(inputValue );
                        }
                      }}
                    />
              </div>
              <div className="col-md-2">
                <Button
            className="new_button_filled hight-56"
            style={{width:"100%"}}
                  htmlType="submit"
                  loading={loader}
                  onClick={() => activate2FA()}
                >
                  Next
                </Button>
              </div>
              <div className="col-md-2">
                <Button
                  type="primary"
                  block
                  className="new_button_outline hight-56"
                  style={{width:"100%"}}
                  onClick={() => {setOpenModal(false); setIsOpen(false) }}
                >
                  Skip
                </Button>
              </div>
            </div>
          </div> */}
      </Modal>
    </>
  )
}
