import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    incrementByAmount: "",
};

export const investor = createSlice({
    name: "investor",
    initialState,
    reducers: {
        incrementByAmount: (state, action) => {
            state.value += action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {incrementByAmount} = investor.actions;

export default investor.reducer;
