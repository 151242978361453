// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new_input_border {
    background: #ffffff !important;
    border-radius: 0;
    border: 1px solid black !important;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewInputElement/NewInputPassword/style.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,gBAAgB;IAChB,kCAAkC;IAClC,gCAAgC;IAChC,eAAe;AACnB","sourcesContent":[".new_input_border {\n    background: #ffffff !important;\n    border-radius: 0;\n    border: 1px solid black !important;\n    font-family: 'Inter', sans-serif;\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
