import React, { useEffect, useState } from 'react'
import './style.css'
import { Button, Card, Spin } from 'antd';
import DepositFunds from '../../Components/DepositAndWithdrawFundsComponents/DepositFunds/DepositFunds';
import WithdrawFunds from '../../Components/DepositAndWithdrawFundsComponents/WithdrawFunds/WithdrawFunds';
import InvestorNotVerifiedBoxWithMoreDetails from '../../Components/InvestorNotVerifiedBox/InvestorNotVerifiedBoxWithMoreDetails';
import { GetProfile, GetWalletBalance, clientApi } from '../../Utils/AxiosCall';
import { useSearchParams } from 'react-router-dom';
import WebThreeDepositFunds from '../../Components/WebThreeDepositFunds/WebThreeDepositFunds';
import PageHeader from '../../Components/Components/Molecule/PageHeader/PageHeader';
import DepositAndWithdrawFundsIcon from '../../Assets/images/deposit-and-withdraw.svg';
import HeaderLabel from '../../Components/Components/Atom/HeaderLabel/HeaderLabel';
import { useSelector } from 'react-redux';
import HeaderLabelTwo from '../../Components/Components/Atom/HeaderLabel/HeaderLabelTwo';
import { AmountConverter, newNumberformat } from '../../Helper/HelperFunction';
import { useAccount, useBalance, useChains, useConnect } from 'wagmi';
import { LoadingOutlined } from '@ant-design/icons';
export default function DepositAndWithdrawFunds() {
  const SelectedStableCoin = useSelector(state => state.global.SelectedStableCoin);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const urlStatus = searchParams.get("withdrawal")
    var verification = localStorage.getItem("investorStatus");
    const localAddress=localStorage.getItem("walletAddress")
    const [addresss, setAddresss] = useState("")
    const [profileData, setProfileData] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
        const data = await GetProfile(); 
        console.log(data)
        setProfileData(data); // Update state with fetched data
         verification = localStorage.getItem("investorStatus");
        // Use 'verification' and 'profileData' as needed
      };
  
      fetchData();
    }, []);
    const { address, isConnecting, isConnected, isDisconnected, status } =useAccount();
    const chains = useChains()
    const { connect } = useConnect()

    const [stableCoin, setStableCoin] = useState(0)
    const [stableCoinLoading, setStableCoinLoading] = useState(false)
    console.log(address)
    function shortenAddress( chars = 4) {
        return `${addresss?.substring(0, 5)}......${addresss?.substring(addresss?.length - 11)}`;
      }
      
     useEffect(() => {
      console.log(localAddress,isConnected,isDisconnected,address)
         if (address?.length>11) {
            setAddresss(address)
         }else{
              setAddresss("")
         }         
     }, [localAddress,isConnected,isDisconnected,address])
   
     console.log("addresss :"+addresss,"localAddress :"+localAddress)
     const[assetSelectedMaster,setAssetSelectedMaster]=useState("") // DAI
     const { data: tokenBalance, isLoading: tokenLoading, error: tokenError } = useBalance({
      address: address,
      token: assetSelectedMaster, // Replace with the token contract address // Link Token
      chainId: chains[0]?.id, // Replace with the chain ID (e.g., 1 for Ethereum mainnet)
  });
  const [originalAssetList, setOriginalAssetList] = useState([])
  const [asset_list, setAsset_list] = useState([])
  const convertToAssetList = async (originalArray) => {
    return originalArray.map(item => {
        return {
            label: item?.tokenName,
            value: item?.contractAddress
        };
    });
}
  const getNetworkAddress = async () => {
    try {
        const response = await clientApi.get(`/api/investor/wallet/network-address?chainId=${chains[0]?.id}`);
        if (response?.status === 200) {
            console.log(response?.data)
            setOriginalAssetList(response?.data)
            setAsset_list(await convertToAssetList(response?.data))
        }
    } catch (error) {
        console.log(error)
    }
}
useEffect(() => {
  if (asset_list && SelectedStableCoin) {
      // Find the asset in the list that matches the value from Redux
      const matchingAsset = asset_list.find(asset => asset.label === SelectedStableCoin);
      if (matchingAsset) {
          // If a matching asset is found, select its value
          setAssetSelectedMaster(matchingAsset.value);
      } else if (asset_list.length > 0) {
          // If no matching asset is found but the list is not empty, select the first asset by default
      }
  }
}, [asset_list, SelectedStableCoin]);
useEffect(() => {getNetworkAddress()}, [chains]);
const [SelectedWalletDetails, setSelectedWalletDetails] = useState({})
const[walletListDetails,setWalletListDetails]=useState({})
useEffect(() => {
  const Get=async()=>{
    try {
      const response=await GetWalletBalance()
      if(response){
        const selectedItem = response?.walletBalances?.find(item => item.walletAddress === address);
        setSelectedWalletDetails(selectedItem)
      }

    } catch (error) {
      
    }
  }
  Get()
}, [address])

  const SubComponent=()=>{
    const WalletComponent=()=>{
      return(<div>
      {
        address?
        <span>{newNumberformat(SelectedWalletDetails?.walletBalance||0)}</span> 
                :
               <div style={{fontSize:"16px",fontWeight:"500",color:"var(--error-color)",display:"flex",alignItems:"center"}} >No wallet connected</div>
      }
      </div>)
    }
    return(
      <>
      <HeaderLabelTwo HeaderLabel={"Stablecoin"} data={SelectedStableCoin} />
      <HeaderLabelTwo HeaderLabel={"Total balance"} data={newNumberformat(profileData?.accounts[SelectedStableCoin]?.cashBalance||0)} />
      <HeaderLabelTwo HeaderLabel={"Linked wallet balance"} data={<WalletComponent/>} />
      </>
    )
  }
    
  return (
<div>
      <PageHeader title="Add and withdraw funds" image={DepositAndWithdrawFundsIcon} subComponent={<SubComponent/>} />
      <div style={{justifyContent:"center", display:"flex"}}>
      <div style={{padding:"0px 48px",width:"100%",maxWidth:"1700px"}}>
        <div className='custom-hr' style={{ paddingTop: "20px", paddingBottom: "32px" }}>
          <hr className=' hr-style-2 '></hr>
        </div>
        {
            verification !=="NOT_VERIFIED" &&  verification !=="REGISTRATION" ?
            <>
             <WebThreeDepositFunds  />
              </>
            :
          <InvestorNotVerifiedBoxWithMoreDetails />
        }
     
     </div>
     </div>
</div>
  )
}
