import { CaretDownOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Button } from "antd"; // Corrected import
import React, { useState, useEffect } from "react";
import { store } from "../../../Store/store";
import usdc_icon from "../../../Assets/images/USDC.svg";
import usdt_icon from "../../../Assets/images/USDT.svg";
import check_icon from "../../../Assets/images/check.svg";
import { AiOutlineDoubleRight } from "react-icons/ai";
import {
  setCurrencyCode,
  setSelectedStableCoin,
} from "../../../Store/reducers/global";
import { Link } from "react-router-dom";

export default function GuestHeader(props) {
  return (
    <div className="header-style" style={{ width: "100%" }}>
      <div className="header-inner">
        <div className="header-left">
          <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
            {props?.collapsed && (
              <div
                className="collapse-toggle-button-header"
                onClick={() => {
                  props?.setCollapsed(!props?.collapsed);
                }}
              >
                <AiOutlineDoubleRight size={14} />
              </div>
            )}
          </div>
          <CurrencySwitch />
        </div>
        <div className="header-right">
          <Link to={"/user/login"}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#EC441F",
                borderColor: "#EC441F",
                borderRadius: "3px",
                height: "40px",
                width: "120px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
              }}
            >
              Sign in
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

const CurrencySwitch = () => {
  const state = store.getState();
  const SelectedStableCoin = state.global.SelectedStableCoin;
  const [stableCoin, setStableCoin] = useState(SelectedStableCoin);

  const SetStableCoin = (coin) => {
    setStableCoin(coin);
    store.dispatch(setSelectedStableCoin(coin));
    store.dispatch(setCurrencyCode(coin));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const menu = (
    <Menu style={{ backgroundColor: "#242424" }}>
      <div className="header-dropdown-menu-bottom-switch">
        <div style={{ height: "8px" }}></div>
        <div
          className="header-dropdown-menu-item"
          onClick={() => {
            SetStableCoin("USDT");
          }}
          style={
            stableCoin === "USDT"
              ? { borderLeft: "4px solid #EC441F" }
              : { borderLeft: "4px solid transparent " }
          }
        >
          <div>
            <img src={usdt_icon} alt="usdt"></img>
          </div>
          <div
            style={{
              width: "84px",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "20px",
            }}
          >
            USDT
          </div>
          {stableCoin === "USDT" ? (
            <div>
              <img src={check_icon} alt="check"></img>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className="header-dropdown-menu-item"
          onClick={() => {
            SetStableCoin("USDC");
          }}
          style={
            stableCoin === "USDC"
              ? { borderLeft: "4px solid #EC441F" }
              : { borderLeft: "4px solid transparent" }
          }
        >
          <div>
            <img src={usdc_icon} alt="usdc"></img>
          </div>
          <div
            style={{
              width: "84px",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "20px",
            }}
          >
            USDC
          </div>
          {stableCoin === "USDC" ? (
            <div>
              <img src={check_icon} alt="check"></img>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Menu>
  );

  useEffect(() => {
    console.log(SelectedStableCoin);
  }, [SelectedStableCoin]);

  return (
    <div style={{ position: "relative" }}>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomLeft">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <div>Stablecoin</div>
          <div
            style={{
              height: "40px",
              width: "164px",
              borderRadius: "3px",
              border: "1px solid #24242459",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px",
              gap: "8px",
              backgroundColor: "#FFFFFF",
              cursor: "pointer",
            }}
          >
            <img
              src={stableCoin === "USDT" ? usdt_icon : usdc_icon}
              alt={stableCoin}
            />
            <div
              style={{
                width: "84px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
              }}
            >
              {stableCoin}
            </div>
            <CaretDownOutlined />
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
