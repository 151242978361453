import React, { useEffect, useState } from 'react'
import { Input } from 'antd';
import { StablecoinWarperFunction, formatNumber, newNumberformat } from '../../../Helper/HelperFunction';
import "./style.css"
function FirstLoanCard({principalIssued,currencySymbol,interestRatePercent,amountAvailable,termInDays,loanStatus,myInvestments,AmountConverter,}) {
  const convertedPrincipalIssued = newNumberformat(principalIssued);
  const convertedAmountAvailable = newNumberformat(amountAvailable);
  const convertedMyInvestments = newNumberformat(myInvestments);
  return (
    <>
<div style={{display:"flex",flexDirection:"row",gap:"48px"}}>
     <div>
      <div className='lone-header'>Loan amount</div>
      <div className='lone-header-value'>{convertedPrincipalIssued}</div>
     </div>
     <div>
      <div className='lone-header'>Interest</div>
      <div className='lone-header-value'>{`${newNumberformat(interestRatePercent)||"0"}%`}</div>
     </div>
     <div>
      <div className='lone-header'>Available loan amount</div>
      <div className='lone-header-value'>{`${convertedAmountAvailable || "0.00"}`}</div>
     </div>
     <div>
      <div className='lone-header'>Remaining loan maturity</div>
      <div className='lone-header-value'>{`${termInDays || "0"} days`}</div>
     </div>
     <div>
      <div className='lone-header'>Loan status</div>
      <div className='lone-header-value'>{loanStatus||""}</div>
     </div>
     <div>
      <div className='lone-header'>Deployed balance</div>
      <div className='lone-header-value'>{` ${convertedMyInvestments|| "0.00"}`}</div>
     </div>
</div>
    </>
  )
}

export default FirstLoanCard