import { Wallet } from "ethers";

const Products_list=[
    {value:"BUSINESS_LOAN",label:"Business loan"},
    {value:"CREDIT_LINE",label:"Credit line"},
    {value:"INSTALLMENT_LOAN",label:"Installment loan"},
    {value:"MORTGAGE_LOAN_BUNDLE",label:"Mortgage loan bundle"},
    {value:"SHORT_TERM_LOANS",label:"Short-term loan"},

]

const Countries_list = [
    { value: "CZ", label: "Czech Republic" },
    { value: "JO", label: "Jordan" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LV", label: "Latvia" },
    { value: "MX", label: "Mexico" },
    { value: "PL", label: "Poland" },
    { value: "ES", label: "Spain" },
  ];

  const Originators_list = [
    { value: "Money for Finance JO", label: "Money for Finance JO" },
    { value: "A24 Group", label: "A24 Group" },
    { value: "AvaFin(Creamfinance) PL MDP", label: "AvaFin(Creamfinance) PL MDP" },
    { value: "Lyric Financial - Utopia Music", label: "Lyric Financial - Utopia Music" },
    { value: "Spanda Capital", label: "Spanda Capital" },
    { value: "AvaFin(Creamfinance) ES", label: "AvaFin(Creamfinance) ES" },
    { value: "AvaFin(Creamfinance) CZ", label: "AvaFin(Creamfinance) CZ" },
    { value: "AvaFin(Creamfinance) MX", label: "AvaFin(Creamfinance) MX" },
    { value: "JMD Investments SIA", label: "JMD Investments SIA" },
    { value: "AvaFin(Creamfinance) PL KIM", label: "AvaFin(Creamfinance) PL KIM" },
    { value: "MDI Finance SIA", label: "MDI Finance SIA" },
  ];

  const Status_list = [
    { value: "Current", label: "Current" },
    { value: "Late 1-15", label: "Late 1-15" },
    { value: "Late 16-30", label: "Late 16-30" },
    { value: "Late 31-60", label: "Late 31-60" },
    { value: "Late 60+", label: "Late 60+" },
  ];

  const Loan_extensions_list=[
    {value:"" ,label:"All "},
    {value:true ,label:"Loans with extension"},
    {value:false ,label:"Loans without extension"},
  ]
  const personal_details_country_list = [
    {value: "AT", label: "Austria"},
    {value: "BE", label: "Belgium"},
    {value: "BG", label: "Bulgaria"},
    {value: "HR", label: "Croatia"},
    {value: "CY", label: "Cyprus"},
    {value: "CZ", label: "Czech Republic"},
    {value: "DK", label: "Denmark"},
    {value: "EE", label: "Estonia"},
    {value: "FI", label: "Finland"},
    {value: "FR", label: "France"},
    {value: "DE", label: "Germany"},
    {value: "GR", label: "Greece"},
    {value: "HU", label: "Hungary"},
    {value: "IS", label: "Iceland"},
    {value: "IE", label: "Ireland"},
    {value: "IT", label: "Italy"},
    {value: "LV", label: "Latvia"},
    {value: "LI", label: "Liechtenstein"},
    {value: "LT", label: "Lithuania"},
    {value: "LU", label: "Luxembourg"},
    {value: "MT", label: "Malta"},
    {value: "NL", label: "Netherlands"},
    {value: "NO", label: "Norway"},
    {value: "PL", label: "Poland"},
    {value: "PT", label: "Portugal"},
    {value: "RO", label: "Romania"},
    {value: "SK", label: "Slovakia"},
    {value: "SI", label: "Slovenia"},
    {value: "ES", label: "Spain"},
    {value: "SE", label: "Sweden"},
    {value: "CH", label: "Switzerland"},
    {value: "GB", label: "United Kingdom"}
];

  const sourceOfFunds_list=[
    { "value": "SALARY", "label": "Salary/Pension" },
    { "value": "SAVINGS", "label": "Savings" },
    { "value": "CAPITAL_INCOME", "label": "Capital income" },
    { "value": "DIVIDENDS_OR_INVESTMENTS", "label": "Dividends or gains from investments" },
    { "value": "GIFT", "label": "Gift" },
    { "value": "INHERITANCE", "label": "Inheritance" },
    { "value": "BUSINESS_INCOME", "label": "Business income" },
    { "value": "OTHER", "label": "Other" }
  ]
const annualIncome_list=[
  { "value": "LESS_THAN_20K", "label": "Less than $20 000" },
  { "value": "20K_TO_50K", "label": "$20 000 to $50 000" },
  { "value": "50K_TO_75K", "label": "$50 000 to $75 000" },
  { "value": "75K_TO_100K", "label": "$75 000 to $100 000" },
  { "value": "MORE_THAN_100K", "label": "More than $100 000" }
]
const fundsAvailableForInvestment_list=[
  { "value": "LESS_THAN_10%", "label": "Less than 10%" },
  { "value": "10%_TO_20%", "label": "10% to 20%" },
  { "value": "MORE_THAN_20%", "label": "More than 20%" }
]

const  discountOptions= [
  { value: 2.0, label: '+2,0% Premium' },
  { value: 1.5, label: '+1,5% Premium' },
  { value: 1.0, label: '+1,0% Premium' },
  { value: 0.5, label: '+0,5% Premium' },
  { value: 0, label: '0%' },
  { value: -0.5, label: '-0,5% Discount' },
  { value: -1.0, label: '-1,0% Discount' },
  { value: -1.5, label: '-1,5% Discount' },
  { value: -2.0, label: '-2,0% Discount' },
  { value: -3.0, label: '-3,0% Discount' },
  { value: -4.0, label: '-4,0% Discount' },
  { value: -5.0, label: '-5,0% Discount' },
  { value: -6.0, label: '-6,0% Discount' },
  { value: -7.0, label: '-7,0% Discount' },
  { value: -8.0, label: '-8,0% Discount' },
  { value: -9.0, label: '-9,0% Discount' },
  { value: -10.0, label: '-10,0% Discount' },
  { value: -15.0, label: '-15,0% Discount' },
  { value: -20.0, label: '-20,0% Discount' }
]
const PageSizeList1=[
  { value: 5, label: "5 / page" },
  { value: 10, label: "10 / page" },
  { value: 20, label: "20 / page" },
  { value: 50, label: "50 / page" },
  { value: 100, label: "100 / page" },
  { value: 300, label: "300 / page" },
  { value: 500, label: "500 / page" },
]

const WalletTestData = [
  {
    walletName: "MetaMask",
    WalletAddress: "0x1234567890123456789012345678901234567890",
    WalletBalance: 200,
  },
  {
    walletName: "Trust Wallet",
    WalletAddress: "0x0987654321098765432109876543210987654321",
    WalletBalance: 1.5,
  },
  {
    walletName: "Coinbase Wallet",
    WalletAddress: "0xabcdefabcdefabcdefabcdefabcdefabcdefabcd",
    WalletBalance: 2.3,
  },
  {
    walletName: "Ledger",
    WalletAddress: "0x123abc123abc123abc123abc123abc123abc123a",
    WalletBalance: 0.75,
  },
  {
    walletName: "Trezor",
    WalletAddress: "0x456def456def456def456def456def456def456d",
    WalletBalance: 3.2,
  },
  {
    walletName: "Exodus",
    WalletAddress: "0x789ghi789ghi789ghi789ghi789ghi789ghi789g",
    WalletBalance: 4.0,
  },
  {
    walletName: "Coinbase Wallet",
    WalletAddress: "0xabcdefabcdefabcdefabcdefabcdefabcdefabcd",
    WalletBalance: 2.3,
  },
  {
    walletName: "Ledger",
    WalletAddress: "0x123abc123abc123abc123abc123abc123abc123a",
    WalletBalance: 0.75,
  },
  // {
  //   walletName: "Trezor",
  //   WalletAddress: "0x456def456def456def456def456def456def456d",
  //   WalletBalance: 3.2,
  // },
  // {
  //   walletName: "Exodus",
  //   WalletAddress: "0x789ghi789ghi789ghi789ghi789ghi789ghi789g",
  //   WalletBalance: 4.0,
  // }
];

  export {Products_list,Countries_list,Originators_list,Status_list,Loan_extensions_list,
          personal_details_country_list,sourceOfFunds_list,annualIncome_list,fundsAvailableForInvestment_list,
          discountOptions,PageSizeList1,WalletTestData
        }