import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import './style.css'
import { Button, Checkbox, Input, Select, message } from 'antd';
import { HandelError, clientApi } from '../../Utils/AxiosCall';
import { transformData } from '../../Helper/SelectBoxDataGenrater';
import { Loan_extensions_list } from '../../Helper_Data/SelectBoxData';
import { useSelector } from 'react-redux';


export default function MyPortfolioFilter({ filters, setFilters, isModalOpen, setIsModalOpen, GetMyPortfolio }) {
  const [countries_list, setCountries_list] = useState([]);
  const [search_listener, setSearch_listener] = useState(0)
  const [originators_list, setOriginators_list] = useState([])
  const [collectionStatuses_list, setCollectionStatuses_list] = useState([])
  const [products_list, setProducts_list] = useState([])
      var currencyCode = useSelector(state => state.global.currencyCode);
  useEffect(() => {
    GetReferenceData()
  }, []);
  const GetReferenceData = async () => {
    try {
      const response = await clientApi.post('/api/investor/reference-data', {})
      if (response?.status === 200) {
        const countries = await transformData(response.data.countries);
        const originators = await transformData(response.data.originators);
        const collectionStatuses = await transformData(response.data.collectionStatuses);
        const products = await transformData(response.data.products);
        console.log(countries, originators, collectionStatuses, products)
        try {
          await setCountries_list(countries);
          await setOriginators_list(originators);
          await setCollectionStatuses_list(collectionStatuses);
          await setProducts_list(products);
        } catch (error) {
          console.log(error)
        }
        console.log(countries_list, originators_list, collectionStatuses_list, products_list)

      }
    } catch (error) {
      console.log(error)
      HandelError(error);
    }
  }
  const handleClose = async () => {
    setIsModalOpen(false);
    setFilters({
      autoInvestId: null,
      collectionStatuses: [],
      countries: [],
      currencyCode: currencyCode,
      extensions: null,
      forSale: true,
      interestRatePercentFrom: null,
      interestRatePercentTo: null,
      investStrategyId: null,
      loanId: null,
      originators: [],
      products: [],
      remainingTermInDaysFrom: null,
      remainingTermInDaysTo: null,
      showActive:true,
      showClosed: true
    })
    await GetMyPortfolio()
  }
  useEffect(() => {
 console.log("filters",filters)
  }, [filters])
  

  return (
    <div>
      {/* show={show} */}
      <Modal show={isModalOpen} centered style={{ display: "flex", alignItems: "center", justifyContent: "center" }} size='lg' >
          <div style={{padding:"48px"}} >
          <div className='d-flex justify-content-center align-items-center' style={{paddingBottom:"48px"}}>
  <span className="popup-titel-text-style">Filters</span>
</div>
            <div className='custom-select-box'>
              <div className="row">
                <div className="col-md-4 ">
                  <div className="row">
                    <div className="col-12">
                      Loan ID:
                    </div>
                    <div className="col-12 custom-padding-top-12 ">
                      <Input
                        style={{ width: "100%", height: "42px" }}
                        className='new_input_normal_border'
                        placeholder='ID'
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Regular expression to match only numeric values
                          const regex = /^[0-9]*$/;
                          if (regex.test(inputValue)) {
                            setFilters({ ...filters, loanId: inputValue });
                          }
                        }}
                      ></Input>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="row">
                    <div className="col-12">
                      Loan Type:
                    </div>
                    <div className="col-12 custom-padding-top-12 new_select_normal_border">
                      <Select
                        size={"large"}
                        mode="multiple"
                        value={filters.products}
                        placeholder={"All"}
                        className="new_input_normal_border  select-box-height"
                        options={products_list}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setFilters({ ...filters, products: e });
                        }}
                        dropdownStyle={{ zIndex: 1200 }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="row">
                    <div className="col-12">
                      Loan Originators:
                    </div>
                    <div className="col-12 custom-padding-top-12">
                      <Select

                        mode="multiple"
                        placeholder={"All"}
                        className="new_input_normal_border select-box-height"
                        options={originators_list}
                        style={{ width: "100%", outline:'none'}}
                        dropdownStyle={{ zIndex: 1200 }}
                        // size={"large"}
                        onChange={(e) => {
                          setFilters({ ...filters, originators: e });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-4 custom-padding-top-24">
                  <div className="row">
                    <div className="col-12">
                      Loan Extensions:
                    </div>
                    <div className="col-12 custom-padding-top-12">
                      <Select
                        placeholder={"All"}
                        className="select-box-height"
                        options={Loan_extensions_list}
                        style={{ width: "100%", }}
                        dropdownStyle={{ zIndex: 1200 }}
                        size={"large"}
                        onChange={(e) => {
                          setFilters({ ...filters, extensions: e });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 custom-padding-top-24">
                  <div className="row">
                    <div className="col-12">
                      Countries:
                    </div>
                    <div className="col-12 custom-padding-top-12">
                      <Select
                        mode="multiple"
                        placeholder={"All"}
                        className="new_select select-box-height"
                        options={countries_list}
                        style={{ width: "100%", }}
                        dropdownStyle={{ zIndex: 1200 }}
                        size={"large"}
                        onChange={(e) => {
                          setFilters({ ...filters, countries: e });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 custom-padding-top-24">
                  <div className="row">
                    <div className="col-12">
                      Status:
                    </div>
                    <div className="col-12 custom-padding-top-12">
                      <Select
                        mode="multiple"
                        placeholder={"All"}
                        className="new_select select-box-height"
                        options={collectionStatuses_list}
                        style={{ width: "100%", }}
                        dropdownStyle={{ zIndex: 1200 }}
                        size={"large"}
                        onChange={(e) => {
                          setFilters({ ...filters, collectionStatuses: e });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 custom-padding-top-24">
                  <div className="row">
                    <div className="col-12">
                      Interest Rate:
                    </div>
                    <div className="col-6 custom-padding-top-12">
                      <Input style={{ width: "100%", height: "42px" }} className='new_input' placeholder='From %'
                        onKeyPress={(e) => {
                          if (!/[0-9.]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Regular expression to match only numeric values
                          const regex = /^[0-9]*\.?[0-9]*$/;
                          if (regex.test(inputValue)) {
                            setFilters({ ...filters, interestRatePercentFrom: inputValue });
                          }
                        }}
                      ></Input>
                    </div>
                    <div className="col-6 custom-padding-top-12">
                      <Input style={{ width: "100%", height: "42px" }} className='new_input' placeholder='To %'
                        onKeyPress={(e) => {
                          if (!/[0-9.]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Regular expression to match only numeric values
                          const regex = /^[0-9]*\.?[0-9]*$/;
                          if (regex.test(inputValue)) {
                            setFilters({ ...filters, interestRatePercentTo: inputValue });
                          }
                        }}
                      ></Input>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 custom-padding-top-24">
                  <div className="row">
                    <div className="col-12">
                      Remaining Term in Days:
                    </div>
                    <div className="col-6 custom-padding-top-12">
                      <Input style={{ width: "100%", height: "42px" }} className='new_input' placeholder='From '
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Regular expression to match only numeric values
                          const regex = /^[0-9]*$/;
                          if (regex.test(inputValue)) {
                            setFilters({ ...filters, remainingTermInDaysFrom: inputValue });
                          }
                        }}
                      ></Input>
                    </div>
                    <div className="col-6 custom-padding-top-12">
                      <Input style={{ width: "100%", height: "42px" }} className='new_input' placeholder='To '
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Regular expression to match only numeric values
                          const regex = /^[0-9]*$/;
                          if (regex.test(inputValue)) {
                            setFilters({ ...filters, remainingTermInDaysTo: inputValue });
                          }
                        }}
                      ></Input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row custom-padding-top-24">

                <div className="col-md-4 ">
                  <Checkbox
                    checked={filters.showActive}
                    onChange={() => setFilters({ ...filters, showActive: !filters.showActive })}
                    style={{ marginRight: "12px" }}
                  ></Checkbox>
                  Include Active
                </div>
                <div className="col-md-4 d-md-flex justify-content-md-center ">
                  <Checkbox
                    checked={filters.showClosed}
                    onChange={() => setFilters({ ...filters, showClosed: !filters.showClosed })}
                    style={{ marginRight: "12px" }}
                  ></Checkbox>
                  Include Closed
                </div>
                <div className="col-md-4 d-md-flex justify-content-md-end ">
                  <Checkbox
                    checked={filters.forSale}
                    onChange={() => setFilters({ ...filters, forSale: !filters.forSale })}
                    style={{ marginRight: "12px" }}
                  ></Checkbox>
                  For Sale
                </div>
              </div>

            </div>

            <div className="row custom-padding-top-24">
              <div className="col-md-6 pb-4 pb-md-0">
                <Button
                  type="primary"
                  block
                  style={{width:"100%"}}
                  className='new_button_outline hight'
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-md-6 pb-4 pb-md-0">
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  style={{width:"100%"}}
                  className='new_button_filled hight'
                  onClick={() => {GetMyPortfolio()}}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
      </Modal>
    </div>
  )
}
