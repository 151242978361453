import React, { useEffect, useState } from 'react'
import "./style.css"
import { HandelError, clientApi } from '../../../Utils/AxiosCall'
import { Button, Input, message, Form } from 'antd'
import QRCode from "react-qr-code";
import { Modal } from 'react-bootstrap';
export default function TwoFactorAuthentication({ fetchProfile }) {
  const [twoFA, setTwoFA] = useState("")
  const [totp, setTotp] = useState("")
  const [disable2FA, setDisable2FA] = useState(false)
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [twoFAStatus, setTwoFAStatus] = useState(localStorage.getItem("2FAStatus"))
  const [error, setError] = useState("")
  const setUp2FA = async () => {
       const status=localStorage.getItem("2FAStatus")
    try {
      if (status!=="true") {
        const data = await clientApi.post("/api/investor/2fa/setup");
        setTwoFA(data.data)
        console.log(data);   
      }

    } catch (error) {
      HandelError(error);
    }
  }
  const activate2FA = async () => {
    setError("")
    try {
        const data = await clientApi.post("/api/investor/2fa/enable", { totp: totp });
        console.log(data)
        if (data.status === 200) {
          setDisable2FA(true)
          setTotp("")
          setTwoFAStatus("true")
          message.success("Two factor authentication enabled");
          localStorage.setItem("2FAStatus", "true");
          fetchProfile()
        }

    } catch (error) {
      setTotp("")
      if (error?.response?.data?.validation?.fieldErrors?.totp === "INVALID_VALUE") {
       return  setError("Invalid code")
      } else {
        HandelError(error);
      }
     
    }
    
  }
  useEffect(() => {
    setUp2FA()
    if (!twoFAStatus) {
      setTwoFAStatus(localStorage.getItem("2FAStatus"))
    }
  
  }, [])

  const handleClose = async () => {
    setIsModalOpen(false);
    setError("")
  }
  const Disable2FA = async () => {
    setError("")
    try {
      const data = await clientApi.post("/api/investor/2fa/disable", { totp: totp });
      console.log(data)
      if (data.status === 200) {
      
        setDisable2FA(true)
        setTotp("")
        setTwoFAStatus("false")
        message.success("Two factor authentication disable");
        localStorage.setItem("2FAStatus", "false");
        fetchProfile()
        setIsModalOpen(false);
        setUp2FA()    
      }
    } catch (error) {
      console.log(error);
      setTotp("")
      if (error?.response?.data?.validation?.fieldErrors?.totp === "INVALID_VALUE") {

        message.error("Invalid code");
        setError("Invalid code")
      } else {
        message.error("Ops! Try again.");
      }
      HandelError(error);
    }
  }

  return (
    <div className='genaral-font-size-16'>
      {
        twoFAStatus === "true" ?
          <>
            <div className='custom-padding-bottom-24'>Two-factor Authentication has been <span style={{ color: "#EC441F",fontWeight:"700" }}>Enabled</span>.</div>
            <div className="row">
              <div className="col-12">
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  loading={loader}
                  style={{ backgroundColor: "#EC441F", borderRadius: '0', height: '56px', width: "148px" }}
                  onClick={() => setIsModalOpen(true)}
                >
                  Disable
                </Button>
              </div>
            </div>
          </>
          :
          twoFAStatus === "false"?
          <>
            <div className='custom-ol custom-padding-bottom-24'>
              <div>
                <div className='custom-padding-bottom-24'>1. Install the Google Authenticator app on your mobile Android or iOS device if you don’t have one.</div>
                <div className='custom-padding-bottom-24'>2. Scan the code with the Authenticator app.

                  <div className='custom-padding-top-24' style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{marginLeft:"24px", marginRight: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      {
                        twoFA.registrationURL ?
                          <QRCode style={{ height: "150px", width: "150px" }} value={twoFA.registrationURL} />
                          :
                          <div style={{ height: "150px", width: "150px", backgroundColor: "gray" }}></div>
                      }
                    </div>
                    <div>
                      <div className='custom-genaral-padding-12'>
                        <p>Authentication secret code:<b>{twoFA.secret}</b></p>
                        <ul className='black-circle-list'>
                          <li >Please write down or print a copy of secret code and put in a safe place.</li>
                          <li >If your phone gets lost, stolen or erased, you will need this code to setup Authenticator app.</li>
                          <li >Do not share this code with anyone.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
                <div >
                  3. Enter the Two-factor authentication code provided by the Authenticator app.
                </div>
              </div>
            </div>
            <div className="row">

              <div className="col-md-10">
                <Form>
                  <Form.Item
                    validateStatus={error ? "error" : ""}
                    help={error}
                  >
                    <Input
                      type="text"
                      placeholder="6-digit code"
                      // pattern="[0-9]*"
                      maxLength={6}
                      className="new_input hight-56"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Regular expression to match only numeric values
                        const regex = /^[0-9]*$/;
                        if (regex.test(inputValue)) {
                          setTotp(inputValue );
                        }
                      }}
                    />
                  </Form.Item>
                </Form>
              </div>

              <div className="col-md-2 pb-4 pb-md-0">
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  loading={loader}
                  style={{ backgroundColor: "#EC441F", borderRadius: '0', height: '56px', width: "100%" }}
                  onClick={() => activate2FA()}
                >
                  Next
                </Button>
              </div>
            </div>
          </>
          :
          <></>
      }


      <Modal show={isModalOpen} centered style={{ display: "flex", alignItems: "center", justifyContent: "center", }} size='lg' >
        <Modal.Body>
          <div className="" style={{ padding: "48px" }}>
            <center><h2 className="eb_garamond_font" style={{ marginBottom: "48px" }}>Disable Two-Factor Authentication</h2></center>
            <div className="row" style={{ marginBottom: "24px" }} >
              <div className="col-12 col-md-6">

                <Form>
                  <Form.Item
                    validateStatus={error ? "error" : ""}
                    help={error}
                  >
<Input
    type="text"
    placeholder="6-digit code"
    maxLength={6}
    className="new_input"
    style={{ height: "52px" }}
    onKeyPress={(e) => {
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    }}
    onCha
    onChange={(e) => {
        const re = /^[0-9]*$/;; // RegEx to only accept numbers
        if (e.target.value === '' || re.test(e.target.value)) {
            setTotp(e.target.value);
        }
    }}
/>
                  </Form.Item>
                </Form>

              </div>
              <div className="col-6 col-md-3">
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  // loading={loader}
                  style={{ backgroundColor: "#EC441F", borderRadius: '0', height: '52px' }}
                  onClick={() => { Disable2FA() }}
                >
                  Confirm
                </Button>
              </div>
              <div className="col-6 col-md-3">
                <Button
                  type="primary"
                  block
                  style={{ backgroundColor: "#fff", borderRadius: '0', borderBlockColor: "#EC441F", height: '52px', color: "#EC441F", border: "1px solid #EC441F" }}
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>

      </Modal>

    </div>
  )
}
