import React from 'react'
import './CustomChips.css'
export default function CustomChips({onClick, label,active}) {
  return (
    <div className={`custom-chips ${active?'active':''} `} onClick={(e) => { onClick() }}>
        <div className='custom-chips-data'>
        {label}
        </div>
    </div>
  )
}