import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import { Button, Col, Form, Input, Row, Typography, message } from "antd";
import { clientApi } from "../../Utils/AxiosCall";
import PasswordInput from "../../Components/Components/Atom/PasswordInput/PasswordInput";
export default function ResetPassword() {
  const { token } = useParams();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({
    error: false,
    message: "",
  });
  const [validationError, setValidationError] = useState({
    password: {
      error: false,
      message:
        "Minimal password requirements: 6 symbols and at least one number",
      exists: false,
    },
  });
  const [formData, setFormData] = useState({
    password: "",
  });
  const validateForm = () => {
    let isValid = true;
    let validationErrorData = {
      password: {
        error: false,
        message:
          "Minimal password requirements: 6 symbols and at least one number",
        exists: false,
      },
    };

    // Regular expression for password validation (at least one number)
    const passwordRegex = /.*[0-9].*/;

    if (!formData.password) {
      validationErrorData.password.error = true;
      validationErrorData.password.message = "Password is required";
      validationErrorData.password.exists = false;
      isValid = false;
    } else if (
      formData.password.length < 6 ||
      !passwordRegex.test(formData.password)
    ) {
      validationErrorData.password.error = true;
      validationErrorData.password.message =
        "Minimal password requirements: 6 symbols and at least one number";
      validationErrorData.password.exists = false;
      isValid = false;
    }

    const hasError = Object.values(validationErrorData).some(
      (field) => field.error
    );

    // Update the error state based on validation results
    if (hasError) {
      setError({
        error: true,
        message: "Form cannot be submitted, see errors below",
      });
    } else {
      setError({
        error: false,
        message: "",
      });
    }
    setValidationError(validationErrorData);
    return isValid;
  };

  const onFinish = async () => {
    console.log(formData, "formData");
    const isValid = validateForm();
    if (!isValid) return;
    await resetPassword();
  };
  const resetPassword = async () => {
    setLoader(true);
    try {
      const response = await clientApi.post(
        "/api/investor/public/reset-password",
        {
          password: formData.password,
          passwordRepeat: formData.password,
          token: token,
        }
      );
      if (response?.status === 200) {
        setLoader(false);
        setError("");
        localStorage.clear();
        message.success("Password Changed Successfully");
        setTimeout(() => {
          window.location.assign("/user/login");
        }, 3000);
      }
    } catch (error) {
      setLoader(false);
      if (
        error?.response?.data?.validation?.fieldErrors?.password === "INVALID"
      ) {
        setValidationError({
          ...validationError,
          password: {
            error: true,
            message: "Enter valid password",
            exists: false,
          },
        });
      }
      if (
        error?.response?.data?.validation?.fieldErrors?.password ===
        "INVALID_VALUE"
      ) {
        setValidationError({
          ...validationError,
          password: {
            error: true,
            message: "Enter valid password",
            exists: false,
          },
        });
      }
    }
  };
  const validateToken = async () => {
    try {
      const response = await clientApi.post(
        "/api/investor/public/validate-reset-password-token",
        { token: token }
      );
      console.log(response);
      if (response?.status === 200) {
        if (!response.data?.valid) {
          message.error("Invalid Token");
          return setError("Invalid Token");
        }
        setError("");
      }
    } catch (error) {
      if (error?.response?.data?.validation?.fieldErrors?.token === "INVALID") {
        setError("Invalid Token");
        setValidationError({
          ...validationError,
          password: { error: true, message: "", exists: true },
        });
      }
    }
  };
  useEffect(() => {
    validateToken();
  }, [token]);

  return (
    <div className="onboarding-page-container">
      <Row justify="center" align="middle" className="antd-row-height-style">
        <Col xs={24} sm={16} md={16} lg={10}>
          <center
            className="login-sign-up-heading"
            style={{ paddingTop: "3rem" }}
          >
            Reset password
          </center>
          <div className="onboarding-page-form">
            <PasswordInput
              label={"Password"}
              placeholder={"Enter your password"}
              isRequired={true}
              value={formData.password}
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
              }}
              validationError={validationError.password}
            />
            <div className="" style={{ marginTop: "32px" }}>
              <Button
                type="primary"
                block
                loading={loader}
                className={`${
                  !formData.password
                    ? "new_button_filled_disabled"
                    : "new_button_filled"
                }   hight-56`}
                onClick={() => {
                  onFinish();
                }}
              >
                Change Password
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
