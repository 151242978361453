import { Layout, theme } from "antd";
import { Link, Outlet } from "react-router-dom";
import FuCapitalLogo from "../Assets/images/Fu Capital logo.svg";

const { Header, Content, Footer } = Layout;
const NoLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout className="layout" style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: "#242424",
          display: "flex",
          alignItems: "center",
          height: "72px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "16px 48px",
          }}
        >
          <Link to={"/user/login"}>
            <img
              src={FuCapitalLogo}
              style={{ height: 38.18, width: 106.68 }}
              alt="Fu Capital Logo"
            />
          </Link>
        </div>
      </Header>

      <Content
        style={{
          padding: "0px 48px",
          background: "#F6F6F6",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
        }}
      >
        <Outlet />
      </Content>

      <div className="custom-footer">
        <Footer className="d-flex justify-content-center">
          <span>©2024, FU Capital Inc.</span>
        </Footer>
      </div>
    </Layout>
  );
};
export default NoLayout;
