// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noFooterTimePick .ant-picker-footer{
    display: none;
    }
    .noFooterTimePick .ant-picker-date-panel{
      height: 265px !important;
    }`, "",{"version":3,"sources":["webpack://./src/Components/Components/Atom/DatePickerComponent/DatePickerComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb;IACA;MACE,wBAAwB;IAC1B","sourcesContent":[".noFooterTimePick .ant-picker-footer{\n    display: none;\n    }\n    .noFooterTimePick .ant-picker-date-panel{\n      height: 265px !important;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
