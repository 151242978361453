// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-card-container-style {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #2424240A;
}
.chart-card-container-style-header {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    
  }
  .chart-card-container-footer{
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
color: var(--secondary-color);

  }`, "",{"version":3,"sources":["webpack://./src/Components/Components/Atom/BarChartCard/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,2BAA2B;AAC7B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;;EAElB;EACA;AACF,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;AAChB,6BAA6B;;EAE3B","sourcesContent":[".chart-card-container-style {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  padding: 24px;\n  background-color: #fff;\n  border-radius: 6px;\n  border: 1px solid #2424240A;\n}\n.chart-card-container-style-header {\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 24px;\n    text-align: left;\n    \n  }\n  .chart-card-container-footer{\nfont-size: 14px;\nfont-weight: 400;\nline-height: 20px;\ntext-align: left;\ncolor: var(--secondary-color);\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
