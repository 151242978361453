import React, { useEffect, useState } from "react";
import "./style.css";
import {
  Button,
  Card,
  DatePicker,
  Flex,
  Pagination,
  Select,
  Table,
  Tag,
  message,
} from "antd";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { GetProfile, HandelError, clientApi } from "../../Utils/AxiosCall";
import dayjs from "dayjs";
import { FiCode } from "react-icons/fi";
import {
  AmountConverter,
  StablecoinWarperFunction,
  formatNumber,
  newNumberformat,
} from "../../Helper/HelperFunction";
import { PageSizeList1 } from "../../Helper_Data/SelectBoxData";
import StatementIcon from "../../Assets/images/receipt-long.svg";
import HeaderLabel from "../../Components/Components/Atom/HeaderLabel/HeaderLabel";
import HeaderLabelTwo from "../../Components/Components/Atom/HeaderLabel/HeaderLabelTwo";
import PageHeader from "../../Components/Components/Molecule/PageHeader/PageHeader";
import FromDateIcon from "../../Assets/images/from-date.svg";
import ToDateIcon from "../../Assets/images/end-date.svg";
import DatePickerComponent from "../../Components/Components/Atom/DatePickerComponent/DatePickerComponent";
import CustomChips from "../../Components/Components/Atom/CustomChips/CustomChips";
import PdfIcon from "../../Assets/images/pdf-icon.svg";
import excelIcon from "../../Assets/images/export-excel.svg";
import { MdArrowDropDown } from "react-icons/md";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
export default function AccountStatement() {
  const currencyCode = useSelector((state) => state.global.SelectedStableCoin);
  const [loader, setLoader] = useState(false);
  const [filters, setFilters] = useState({ dateFrom: "", dateTo: "" });
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState("");
  const [filter, setFilter] = useState({
    currencyCode: currencyCode,
    dateFrom: null,
    dateTo: moment().format("YYYY-MM-DD"),
  });

  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    GetAccountStatement();
    GetProfile(); // Assuming GetProfile is defined elsewhere
  }, [page, pageSize]);
  // useEffect(() => {
  //   SelectDateFilter("All Time");
  // }, []);

  const ButtonClick = async () => {
    if (!filters.dateFrom || !filters.dateTo) {
      message.error("Please select date range");
      return;
    }
    setActiveChip("");
    setFilter({
      ...filter,
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
    });
    GetAccountStatement(filters);
  };
  const [lastRecordDate, setLastRecordDate] = useState();
  const GetAccountStatement = async (customFilters = filter) => {
    setLoader(true);
    try {
      const response = await clientApi.post(
        "/api/investor/query-account-statement",
        { filter: customFilters, page, pageSize }
      );
      if (response?.status === 200) {
        setData(response.data);
        setTotalItems(response.data?.total);
        setFilter({
          ...filter,
          dateFrom: filter?.dateFrom || response?.data?.lastRecordDate,
          dateTo: customFilters.dateTo,
        });
        setLastRecordDate(
          lastRecordDate ||
            moment(response?.data?.lastRecordDate).format("YYYY-MM-DD")
        );
      }
    } catch (error) {
      console.error(error);
      setError(error.toString());
    } finally {
      setLoader(false);
    }
  };

  const [activeChip, setActiveChip] = useState("All Time");
  const SelectDateFilter = async (dateRange) => {
    console.log(lastRecordDate);
    setActiveChip(dateRange);
    const ranges = {
      Today: [dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      "Last 7 Days": [
        dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ],
      "This Month": [
        dayjs().startOf("month").format("YYYY-MM-DD"),
        dayjs().endOf("month").format("YYYY-MM-DD"),
      ],
      "Previous Month": [
        dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
      ],
      "Previous Year": [
        dayjs().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
        dayjs().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
      ],
      "All Time": [
        lastRecordDate === "Invalid date"
          ? moment().format("YYYY-MM-DD")
          : lastRecordDate,
        moment().format("YYYY-MM-DD"),
      ],
    };
    console.log(ranges);
    const [dateFrom, dateTo] = ranges[dateRange]; // Default to [null, null] if dateRange is not found
    setFilter({ ...filter, dateFrom, dateTo }); // Ensure filter is defined and accessible
    setFilters({ ...filters, dateFrom, dateTo, currencyCode });

    GetAccountStatement({ dateFrom, dateTo, currencyCode });
  };
  const exportData = async (uri) => {
    console.log(filter);
    setLoader(true);
    const pageSizes = data?.total;
    try {
      const response = await clientApi.post(
        uri,
        { filter, page: 1, pageSizes },
        { responseType: "arraybuffer" }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const filename = response.headers["x-filename"];
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
      HandelError(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const currentDate = moment().format("YYYY-MM-DD");
  const SubComponent = ({ data }) => {
    return (
      <>
        <HeaderLabelTwo
          HeaderLabel="Date from"
          data={
            moment(filters?.dateFrom).format("YYYY-MM-DD") ||
            moment().format("YYYY-MM-DD")
          }
        />
        <HeaderLabelTwo
          HeaderLabel="Date to"
          data={moment(filters?.dateTo).format("YYYY-MM-DD")}
        />
        <HeaderLabelTwo
          HeaderLabel="Opening balance"
          data={newNumberformat(data?.openingBalance) || 0}
          defaultValue="0"
        />
        <HeaderLabelTwo
          HeaderLabel="Closing balance"
          data={newNumberformat(data?.closingBalance) || 0}
          defaultValue="0"
        />
      </>
    );
  };
  useEffect(() => {
    if (!filters.dateFrom) {
      SelectDateFilter("All Time");
    }
  }, [data]);
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <PageHeader
          title="Account Statement"
          image={StatementIcon}
          subComponent={<SubComponent data={data} />}
        />
        <div style={{ justifyContent: "center", display: "flex" }}>
          <div className="page-width-control">
            <div
              className="custom-hr"
              style={{ paddingTop: "32px", paddingBottom: "16px" }}
            >
              <hr className=" hr-style-2 "></hr>
            </div>
            <div className="statement-first-container">
              <FilterOptions
                filters={filters}
                setFilters={setFilters}
                dateFormat={dateFormat}
                ButtonClick={ButtonClick}
                loader={loader}
                SelectDateFilter={SelectDateFilter}
                activeChip={activeChip}
              />
              <Summary data={data} exportData={exportData} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <Transactions_link count={data?.total} exportData={exportData} />
        <Transactions_Table
          data={data?.items}
          setPageSize={setPageSize}
          pageSize={pageSize}
          totalItems={totalItems}
          setPage={setPage}
          page={page}
          loader={loader}
          GetAccountStatement={GetAccountStatement}
        />
      </div>
    </>
  );
}

// To resolve the error, rename the ButtonClick function passed as a prop to avoid naming conflict with the ButtonClick function declared in the same file.

const FilterOptions = ({
  filters,
  setFilters,
  dateFormat,
  ButtonClick,
  loader,
  SelectDateFilter,
  activeChip,
}) => {
  return (
    <>
      <div className="card card-style-statement filter-container">
        <div className="filter-header">Filter</div>
        <div className="filter-date-piker-container">
          <DatePickerComponent
            placeholder={"From Date"}
            dateFormat={dateFormat}
            value={filters?.dateFrom || moment().format("YYYY-MM-DD")}
            onChange={(e) => {
              setFilters({
                ...filters,
                dateFrom: dayjs(e).format("YYYY-MM-DD"),
              });
            }}
            suffixIcon={FromDateIcon}
            className={"new_input new_input_normal_border date-picker-height"}
          />
          <DatePickerComponent
            disablingDate={filters?.dateFrom}
            placeholder={"To Date"}
            dateFormat={dateFormat}
            value={filters?.dateTo}
            onChange={(e) => {
              setFilters({ ...filters, dateTo: dayjs(e).format("YYYY-MM-DD") });
            }}
            suffixIcon={ToDateIcon}
            className={"new_input new_input_normal_border date-picker-height"}
          />
          <div style={{ paddingTop: "32px", paddingBottom: "24px" }}>
            <Button
              style={{ width: "100%", height: "48px" }}
              className="new_button_filled"
              loading={loader}
              onClick={() => {
                ButtonClick();
              }}
            >
              Confirm
            </Button>
          </div>
          <div>
            <Flex gap="8px 8px" wrap>
              <CustomChips
                label={"Today"}
                active={activeChip === "Today"}
                onClick={() => SelectDateFilter("Today")}
              />
              <CustomChips
                label={"Last 7 Days"}
                active={activeChip === "Last 7 Days"}
                onClick={() => SelectDateFilter("Last 7 Days")}
              />
              <CustomChips
                label={"This Month"}
                active={activeChip === "This Month"}
                onClick={() => SelectDateFilter("This Month")}
              />
              <CustomChips
                label={"Previous Month"}
                active={activeChip === "Previous Month"}
                onClick={() => SelectDateFilter("Previous Month")}
              />
              <CustomChips
                label={"Previous Year"}
                active={activeChip === "Previous Year"}
                onClick={() => SelectDateFilter("Previous Year")}
              />
              <CustomChips
                label={"All Time"}
                active={activeChip === "All Time"}
                onClick={() => SelectDateFilter("All Time")}
              />
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
};

// Ensure to pass the ButtonClick function as ButtonClickProp when using FilterOptions component to avoid the naming conflict.

const Summary = ({ data, exportData }) => {
  const BoldDataComponent = ({ label, value }) => {
    return (
      <>
        <div className="summery-container-body-data summery-container-body-data-bold">
          <div>{label}</div>
          <div>{newNumberformat(value) || 0}</div>
        </div>
      </>
    );
  };
  const NormalDataComponent = ({ label, value }) => {
    return (
      <>
        <div className="summery-container-body-data ">
          <div className="summery-container-body-data-label">{label}</div>
          <div className="summery-container-body-data-label-bold">
            {newNumberformat(value) || 0}
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="summery-container">
      <div className="heading">Summary</div>
      <div className="summery-container-body">
        <BoldDataComponent
          label="Opening Balance"
          value={data?.openingBalance}
        />
        <NormalDataComponent label="Added funds" value={data?.deposit} />
        <NormalDataComponent label="Withdrawal" value={data?.withdrawal} />
        <NormalDataComponent
          label="Deployed on the Primary market"
          value={data?.primaryMarketPrincipalInvested}
        />
        <NormalDataComponent
          label="Deployed on the Secondary market"
          value={data?.secondaryMarketPrincipalBought}
        />
        <NormalDataComponent
          label="Sold on Secondary Market"
          value={data?.secondaryMarketPrincipalSold}
        />
        <NormalDataComponent
          label="Principal income"
          value={data?.principalReceived}
        />
        <NormalDataComponent
          label="Interest income"
          value={data?.interestReceived}
        />
        <NormalDataComponent
          label="Bonus Received"
          value={data?.bonusReceived}
        />
        <NormalDataComponent
          label="Referral bonus received"
          value={data?.referralBonusReceived}
        />
        <NormalDataComponent
          label="Secondary Market Income"
          value={data?.secondaryMarketIncome}
        />
        {/* <NormalDataComponent label="Secondary Market Expense" value={data?.secondaryMarketExpense} /> */}
        <BoldDataComponent
          label="Closing Balance"
          value={data?.closingBalance}
        />
      </div>
      <div className="export-pdf">
        <div
          className="export-pdf-div"
          onClick={() =>
            exportData("/api/investor/download-account-statement-pdf")
          }
        >
          <div>
            <img src={excelIcon} alt="pdf" />
          </div>
          <div>Download PDF statement</div>
        </div>
      </div>
    </div>
  );
};
const Transactions_link = ({ count, exportData }) => {
  console.log(count);
  return (
    <div className="custom-padding-top-48">
      <div
        className=""
        style={{
          display: "flex",
          backgroundColor: "#FFFFFFA6",
          justifyContent: "center",
        }}
      >
        <div className="page-width-control">
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between",
              height: "72px",
              borderBottom: "1px solid #2424240A",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <div className="transactions-text">Total Transactions:</div>
              <div className="transactions-value">{count || 0}</div>
            </div>
            <div
              className="export-pdf"
              style={{ display: "flex", alignItems: "center", height: "72px" }}
            >
              <div
                className="export-excl-div"
                onClick={() =>
                  exportData("/api/investor/download-account-statement")
                }
              >
                <div>
                  <img src={PdfIcon} alt="pdf" />
                </div>
                <div>Export to excel</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Transactions_Table = ({
  data,
  setPageSize,
  pageSize,
  totalItems,
  setPage,
  page,
  loader,
  GetAccountStatement,
}) => {
  const AmountConverter = ({ value }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
      StablecoinWarperFunction(value).then(setData);
    }, [value]);

    if (data === null) return null; // or return a loading spinner

    return <>{data}</>;
  };
  const shortenAddress = (address) => {
    return `${address?.substring(0, 4)}...${address?.substring(
      address?.length - 4
    )}`;
  };
  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "txId",
      key: "txId",
      align: "center",
      width: 155.71,
    },
    {
      title: "Date",
      dataIndex: "txTsFormatted",
      key: "txTsFormatted",
      align: "center",
      width: 155.71,
    },
    {
      title: "Transaction type",
      dataIndex: "txType",
      key: "txType",
      align: "center",
      width: 155.71,
      render: (text, record) => <div>{record.txTypeLabel}</div>,
    },
    {
      title: "Lender",
      dataIndex: "originatorCompanyName",
      key: "originatorCompanyName",
      align: "center",
      width: 155.71,
      render: (text, record) => <>{record.originatorCompanyName || "-"}</>,
    },
    {
      title: "Loan ID",
      dataIndex: "loanId",
      key: "loanId",
      align: "center",
      width: 155.71,
      render: (text, record) => (
        <div>
          {record.loanId ? (
            <div className="" style={{ fontSize: "14px" }}>
              <Link
                className="primary-color"
                style={{ textDecoration: "none" }}
                to={`/user/loan/${record.loanId}`}
              >
                <span style={{ borderBottom: "1px solid #EC441F33" }}>
                  {record.loanId}
                </span>
              </Link>{" "}
            </div>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: "Transaction amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: 155.71,
      render: (text, record) => <div>{record.amount || 0}</div>,
    },
    {
      title: "Wallet",
      dataIndex: "walletAddress",
      key: "walletAddress",
      align: "center",
      width: 155.71,
      render: (text, record) => (
        <div>
          {record?.walletAddress ? shortenAddress(record?.walletAddress) : "NA"}
        </div>
      ),
    },
    {
      title: "Available balance",
      dataIndex: "cashBalance",
      key: "cashBalance",
      align: "center",
      width: 155.71,
      render: (text, record) => (
        <div>{newNumberformat(record.cashBalance || 0)}</div>
      ),
    },
  ];
  const handlePageChange = (pageNumber) => {
    console.log(pageNumber);
    setPage(pageNumber);
  };

  const handlePageSizeChange = (newSize) => {
    console.log(newSize);
    // setPageSize(newSize?.value);
    setPageSize(newSize);
  };

  return (
    <div
      className=""
      style={{
        display: "flex",
        background: "#FFFFFF",
        justifyContent: "center",
      }}
    >
      <div className="page-width-control">
        <Table
          columns={columns}
          dataSource={data}
          loading={loader}
          responsive
          scroll={{ x: "max-content" }}
          pagination={false}
          showSizeChanger
          className="custom-table custom-padding-bottom-24"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "24px",
            paddingBottom: "24px",
          }}
        >
          <div>
            <Pagination
              current={page}
              pageSize={pageSize}
              total={totalItems}
              onChange={handlePageChange}
              onShowSizeChange={handlePageSizeChange}
              className="custom-pagination"
              pageSizeOptions={["10", "20", "50", "100"]}
              showSizeChanger={false}
            />
          </div>
          <div className="new_input_normal_border_pagination ">
            <Select
              placeholder={"All"}
              className="new_input_normal_border_pagination"
              options={PageSizeList1}
              style={{ width: "100%", height: "40px", paddingRight: "8px" }}
              dropdownStyle={{ zIndex: 1200 }}
              suffixIcon={<MdArrowDropDown color="black" size={24} />}
              // size={"large"}
              value={pageSize}
              onChange={handlePageSizeChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
