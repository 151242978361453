import React, { useState, useEffect } from 'react'
import './style.css'
import Modal from 'react-bootstrap/Modal';
import { Button, Card, Checkbox, Input, Select, message } from 'antd';
import { Loan_extensions_list } from '../../Helper_Data/SelectBoxData';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import filter_icon from '../../Assets/images/filter_list.svg';
import close_icon from '../../Assets/images/IconButton.svg';
import { FiChevronDown } from 'react-icons/fi';
import { IoMdArrowDropdown } from 'react-icons/io';
import { VscPercentage } from "react-icons/vsc";
export default function PrimaryMarketFilterNew({ filter, setFilter, isModalOpen, setIsModalOpen, countries_list,
  originators_list, collectionStatuses_list, products_list, clearFlitter, handleSearch,totalCount }) {
  const [searchStatus, setSearchStatus] = useState(false);
  const handleClose = async () => {
    // setIsModalOpen(false);
    setSearchStatus(false)
    await clearFlitter();
    await handleSearch();
  }
  const [show, setShow] = useState(true);
  function CustomToggle({ children, eventKey }) {
  
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if(show){
        setShow(false)
      }else{
        setShow(true)
      }
    });
    
    return (
      <div
        style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "72px" }}
      >
        <div style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}>
          <div style={{color:"#161616B2",fontSize:"14px",lineHeight:"20px",fontWeight:"400"}} >{!searchStatus ? "Available loans:" : "Filtered loans:"} </div>
          <div style={{color:"#242424",fontSize:"16px",lineHeight:"20px",fontWeight:"700"}}>{totalCount?.total||0}</div>
          <div style={{color:"#161616B2",fontSize:"14px",lineHeight:"20px"}}>{searchStatus && <>/</>}</div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {
              searchStatus && <>
                <div onClick={()=>handleClose()} style={{ height: "24px", fontSize: "16px", lineHeight: "24px", fontWeight: "700", color: "#EC441F", display: "flex", alignItems: "center", borderBottom: "1px solid #EC441F33",cursor:"pointer" }}>
                  {totalCount.defaultTotal||0}
                </div>
              </>
            }

          </div>

        </div>
        <div onClick={decoratedOnClick} className='filter-button-style'>
          <> <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}><div>{!show ? "Close filters" : "Show filters"}</div><div>{!show ? <img src={close_icon} alt="Close filters" /> : <img src={filter_icon} alt="Show filters" />}</div>  </div></>
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", }}>
      <div style={{ width: "100%" }}>
        <Accordion className='custom-card'>
          <Card className='custom-card'>
            <CustomToggle eventKey="0"></CustomToggle>
            <Accordion.Collapse eventKey="0">
              <div className="filter-flex-column-container">
                <div className="filter-flex-row-container">
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Products</div>
                    <div className='custom-select-box new_select_normal_border'>
                      <Select
                        mode="multiple"
                        placeholder={"All"}
                        className="filter-felid-width-style new_input_normal_border filter-select-style"
                        options={products_list}
                        size='large'
                        style={{ width: "100%" }}
                        dropdownStyle={{ zIndex: 1200 }}
                        value={filter.products}
                        onChange={(e) => {
                          setFilter({ ...filter, products: e });
                        }}
                        suffixIcon={<IoMdArrowDropdown color="black" size={14} />}
                        // showSearch
                        // filterOption={(input, option) =>
                        //   option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        // }
                        showSearch={false}
                      />
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Countries</div>
                    <div className='custom-select-box new_select_normal_border'>
                      <Select
                        mode="multiple"
                        placeholder={"All"}
                        className="filter-felid-width-style new_input_normal_border filter-select-style"
                        options={countries_list}
                        dropdownStyle={{ zIndex: 1200 }}
                        size={"large"}
                        style={{ width: "100%" }}
                        value={filter.countries}
                        onChange={(e) => {
                          setFilter({ ...filter, countries: e });
                        }}
                        suffixIcon={<IoMdArrowDropdown color="black" size={14} />}
                        showSearch={false}
                      />
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Lenders</div>
                    <div className='custom-select-box new_select_normal_border'>
                      <Select
                        mode="multiple"
                        placeholder={"All"}
                        className="filter-felid-width-style new_input_normal_border filter-select-style"
                        options={originators_list}
                        dropdownStyle={{ zIndex: 1200 }}
                        style={{ width: "100%" }}
                        size={"large"}
                        value={filter.originators}
                        onChange={(e) => {
                          setFilter({ ...filter, originators: e });
                        }}
                        suffixIcon={<IoMdArrowDropdown color="black" size={14} />}
                        showSearch={false}
                      />
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Status</div>
                    <div className='custom-select-box new_select_normal_border'>
                      <Select
                        placeholder={"All"}
                        className=" new_input_normal_border filter-select-style"
                        options={collectionStatuses_list}
                        dropdownStyle={{ zIndex: 1200 }}
                        style={{ width: "100%" }}
                        size={"large"}
                        value={filter.collectionStatuses}
                        onChange={(e) => {
                          setFilter({ ...filter, collectionStatuses: e });
                        }}
                        suffixIcon={<IoMdArrowDropdown color="black" size={14} />}
                        showSearch={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-flex-row-container ">
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Extensions</div>
                    <div className='custom-select-box new_select_normal_border'>
                      <Select
                        placeholder={"All"}
                        className="filter-felid-width-style new_input_normal_border filter-select-style"
                        options={Loan_extensions_list}
                        dropdownStyle={{ zIndex: 1200 }}
                        style={{ width: "100%" }}
                        size={"large"}
                        value={filter.extensions}
                        onChange={(e) => {
                          setFilter({ ...filter, extensions: e });
                        }}
                        suffixIcon={<IoMdArrowDropdown color="black" size={14} />}
                        showSearch={false}
                      />
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Interest</div>
                    <div className='' style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                      <div>
                        <Input style={{ width: "100%", height: "40px" }} className='new_input_filter new_input_normal_border' placeholder='From '
                          onKeyPress={(e) => {
                            if (!/[0-9.]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.interestRatePercentFrom}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*\.?[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, interestRatePercentFrom: inputValue });
                            }
                          }}
                          suffix={<VscPercentage color="black" size={14} />}
                        ></Input>
                      </div>
                      <div >
                        <Input style={{ width: "100%", height: "40px" }} className='new_input new_input_filter new_input_normal_border' placeholder='To '
                          onKeyPress={(e) => {
                            if (!/[0-9.]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.interestRatePercentTo}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*\.?[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, interestRatePercentTo: inputValue });
                            }
                          }}
                          suffix={<VscPercentage color="black" size={14} />}
                        ></Input>
                      </div>
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Loan&nbsp;duration&nbsp;in&nbsp;days</div>
                    <div className='' style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                      <div>
                        <Input style={{ width: "100%", height: "40px" }} className='new_input_filter new_input_normal_border' placeholder='From '
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.loanDurationInDaysFrom}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, loanDurationInDaysFrom: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                      <div >
                        <Input style={{ width: "100%", height: "40px" }} className='new_input new_input_filter new_input_normal_border' placeholder='To '
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.loanDurationInDaysTo}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, loanDurationInDaysTo: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Remaining&nbsp;loan&nbsp;maturity&nbsp;in&nbsp;days</div>
                    <div className='' style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                      <div>
                        <Input style={{ width: "100%", height: "40px" }} className='new_input_filter new_input_normal_border' placeholder='From '
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.remainingTermInDaysFrom}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, remainingTermInDaysFrom: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                      <div >
                        <Input style={{ width: "100%", height: "40px" }} className='new_input new_input_filter new_input_normal_border' placeholder='To '
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.remainingTermInDaysTo}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, remainingTermInDaysTo: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-flex-row-container ">
                <div className="filter-flex-column-small-gap filter-felid-width-style">
                    <div className="filter-product-label-style">Available loan amount</div>
                    <div className='' style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                      <div>
                        <Input style={{ width: "100%", height: "40px" }} className='new_input_filter new_input_normal_border' placeholder='From '
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.principalOfferFrom}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, principalOfferFrom: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                      <div >
                        <Input style={{ width: "100%", height: "40px" }} className='new_input new_input_filter new_input_normal_border' placeholder='To '
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={filter.principalOfferTo}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Regular expression to match only numeric values
                            const regex = /^[0-9]*$/;
                            if (regex.test(inputValue)) {
                              setFilter({ ...filter, principalOfferTo: inputValue });
                            }
                          }}
                        ></Input>
                      </div>
                    </div>
                  </div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style" ></div>
                  <div className="filter-flex-column-small-gap filter-felid-width-style" ></div>                 
                   <div className="filter-flex-column-small-gap filter-felid-width-style" ></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                  <div style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}>
                    <div>
                      <Checkbox
                        checked={filter.excludeAlreadyInvested}
                        onChange={() => setFilter({ ...filter, excludeAlreadyInvested: !filter.excludeAlreadyInvested })}
                      ></Checkbox>
                    </div>
                    <div style={{ fontSize: "14px", lineHeight: "24px", fontWeight: "400", color: "#242424" }}>Exclude already deployed</div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}>
                    <div>
                      <Checkbox
                        checked={filter.buybackOnly}
                        onChange={() => setFilter({ ...filter, buybackOnly: !filter.buybackOnly })}
                      ></Checkbox>
                    </div>
                    <div style={{ fontSize: "14px", lineHeight: "24px", fontWeight: "400", color: "#242424" }}>Show loans only with Buyback obligation</div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "24px" }}>
                  <div>
                    <Button
                      type="primary"
                      block
                      htmlType="submit"
                      className='new_button_filled'
                      style={{ height: '40px', width: "130px" }}
                      onClick={() => { handleSearch(); setSearchStatus(true) }}
                    >
                      Apply filter
                    </Button>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center",cursor:"pointer" }}>
                    <div style={{ height: "24px", fontSize: "16px", lineHeight: "24px", fontWeight: "400", color: "#242424", display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "1px solid #24242426" }} onClick={() => { handleClose() }}>
                      Reset
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>

  )
}