import React, { useEffect, useState } from "react";
import "./style.css";
import {
  Card,
  message,
  Button,
  Table,
  Pagination,
  Input,
  Select,
  Modal,
} from "antd";
import { GetProfile, HandelError, clientApi } from "../../Utils/AxiosCall";
import ReactCountryFlag from "react-country-flag";
import countries from "i18n-iso-countries";
import MyPortfolioFilter from "../../Components/MyPortfolioFilter/MyPortfolioFilter";
import {
  discountOptions,
  PageSizeList1,
} from "../../Helper_Data/SelectBoxData";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/portfolio_page_logo.svg";
import { FiSliders } from "react-icons/fi";
import InvestSellSuccessModal from "../../Components/SuccessModal/InvestSellSuccessModal";
import {
  AmountConverter,
  StablecoinToUSDConverter,
  StablecoinWarperFunctionWithOutFormatting,
  newNumberformat,
  newNumberformatWithFraction,
} from "../../Helper/HelperFunction";
import { useSelector } from "react-redux";
import HeaderLabelTwo from "../../Components/Components/Atom/HeaderLabel/HeaderLabelTwo";
import PageHeader from "../../Components/Components/Molecule/PageHeader/PageHeader";
import PortfolioIcon from "../../Assets/images/Portfolio-icon.svg";
import chartIcon from "../../Assets/images/finance-chart-icon.svg";
import excalIcon from "../../Assets/images/excal-icon-port.svg";
import close_icon from "../../Assets/images/IconButton.svg";
import errorIcon from "../../Assets/images/error-icon-market-box.svg";
import checkIcons from "../../Assets/images/check-icon-invest.svg";
import { MdArrowDropDown } from "react-icons/md";
import { Tooltip } from "antd";
import BarChartCard from "../../Components/Components/Atom/BarChartCard/BarChartCard";
import DoughnutChartCard from "../../Components/Components/Atom/DoughnutChartCard/DoughnutChartCard";
import MyPortfolioFilterNew from "../../Components/MyPortfolioFilter/MyPortfolioFilterNew";
import { transformData } from "../../Helper/SelectBoxDataGenrater";
import { MetaMaskAvatar } from "react-metamask-avatar";
import {
  IoIosInformationCircleOutline,
  IoMdArrowDropdown,
} from "react-icons/io";
import { AiOutlineEye } from "react-icons/ai";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default function MyPortfolio() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [data, setData] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  var currencyCode = useSelector((state) => state.global.SelectedStableCoin);
  const [filters, setFilters] = useState({
    autoInvestId: null,
    collectionStatuses: [],
    countries: [],
    currencyCode: currencyCode,
    extensions: null,
    forSale: false,
    interestRatePercentFrom: null,
    interestRatePercentTo: null,
    investStrategyId: null,
    loanId: null,
    originators: [],
    products: [],
    remainingTermInDaysFrom: null,
    remainingTermInDaysTo: null,
    showActive: false,
    showClosed: false,
    walletAddresses: [],
    loanDurationInDaysTo: null,
    loanDurationInDaysFrom: null,
  });
  const [chartData, setChartData] = useState({});

  const filter = {};
  const CheckFilter = async () => {
    try {
      if (filters.loanId) filter.loanId = filters.loanId;
      if (filters.collectionStatuses.length > 0)
        filter.collectionStatuses = filters.collectionStatuses;
      if (filters.countries.length > 0) filter.countries = filters.countries;
      if (filters.currencyCode) filter.currencyCode = filters.currencyCode;
      if (filters.extensions !== null) filter.extensions = filters.extensions;
      if (filters.interestRatePercentFrom !== null)
        filter.interestRatePercentFrom = filters.interestRatePercentFrom;
      if (filters.interestRatePercentTo !== null)
        filter.interestRatePercentTo = filters.interestRatePercentTo;
      if (filters.showActive !== null) filter.showActive = filters.showActive;
      if (filters.showClosed !== null) filter.showClosed = filters.showClosed;
      if (filters.remainingTermInDaysFrom !== null)
        filter.remainingTermInDaysFrom = filters.remainingTermInDaysFrom;
      if (filters.remainingTermInDaysTo !== null)
        filter.remainingTermInDaysTo = filters.remainingTermInDaysTo;
      if (filters.autoInvestId) filter.autoInvestId = filters.autoInvestId;
      if (filters.investStrategyId)
        filter.investStrategyId = filters.investStrategyId;
      if (filters.forSale !== null) filter.forSale = filters.forSale;
      if (filters.products.length > 0) filter.products = filters.products;
      if (filters.originators.length > 0)
        filter.originators = filters.originators;
      if (filters.walletAddresses.length > 0)
        filter.walletAddresses = filters.walletAddresses;
      if (filters.loanDurationInDaysFrom !== null)
        filter.loanDurationInDaysFrom = filters.loanDurationInDaysFrom;
      if (filters.loanDurationInDaysTo !== null)
        filter.loanDurationInDaysTo = filters.loanDurationInDaysTo;

      return filter;
    } catch (error) {
      console.log(error);
    }
  };
  const [labelStatus, setLabelStatus] = useState({
    active: false,
    forSale: false,
    closed: false,
  });
  const GetMyPortfolio = async () => {
    setLoader(true);
    await CheckFilter();
    if (!filter.currencyCode) {
      filter.currencyCode = currencyCode;
    }
    let newFilterData = { ...filter };
    if (
      filter.showActive === false &&
      filter.showClosed === false &&
      filter.forSale === false
    ) {
      const { showActive, showClosed, forSale, ...remainingFilters } =
        newFilterData;
      newFilterData = remainingFilters;
    }
    if (
      filter.showActive === true &&
      filter.showClosed === true &&
      filter.forSale === true
    ) {
      const { showActive, showClosed, forSale, ...remainingFilters } =
        newFilterData;
      newFilterData = remainingFilters;
    }
    try {
      const response = await clientApi.post(
        "/api/investor/query-my-investments",
        { filter: newFilterData, page, pageSize }
      );
      if (response?.status === 200) {
        console.log(response.data);
        setData(response.data);
        setTotalItems(response?.data?.total);
        setLabelStatus({
          active: filters.showActive,
          forSale: filters.forSale,
          closed: filters.showClosed,
        });
        if (isModalOpen) {
          setIsModalOpen(false);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      HandelError(error);
    }
    try {
      const response = await clientApi.post(
        "/api/investor/portfolio-statistics",
        { filter: newFilterData, page, pageSize }
      );
      if (response?.status === 200) {
        console.log(response.data);
        setChartData(response.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      HandelError(error);
    }
  };
  const exportData = async () => {
    setLoader(true);
    if (!filter.currencyCode) {
      filter.currencyCode = currencyCode;
    }
    try {
      const response = await clientApi.post(
        "/api/investor/download-my-investments",
        { filter: filter, page, pageSize },
        { responseType: "arraybuffer" }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const filename = response.headers["x-filename"];
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
      HandelError(error);
    } finally {
      setLoader(false);
    }
  };
  const [counts, setCounts] = useState({
    active: 0,
    forSale: 0,
    closed: 0,
  });
  const [accountOverviewData, setAccountOverviewData] = useState({});
  const GetAccountOverview = async () => {
    try {
      // EUR || USD
      const response = await clientApi.post("/api/investor/account-summary", {
        currencyCode: currencyCode,
      });
      console.log(response.data);
      if (response?.status === 200) {
        setAccountOverviewData(response.data);
      }
    } catch (error) {
      HandelError(error);
    }
  };
  const GetTheCount = async () => {
    try {
      const activeResponse = await clientApi.post(
        "/api/investor/query-my-investments",
        {
          filter: {
            currencyCode: currencyCode,
            showActive: true,
            forSale: false,
            showClosed: false,
          },
          page: 1,
          pageSize: 1,
        }
      );
      setCounts((counts) => ({
        ...counts,
        active: activeResponse?.data?.total || 0,
      }));
    } catch (error) {
      // HandleError(error);
    }
    try {
      const forSaleResponse = await clientApi.post(
        "/api/investor/query-my-investments",
        {
          filter: {
            currencyCode: currencyCode,
            showActive: false,
            forSale: true,
            showClosed: false,
          },
          page: 1,
          pageSize: 1,
        }
      );
      setCounts((counts) => ({
        ...counts,
        forSale: forSaleResponse?.data?.total || 0,
      }));
    } catch (error) {
      // HandleError(error);
    }
    try {
      const closedResponse = await clientApi.post(
        "/api/investor/query-my-investments",
        {
          filter: {
            currencyCode: currencyCode,
            showActive: false,
            forSale: false,
            showClosed: true,
          },
          page: 1,
          pageSize: 1,
        }
      );
      setCounts((counts) => ({
        ...counts,
        closed: closedResponse?.data?.total || 0,
      }));
    } catch (error) {
      // HandleError(error);
    }
  };
  const [referenceData, setReferenceData] = useState({});
  const [countries_list, setCountries_list] = useState([]);
  const [search_listener, setSearch_listener] = useState(0);
  const [originators_list, setOriginators_list] = useState([]);
  const [collectionStatuses_list, setCollectionStatuses_list] = useState([]);
  const [products_list, setProducts_list] = useState([]);
  const GetReferenceData = async () => {
    try {
      const response = await clientApi.post("/api/investor/reference-data");
      if (response?.status === 200) {
        console.log(response.data);
        setReferenceData(response.data);
        const countries = await transformData(response.data.countries);
        const originators = await transformData(response.data.originators);
        const collectionStatuses = await transformData(
          response.data.collectionStatuses
        );
        const products = await transformData(response.data.products);
        try {
          await setCountries_list(countries);
          await setOriginators_list(originators);
          await setCollectionStatuses_list(collectionStatuses);
          await setProducts_list(products);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      HandelError(error);
    }
  };
  useEffect(() => {
    // GetMyPortfolio()
    ListALLData();
    GetProfile();
    GetAccountOverview();
    GetTheCount();
    GetReferenceData();
  }, []);
  const SubComponent = ({ data }) => {
    console.log(accountOverviewData.principalInvested);
    return (
      <>
        <HeaderLabelTwo
          HeaderLabel="Deployed balance"
          data={
            accountOverviewData?.principalInvested
              ? newNumberformat(accountOverviewData?.principalInvested)
              : 0
          }
          defaultValue="0"
        />
        <HeaderLabelTwo
          HeaderLabel="Ongoing"
          defaultValue="0"
          data={newNumberformat(counts?.active)}
        />
        <HeaderLabelTwo
          HeaderLabel="For sale"
          defaultValue="0"
          data={newNumberformat(counts?.forSale)}
        />
        <HeaderLabelTwo
          HeaderLabel="Closed/Sold"
          defaultValue="0"
          data={newNumberformat(counts?.closed)}
        />
      </>
    );
  };
  const TableHeaderLeftValue = ({ label, data }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
          alignItems: "center",
        }}
      >
        <div className="new-table-container-header-label">{label}</div>
        <div className="new-table-container-header-bold">
          {newNumberformat(data || 0)}
        </div>
      </div>
    );
  };
  const TableHeaderRightValue = ({ label, imguri, onClick }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => onClick()}
      >
        <div className="new-table-container-header-label">
          <img
            src={imguri}
            style={{ width: "24px", height: "24px" }}
            alt="icon"
          />
        </div>
        <div className="new-table-container-header-link">{label}</div>
      </div>
    );
  };
  const [openChart, setOpenChart] = useState(false);
  const clearFlitter = async () => {
    await setFilters({
      autoInvestId: null,
      collectionStatuses: [],
      countries: [],
      currencyCode: filters?.currencyCode || currencyCode,
      extensions: null,
      forSale: false,
      interestRatePercentFrom: null,
      interestRatePercentTo: null,
      investStrategyId: null,
      loanId: null,
      originators: [],
      products: [],
      remainingTermInDaysFrom: null,
      remainingTermInDaysTo: null,
      showActive: false,
      showClosed: false,
      walletId: "",
      walletAddresses: [],
      loanDurationInDaysTo: null,
      loanDurationInDaysFrom: null,
    });
  };

  const ResetData = async () => {
    setPage(1);
    if (!filter.currencyCode) {
      filter.currencyCode = currencyCode;
    }
    try {
      const response = await clientApi.post(
        "/api/investor/query-my-investments",
        { filter: { currencyCode: currencyCode }, page, pageSize }
      );
      if (response?.status === 200) {
        console.log(response.data);
        setData(response.data);
        setTotalItems(response?.data?.total);
        setLabelStatus({
          showActive: false,
          forSale: false,
          showClosed: false,
        });
        if (isModalOpen) {
          setIsModalOpen(false);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      HandelError(error);
    }
    try {
      const response = await clientApi.post(
        "/api/investor/portfolio-statistics",
        {
          filter: {
            currencyCode: currencyCode,
            showActive: true,
            forSale: false,
            showClosed: false,
          },
          page,
          pageSize,
        }
      );
      if (response?.status === 200) {
        setFilters({
          ...filters,
          showActive: false,
          showClosed: false,
          forSale: false,
        });
        setLabelStatus({ showActive: true, forSale: false, showClosed: false });
        console.log(response.data);
        setChartData(response.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      HandelError(error);
    }
    clearFlitter();
  };
  const ListALLData = async () => {
    setPage(1);
    try {
      const response = await clientApi.post(
        "/api/investor/query-my-investments",
        { filter: { currencyCode: currencyCode }, page, pageSize }
      );
      if (response?.status === 200) {
        console.log(response.data);
        setData(response.data);
        setTotalItems(response?.data?.total);
        setFilters({
          ...filters,
          showActive: null,
          showClosed: null,
          forSale: null,
        });
        setLabelStatus({
          showActive: false,
          forSale: false,
          showClosed: false,
        });
        if (isModalOpen) {
          setIsModalOpen(false);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      HandelError(error);
    }
    try {
      const response = await clientApi.post(
        "/api/investor/portfolio-statistics",
        { filter: { currencyCode: currencyCode }, page, pageSize }
      );
      if (response?.status === 200) {
        console.log(response.data);
        setFilters({
          ...filters,
          showActive: false,
          showClosed: false,
          forSale: false,
        });
        setChartData(response.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      HandelError(error);
    }
  };
  return (
    <div>
      <>
        <PageHeader
          title="Portfolio"
          image={PortfolioIcon}
          subComponent={<SubComponent data={data} />}
        />

        <div style={{ backgroundColor: "#FFFFFFA6", width: "100%" }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div
              style={{ padding: "0px 48px", width: "100%", maxWidth: "1700px" }}
            >
              <MyPortfolioFilterNew
                filters={filters}
                setFilters={setFilters}
                countries_list={countries_list}
                products_list={products_list}
                collectionStatuses_list={collectionStatuses_list}
                originators_list={originators_list}
                handleSearch={GetMyPortfolio}
                totalCount={data}
                clearFlitter={clearFlitter}
                ResetData={ResetData}
                ListALLData={ListALLData}
                labelStatus={labelStatus}
              />
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "white", marginTop: "1px" }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div
              style={{ padding: "0px 48px", width: "100%", maxWidth: "1700px" }}
            >
              <div style={{ height: "24px" }}></div>
              <div className="new-table-container-header">
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "32px" }}
                >
                  <TableHeaderLeftValue
                    label={"Total deployed:"}
                    data={data?.totalOutstanding}
                  />
                  <TableHeaderLeftValue
                    label={"Total interest income:"}
                    data={data?.totalInterestReceived}
                  />
                </div>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "32px" }}
                >
                  <TableHeaderRightValue
                    label={"Show charts"}
                    imguri={chartIcon}
                    onClick={() => setOpenChart(true)}
                  />
                  <TableHeaderRightValue
                    label={"Export to Excel"}
                    imguri={excalIcon}
                    onClick={() => exportData()}
                  />
                </div>
              </div>
              <div style={{ height: "24px" }}></div>
              <Tables
                currencyCode={currencyCode}
                GetTheCount={GetTheCount}
                data={data?.items}
                setPageSize={setPageSize}
                pageSize={pageSize}
                totalItems={totalItems}
                setPage={setPage}
                page={page}
                loader={loader}
                GetMyPortfolio={GetMyPortfolio}
              />
            </div>
          </div>
        </div>
        <ChartComponent
          setOpen={setOpenChart}
          open={openChart}
          data={data}
          chartData={chartData}
          referenceData={referenceData}
          investedFunds={accountOverviewData?.principalInvested}
        />
        <MyPortfolioFilter
          filters={filters}
          setFilters={setFilters}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          GetMyPortfolio={GetMyPortfolio}
        />
      </>
    </div>
  );
}
const Tables = ({
  currencyCode,
  GetTheCount,
  data,
  setPageSize,
  pageSize,
  totalItems,
  setPage,
  page,
  loader,
  GetMyPortfolio,
}) => {
  const [openSellModal, setOpenSellModal] = useState(false);
  const [openSellSuccessModal, setOpenSellSuccessModal] = useState(false);
  const GetAgreement = async (fileReference) => {
    try {
      const response = await clientApi.get(
        `/api/guest/download/${fileReference}`,
        { responseType: "arraybuffer" }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      let filename = "";
      const contentDisposition = response.headers["content-disposition"];
      if (contentDisposition) {
        const matches = contentDisposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        );
        if (matches && matches[1]) {
          filename = matches[1].trim().replace(/['"]/g, ""); // Remove surrounding quotes and trim whitespace
        }
      }

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename || "Agreement"; // Set a default filename if not provided
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
      HandelError(error);
    }
  };
  function shortenAddress(data) {
    return `${data?.substring(0, 4)}...${data?.substring(data?.length - 4)}`;
  }
  const columns = [
    {
      title: "Country",
      dataIndex: "countryCode",
      key: "countryCode",
      align: "center",
      width: 101,
      render: (ele, key) => {
        const countryName = countries.getName(ele, "en", {
          select: "official",
        });
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <ReactCountryFlag countryCode={ele} svg />
            </div>
            <div>{countryName}</div>
          </div>
        );
      },
    },
    {
      title: "Investment id",
      dataIndex: "investmentId",
      key: "investmentId",
      align: "center",
      width: 101,
    },
    {
      title: "Loan id",
      dataIndex: "loanId",
      key: "loanId",
      align: "center",
      width: 101,
      render: (text, record) => (
        <div>
          <div className="" style={{ fontSize: "14px" }}>
            <Link
              className="primary-color"
              style={{ textDecoration: "none" }}
              to={`/user/loan/${record.loanId}`}
            >
              <span style={{ borderBottom: "1px solid #EC441F33" }}>
                {record.loanId}
              </span>
            </Link>{" "}
          </div>
          <div className="table-column-color" style={{ fontSize: "12px" }}>
            {record.productLabel}
          </div>
          <a
            style={{ textDecoration: "none", color: "black" }}
            onClick={() => GetAgreement(record.agreementFileReference)}
          >
            <span
              className="primary-color"
              style={{ fontSize: "12px", borderBottom: "1px solid #EC441F33" }}
            >
              Agreement
            </span>{" "}
          </a>
        </div>
      ),
    },
    {
      title: "Lender",
      dataIndex: "originatorCompanyName",
      key: "originatorCompanyName",
      align: "center",
      width: 101,
    },
    {
      title: "Interest ",
      dataIndex: "interestRatePercent",
      key: "interestRatePercent",
      align: "center",
      width: 101,
      render: (text, record) => (
        <div>
          <span>{newNumberformat(record.interestRatePercent) || 0}&nbsp;%</span>
        </div>
      ),
    },
    {
      title: "Deployed balance",
      dataIndex: "principalInvested",
      key: "principalInvested",
      align: "center",
      width: 101,
      render: (text, record) => (
        <div>
          <span>{newNumberformat(record.principalInvested) || 0}</span>
        </div>
      ),
    },
    {
      title: "Deployment date",
      dataIndex: "investmentDate",
      key: "investmentDate",
      align: "center",
      width: 106.9,
    },
    {
      title: "Linked wallet",
      dataIndex: "LinkedWallet",
      key: "LinkedWallet",
      align: "center",
      width: 106.9,
      render: (text, record) => (
        <div>
          {record?.walletAddress ? (
            <span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <MetaMaskAvatar address={record?.walletAddress} size={24} />
                {shortenAddress(record?.walletAddress)}
              </div>
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      title: "Remaining duration",
      dataIndex: "investmentDate",
      key: "investmentDate",
      align: "center",
      width: 101,
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {record.termInDays >= 0 ? (
            <>
              <div>
                {record.termPeriod ? (
                  <>
                    {record.termPeriod.years ? (
                      <>{record.termPeriod.years}y&nbsp;</>
                    ) : (
                      <></>
                    )}
                    {record.termPeriod.months ? (
                      <>{record.termPeriod.months}m&nbsp;</>
                    ) : (
                      <></>
                    )}
                    {record.termPeriod.days ? (
                      <>{record.termPeriod.days}d</>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : record.termInDays < 0 ? (
            <>
              <div class="text-danger-1">{record.termInDays}d</div>
            </>
          ) : (
            ""
          )}

          {/* <div className='table-column-color' >{record.collectionStatus}</div> */}
        </div>
      ),
    },
    {
      title: "Loan maturity date",
      dataIndex: "maturityDate",
      key: "maturityDate",
      align: "center",
      width: 106.9,
    },
    {
      title: "Collection Status",
      dataIndex: "collectionStatus",
      key: "collectionStatus",
      align: "center",
      width: 106.9,
      render: (text, record) => (
        <div>
          <span>{record.collectionStatus}</span>
        </div>
      ),
    },
    // {
    //   title: 'Outstanding/ Invested',
    //   dataIndex: 'principalOutstanding',
    //   key: 'principalOutstanding',
    //   align: 'center',
    //   width:101,
    //   render: (text, record) => (
    //     <div >
    //       <span><AmountConverter value={record.principalOutstanding} /></span>
    //       <div className='table-column-color'><AmountConverter value={record.principalInvested} /></div>
    //     </div>
    //   ),
    // },
    {
      title: "Interest income",
      dataIndex: "interestReceived",
      key: "interestReceived",
      align: "center",
      width: 101,
      render: (text, record) => (
        <div>
          <span>{newNumberformat(record.interestReceived)}</span>
        </div>
      ),
    },
    {
      title: (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            For&nbsp;Sale
            <Tooltip title="Sale price and discount/ premium">
              <IoIosInformationCircleOutline
                style={{ marginLeft: 4, cursor: "pointer" }}
                size={16}
              />
            </Tooltip>
          </div>
        </>
      ),
      dataIndex: "smOfferPrincipalAvailable",
      key: "smOfferPrincipalAvailable",
      align: "center",
      width: 101,
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          {record.smOfferPrincipalAvailable ? (
            <>
              <span>{newNumberformat(record.smPrice)} </span>
              <p style={{ fontSize: "12px", margin: "0" }}>
                {record.smDiscountOrPremiumPercent > 0 ? (
                  <>
                    <span style={{ color: "var(--success-color)" }}>
                      {record.smDiscountOrPremiumPercent
                        ? newNumberformat(record.smDiscountOrPremiumPercent)
                        : "0.0"}
                      %
                    </span>
                  </>
                ) : record.smDiscountOrPremiumPercent < 0 ? (
                  <>
                    <span class="text-danger-1">
                      {record.smDiscountOrPremiumPercent
                        ? newNumberformat(record.smDiscountOrPremiumPercent)
                        : "0.0"}
                      %
                    </span>
                  </>
                ) : (
                  "0.0%"
                )}
              </p>
            </>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "Action",
      key: "Action",
      align: "center",
      width: 65,
      render: (ele, key) => (
        <>
          {key.smOfferPrincipalAvailable > 0 ? (
            <Button
              onClick={() => {
                handleCancelModal(key);
              }}
              style={{ height: "40px", width: "91px" }}
              className="new_button_filled_cancel"
            >
              Cancel
            </Button>
          ) : key.principalOutstanding > 0 ? (
            <Button
              onClick={() => {
                handleSellModalOpen(key);
              }}
              style={{ height: "40px", width: "91px" }}
              className="new_button_filled-font-12px"
            >
              Sell
            </Button>
          ) : (
            <Button
              style={{ height: "40px", width: "91px" }}
              className="new_button_filled_disabled"
              disabled
            >
              Sold
            </Button>
          )}
        </>
      ),
    },
  ];
  const handlePageChange = async (pageNumber) => {
    console.log(pageNumber);
    await setPage(pageNumber);
  };

  const handlePageSizeChange = async (newSize) => {
    // console.log(newSize);
    await setPageSize(newSize);
  };
  useEffect(() => {
    GetMyPortfolio();
  }, [page, pageSize]);
  const [amount, setAmount] = useState("");
  const [discount, setDiscount] = useState(0);
  const [investorId, setInvestorId] = useState("");
  const [errorSell, setErrorSell] = useState("");
  const [investorData, setInvestorData] = useState({}); // investment data of selected row
  const [initialAmount, setInitialAmount] = useState("0.00");
  const [openSellCancelsModal, setOpenSellCancelModal] = useState(false);

  const handleSellModalOpen = (data) => {
    console.log(data);
    setInvestorData(data);
    setInitialAmount(data.principalInvested);
    setAmount(data.principalInvested);
    setInvestorId(data.investmentId);
    setErrorSell("");
    setOpenSellModal(true);
  };
  const handleSellModalClose = () => {
    setAmount("");
    setDiscount(0);
    setInvestorId("");
    setOpenSellModal(false);
    setErrorSell("");
    setInvestorData({});
  };
  const handleSell = async () => {
    // console.log(amount)
    // console.log(investorData?.principalInvested)
    if (
      amount === "" ||
      amount === 0 ||
      amount === null ||
      amount === undefined ||
      amount === "0"
    ) {
      setErrorSell("Enter a valid amount");
      return;
    }
    if (Number(amount) > investorData?.principalInvested) {
      setErrorSell("maximum amount is " + investorData?.principalInvested);
      return;
    }
    // console.log(investorId, amountUSD, discount)
    try {
      const response = await clientApi.post("/api/investor/sell-investment", {
        investmentId: investorId,
        amount: amount,
        discountOrPremiumPercent: discount,
      });
      if (response?.status === 200) {
        handleSellModalClose();
        setOpenSellSuccessModal(true);
        GetMyPortfolio();
        GetTheCount();
      }
    } catch (error) {
      if (
        error?.response?.data?.validation?.fieldErrors?.amount ===
        "INVALID_VALUE"
      ) {
        message.error("Enter valid amount");
        // setErrorSell("Enter valid amount");
      } else {
        HandelError(error);
      }
    }
  };
  const handleCancelModal = (data) => {
    setInvestorData(data);
    setOpenSellCancelModal(true);
  };
  const handleCancelModalClose = (data) => {
    setAmount("");
    setDiscount(0);
    setInvestorId("");
    setOpenSellCancelModal(false);
    setErrorSell("");
    setInvestorData({});
    setNewAmount(0);
  };
  const handleCancel = async (data) => {
    try {
      const response = await clientApi.post("/api/investor/sell-investment", {
        investmentId: investorData?.investmentId,
        amount: 0,
        discountOrPremiumPercent: 0,
      });
      if (response?.status === 200) {
        message.success("Cancellation successful");
        setOpenSellCancelModal(false);
        GetMyPortfolio();
        GetTheCount();
      }
    } catch (error) {
      console.log(error);
      HandelError(error);
    }
  };
  const [disableButton, setDisableButton] = useState(true);
  const [newAmount, setNewAmount] = useState(0);
  const calculateAmount = () => {
    if (openSellModal) {
      console.log("Original Amount:", amount);
      console.log("Discount:", discount);
      const newAmount = amount + amount * (discount / 100);
      console.log("New Amount:", newAmount);
      setNewAmount(newAmount);
      if (newAmount >= 10) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
        message.error(
          `Transactions must be valued at 10 ${currencyCode} or more. Please adjust the amount to proceed`
        );
      }
    }
  };

  useEffect(() => {
    calculateAmount(discount);
  }, [amount, discount, openSellModal]);

  return (
    <div className="">
      <Table
        columns={columns}
        dataSource={data}
        loading={loader}
        responsive
        scroll={{ x: "max-content" }}
        pagination={false}
        showSizeChanger
        className="custom-table custom-padding-bottom-24"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: "24px",
          paddingBottom: "24px",
        }}
      >
        <div>
          <Pagination
            current={page}
            pageSize={pageSize}
            total={totalItems}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange}
            className="custom-pagination"
            pageSizeOptions={["10", "20", "50", "100"]}
            showSizeChanger={false}
          />
        </div>
        <div className="new_input_normal_border_pagination ">
          <Select
            placeholder={"All"}
            className="new_input_normal_border_pagination"
            options={PageSizeList1}
            style={{ width: "100%", height: "40px", paddingRight: "8px" }}
            dropdownStyle={{ zIndex: 1200 }}
            suffixIcon={<MdArrowDropDown color="black" size={24} />}
            // size={"large"}
            value={pageSize}
            onChange={handlePageSizeChange}
          />
        </div>
      </div>

      <Modal
        className="investment-modal"
        open={openSellModal}
        centered
        closable={false}
        footer={null}
        width={560}
        onCancel={() => handleSellModalClose()}
        style={{}}
      >
        <div style={{ padding: "8px", minHeight: "472px" }}>
          <div
            className="modal-close-icon"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img
              src={close_icon}
              alt="close"
              onClick={() => handleSellModalClose()}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div style={{ padding: "0px 26px 32px 26px" }}>
            <div className="invest-modal-header">Sell</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "48px",
                paddingBottom: "32px",
              }}
            >
              <div>
                <div className="invest-modal-subheader">Loan ID</div>
                <div className="invest-modal-subheader-value">
                  {investorData?.loanId}
                </div>
              </div>
              <div>
                <div className="invest-modal-subheader">Deployment date</div>
                <div className="invest-modal-subheader-value">
                  {investorData?.investmentDate}
                </div>
              </div>
              <div>
                <div className="invest-modal-subheader">Interest </div>
                <div className="invest-modal-subheader-value">
                  {newNumberformat(investorData?.interestRatePercent)} %
                </div>
              </div>
            </div>
            <div className="custom-select-box">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 ">Amount to Sell:</div>
                    <div className="col-12" style={{ paddingTop: "4px" }}>
                      {/* <Input style={{ width: "100%", height: "56px" }} className='new_input_filter new_input_normal_border' placeholder='Amount'
                      onKeyPress={(e) => {
                        if (!/[0-9.]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Regular expression to match only numeric values
                        const regex = /^[0-9]*\.?[0-9]*$/;
                        if (regex.test(inputValue)) {
                          setAmount(inputValue);
                        }
                      }}
                       value={amount}
                    ></Input> */}
                      <div
                        className={`secondary-market-input-disabled secondary-market-input-disabled-font ${
                          disableButton
                            ? "secondary-market-input-disabled-border"
                            : ""
                        }`}
                      >
                        {/* error-icon-market-box errorIcon*/}
                        <span>{newNumberformat(amount)}</span>{" "}
                        {disableButton ? (
                          <img src={errorIcon} alt="icon" />
                        ) : (
                          <img src={checkIcons} alt="icon" />
                        )}
                      </div>
                    </div>
                    {/* <div style={{fontWeight:"12px",fontWeight:"400",lineHeight:"16px",letterSpacing:"0.3px",color:"#161616B2",paddingTop:"4px"}}>
                  Amount deployed {initialAmount||"0,00"}
        </div> */}
                  </div>
                </div>
                <div className="col-12 custom-padding-top-24">
                  <div className="row">
                    <div className="col-12">Discount or Premium</div>
                    <div
                      className="col-12 custom-select-box new_select_normal_border"
                      style={{ paddingTop: "4px" }}
                    >
                      <Select
                        style={{ width: "100%", height: "56px" }}
                        options={discountOptions}
                        className="filter-felid-width-style new_input_normal_border filter-select-style"
                        value={discount}
                        defaultValue={0}
                        dropdownStyle={{ zIndex: 1200 }}
                        onChange={(e) => {
                          // calculateAmount(e);
                          setDiscount(e);
                        }}
                        suffixIcon={
                          <IoMdArrowDropdown color="black" size={14} />
                        }
                      ></Select>
                    </div>
                  </div>
                </div>
              </div>
              {errorSell ? (
                <p className="custom-padding-top-24" style={{ color: "red" }}>
                  {errorSell}
                </p>
              ) : (
                ""
              )}

              <div className="row" style={{ paddingTop: "40px" }}>
                <div className="col-md-12">
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    // loading={loader}
                    classNames={`${
                      disableButton
                        ? "new_button_filled_disabled"
                        : "new_button_filled"
                    }`}
                    // className='new_button_filled'
                    style={{ height: "48px" }}
                    onClick={() => {
                      handleSell();
                    }}
                    disabled={disableButton}
                  >
                    Sell for {newNumberformat(newAmount)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <CancelModal
        isOpen={openSellCancelsModal}
        handleCancel={handleCancelModalClose}
        callBack={handleCancel}
        data={investorData}
      />
      <InvestSellSuccessModal
        status={openSellSuccessModal}
        setStatus={setOpenSellSuccessModal}
      />
    </div>
  );
};

const ChartComponent = ({
  open,
  setOpen,
  data,
  chartData,
  referenceData,
  investedFunds,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine the flex direction based on the window width
  const flexDirection = windowWidth <= 1150 ? "column" : "row";
  console.log(chartData?.amountByCountry?.labels);
  console.log(chartData?.amountByCountry?.values);

  // Process lenders data to show top 4 and "Others"
  const { labels: lenderLabels = [], values: lenderValues = [] } =
    chartData?.amountByOriginator || {};
  const combinedLendersData = lenderLabels.map((label, index) => ({
    label,
    value: lenderValues[index],
  }));
  const sortedLendersData = combinedLendersData.sort(
    (a, b) => b.value - a.value
  );
  const topLenders = sortedLendersData.slice(0, 4);
  const othersLenders = sortedLendersData.slice(4);
  const othersLendersSum = othersLenders.reduce(
    (sum, lender) => sum + lender.value,
    0
  );
  const finalLenderLabels = topLenders
    .map((lender) => lender.label)
    .concat(othersLenders.length > 0 ? ["Others"] : []);
  const finalLenderValues = topLenders
    .map((lender) => lender.value)
    .concat(othersLenders.length > 0 ? [othersLendersSum] : []);

  // Process countries data to show top 4 and "Others"
  const { labels: countryLabels = [], values: countryValues = [] } =
    chartData?.amountByCountry || {};
  const combinedCountriesData = countryLabels.map((label, index) => ({
    label,
    value: countryValues[index],
  }));
  const sortedCountriesData = combinedCountriesData.sort(
    (a, b) => b.value - a.value
  );
  const topCountries = sortedCountriesData.slice(0, 4);
  const othersCountries = sortedCountriesData.slice(4);
  const othersCountriesSum = othersCountries.reduce(
    (sum, country) => sum + country.value,
    0
  );
  const finalCountryLabels = topCountries
    .map((country) => country.label)
    .concat(othersCountries.length > 0 ? ["Others"] : []);
  const finalCountryValues = topCountries
    .map((country) => country.value)
    .concat(othersCountries.length > 0 ? [othersCountriesSum] : []);

  const updatedLoanStatusLabels = chartData?.amountByLoanStatus?.labels?.map(
    (label) => {
      if (label === "Current") {
        return "Active";
      } else if (
        ["late 1-15", "late 16-30", "late 31-60", "late 61+"].includes(label)
      ) {
        return "Delayed";
      } else {
        return label;
      }
    }
  );

  return (
    <>
      <Modal
        className="investment-modal"
        open={open}
        centered
        closable={false}
        footer={null}
        width={1136}
        onCancel={() => setOpen(false)}
        style={{}}
      >
        <div style={{ padding: "8px", minHeight: "840px" }}>
          <div
            className="modal-close-icon"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img
              src={close_icon}
              alt="close"
              onClick={() => setOpen(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div style={{ padding: "0px 26px 32px 26px" }}>
            <div className="invest-modal-header">Charts</div>
            <div style={{ display: "flex", flexDirection: "row", gap: "48px" }}>
              <HeaderLabelTwo
                HeaderLabel="Deployed funds"
                data={newNumberformat(investedFunds || 0)}
              />
              <HeaderLabelTwo
                HeaderLabel="Claims"
                data={newNumberformat(data?.total || 0)}
              />
              <HeaderLabelTwo
                HeaderLabel="Total interest income"
                data={newNumberformat(data?.totalInterestReceived || 0)}
              />
              {/* <HeaderLabelTwo HeaderLabel="Total interest received" data={data?.totalInterestReceived||0}/> */}
            </div>
            <div style={{ height: "32px" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: flexDirection,
                gap: "24px",
              }}
            >
              <BarChartCard
                harder={"Interest"}
                labels={chartData?.amountByInterestRate?.labels}
                values={chartData?.amountByInterestRate?.values}
                footerLabel={"Weighted average:"}
                footerValue={`${newNumberformat(
                  chartData?.weightedAverageInterestRate || "0"
                )}%`}
              />
              <BarChartCard
                harder={"Maturity"}
                labels={chartData?.amountByRemainingTerm?.labels}
                values={chartData?.amountByRemainingTerm?.values}
                footerLabel={"Weighted average:"}
                footerValue={`${newNumberformat(
                  chartData?.weightedAverageRemainingTerm || "0"
                )} days`}
              />
            </div>
            <div style={{ height: "24px" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "24px",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <DoughnutChartCard
                label={"Loan status"}
                Chartlabels={updatedLoanStatusLabels}
                values={chartData?.amountByLoanStatus?.values}
              />
              <DoughnutChartCard
                label={"Lenders"}
                Chartlabels={finalLenderLabels}
                values={finalLenderValues}
              />
              <DoughnutChartCard
                label={"Countries"}
                Chartlabels={finalCountryLabels}
                values={finalCountryValues}
                referenceData={referenceData}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const CancelModal = ({ isOpen, handleCancel, callBack, data }) => {
  return (
    <>
      <Modal
        className="investment-modal"
        open={isOpen}
        centered
        closable={false}
        footer={null}
        width={560}
        onCancel={() => handleCancel()}
        style={{}}
      >
        <div style={{ padding: "8px" }}>
          <div
            className="modal-close-icon"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img
              src={close_icon}
              alt="close"
              onClick={() => handleCancel()}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div style={{ padding: "0px 26px 32px 26px" }}>
            <div className="invest-modal-header">Cancel sell</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "48px",
                paddingBottom: "32px",
              }}
            >
              <div>
                <div className="invest-modal-subheader">Loan ID</div>
                <div className="invest-modal-subheader-value">
                  {data?.loanId}
                </div>
              </div>
              <div>
                <div className="invest-modal-subheader">Start sell date</div>
                <div className="invest-modal-subheader-value">
                  {data?.investmentDate}
                </div>
              </div>
              <div>
                <div className="invest-modal-subheader">
                  Price and discount/premium
                </div>
                <div
                  className="invest-modal-subheader-value"
                  style={{ display: "flex", flexDirection: "row", gap: "8px" }}
                >
                  <div>
                    {newNumberformatWithFraction(
                      data?.smOfferPrincipalAvailable
                    )}
                  </div>
                  <div
                    className={`status-box-code status-box-code-label ${
                      data?.smDiscountOrPremiumPercent > 0
                        ? "status-box-code-background-error"
                        : data?.smDiscountOrPremiumPercent < 0
                        ? "status-box-code-background-success"
                        : data?.smDiscountOrPremiumPercent == 0
                        ? "status-box-code-background-zero"
                        : ""
                    }`}
                  >
                    {data?.smDiscountOrPremiumPercent == 0
                      ? "0,0"
                      : newNumberformat(data?.smDiscountOrPremiumPercent)}{" "}
                    %
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ paddingTop: "40px" }}>
              <div className="col-md-12">
                <Button
                  block
                  htmlType="submit"
                  // loading={loader}
                  className="new_button_filled_cancel"
                  style={{ height: "48px" }}
                  onClick={() => {
                    callBack();
                  }}
                >
                  Cancel sell
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
