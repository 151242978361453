import React from "react";
import {useDispatch, useSelector} from "react-redux";
import LoadingBar from "react-top-loading-bar";
import {pageTopLoader} from "../../Store/reducers/global";

const PageTopLoader = (value) => {
    const dispatch = useDispatch();
    const globalState = useSelector((state) => state?.global)?.pageTopLoader;
    return (
        <LoadingBar
            color="#1e8bc3"
            progress={globalState?.value}
            onLoaderFinished={() => dispatch(pageTopLoader(value))}
        />
    );
};

export default PageTopLoader;
