// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-up-error{
font-family: 'Inter';
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
font-variation-settings: 'slnt' 0;
color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/Components/Components/Atom/SingUpErorrCard/style.css"],"names":[],"mappings":"AAAA;AACA,oBAAoB;AACpB,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;AAChB,iCAAiC;AACjC,cAAc;AACd","sourcesContent":[".sign-up-error{\nfont-family: 'Inter';\nfont-size: 16px;\nfont-weight: 400;\nline-height: 24px;\ntext-align: left;\nfont-variation-settings: 'slnt' 0;\ncolor: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
