import React from 'react'
import './style.css'
import errorIcon from '../../../../Assets/images/error-icon-sign-up.svg'
const SingUpErrorCard = ({data}) => {
  return (
    <div style={{paddingTop:"24px",paddingBottom:"32px",width:"100%"}}>
      <div style={{padding:"12px 16px 12px 16px",display:"flex",flexDirection:"row",gap:"8px",backgroundColor:"#D02342",borderRadius:"6px",}}>
        <div style={{display:"flex",alignItems:"flex-start"}}>
          <img src={errorIcon} alt="icon" />
        </div>
        <div className='sign-up-error'>
          {data}
        </div>
      </div>
    </div>
  )
}

export default SingUpErrorCard
