import { Button, Col, Form, Input, Row, Typography } from "antd";
import React from "react";
import { useState } from "react";
import "./style.css";
import { clientApi } from "../../Utils/AxiosCall";
import { validateEmail } from "../../Helper/HelperFunction";
import SignUpInputBox from "../../Components/Components/Atom/SignUpInputBox/SignUpInputBox";
import { message } from "antd";

export default function ForgotPassword() {
  const [loader, setLoader] = useState(false);
  const [sendStatus, setSendStatus] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [error, setError] = useState({
    error: false,
    message: "",
  });
  const [validationError, setValidationError] = useState({
    email: {
      error: false,
      message: "",
      exists: false,
    },
  });
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const validateForm = () => {
    let isValid = true;
    let validationErrorData = {
      email: {
        error: false,
        message: "",
        exists: false,
      },
    };

    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Regular expression for password validation (at least one number)
    const passwordRegex = /.*[0-9].*/;

    if (!formData.email) {
      validationErrorData.email.error = true;
      validationErrorData.email.message = "Email is required";
      validationErrorData.email.exists = false;
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      validationErrorData.email.error = true;
      validationErrorData.email.message = "Invalid email format";
      validationErrorData.email.exists = false;
      isValid = false;
    }
    const hasError = Object.values(validationErrorData).some(
      (field) => field.error
    );

    // Update the error state based on validation results
    if (hasError) {
      setError({
        error: true,
        message: "Form cannot be submitted, see errors below",
      });
    } else {
      setError({
        error: false,
        message: "",
      });
    }
    setValidationError(validationErrorData);
    return isValid;
  };
  const onFinish = async (values) => {
    // e.preventDefault();
    console.log(formData, "formData");
    const isValid = validateForm();
    if (!isValid) return;

    setLoader(true);
    try {
      setLoader(true);
      setEmailId(values.email);
      const response = await clientApi.post(
        "/api/investor/public/send-reset-password-link",
        { email: values.email }
      );
      console.log(response);
      if (response?.status === 200) {
        setLoader(false);
        setSendStatus(true);
        // setError("")
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      setEmailId("");
      if (
        error?.response?.data?.validation?.fieldErrors?.email === "NOT_FOUND"
      ) {
        // setError("Email not found")
        message.error("Email is not registered");
        setValidationError({
          ...validationError,
          email: { error: true, message: "", exists: false },
        });
      }
    }
  };
  return (
    <div className="onboarding-page-container">
      <Row justify="center" align="middle" className="antd-row-height-style">
        <Col xs={24} sm={16} md={16} lg={10}>
          <center
            className="login-sign-up-heading"
            style={{ paddingTop: "3rem" }}
          >
            Forgot password
          </center>
          {!sendStatus ? (
            <>
              <div className="onboarding-page-form">
                <div>
                  <SignUpInputBox
                    label={"Email address"}
                    placeholder={"Your email address"}
                    isRequired={true}
                    value={formData.email}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e.target.value });
                    }}
                    validationError={validationError.email}
                  />
                </div>
                <div style={{ height: "32px" }}></div>
                <Button
                  // className="new_button_filled hight-56"
                  className={`${
                    !formData.email
                      ? "new_button_filled_disabled"
                      : "new_button_filled"
                  }   hight-56`}
                  style={{ width: "100%" }}
                  htmlType="submit"
                  onClick={() => onFinish(formData)}
                  loading={loader}
                  disabled={!formData.email}
                >
                  Send
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="onboarding-page-form ">
                <center>
                  <span className="text-style">
                    We have send you an email with a token, please click on the
                    link to reset your password.
                  </span>
                </center>
                <div
                  className=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "32px",
                    color: "tomato",
                  }}
                >
                  {/* {error} */}
                </div>
                <div className="">
                  <div
                    className="new_button_filled hight-56"
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    // htmlType="submit"
                    // loading={loader}
                  >
                    {emailId}
                  </div>
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}
